<template>
  <v-card>
    <v-toolbar dark color="primary">
      <template #extension>
        <v-tabs v-model="tabSelected" align-with-title>
          <v-tabs-slider color="yellow" />

          <v-tab v-for="tab in tabs" :key="tab.key">
            {{ tab.text }}
          </v-tab>
        </v-tabs>
      </template>

      <v-toolbar-title>
        {{
          $t('pages.users.modal.title', {
            userId: user.id,
            userEmail: user.email
          })
        }}
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-tabs-items v-model="tabSelected">
      <v-tab-item :key="0">
        <v-card flat>
          <v-card-text>
            <EditInformation @close="$emit('close')" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="1">
        <v-card flat>
          <v-card-text>
            <ChangePassword @close="$emit('close')" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="2">
        <v-card flat>
          <v-card-text>
            <BanForm @close="$emit('close')" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="3">
        <v-card flat>
          <v-card-text>
            <DeleteAccount @close="$emit('close')" />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="4">
        <v-card flat>
          <v-card-text>
            <SendMoney @close="$emit('close')" />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

import EditInformation from './ModalDetailsSections/EditInformation'
import ChangePassword from './ModalDetailsSections/ChangePassword'
import BanForm from './ModalDetailsSections/BanForm'
import DeleteAccount from './ModalDetailsSections/DeleteAccount'
import SendMoney from './ModalDetailsSections/SendMoney'

import {
  TAB_EDIT_INFORMATIONS,
  TAB_CHANGE_PASSWORD,
  TAB_BAN,
  TAB_DELETE_ACCOUNT,
  TAB_SEND_MONEY
} from '~/i18n/fr/pages/users.js'

const TABS = [
  TAB_EDIT_INFORMATIONS,
  TAB_CHANGE_PASSWORD,
  TAB_BAN,
  TAB_DELETE_ACCOUNT,
  TAB_SEND_MONEY
]

export default {
  components: {
    EditInformation,
    ChangePassword,
    BanForm,
    DeleteAccount,
    SendMoney
  },
  data: () => ({
    TAB_EDIT_INFORMATIONS,
    TAB_CHANGE_PASSWORD,
    TAB_BAN,
    TAB_DELETE_ACCOUNT,
    TAB_SEND_MONEY,

    tabSelected: 0
  }),
  computed: {
    ...mapState('user', ['user']),

    tabs() {
      return TABS.map((tab) => ({
        key: tab,
        text: this.$t(`pages.users.modal.tabs.${tab}`)
      }))
    }
  }
}
</script>
