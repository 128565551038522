import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_c('GiftActions')],1),_vm._v(" "),_c(VCardText,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c(VTabs,{attrs:{"centered":""},model:{value:(_vm.currentTabIndex),callback:function ($$v) {_vm.currentTabIndex=$$v},expression:"currentTabIndex"}},[_c(VTabsSlider,{attrs:{"color":"white"}}),_vm._v(" "),_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.key},[_vm._v("\n          "+_vm._s(tab.title)+"\n        ")])})],2)],1),_vm._v(" "),_c(VTabsItems,{model:{value:(_vm.currentTab.key),callback:function ($$v) {_vm.$set(_vm.currentTab, "key", $$v)},expression:"currentTab.key"}},[_c(VTabItem,{attrs:{"value":"active"}},[_c('RewardsList',{attrs:{"is-loading":_vm.isLoading,"is-loaded":_vm.isLoaded,"error":_vm.error,"rewards":_vm.availableRewards,"no-reward-text":_vm.$t('pages.program.catalog.availableRewards.noneFound')},on:{"retry":_vm.fetchRewards}})],1),_vm._v(" "),_c(VTabItem,{attrs:{"value":"inactive"}},[_c('RewardsList',{attrs:{"is-loading":_vm.isLoading,"is-loaded":_vm.isLoaded,"error":_vm.error,"rewards":_vm.unavailableRewards,"no-reward-text":_vm.$t('pages.program.catalog.unavailableRewards.noneFound')},on:{"retry":_vm.fetchRewards}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }