import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"scrollable":"","width":"900"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"text":"","color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("\n      "+_vm._s(_vm.$t('pages.user.profile.pot.seeDetails'))+"\n    ")])]}}]),model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_vm._v(" "),_c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_c('span',{staticClass:"font-weight-bold"},[_vm._v("\n          "+_vm._s(_vm.$t('pages.user.profile.pot.modal.title'))+"\n        ")])]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VToolbarItems,[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.isDialogOpen = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_vm._v(" "),_c(VCardText,[_c('div',{staticClass:"pt-5 pb-3"},[_c('h3',[_vm._v("\n          "+_vm._s(_vm.$t('pages.user.profile.pot.modal.currentlyInMyPot'))+"\n        ")]),_vm._v(" "),_c(VList,{attrs:{"outlined":"","two-line":""}},[(_vm.isPotLoading)?_c(VSkeletonLoader,{attrs:{"type":"list-item-avatar-two-line"}}):(_vm.error)?_c('DisplayErrorV2',{attrs:{"error":_vm.error,"retriable":""},on:{"retry":_vm.fetchFirstTransfer}}):(_vm.isPotLoaded)?[(_vm.noPotRefunds)?_c(VListItem,[_vm._v("\n              "+_vm._s(_vm.$t('pages.user.profile.pot.modal.noRefund'))+"\n            ")]):[_vm._l((_vm.potRefunds),function(potRefund){return _c('ListPotRefundsItem',{key:potRefund.id,attrs:{"pot-refund":potRefund}})}),_vm._v(" "),_vm._l((_vm.otherAppPotRefunds),function(app){return _c('ListPotRefundsOtherAppItem',{key:app.id,attrs:{"app":app}})})]]:_vm._e()],2)],1),_vm._v(" "),(_vm.isPotLoaded)?_vm._l((_vm.transferPayments),function(transferPayment,index){return _c('TransferPaymentDetails',{key:transferPayment.id,staticClass:"pb-3",attrs:{"transfer-payment":transferPayment,"is-last":index + 1 === _vm.transferPayments.length}})}):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }