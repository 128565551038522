<template>
  <v-stepper v-model="currentStep">
    <v-stepper-items>
      <v-stepper-content
        v-for="n in stepsCount"
        :key="`stepper-content-${n}`"
        :step="n"
      >
        <ValidationObserver :ref="`step-${n}`" slim>
          <slot :name="`step-${n}`" />
        </ValidationObserver>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
export default {
  components: { ValidationObserver },
  props: {
    stepsCount: {
      type: Number,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    }
  },
  methods: {
    validateCurrentStep() {
      return this.$refs[`step-${this.currentStep}`][0].validate()
    }
  }
}
</script>
