import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutUser',[_c('div',{staticClass:"layout-declarations d-flex fill-height"},[_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(_vm.canShowGallery),expression:"canShowGallery"}],staticClass:"layout-declarations__fab white--text",attrs:{"color":"primary","fab":"","small":"","fixed":"","bottom":"","right":""},on:{"click":_vm.toggleGalleryColumn}},[_c(VIcon,[_vm._v("mdi-receipt")])],1),_vm._v(" "),_c('aside',{staticClass:"layout-declarations__aside layout-declarations__aside--left grey lighten-2",style:({
        flex: ("0 0 " + _vm.leftSideWidth + "px"),
        width: (_vm.leftSideWidth + "px")
      })},[_c('DeclarationsListFilter',{staticClass:"white pa-3 pt-5 pr-2 mr-1"}),_vm._v(" "),(_vm.error)?_c('DisplayError',{attrs:{"error":_vm.error,"with-line-return":"","retriable":""},on:{"retry":function($event){return _vm.fetchAll({ userId: _vm.$route.params.userId })}}}):(_vm.isLoading)?_c('DeclarationsListLoading'):(!_vm.isLoaded)?_c('div'):(_vm.noDeclarations)?_c('div',{staticClass:"ma-3"},[_c(VAlert,{attrs:{"type":"error"}},[_vm._v("\n          "+_vm._s(_vm._f("capitalize")(_vm.$t('pages.declarations.noDeclaration')))+"\n        ")])],1):_c('DeclarationsListLoaded')],1),_vm._v(" "),_c('main',{ref:"scroll-container",staticClass:"layout-declarations__main"},[_c('nuxt')],1),_vm._v(" "),_c(VDialog,{attrs:{"content-class":"layout-declarations__gallery","width":"500px","value":_vm.isGalleryShown},on:{"input":_vm.setIsGalleryShown}},[(_vm.hasOneSubpicture)?_c('DeclarationImage',{attrs:{"subpicture":_vm.subpictures[0]}}):_vm._e(),_vm._v(" "),(_vm.hasMoreThanOneSubpicture)?_c('DeclarationGallery',{attrs:{"subpictures":_vm.subpictures}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }