import env from './env'

export const originFromEnv = (env) => {
  switch (env) {
    case 'development':
      return 'http://localhost:15800'
    case 'staging':
      return 'https://staging-admin.purchease.com'
    case 'production':
      return 'https://admin.purchease.com'
    default:
      throw new Error(`unhandled env ${env}`)
  }
}

export default originFromEnv(env)
