import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"2"}},[_c(VToolbar,{attrs:{"color":!_vm.colorIsNil ? _vm.color : _vm.selectedProgram.dominant_color,"dark":!_vm.isBlack}},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"cursor-move"},'v-icon',attrs,false),on),[_vm._v("\n          mdi-drag-horizontal-variant\n        ")])]}}])},[_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('pages.program.catalog.shelf.reorderShelves'))+"\n      ")])]),_vm._v(" "),_c(VToolbarTitle,[_c(VTextField,{attrs:{"hide-details":"","dark":!_vm.isBlack,"background-color":!_vm.colorIsNil ? _vm.color : _vm.selectedProgram.dominant_color,"solo":"","flat":"","append-icon":"mdi-pencil"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateShelfRow(_vm.row.id)}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mr-5"},'div',attrs,false),on),[_c(VSwitch,{attrs:{"color":"#FFF","inset":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                "+_vm._s(_vm.titleColorText)+"\n              ")]},proxy:true}],null,true),model:{value:(_vm.isBlack),callback:function ($$v) {_vm.isBlack=$$v},expression:"isBlack"}})],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('pages.program.catalog.shelf.changeShelfTitleColor'))+"\n        ")])]),_vm._v(" "),_c(VMenu,{attrs:{"open-on-hover":"","offset-x":"","right":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
var attrsMenu = ref.attrs;
return [_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"mr-5"},[_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('div',_vm._g(_vm._b({},'div',attrsMenu,false),onMenu),[_c(VSwitch,{attrs:{"color":"#FFF","inset":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                        "+_vm._s(_vm.backgroundColortext)+"\n                      ")]},proxy:true}],null,true),model:{value:(_vm.colorIsNil),callback:function ($$v) {_vm.colorIsNil=$$v},expression:"colorIsNil"}})],1)])])]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n              "+_vm._s(_vm.$t('pages.program.catalog.shelf.changeShelfBackgroundColor'))+"\n            ")])])]}}])},[_vm._v(" "),_c(VCard,{directives:[{name:"show",rawName:"v-show",value:(!_vm.colorIsNil),expression:"!colorIsNil"}],attrs:{"light":""}},[_c(VColorPicker,{attrs:{"mode":"hexa","hide-mode-switch":""},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}})],1)],1)],1),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c('div',[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"loading":_vm.row.isSaving,"disabled":_vm.row.isDeleting,"icon":"","dark":""},on:{"click":function($event){return _vm.updateShelfRow(_vm.row.id)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-content-save")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('pages.program.catalog.shelf.saveChanges'))+"\n        ")])]),_vm._v(" "),_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"loading":_vm.row.isDeleting,"disabled":_vm.row.isSaving,"icon":"","dark":""},on:{"click":function($event){return _vm.removeShelfRow(_vm.row.id)}}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-delete")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('pages.program.catalog.shelf.deleteShelf'))+"\n        ")])]),_vm._v(" "),(_vm.hasWriteContentRight)?_c('AddReward',{attrs:{"row-id":_vm.row.id,"disabled":_vm.row.isDeleting}}):_vm._e()],1)],1),_vm._v(" "),_c(VCardText,[(_vm.noDisplayedRewards)?_c('div',[_vm._v("\n      "+_vm._s(_vm.$t('pages.program.catalog.shelf.noRewardsInShelf'))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.hasWriteContentRight)?_c('VueDraggable',{staticClass:"d-flex flex-nowrap overflow-x-auto",attrs:{"group":"reward"},on:{"change":_vm.onChange},model:{value:(_vm.rewards),callback:function ($$v) {_vm.rewards=$$v},expression:"rewards"}},_vm._l((_vm.rewards),function(reward){return _c('RewardItem',{key:("reward-container-" + (reward.id)),attrs:{"row-id":_vm.row.id,"reward":reward,"show-inactive-rewards":_vm.showInactiveRewards}})}),1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }