<template>
  <v-app>
    <v-navigation-drawer
      v-if="selectedProgram"
      v-model="drawer"
      :mini-variant.sync="isMenuMinimized"
      :clipped="clipped"
      fixed
      app
      permanent
    >
      <TheMenu @close="isMenuMinimized = !isMenuMinimized" />
      <TheSettings />
    </v-navigation-drawer>

    <!--
      56 for short toolbar
      48 for tabs
      // https://github.com/vuetifyjs/vuetify/blob/v2.2.10/packages/vuetify/src/components/VToolbar/VToolbar.ts#L65
      :extended="$scopedSlots['app-bar__extension']"
    -->
    <v-app-bar
      :clipped-left="clipped"
      :extension-height="extensionHeight"
      app
      fixed
      dark
      :short="!isMetrics"
      color="primary"
      class="v-toolbar--extension-without-padding z-index-app-bar"
    >
      <v-app-bar-nav-icon
        class="d-inline-flex d-lg-none"
        @click.stop="drawer = !drawer"
      />
      <!-- <v-btn @click.stop="isMenuMinimized = !isMenuMinimized" icon>
        <v-icon>mdi-{{ `chevron-${isMenuMinimized ? 'right' : 'left'}` }}</v-icon>
      </v-btn>
      <v-btn @click.stop="clipped = !clipped" icon>
        <v-icon>mdi-application</v-icon>
      </v-btn>
      <v-btn @click.stop="fixed = !fixed" icon>
        <v-icon>mdi-minus</v-icon>
      </v-btn> -->
      <v-toolbar-title>
        {{ title || defaultTitle }}
      </v-toolbar-title>
      <v-spacer />
      <!-- <v-btn @click.stop="rightDrawer = !rightDrawer" icon>
        <v-icon>mdi-menu</v-icon>
      </v-btn>-->

      <slot name="app-bar__right" />

      <template v-if="$scopedSlots['app-bar__extension']" #extension>
        <slot name="app-bar__extension" />
      </template>
    </v-app-bar>

    <v-main class="z-index-main">
      <slot v-if="$scopedSlots.default" />
      <nuxt v-else />
    </v-main>

    <TheSnackbar />

    <!-- z-index must be bigger than 5 (the one of the menu) -->
    <v-overlay :value="isRefreshing" z-index="10">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>

    <v-footer :fixed="fixed" app>
      <span>&copy; 2022</span>
      <v-spacer v-if="isLogged" />
      <span v-if="error" class="mr-3">
        {{ $t(`errors.known.${error}`) }}
      </span>
      <v-btn v-if="isLogged" :loading="isLoading" small rounded @click="logout">
        {{ $t('auth.logout.title') }}
      </v-btn>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import TheMenu from '@/components/layouts/TheMenu'
import TheSnackbar from '@/components/layouts/TheSnackbar'
import TheSettings from '@/components/layouts/TheSettings'

export default {
  components: {
    TheMenu,
    TheSnackbar,
    TheSettings
  },
  props: {
    extensionHeight: {
      type: [Number, String],
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      isFabMenuOpen: false,
      clipped: false,
      drawer: true,
      fixed: false
    }
  },
  computed: {
    ...mapState('auth', [
      'admin',
      'isLoading',
      'error',
      'selectedProgram',
      'isRefreshing'
    ]),
    ...mapGetters('auth', ['isLogged']),

    isMenuMinimized: {
      get() {
        return this.$store.state.layout.isMenuMinimized
      },
      set(value) {
        this.$store.commit('layout/setMenuMinimizedState', value)
      }
    },

    isMetrics() {
      const matchedRoute = this.$router.currentRoute.matched[0]
      if (!matchedRoute) return false

      const path = matchedRoute.path
      return path.includes('metrics')
    },

    defaultTitle() {
      const matchedRoute = this.$router.currentRoute.matched[0]
      if (!matchedRoute) {
        return this.$t('layout.title')
      }

      const path = matchedRoute.path
      if (path === '') return

      const translationKey = `pages.titles.${path}`
      if (this.$te(translationKey)) {
        return this.$t(translationKey)
      }

      this.$airbrakeNotify({
        error: new Error(
          `missing page title for path : ${path} (key: ${translationKey})`
        )
      })
      return this.$t('layout.title')
    }
  },
  methods: {
    ...mapActions('auth', ['logout'])
  }
}
</script>

<style scoped>
.z-index-main {
  z-index: 0;
}

.z-index-app-bar {
  z-index: 1;
}
</style>
