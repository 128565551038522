import {
  createLoadableState,
  LoadableState
} from '~/utils/store/concerns/loadable/state'

/* eslint-disable camelcase */
export interface Descriptions {
  tag_line: null | string
  catchphrase: null | string
  disclaimer_message: null | string
  description: null | string
  is_brand_description_image_visible: boolean
  brand_description: null | string
  brand_html_description: null | string
}
export interface Images {
  square_image_url: string
  background_image_url: string
}
/* eslint-enable camelcase */

export interface NotLoadableState {
  descriptions: null | Descriptions
  images: null | Images

  original: null | Descriptions
  modified: null | Descriptions
}
export interface State extends NotLoadableState, LoadableState {}

export default () =>
  createLoadableState({
    descriptions: null,
    images: null,

    original: null,
    modified: null
  })
