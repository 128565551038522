import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":_vm.width},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"elevation":"2","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-file-upload")])],1)]}}]),model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[_vm._v(" "),(_vm.isDialogOpen)?_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 primary white--text"},[_vm._v("\n      "+_vm._s(_vm.$t('layout.program.importProducts.title'))+"\n      "),_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.isDialogOpen = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_vm._v(" "),_c(VStepper,{model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c(VStepperHeader,[_vm._l((_vm.stepsCount),function(n){return [_c(VStepperStep,{key:("stepper-header-" + n),attrs:{"complete":_vm.currentStep > n,"editable":_vm.currentStep > n,"step":n}},[_vm._v("\n            "+_vm._s(_vm.titles[n])+"\n          ")]),_vm._v(" "),(n !== _vm.stepsCount)?_c(VDivider,{key:("stepper-divider-" + n)}):_vm._e()]})],2),_vm._v(" "),_c(VStepperItems,_vm._l((_vm.stepsCount),function(n){return _c(VStepperContent,{key:("stepper-content-" + n),attrs:{"step":n}},[_c(("Step" + n),{tag:"component",on:{"close":function($event){_vm.isDialogOpen = false},"go-to-prev-step":function($event){_vm.currentStep -= 1},"go-to-next-step":function($event){_vm.currentStep += 1}}})],1)}),1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }