export default {
  accesses: {
    title: 'Modifier les propriétés de visibilité à partir de maintenant',

    labels: {
      'openToNewSubscriptions?': 'Ouvert aux inscriptions ?',
      'openToNewcomers?': 'Accessible nouveaux users ?',
      renewPolicy: 'Politique de renouvellement',
      maxExpirationDate: "Date d'expiration max",
      fixedExpirationAt: "Date d'expiration fixée à"
    },

    buttons: {
      audience: 'Audiences',
      expirations: 'Expirations',
      addUser: 'Ajout Utilisateur'
    },

    noTimeline: "Pas de chronologie sur les conditions d'accès au programme",

    audience: {
      title: 'Audiences',

      calculationInProgress: 'Calcul en cours...',
      calculationLaunchedAt: 'Calcul lancé %{at}.',
      itCanTakeSeveralMinutes: 'Cela peut prendre plusieurs minutes.',
      youCanLeaveThePageAndComeBackLater:
        'Vous pouvez quitter la page et revenir plus tard.',

      calculationCachedAt: 'Calcul mis en cache %{at}.',
      calculationCacheExpiresAt: 'Expiration du cache %{at}.',

      usersWithVisibility: 'utilisateurs avec visibilité',
      whoseSubscribed: 'dont inscrits',
      usersWithoutVisibility: 'utilisateurs sans visibilité',

      actives3months: 'actifs les 3 derniers mois',
      inactives: 'inactifs depuis plus de 3 mois',
      churned: "inactifs depuis plus d'un an",

      unknownError: 'une erreur inconnue est survenue, nous allons enquêter'
    },
    expirations: {
      title: "Dates d'expiration des cartes",
      chartTitle: 'Décompte des expirations journalières'
    },
    addUser: {
      title: 'Ajouter un utilisateur au programme (VIP et interne)',
      validateButton: 'Valider'
    }
  }
}
