import { pick, omit } from 'lodash-es'

import env from './constants/env'

export const projectId =
  process.env.SAS_BACKOFFICE_FRONTEND_AIRBRAKE_PROJECT_ID ||
  process.env.AIRBRAKE_PROJECT_ID
export const projectKey =
  process.env.SAS_BACKOFFICE_FRONTEND_AIRBRAKE_PROJECT_KEY ||
  process.env.AIRBRAKE_PROJECT_KEY

export const instantiate = (Notifier) => {
  return new Notifier({
    projectId,
    projectKey,
    environment: env
  })
}

export const extend = (Notifier) =>
  class NotifierCommon extends Notifier {
    sanitizeReq(req) {
      if (!req) return

      return {
        hasCookies: !!req.headers.cookie,
        headers: omit(req.headers, 'cookie'),
        data: req.body,
        ...pick(req, [
          'httpVersion',
          'method',
          'statusCode',
          'statusMessage',
          'url',
          'originalUrl' // wanna compare "url" & "originalUrl"
        ])
      }
    }

    sanitizeRes(res) {
      if (!res) return

      return {
        ...pick(res, ['statusCode', 'headersSent'])
      }
    }

    // TODO: fetch integration
    // https://developer.mozilla.org/fr/docs/Web/API/Fetch_API/Using_Fetch
    // https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/TypeError
    // fetch()
    //   .then(
    //     (response: Response) => response.ok
    //     ? 'server responded with success'
    //     : 'server responded with error'
    //   )
    //   .catch(error: TypeError) // network error (CORS, certificat errors, ...)

    extractAxiosErrorDetails(error) {
      // axios >= 0.19.0
      if (!error.isAxiosError) return

      let data
      try {
        data = JSON.parse(error.config.data)
      } catch (e) {}

      return {
        request: {
          url: error.config && error.config.url,
          method: error.config && error.config.method,
          data,
          queryString: error.config && error.config.params
        },
        response: pick(error.response, ['status', 'data'])
      }
    }
  }
