import { Store, ActionContext } from 'vuex'

import { State } from './state'
import { RootState } from '~/store/state'

import {
  handleAxiosErrorServerFromStoreV3,
  handleAxiosErrorFrontendFromStoreV3
} from '@/utils/functions/handle-errors'
import mockedHeader from '@/utils/mocked-data/program/header'

export const fetch = async function(
  this: Store<State>,
  { commit, rootState }: ActionContext<State, RootState>
) {
  const isMocked = false

  if (!rootState.auth.selectedProgram) {
    commit('setError', this.$i18n.t('errors.program.notSelected'))
    return
  }

  commit('setLoadingState', true)
  commit('resetError')

  let response
  try {
    if (isMocked) {
      response = mockedHeader
    } else {
      response = await this.$axios.get(
        `/programs/${rootState.auth.selectedProgram.identifier}/config_points`
      )
    }
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({ error, commit, store: this })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStoreV3({ response, commit, store: this })
    return
  }

  commit('setConfig', response.data.result)
  commit('setLoadedState', true)
  commit('setLoadingState', false)
}
