import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t('pages.program.catalog.changeRewards.title')))]),_vm._v(" "),_c('ol',[_c('li',[_vm._v("\n      "+_vm._s(_vm.$t(
          'pages.program.catalog.changeRewards.explanations.removeUnecessaryLines'
        ))+"\n    ")]),_vm._v(" "),_c('li',[_vm._v("\n      "+_vm._s(_vm.$t(
          'pages.program.catalog.changeRewards.explanations.attributeReplacementRewards'
        ))+"\n    ")]),_vm._v(" "),_c('li',[_vm._v("\n      "+_vm._s(_vm.$t(
          'pages.program.catalog.changeRewards.explanations.previewChanges',
          {
            buttonText: _vm.$t(
              'pages.program.catalog.changeRewards.previewChanges'
            )
          }
        ))+"\n    ")])]),_vm._v(" "),_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c('h1',[_vm._v("\n        "+_vm._s(_vm.$t('pages.program.catalog.changeRewards.rewardsToReplace'))+"\n      ")])]),_vm._v(" "),_c(VCol,{attrs:{"cols":"6"}},[_c('h1',[_vm._v("\n        "+_vm._s(_vm.$t('pages.program.catalog.changeRewards.replacementRewards'))+"\n      ")])])],1),_vm._v(" "),_vm._l((_vm.loyaltyRewardsToChangeCount),function(i){return _c('RowSwitchReward',{key:("reward-" + i),attrs:{"index":i - 1,"new-loyalty-rewards-model":_vm.newLoyaltyRewardsModel,"loyalty-rewards-to-change":_vm.loyaltyRewardsToChange},on:{"row-is-deleted":_vm.deleteRow}})}),_vm._v(" "),(_vm.loyaltyRewardsToChangeCount < _vm.loyaltyRewardsRows.length)?_c('SelectReward',{attrs:{"rewards":_vm.loyaltyRewardsRows,"next-row-message":_vm.message},on:{"reward-is-selected":_vm.addRow}}):_vm._e(),_vm._v(" "),_c('PreviewButtonAndModal')],2)}
var staticRenderFns = []

export { render, staticRenderFns }