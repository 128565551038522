import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{staticClass:"red darken-1",attrs:{"dark":""}},[_c(VListItemAvatar,{attrs:{"tile":"","size":"100px"}},[_c(VImg,{attrs:{"alt":_vm.$t('crud.offers.edit.products.imageAlt'),"src":"https://cdn1.skerou.com/images/products/unknown.png"}})],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_c('strong',[_vm._v(_vm._s(_vm.$t('crud.offers.edit.products.list.item.ean'))+" :")]),_vm._v("\n      "+_vm._s(_vm.ean)+"\n    ")]),_vm._v(" "),_c(VListItemSubtitle,[_vm._v("\n      "+_vm._s(_vm.$t('crud.offers.edit.products.unknownProduct'))+"\n    ")]),_vm._v(" "),_c(VListItemTitle,[_c('strong',[_vm._v("\n        "+_vm._s(_vm.$t('crud.offers.edit.products.mustCreateProduct'))+" !\n      ")])]),_vm._v(" "),(_vm.isEditing)?_c('ProductEditModal',{attrs:{"ean":_vm.ean},on:{"product-saved":function($event){return _vm.$emit('product-created', $event)}},model:{value:(_vm.isEditing),callback:function ($$v) {_vm.isEditing=$$v},expression:"isEditing"}}):_vm._e()],1),_vm._v(" "),(!_vm.disabled && !_vm.readonly)?_c(VListItemAction,[_c(VIcon,{attrs:{"disabled":_vm.isEditing},on:{"click":function($event){_vm.isEditing = true}}},[_vm._v("\n      mdi-plus\n    ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }