import {
  PROGRAM_KIND_POINTS_VIA_PRICE,
  PROGRAM_KIND_STAMPS,
  PROGRAM_KIND_PUZZLE,
  PROGRAM_KIND_COLLECTOR
} from '~/store/program/state.js'

import toFormData, {
  toFormDataWithArrayIndexV2
} from '@/utils/functions/toFormData'

function createPreviewer(keyForFile, keyForUrl) {
  return (state) => {
    if (state.form.images[keyForFile]) {
      return URL.createObjectURL(state.form.images[keyForFile])
    }
    if (state.form.images[keyForUrl]) {
      return state.form.images[keyForUrl]
    }

    return null
  }
}

export const squareImagePreview = createPreviewer(
  'squareImageFile',
  'squareImageUrl'
)
export const rectoImagePreview = createPreviewer(
  'rectoImageFile',
  'rectoImageUrl'
)
export const versoImagePreview = createPreviewer(
  'versoImageFile',
  'versoImageUrl'
)
export const backgroundImagePreview = createPreviewer(
  'backgroundImageFile',
  'backgroundImageUrl'
)
export const eligibleBrandImagePreview = createPreviewer(
  'eligibleBrandImageFile',
  'eligibleBrandImageUrl'
)
export const onboardingClubImagePreview = createPreviewer(
  'onboardingClubImageFile',
  'onboardingClubImageUrl'
)
export const onboardingClubImage2xPreview = createPreviewer(
  'onboardingClubImage2xFile',
  'onboardingClubImage2xUrl'
)
export const onboardingClubImage3xPreview = createPreviewer(
  'onboardingClubImage3xFile',
  'onboardingClubImage3xUrl'
)

export const stampLogoImagePreview = createPreviewer(
  'stampLogoImageFile',
  'stampLogoImageUrl'
)

export const puzzleBoardsEmptyImagePreviews = (state) => {
  return state.form.puzzleBoards.map((board) => {
    if (board.emptyImageFile) {
      return URL.createObjectURL(board.emptyImageFile)
    }
    if (board.emptyImageUrl) {
      return board.emptyImageUrl
    }

    return null
  })
}

export const puzzleBoardsCompletedImagePreviews = (state) => {
  return state.form.puzzleBoards.map((board) => {
    if (board.completedImageFile) {
      return URL.createObjectURL(board.completedImageFile)
    }
    if (board.completedImageUrl) {
      return board.completedImageUrl
    }

    return null
  })
}

export const puzzlePiecesOwnedImagePreviews = (state) => {
  return state.form.puzzleBoards.map((board) =>
    board.pieces.map((piece) => {
      if (piece.ownedImageFile) {
        return URL.createObjectURL(piece.ownedImageFile)
      }
      if (piece.ownedImageUrl) {
        return piece.ownedImageUrl
      }

      return null
    })
  )
}

export const payloadForBackend = (state) => {
  const res = {
    kind: state.form.kind,

    name: state.form.name,
    hidden: state.form.hidden,
    parent_identifier: state.form.parentIdentifier,
    client_id: state.form.clientId,
    tag_line: state.form.tagLine,
    partner_ids: state.form.partners,
    display_rank: state.form.displayRank,
    catchphrase: state.form.catchPhrase,
    description: state.form.description,
    brand_description: state.form.brandDescription,
    disclaimer_message: state.form.disclaimerMessage,
    brand_html_description: state.form.brandHtmlDescription,

    onboarding_club_image_file: state.form.images.onboardingClubImageFile,
    onboarding_club_image_url: state.form.images.onboardingClubImageUrl,
    onboarding_club_image_2x_file: state.form.images.onboardingClubImage2xFile,
    onboarding_club_image_2x_url: state.form.images.onboardingClubImage2xUrl,
    onboarding_club_image_3x_file: state.form.images.onboardingClubImage3xFile,
    onboarding_club_image_3x_url: state.form.images.onboardingClubImage3xUrl,
    square_image_file: state.form.images.squareImageFile,
    square_image_url: state.form.images.squareImageUrl,
    recto_image_file: state.form.images.rectoImageFile,
    recto_image_url: state.form.images.rectoImageUrl,
    verso_image_file: state.form.images.versoImageFile,
    verso_image_url: state.form.images.versoImageUrl,
    background_image_file: state.form.images.backgroundImageFile,
    background_image_url: state.form.images.backgroundImageUrl,
    eligible_brand_image_file: state.form.images.eligibleBrandImageFile,
    eligible_brand_image_url: state.form.images.eligibleBrandImageUrl,

    dominant_color: state.form.dominantColor
  }

  switch (state.form.kind) {
    case PROGRAM_KIND_POINTS_VIA_PRICE:
      res[PROGRAM_KIND_POINTS_VIA_PRICE] = {
        welcoming_points: state.form.welcomingPoints,
        cents_for_points: state.form.centsForPoints,
        credit_coefficient: state.form.creditCoefficient,
        max_points_per_month: state.form.maxPointsPerMonth,
        max_points_per_year: state.form.maxPointsPerYear
      }
      break
    case PROGRAM_KIND_STAMPS:
      res[PROGRAM_KIND_STAMPS] = {
        stamps_max_count: state.form.stampsMaxCount,
        stamps_by_line: state.form.stampsByLine,
        stamp_logo_image_url: state.form.images.stampLogoImageUrl,
        stamp_logo_image_file: state.form.images.stampLogoImageFile,
        is_renewable: state.form.isRenewable
      }
      break
    case PROGRAM_KIND_PUZZLE:
      res[PROGRAM_KIND_PUZZLE] = {
        pieces_count: state.form.puzzleTotalPiecesCount,
        boards: state.form.puzzleBoards.map((board) => ({
          rank: board.rank,
          width: board.width,
          height: board.height,
          margin: board.margin,
          empty_image_url: board.emptyImageUrl,
          empty_image_file: board.emptyImageFile,
          completed_image_url: board.completedImageUrl,
          completed_image_file: board.completedImageFile,
          pieces: board.pieces.map((piece) => ({
            x: piece.x,
            y: piece.y,
            width: piece.width,
            height: piece.height,
            owned_image_url: piece.ownedImageUrl,
            owned_image_file: piece.ownedImageFile
          }))
        })),
        is_renewable: state.form.isRenewable
      }
      break
    case PROGRAM_KIND_COLLECTOR:
      res[PROGRAM_KIND_COLLECTOR] = {}
  }

  return res
}

export const formattedPayloadForBackend = (state, getters) => {
  if (state.form.kind === PROGRAM_KIND_PUZZLE) {
    return toFormDataWithArrayIndexV2(getters.payloadForBackend)
  } else {
    return toFormData(getters.payloadForBackend)
  }
}

export const formattedPayloadForBackendPuzzleUpdate = (_, getters) =>
  toFormDataWithArrayIndexV2(getters.payloadForBackend[PROGRAM_KIND_PUZZLE])

export const hasPieces = (state) =>
  state.form && state.form.puzzleTotalPiecesCount > 0
export const hasNoPieces = (state) =>
  state.form && state.form.puzzleTotalPiecesCount === 0

export const puzzleBoardsCount = (state) => state.form.puzzleBoards.length
export const hasMoreThanOneBoard = (_, getters) => getters.puzzleBoardsCount > 1
