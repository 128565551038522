<template>
  <div>
    <SelectAccount @input="editorId = $event" />

    <v-btn
      :loading="isDeleting"
      :disabled="isDeleting"
      color="error"
      plain
      @click="launchTimer"
    >
      {{
        $t('pages.users.modal.accountActions.deleteAccount.delete') | capitalize
      }}
    </v-btn>

    <v-expand-transition>
      <h5 v-show="timerShow">
        {{
          $tc(
            'pages.users.modal.accountActions.deleteAccount.timeDown',
            timerCount
          )
        }}
        <v-btn v-show="timerShow" text small @click="cancel">
          {{
            $t('pages.users.modal.accountActions.deleteAccount.cancel')
              | capitalize
          }}
        </v-btn>
      </h5>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import SelectAccount from './SelectAccount'
import MixinAjaxToCore from '~/mixins/ajaxToCore'
export default {
  components: { SelectAccount },
  mixins: [MixinAjaxToCore],
  data: () => ({
    editorId: null,

    isDeleting: false,
    timerShow: false,
    timerCount: null
  }),
  computed: {
    ...mapState('user', ['user'])
  },
  watch: {
    timerCount() {
      if (this.timerCount === null) return
      if (this.timerCount > 0) return

      this.stopTimer()
      this.deleteAccount()
    }
  },
  methods: {
    ...mapMutations('snackbar', ['addToast']),

    deleteAccount() {
      return this.fetchCoreFromComponentAndHandleError({
        isLoadingKey: 'isDeleting',
        errorInToast: true,

        axios: {
          method: 'POST',
          url: `/users/${this.user.id}/delete_account`,
          data: { editor_id: this.editorId }
        },

        onSuccess: (_result) => {
          this.addToast({
            text: this.$t(
              'pages.users.modal.accountActions.deleteAccount.success'
            )
          })
          this.$emit('close')
        }
      })
    },

    launchTimer() {
      this.timerCount = 15
      this.timerInterval = setInterval(() => {
        this.timerCount = this.timerCount - 1
      }, 1000)
      this.timerShow = true
    },
    stopTimer() {
      this.timerShow = false
      clearInterval(this.timerInterval)
      this.timerInterval = null
      this.timerCount = null
    },
    cancel() {
      this.stopTimer()
    }
  }
}
</script>
