<template>
  <ListLayoutItem
    :is-selected="isSelected"
    :to="`${routeBasePath}/declarations/${declaration.identifier}`"
    :has-zendesk-tickets="declaration.has_zendesk_tickets"
    :is-eligible="declaration.is_eligible"
    :next-timeline-event="declaration.timeline_next_event"
    :timeline-events="declaration.timeline_events"
  >
    <template #image="{ size }">
      <v-img
        v-if="declaration.retailer_image_url"
        :src="declaration.retailer_image_url"
        :height="size"
        :width="size"
        contain
      />
      <v-sheet
        v-else
        class="d-flex align-center justify-center"
        :height="size"
        :width="size"
      >
        <v-icon :size="size - 4">{{ iconName }}</v-icon>
      </v-sheet>
    </template>
    <template #title>
      {{ declaration.retailer_name || retailerSubstituteName }}
    </template>
    <template v-if="declaration.date_time" #subtitle>
      {{ declaration.date_time | momentFormat('human-long') }}
    </template>
    <template #subtitle-2>
      {{ $t('pages.declarations.captured') }}
      {{ declaration.captured_at | momentFormat('human-long') }}
    </template>
    <template #id> #{{ declaration.id }} </template>
  </ListLayoutItem>
</template>

<script>
import { mapGetters } from 'vuex'
import ListLayoutItem from './ListLayoutItem'
import MixinRetailer from '@/mixins/retailer'
import hasOwnProp from '@/utils/functions/hasOwnProp'
export default {
  components: { ListLayoutItem },
  mixins: [MixinRetailer],
  props: {
    declaration: {
      type: Object,
      required: true,
      validator: (o) =>
        hasOwnProp(o, 'identifier') &&
        hasOwnProp(o, 'retailer_image_url') &&
        hasOwnProp(o, 'retailer_name') &&
        hasOwnProp(o, 'date_time') &&
        hasOwnProp(o, 'captured_at') &&
        hasOwnProp(o, 'id') &&
        hasOwnProp(o, 'type') &&
        hasOwnProp(o, 'is_eligible') &&
        hasOwnProp(o, 'has_zendesk_tickets') &&
        hasOwnProp(o, 'timeline_next_event') &&
        hasOwnProp(o, 'timeline_events') &&
        true
    }
  },
  computed: {
    ...mapGetters('user', ['routeBasePath']),

    isSelected() {
      return (
        this.$route.params.declarationIdentifier &&
        this.$route.params.declarationIdentifier === this.declaration.identifier
      )
    },

    iconName() {
      switch (this.declaration.type) {
        case 'ProofOfPurchase::ExternalInvalidPicture':
          return 'mdi-image'
        case 'ProofOfPurchase::ExternalGlassPicture':
          // return 'mdi-beer'
          return 'mdi-glass-mug-variant'
        case 'ProofOfPurchase::ExternalReceipt':
        case 'receipt':
          return 'mdi-receipt'
        default:
          this.$airbrakeNotify({
            error: new Error(`unknown type : ${this.declaration.type}`)
          })
          return 'mdi-help'
      }
    }
  }
}
</script>
