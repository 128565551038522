import { handleErrorAxiosCoreStore } from '@/utils/functions/handle-errors'
import { UNKNOWN_ERROR_USER } from '@/utils/constants/errors'

export const fetchFromApi = async (store, commit, url, config = {}) => {
  commit('setLoadingState', true)
  commit('setData', null)
  commit('resetError')

  let response
  try {
    response = await store.$axios.get(url, config)
  } catch (error) {
    handleErrorAxiosCoreStore({ error, commit, store })
    return
  }

  if (!response.data.success) {
    store.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setError', UNKNOWN_ERROR_USER)
    commit('setLoadingState', false)
    return
  }

  const result = response.data.result

  commit('setData', result)
  commit('setLoadedState', true)
  commit('setLoadingState', false)
}
