import {
  handleAxiosErrorServerFromStore,
  handleAxiosErrorServerFromStoreV3,
  handleAxiosErrorFrontendFromStoreV4
} from '@/utils/functions/handle-errors'
import mockedConfigCatalog from '@/utils/mocked-data/program/config_catalog'

export async function fetchRewards({ commit, rootState }) {
  const isMocked = false

  // commit('setLoadedState', false)
  commit('setLoadingState', true)
  commit('setError', null)

  let response
  try {
    if (isMocked) {
      response = mockedConfigCatalog
    } else {
      response = await this.$axios.get(
        `/programs/${rootState.route.params.programIdentifier}/config_catalog`
      )
    }
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this
    })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStoreV4({
      response,
      commit,
      store: this
    })
    return
  }

  commit('setCatalog', response.data.result)
  commit('setLoadedState', true)
  commit('setLoadingState', false)
}

export async function updateCatalog({ commit, rootState, state }, params) {
  const { isPreview, datetime } = params

  if (isPreview) {
    commit('setLoadedState', false)
  }

  commit('setLoadingState', true)

  const loyaltyRewardsToChangeIds = state.loyaltyRewardsToChange.map((reward) =>
    parseInt(reward.loyalty_reward.id)
  )
  const nextLoyaltyRewardsIds = state.newLoyaltyRewards.map((reward) =>
    parseInt(reward.loyalty_reward.id)
  )

  let response
  try {
    if (isPreview) {
      response = await this.$axios.get(
        `/programs/${rootState.route.params.programIdentifier}/change_catalog_preview`,
        {
          params: {
            current_loyalty_rewards_ids: loyaltyRewardsToChangeIds,
            next_loyalty_rewards_ids: nextLoyaltyRewardsIds
          }
        }
      )
    } else {
      response = await this.$axios.post(
        `/programs/${rootState.route.params.programIdentifier}/change_catalog_execute`,
        {
          current_loyalty_rewards_ids: loyaltyRewardsToChangeIds,
          next_loyalty_rewards_ids: nextLoyaltyRewardsIds,
          available_at: datetime
        }
      )
    }
  } catch (error) {
    handleAxiosErrorServerFromStore({
      error,
      errorInToast: true,
      commit,
      store: this
    })

    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })

    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t(
          'pages.program.catalog.changeRewards.modal.errorMessage'
        )
      },
      { root: true }
    )
    commit('setLoadedState', false)
    commit('setLoadingState', false)

    return
  }

  if (isPreview) {
    commit('setCatalogPreview', response.data.result.preview)
    commit('setLoadedState', true)
  } else {
    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t(
          datetime
            ? 'pages.program.catalog.changeRewards.modal.updateLaterSuccessMessage'
            : 'pages.program.catalog.changeRewards.modal.updateNowSuccessMessage'
        )
      },
      { root: true }
    )
  }

  commit('setLoadingState', false)
}

export const fetchExclusivePartners = async function({ commit }, rewardId) {
  let response
  try {
    response = await this.$axios.get(
      `/loyalty_rewards/${rewardId}/partner_exclusivity`
    )
  } catch (error) {
    handleAxiosErrorServerFromStore({
      error,
      commit,
      store: this
    })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    return
  }
  commit('setloyaltyRewardExclusivity', response.data.result)
}
