import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutUser',[_c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[(_vm.isLoading)?_c(VProgressLinear,{attrs:{"indeterminate":"","color":"white","background-color":"orange"}}):_vm._e(),_vm._v(" "),_c(VToolbar,{attrs:{"dark":"","color":"orange","src":"https://cdn2.fidmarques.com/production/custom_landing_pages/assets/background-fidmarques@2x.png"}},[_c(VTabs,{attrs:{"optional":"","centered":""},model:{value:(_vm.tabSelected),callback:function ($$v) {_vm.tabSelected=$$v},expression:"tabSelected"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.key,attrs:{"nuxt":"","to":tab.to,"tab-value":tab.key}},[_c(VImg,{attrs:{"src":tab.src,"alt":tab.title,"height":_vm.size,"width":_vm.size,"contain":""}})],1)}),1)],1),_vm._v(" "),_c(VCard,{staticClass:"min-height-100px",attrs:{"tile":""}},[(_vm.screenCanBeLocked)?_c('ScreenLockStatus',{attrs:{"screen":_vm.screen}}):_vm._e(),_vm._v(" "),_c(VCardText,[_c('nuxt')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }