<template>
  <div>
    <h3 class="header">
      <ButtonOpenFbConversation
        :user-id="user.id"
        :v-btn-props="{
          dark: true,
          small: true,
          outlined: true
        }"
      />
      <span class="ml-3 text-no-wrap">ID FB : {{ user.facebook_id }}</span>
    </h3>
  </div>
</template>

<script>
import originBo from '~/utils/constants/origin-bo'
import ButtonOpenFbConversation from '~/components/layouts/ButtonOpenFbConversation'
import hasOwnProp from '~/utils/functions/hasOwnProp'
export default {
  components: { ButtonOpenFbConversation },
  props: {
    user: {
      type: Object,
      required: true,
      validator: (o) =>
        hasOwnProp(o, 'id') &&
        hasOwnProp(o, 'identifiers') &&
        hasOwnProp(o, 'facebook_id') &&
        true
    },
    seniority: {
      type: String,
      required: true
    }
  },
  computed: {
    oldBoUrl() {
      return `${originBo}/admin/users/${this.user.id}`
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  font-size: 12px;
}
</style>
