import env from './env'

export const originFromEnv = (env) => {
  switch (env) {
    case 'development':
      if (process.env.USE_DOCKER_BACKEND) {
        // WARNING
        // MAKE SURE TO HAVE YOU HOST FILE contains
        // 127.0.0.1 sas-bo-api_bash
        return 'http://sas-bo-api_bash:15300'
      } else {
        return 'http://localhost:15300'
      }
    case 'staging':
      return 'https://staging-sas-bo-api.purchease.com'
    case 'production':
      return 'https://production-sas-bo-api.purchease.com'
    default:
      throw new Error(`unhandled env ${env}`)
  }
}

export default originFromEnv(env)
