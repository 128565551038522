<template>
  <LayoutDefault :title="title" :extension-height="extensionHeight">
    <template #app-bar__right>
      <LayoutUserSearch />

      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn icon v-on="on" @click="toggleAdvanceSearch">
            <v-icon>mdi-account-search</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('pages.users.search.advanceSearch') }}</span>
      </v-tooltip>
    </template>

    <template v-if="userIsSelected" #app-bar__extension class="ma-0">
      <LayoutUserBar v-if="userIsSelected" :height="heightUserBar" />
    </template>

    <template #default>
      <LayoutUserSearchAdvance v-if="isAdvanceSearch" />

      <slot v-if="$scopedSlots.default" />
      <nuxt v-else />
    </template>
  </LayoutDefault>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import LayoutDefault from '../default'

import LayoutUserBar from '@/components/layouts/user/Bar'
import LayoutUserSearch from '@/components/layouts/user/Search'
import LayoutUserSearchAdvance from '@/components/layouts/user/SearchAdvance'

export default {
  components: {
    LayoutDefault,
    LayoutUserBar,
    LayoutUserSearch,
    LayoutUserSearchAdvance
  },

  computed: {
    ...mapState('user', ['isLoaded', 'user']),
    ...mapState('user/search', ['isAdvanceSearch']),

    userIsSelected() {
      return !!this.$route.params.userId
    },
    title() {
      return this.userIsSelected
        ? this.$t('pages.user.title')
        : this.$t('pages.users.title')
    },

    heightUserBar() {
      return 64
    },
    extensionHeight() {
      if (this.userIsSelected) {
        return this.heightUserBar + 48
      }

      return 0
    }
  },
  watch: {
    userIsSelected: 'fetchUserFromRoute',
    isLoaded: 'fetchUserFromRoute',
    $route: 'fetchUserFromRoute'
  },
  mounted() {
    this.fetchUserFromRoute()
  },
  methods: {
    ...mapActions('user', ['fetchUserFromRoute']),
    ...mapActions('user/search', ['toggleAdvanceSearch'])
  }
}
</script>
