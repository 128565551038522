import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('p',[_vm._v("\n    "+_vm._s(_vm.$t('layout.program.importProducts.instructions.useThisFileAsReference'))+"\n    :\n    "),_c('a',{attrs:{"href":_vm.LINK,"target":"_blank"}},[_vm._v("\n      "+_vm._s(_vm.$t('layout.program.importProducts.instructions.eansList'))+"\n    ")])]),_vm._v(" "),_c('h4',[_vm._v("\n    "+_vm._s(_vm.$t('layout.program.importProducts.instructions.steps.title'))+"\n    :\n  ")]),_vm._v(" "),_c('ol',_vm._l((3),function(n){return _c('li',{key:n},[_vm._v("\n      "+_vm._s(_vm.$t(("layout.program.importProducts.instructions.steps." + n)))+"\n    ")])}),0),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"email","mode":"eager","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VFileInput,{attrs:{"value":_vm.file,"error-messages":errors},on:{"change":_vm.setFile}})]}}],null,true)}),_vm._v(" "),_c(VCardActions,[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"color":"primary","loading":_vm.isSending},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v("\n      "+_vm._s(_vm.$t('layout.program.importProducts.preview.title'))+"\n    ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }