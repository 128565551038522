export { default as general } from './general'

export { default as applet } from './applet'
export { default as catalog } from './catalog'
export { default as create } from './create'
export { default as periods } from './periods'
export { default as renewalStrategy } from './renewalStrategy'
export { default as rules } from './rules'
export { default as visibility } from './visibility'

// specific
export { default as points } from './points'
export { default as puzzle } from './puzzle'
export { default as stamps } from './stamps'

export const title = 'Gestion du programme'
