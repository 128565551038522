import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,[_c(VTabs,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.key,attrs:{"to":'#' + tab.key}},[_vm._v("\n        "+_vm._s(tab.title)+"\n      ")])}),1),_vm._v(" "),_c(VTabsItems,{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTabItem,{attrs:{"value":_vm.TAB_REWARDS}},[_c(VContainer,{attrs:{"fluid":""}},[_c('CatalogShow')],1)],1),_vm._v(" "),_c(VTabItem,{attrs:{"value":_vm.TAB_SHELVES}},[_c(VContainer,{attrs:{"fluid":""}},[_c('RewardsShelves')],1)],1),_vm._v(" "),_c(VTabItem,{attrs:{"value":_vm.TAB_CHANGE_CATALOG}},[_c(VContainer,{attrs:{"fluid":""}},[_c('CatalogChange')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }