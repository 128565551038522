import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"max-width":"70%","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"tile":"","color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"left":""}},[_vm._v("\n          mdi-plus\n        ")]),_vm._v("\n        "+_vm._s(_vm.$t('pages.program.catalog.createModal.buttonCreate'))+"\n      ")],1)]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_vm._v(" "),_c(VCard,{on:{"keydown":function($event){$event.stopPropagation();}}},[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c(VToolbarTitle,[_vm._v("\n          "+_vm._s(_vm.$t('pages.program.catalog.createModal.modalTitle'))+"\n        ")]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VToolbarItems,[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showModal = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_vm._v(" "),(_vm.showModal)?_c('CreateNewRewardForm',{on:{"close":function($event){_vm.showModal = false}}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }