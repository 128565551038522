import { createLoadableState } from '@/utils/store/concerns/loadable/state'

export default () =>
  createLoadableState({
    renewalStrategy: [],
    allSpendingsPredictions: [],
    renewalStrategyPredictions: [],
    form: {
      period_in_months: 12,
      started_at: Date.now()
    }
  })
