import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VToolbar,{staticClass:"v-toolbar--mini-padding-top",attrs:{"short":"","extended":"","color":_vm.paletteScanobar.black,"dark":"","height":_vm.height},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c(VTabs,{attrs:{"align-with-title":""}},[_c(VTab,{staticClass:"pa-0",staticStyle:{"min-width":"0px"}}),_vm._v(" "),_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.identifier,attrs:{"nuxt":"","to":tab.to},domProps:{"textContent":_vm._s(tab.title)}})})],2)]},proxy:true}])},[_c(VToolbarTitle,{staticClass:"flex-grow-1"},[(_vm.error)?_c('DisplayErrorV2',{attrs:{"error":_vm.error,"retriable":""},on:{"retry":function($event){return _vm.fetchProgramByIdentifier({
          programIdentifier: _vm.$route.params.programIdentifier
        })}}}):(_vm.isLoading)?_c(VSkeletonLoader,{attrs:{"type":"heading","width":"500"}}):(_vm.isLoaded)?_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_vm._v("\n        "+_vm._s(_vm.program.name)+"\n      ")]),_vm._v(" "),(!_vm.isManager && _vm.program && !_vm.program.is_child)?_c('WarningIfProgramHasNoPeriod'):_vm._e(),_vm._v(" "),_c('DialogImportEans')],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }