<template>
  <v-btn
    :disabled="disabled"
    :color="isSelected ? 'primary' : undefined"
    :class="isSelected ? 'white--text' : undefined"
    @click="$emit('click')"
  >
    <v-icon left dark>
      {{
        isSelected
          ? 'mdi-checkbox-marked-outline'
          : 'mdi-checkbox-blank-outline'
      }}
    </v-icon>
    {{ text }}
  </v-btn>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
