import {
  createLoadableState,
  LoadableState
} from '~/utils/store/concerns/loadable/state'

export interface Job {
  uuid: string
}
export interface Header {}

export interface NotLoadableState {
  jobTypes: string[]
  headers: Header[]

  jobs: Job[]

  isCreating: boolean
  createWasSuccessful: boolean

  isDeleting: boolean
  deleteWasSuccessful: boolean
}
export interface State extends NotLoadableState, LoadableState {}

export default () =>
  createLoadableState({
    jobTypes: [],
    headers: [],

    jobs: [],

    isCreating: false,
    createWasSuccessful: false,

    isDeleting: false,
    deleteWasSuccessful: false
  })
