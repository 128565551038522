<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <h3>{{ $t('crud.offers.edit.fields.bonusType.title') + ' *' }}</h3>
        <v-radio-group
          mandatory
          column
          :value="modified.bonusType"
          :disabled="disabled"
          :readonly="readonly"
          @change="updateAttribute({ key: 'bonusType', value: $event })"
        >
          <v-radio
            :value="BONUS_TYPE_FACTOR"
            :label="$t('crud.offers.edit.fields.bonusType.label.multiply')"
            :disabled="disabled"
            :readonly="readonly"
          />
          <v-radio
            :value="BONUS_TYPE_FIXED"
            :label="$t('crud.offers.edit.fields.bonusType.label.add')"
            :disabled="disabled"
            :readonly="readonly"
          />
        </v-radio-group>
      </v-col>
      <v-col cols="6">
        <h3>{{ $t('crud.offers.edit.fields.bonusValue.title') + ' *' }}</h3>
        <ValidationProvider
          v-slot="{ validate, errors }"
          name="bonusValue"
          mode="eager"
          rules="required|numeric|min_value:0"
          slim
        >
          <v-text-field
            v-model.number="bonusValue"
            :label="
              modified.bonusType === BONUS_TYPE_FACTOR
                ? $t('crud.offers.edit.fields.bonusValue.label.multiply')
                : $t('crud.offers.edit.fields.bonusValue.label.add')
            "
            required
            type="number"
            :class="{ 'mt-9': modified.bonusType === BONUS_TYPE_FIXED }"
            :error-messages="errors"
            :disabled="disabled"
            :readonly="readonly"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <ValidationProvider
          v-if="modified.bonusType === BONUS_TYPE_FACTOR"
          v-slot="{ validate, errors }"
          name="bonusMaxQuantity"
          mode="eager"
          rules="integer|min_value:1"
          slim
        >
          <v-text-field
            :label="$t('crud.offers.edit.fields.bonusMaxQuantity.label')"
            :hint="$t('crud.offers.edit.fields.bonusMaxQuantity.hint')"
            type="number"
            :error-messages="errors"
            :value="modified.bonusMaxQuantity"
            :disabled="disabled"
            :readonly="readonly"
            @change="
              updateAttribute({ key: 'bonusMaxQuantity', value: $event })
            "
          />
        </ValidationProvider>
        <ValidationProvider
          v-if="modified.bonusType === BONUS_TYPE_FIXED"
          v-slot="{ errors }"
          name="bonusMinItems"
          mode="eager"
          rules="required|integer|min_value:1"
          slim
        >
          <v-text-field
            :label="$t('crud.offers.edit.fields.bonusMinItems.label')"
            required
            type="number"
            :error-messages="errors"
            :value="modified.bonusMinItems"
            :disabled="disabled"
            :readonly="readonly"
            @change="updateAttribute({ key: 'bonusMinItems', value: $event })"
          />
        </ValidationProvider>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col>
        <v-text-field
          :label="$t('crud.offers.edit.fields.bonusMatchingRegexp.label')"
          :value="modified.bonusMatchingRegexp"
          :disabled="disabled"
          :readonly="readonly"
          @input="
            updateAttribute({ key: 'bonusMatchingRegexp', value: $event })
          "
        />
      </v-col>
      <v-col>
        <v-text-field
          :label="$t('crud.offers.edit.fields.bonusExceptionRegexp.label')"
          :value="modified.bonusExceptionRegexp"
          :disabled="disabled"
          :readonly="readonly"
          @input="
            updateAttribute({ key: 'bonusExceptionRegexp', value: $event })
          "
        />
      </v-col>
    </v-row> -->

    <ListAndVerifyEans
      :disabled="disabled"
      :readonly="readonly"
      :eans="modified.bonusProductsEans"
      eans-required
      @change="updateBonusProductsEans($event)"
    />
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ValidationProvider } from 'vee-validate'

import ListAndVerifyEans from '~/components/program/catalog/create-modal/ListAndVerifyEans.vue'

import { mapModified } from '~/utils/vuex'

export const BONUS_TYPE_FACTOR = 'FACTOR'
export const BONUS_TYPE_FIXED = 'FIXED'

export default {
  components: {
    ValidationProvider,
    ListAndVerifyEans
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    BONUS_TYPE_FACTOR,
    BONUS_TYPE_FIXED
  }),
  computed: {
    ...mapState('crud/offer', ['modified']),
    ...mapModified('crud/offer', ['bonusValue'])
  },
  methods: {
    ...mapMutations('crud/offer', [
      'updateAttribute',
      'updateBonusProductsEans'
    ])
  }
}
</script>
