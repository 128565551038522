import { findIndex } from 'lodash-es'

import createDefaultState, { State, Job } from './state'

export * from '@/utils/store/concerns/loadable/mutations.js'

export const reset = (state: State) => {
  Object.assign(state, createDefaultState())
}

export const handleResult = (state: State, result: any) => {
  state.jobs = result.jobs
  state.jobTypes = result.job_types
  state.headers = result.headers
}

export const setCreatingState = (state: State, bool: boolean) => {
  state.isCreating = bool
}
export const setCreateWasSuccessfulState = (state: State, bool: boolean) => {
  state.createWasSuccessful = bool
}
export const addJob = (state: State, job: Job) => {
  state.jobs.push(job)
}

export const setDeletingState = (state: State, bool: boolean) => {
  state.isDeleting = bool
}
export const setDeleteWasSuccessfulState = (state: State, bool: boolean) => {
  state.deleteWasSuccessful = bool
}
export const deleteJob = (state: State, uuid: string) => {
  const index = findIndex(state.jobs, (job) => job.uuid === uuid)
  if (index === -1) return

  state.jobs.splice(index, 1)
}
