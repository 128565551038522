import * as types from './mutation-types'
import { handleAxiosErrorServerFromStore } from '@/utils/functions/handle-errors'

export function reset({ commit }) {
  commit(types.RESET)
}

export function setAll({ commit }, payload) {
  commit(types.SET_POT_REFUNDS, payload.pot_refunds)
  commit(types.SET_OTHER_APP_POT_REFUNDS, payload.other_app_pot_refunds)

  if (payload.previous_transfer_payment) {
    commit(types.ADD_TRANSFER_PAYMENT, payload.previous_transfer_payment)
    commit(
      types.SET_PREVIOUS_TRANSFER_PAYMENT_ID,
      payload.previous_transfer_payment.id
    )
  }
}

export async function fetchMore({ state, commit }, { userId, editorId }) {
  if (state.isFetchingMore) return

  commit(types.SET_IS_FETCHING_MORE, true)

  const id = state.previousTransferPaymentId
  if (!id) {
    commit(types.SET_PREVIOUS_TRANSFER_PAYMENT_ID, null)
    commit(types.SET_IS_POT_LOADING, false)
    return
  }

  const params = {
    editor_id: editorId,
    user_id: userId,
    transfer_payment_id: id
  }
  let response
  try {
    response = await this.$axios.get(`/users/${userId}/pots_refunds`, {
      params
    })
  } catch (error) {
    handleAxiosErrorServerFromStore({
      error,
      commit,
      store: this,
      loadingMutationName: types.SET_IS_FETCHING_MORE
    })
    return
  }

  commit(types.ATTACH_ALL_REFUNDS, {
    id,
    potRefunds: response.data.result.pot_refunds,
    otherAppPotRefunds: response.data.result.other_app_pot_refunds
  })
  if (response.data.result.previous_transfer_payment) {
    commit(
      types.ADD_TRANSFER_PAYMENT,
      response.data.result.previous_transfer_payment
    )
    commit(
      types.SET_PREVIOUS_TRANSFER_PAYMENT_ID,
      response.data.result.previous_transfer_payment.id
    )
  } else {
    commit(types.SET_PREVIOUS_TRANSFER_PAYMENT_ID, null)
  }

  commit(types.SET_IS_FETCHING_MORE, false)
}

export async function fetchTransfer(
  { state, commit, dispatch },
  { userId, editorId }
) {
  if (state.isLoading) return

  commit(types.RESET)
  commit(types.SET_IS_POT_LOADING, true)
  const params = { editor_id: editorId }

  let response
  try {
    response = await this.$axios.get(`/users/${userId}/pots_refunds`, {
      params
    })
  } catch (error) {
    handleAxiosErrorServerFromStore({
      error,
      commit,
      store: this,
      loadingMutationName: types.SET_IS_POT_LOADING
    })
    return
  }

  dispatch('setAll', response.data.result)
  commit(types.SET_IS_POT_LOADING, false)
  commit(types.SET_IS_POT_LOADED, true)
}
