<template>
  <v-list-item>
    <v-list-item-avatar tile size="100px">
      <v-icon size="80px">mdi-loading mdi-spin</v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        <strong>{{ $t('crud.offers.edit.products.list.item.ean') }} :</strong>
        {{ ean }}
      </v-list-item-title>
      <v-list-item-subtitle>
        <v-skeleton-loader width="300" type="text" />
      </v-list-item-subtitle>
      <v-list-item-title>
        <v-skeleton-loader width="350" type="text" />
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    ean: {
      type: String,
      required: true
    }
  }
}
</script>
