import { State } from './state'

export const backendAction = (state: State) =>
  state.modified && state.modified.id ? 'update' : 'create'

export const imagePreview = (state: State) => {
  if (state.modified) {
    if (state.modified.image_file) {
      return URL.createObjectURL(state.modified.image_file)
    }
    if (state.modified.image_url) {
      return state.modified.image_url
    }
  }

  return state.original && state.original.image_url
}
