<template>
  <v-toolbar
    extended
    :color="paletteScanobar.black"
    dark
    class="v-toolbar--mini-padding-top"
    :height="height"
  >
    <!-- <template #img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
      />
    </template> -->

    <!-- <v-app-bar-nav-icon /> -->

    <v-toolbar-title>
      <DisplayErrorV2
        v-if="error"
        :error="error"
        retriable
        @retry="fetchUserFromRoute"
      />

      <v-skeleton-loader v-else-if="!user" type="heading" width="500" />
      <BarTitle v-else :user="user" :seniority="seniority" />
    </v-toolbar-title>

    <v-spacer />

    <v-row v-if="user" class="header__fraud mt-9">
      <v-col v-if="isVip" :cols="hasCurrentBans ? '6' : '11'" class="pa-0">
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-icon class="ml-10" v-bind="attrs" v-on="on">mdi-crown</v-icon>
          </template>

          <span>{{ user.notes }}</span>
        </v-tooltip>
      </v-col>
      <v-col
        v-else-if="hasNotes"
        :cols="hasCurrentBans ? '6' : '11'"
        class="pa-0"
      >
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <v-icon class="ml-10" v-bind="attrs" v-on="on">
              mdi-message-bulleted
            </v-icon>
          </template>
          <span>{{ user.notes }}</span>
        </v-tooltip>
      </v-col>
      <v-col
        v-if="hasCurrentBans"
        :cols="isVip || hasNotes ? '6' : ''"
        class="header__fraud__line-1 pb-0 pl-0 pt-0"
      >
        <BanChip
          :to="`${routeBasePath}/bans/${currentBans[0].id}`"
          :ban="currentBans[0]"
          message="bannedAt"
          class="ma-0"
        />
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-menu v-if="hasOldBans" offset-y content-class="max-height-95vh">
          <template #activator="{ on, attrs }">
            <v-btn small text v-bind="attrs" v-on="on">
              {{ $tc('layout.user.header.oldBans', oldBansCount) }}
            </v-btn>
          </template>

          <v-list
            v-for="(oldBans, editor) in oldBansByEditorName"
            :key="editor"
            subheader
          >
            <v-subheader v-if="user.apps.length > 1">{{ editor }}</v-subheader>
            <v-divider v-if="user.apps.length > 1" />
            <v-list-item
              v-for="oldBan in oldBans"
              :key="oldBan.id"
              nuxt
              :to="`${routeBasePath}/bans/${oldBan.id}`"
            >
              <v-list-item-icon class="mr-0">
                <v-sheet
                  :color="otherBanColor(oldBan)"
                  elevation="1"
                  height="10"
                  rounded
                  width="10"
                />
              </v-list-item-icon>
              <v-list-item-title class="mb-2">
                {{
                  $t('layout.user.header.bannedAt', {
                    date: momentFormat(oldBan.start_at, 'date')
                  })
                }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <!-- <v-btn icon @click.stop="refreshUser">
      <v-icon>mdi-refresh</v-icon>
    </v-btn> -->

    <!-- <v-btn icon @click.stop="dropUser">
      <v-icon>mdi-close</v-icon>
    </v-btn> -->

    <template #extension>
      <v-tabs align-with-title class="header__tabs">
        <!-- fake tab for pages not in the tab list (ex: ban details) -->
        <v-tab class="pa-0" style="min-width:0px" />

        <v-tab nuxt :to="`${routeBasePath}/timeline`">{{
          $t('layout.user.tabs.timeline')
        }}</v-tab>
        <v-tab nuxt :to="`${routeBasePath}/declarations`">{{
          $t('layout.user.tabs.declarations')
        }}</v-tab>
        <v-tab nuxt :to="`${routeBasePath}/profile`">{{
          $t('layout.user.tabs.profile')
        }}</v-tab>
        <v-tab v-if="hasFidmarques" nuxt :to="`${routeBasePath}/app`">{{
          $t('layout.user.tabs.app')
        }}</v-tab>
      </v-tabs>

      <v-skeleton-loader v-if="isLoading" type="heading" width="500" />
      <BarTitleWithFb
        v-else-if="user && user.facebook_id"
        :user="user"
        :seniority="seniority"
      />

      <v-fab-transition v-if="user">
        <v-btn
          color="primary"
          fab
          dark
          small
          absolute
          bottom
          right
          @click="dialog = !dialog"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-fab-transition>
    </template>

    <v-dialog v-if="dialog" v-model="dialog" max-width="900px">
      <ModalDetails v-if="user && !error" @close="dialog = false" />
    </v-dialog>
  </v-toolbar>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import moment from 'moment'

import BarTitleWithFb from './BarTitleWithFb'
import BarTitle from './BarTitle'
import BanChip from '@/components/features/user/BanChip'
import ModalDetails from '@/components/features/user/header/ModalDetails.vue'
import { momentFormat } from '~/plugins/filters'
import DisplayErrorV2 from '@/components/layouts/DisplayErrorV2'
import MixinPaletteScanobar from '@/mixins/paletteScanobar'
import MixinAjaxToCore from '@/mixins/ajaxToCore'
// import { HOME_USERS } from '@/utils/constants/routes'

export default {
  components: {
    BarTitleWithFb,
    BarTitle,
    DisplayErrorV2,
    ModalDetails,
    BanChip
  },
  mixins: [MixinPaletteScanobar, MixinAjaxToCore],
  props: {
    height: {
      type: Number,
      default: undefined
    }
  },
  data: () => ({
    fab: false,
    dialog: false,
    isLoadingEditors: false
  }),
  computed: {
    ...mapState('user', ['user', 'isLoading', 'error']),
    ...mapGetters('user', [
      'routeBasePath',
      'hasCurrentBans',
      'currentBans',
      'hasOldBans',
      'oldBansCount',
      'oldBansByEditorName',
      'hasFidmarques'
    ]),

    seniority() {
      const diff = moment().diff(moment(this.user.created_at))
      const duration = moment.duration(diff)

      const countDays = parseInt(duration.as('days'))
      if (countDays === 0) {
        return this.$t('global.calendar.full.lessThanADay')
      }
      if (countDays <= 30) {
        return this.$tc('global.calendar.full.day', countDays)
      }

      const countMonths = parseInt(duration.as('months'))
      if (countMonths <= 12) {
        return this.$tc('global.calendar.full.month', countMonths)
      }

      const countYears = parseInt(duration.as('years'))
      return this.$tc('global.calendar.full.year', countYears)
    },

    hasNotes() {
      return this.user.notes
    },
    isVip() {
      return this.user.is_vip
    }
  },
  mounted() {
    this.fetchEditors()
  },
  methods: {
    momentFormat,

    ...mapActions('user', ['fetchUserFromRoute']),
    ...mapMutations('user', ['addEditors']),

    otherBanColor(ban) {
      switch (ban.kind) {
        case 'yellow':
          return 'yellow darken-3'
        case 'red':
          return 'red darken-3'
        default:
          return undefined
      }
    },

    async fetchEditors() {
      await this.fetchCoreFromComponentAndHandleError({
        isLoadingKey: 'isLoadingEditors',
        errorInToast: true,

        axios: {
          url: `/partners/index`
        },

        onError: () => {},
        onSuccess: (result) => {
          this.addEditors(result.partners)
        }
      })
    }

    // ...mapActions('user', ['reset', 'refreshUser']),

    // refreshUser() {
    //   this.reset()
    //   this.$router.push(this.routeBasePath)
    // },

    // dropUser() {
    //   this.reset()
    //   this.$router.push(HOME_USERS)
    // }
  }
}
</script>

<style lang="scss" scoped>
// divider below drawer logo and app bar
// are not aligned without this
.v-toolbar--mini-padding-top {
  margin-top: 1px;
}

.header__fraud {
  text-align: end;
}
.header__fraud__line-1 {
  font-size: 16px;
}
.header__fraud__line-2 {
  font-size: 13px;
}

.max-height-95vh {
  max-height: 95vh;
}

.header__tabs {
  width: initial;
}
</style>
