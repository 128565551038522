export default {
  rulesFor: 'Règles mises en place',

  maxPointsPerMonth: 'Nombre maximum de points par mois',
  maxPointsPerYear: 'Nombre maximum de points par an',
  welcomingPoints: "Nombre de points à l'inscription",

  centsForPoints: '(NCPP) Nombres de centimes par point(s)',
  creditCoefficient: '(CC) Coefficient de crédit',

  ratioLine1:
    'Le ratio de conversion €/points est défini grâce à ces 2 valeurs (NCPP et CC) :',
  ratioLine2:
    "on gagne [CC] point(s) tous les [NCPP] centimes d'euros dépensés",
  example: 'Exemple',
  examples: 'Exemples',
  exampleFidMarques:
    "sur FidMarques, on a typiquement un NCPP de 50 et un CC de 1 : on gagne 1 point tous les 50 centimes (d'où le 1€ = 2pts)",
  exampleMarie:
    "sur Marie, on avait un NCPP de 50 et un CC de 10 : on gagne 10 points tous les 50 centimes (d'où le 1€ = 20pts)",

  scanobar: {
    forGlasses: 'Pour les verres, les règles suivantes sont mises en places',

    sameUserGlassNum: "L'utilisateur peut scanner le verre",
    sameCodeScan: 'Le même code peut être scanner',

    timesByHour: 'fois par heure',
    timesByDay: 'fois par jour',
    timesByPeriodOf: 'fois par période de'
  },

  programSuccessfullyUpdated: 'Programme mis à jour avec succès'
}
