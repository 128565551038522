import { handleAxiosErrorServerFromStore } from '@/utils/functions/handle-errors'

export async function fetchStrategy({ commit }) {
  commit('setIsLoading', true)

  let response
  try {
    response = await this.$axios.get(
      `/programs/${this.$router.currentRoute.params.programIdentifier}/display_renewal_strategy_predictions`,
      {}
    )
  } catch (error) {
    handleAxiosErrorServerFromStore({
      error,
      commit,
      store: this,
      loadingMutationName: 'setIsLoading'
    })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setIsLoading', false)
    return
  }

  commit('setRenewalStrategy', response.data.result)
  commit('setSpendingPredictionsSums')
  commit('setIsLoading', false)
  commit('setIsLoaded', true)
}

export async function updateStrategy({ commit }, prediction) {
  commit('setIsLoading', true)
  let response
  try {
    response = await this.$axios.post(
      `/programs/${this.$router.currentRoute.params.programIdentifier}/update_renewal_strategy_predictions`,
      {
        loyalty_program_renewal_strategy_prediction_id: prediction.id,
        attributes: {
          renewal_rate: prediction.renewal_rate,
          new_comers_subscription_rate: prediction.new_comers_subscription_rate
        }
      }
    )
  } catch (error) {
    handleAxiosErrorServerFromStore({
      error,
      commit,
      store: this,
      loadingMutationName: 'setIsLoading'
    })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setIsLoading', false)
    return
  }
  const text = !response.data.success
    ? this.$i18n.t('pages.program.renewalStrategy.failedRequest')
    : this.$i18n.t('pages.program.renewalStrategy.successedRequest')

  commit('snackbar/addToast', { text }, { root: true })
  commit('setIsLoading', false)
}
