export interface State {
  isDeletingNode: Boolean
  isCreatingNode: Boolean
  isRenamingNode: Boolean
  lastBackendCallFailed: Boolean
  isMovingNode: Boolean

  deletingNodeId: null | number
  editedNodeId: null | number
  editedNodeName: string
}

export default () => ({
  isDeletingNode: false,
  isCreatingNode: false,
  isRenamingNode: false,
  lastBackendCallFailed: false,
  isMovingNode: false,

  deletingNodeId: null,
  editedNodeId: null,
  editedNodeName: ''
})
