export default {
  retailer: 'enseigne',
  notIdentifiedYet: 'pas encore identifiée',
  dateOfPurchase: "Date d'achat",

  purchase: {
    newPurchaseBefore: 'Nouvel achat avant {text}',
    newPurchaseAfter: 'Nouvel achat après {text}',
    newPurchase: 'Nouvel achat',

    destroyLine: 'détruire la ligne',
    confirmLineDestroy: 'Êtes-vous sûr de vouloir supprimer cette ligne ?',
    lineDestroyed: 'ligne supprimée'
  }
}
