import { fromPairs } from 'lodash-es'

export const FIELDS = [
  'external_id',
  'email',
  'first_name',
  'last_name',
  'address_1',
  'address_2',
  'postal_code',
  'city',
  'phone_number'
]

export default () => ({
  isSearching: false,
  hasBeenSearched: false,
  cancelToken: null,
  error: null,

  text: '',
  isAdvanceSearch: false,
  fields: fromPairs(FIELDS.map((f) => [f, ''])),

  isFocused: false,

  users: [],
  hasMore: null,
  nextPaginationKey: null,
  matchStart: null,
  matchEnd: null,

  keywords: {},

  page: 1,
  isfetchingMore: false
})
