export const METRICS_CANALS = '/metrics/canals'
export const METRICS_PROGRAM = '/metrics/program'

// change this constant if you want to redirect elsewhere after login
export const HOME = METRICS_PROGRAM

export const HOME_USERS = '/users/management'
export const HOME_PROGRAMS = '/programs/management'
export const HOME_POINTS_OF_SALE = '/sources/points-of-sale'

export const HOME_METRICS_READ = METRICS_PROGRAM
export const HOME_CONTENT_READ = '/sources/points-of-sale'
export const HOME_ANIMATION_READ = '/users/segments'
export const HOME_USER_READ = '/users/management'
export const HOME_MANAGER_READ = '/administrators/managers'

export const LOGIN = '/auth/login?passwordReset=true'

export const getProgramBaseRoute = (programIdentifier) =>
  `${HOME_PROGRAMS}/${programIdentifier}`
