import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,[_c(VListItemAvatar,{attrs:{"tile":""}},[_c(VImg,{attrs:{"src":_vm.potRefund.image_url}})],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("\n      "+_vm._s(_vm.potRefund.name)+"\n    ")]),_vm._v(" "),_c(VListItemSubtitle,{class:_vm.potRefund.is_credited ? 'success--text' : 'danger--text'},[_vm._v("\n      "+_vm._s(_vm.potRefund.formatted_status)+"\n    ")])],1),_vm._v(" "),_c(VListItemAction,{staticClass:"text-h6"},[_vm._v("\n    "+_vm._s(_vm._f("money")(_vm.potRefund.amount,{ suffix: ' €' }))+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }