<template>
  <nuxt-link v-if="item.originLinkTo" :to="item.originLinkTo" target="_blank">
    <BBcode :text="item.origin_string" />
  </nuxt-link>
  <!-- no link like that atm
  <a
    v-else-if="item.originLinkUrl"
    :href="item.originLinkUrl"
    target="_blank"
  >
    <BBcode :text="item.origin_string" />
  </a>
  -->
  <span v-else>
    <BBcode :text="item.origin_string" />
  </span>
</template>

<script>
import BBcode from '~/components/shared/BBcode'
export default {
  components: { BBcode },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
