import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('crud.offers.edit.fields.bonusType.title') + ' *'))]),_vm._v(" "),_c(VRadioGroup,{attrs:{"mandatory":"","column":"","value":_vm.modified.bonusType,"disabled":_vm.disabled,"readonly":_vm.readonly},on:{"change":function($event){return _vm.updateAttribute({ key: 'bonusType', value: $event })}}},[_c(VRadio,{attrs:{"value":_vm.BONUS_TYPE_FACTOR,"label":_vm.$t('crud.offers.edit.fields.bonusType.label.multiply'),"disabled":_vm.disabled,"readonly":_vm.readonly}}),_vm._v(" "),_c(VRadio,{attrs:{"value":_vm.BONUS_TYPE_FIXED,"label":_vm.$t('crud.offers.edit.fields.bonusType.label.add'),"disabled":_vm.disabled,"readonly":_vm.readonly}})],1)],1),_vm._v(" "),_c(VCol,{attrs:{"cols":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.$t('crud.offers.edit.fields.bonusValue.title') + ' *'))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"bonusValue","mode":"eager","rules":"required|numeric|min_value:0","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c(VTextField,{class:{ 'mt-9': _vm.modified.bonusType === _vm.BONUS_TYPE_FIXED },attrs:{"label":_vm.modified.bonusType === _vm.BONUS_TYPE_FACTOR
              ? _vm.$t('crud.offers.edit.fields.bonusValue.label.multiply')
              : _vm.$t('crud.offers.edit.fields.bonusValue.label.add'),"required":"","type":"number","error-messages":errors,"disabled":_vm.disabled,"readonly":_vm.readonly},model:{value:(_vm.bonusValue),callback:function ($$v) {_vm.bonusValue=_vm._n($$v)},expression:"bonusValue"}})]}}])})],1)],1),_vm._v(" "),_c(VRow,[_c(VCol,[(_vm.modified.bonusType === _vm.BONUS_TYPE_FACTOR)?_c('ValidationProvider',{attrs:{"name":"bonusMaxQuantity","mode":"eager","rules":"integer|min_value:1","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var validate = ref.validate;
              var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":_vm.$t('crud.offers.edit.fields.bonusMaxQuantity.label'),"hint":_vm.$t('crud.offers.edit.fields.bonusMaxQuantity.hint'),"type":"number","error-messages":errors,"value":_vm.modified.bonusMaxQuantity,"disabled":_vm.disabled,"readonly":_vm.readonly},on:{"change":function($event){return _vm.updateAttribute({ key: 'bonusMaxQuantity', value: $event })}}})]}}],null,false,1451839468)}):_vm._e(),_vm._v(" "),(_vm.modified.bonusType === _vm.BONUS_TYPE_FIXED)?_c('ValidationProvider',{attrs:{"name":"bonusMinItems","mode":"eager","rules":"required|integer|min_value:1","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":_vm.$t('crud.offers.edit.fields.bonusMinItems.label'),"required":"","type":"number","error-messages":errors,"value":_vm.modified.bonusMinItems,"disabled":_vm.disabled,"readonly":_vm.readonly},on:{"change":function($event){return _vm.updateAttribute({ key: 'bonusMinItems', value: $event })}}})]}}],null,false,1020183039)}):_vm._e()],1)],1),_vm._v(" "),_c('ListAndVerifyEans',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"eans":_vm.modified.bonusProductsEans,"eans-required":""},on:{"change":function($event){return _vm.updateBonusProductsEans($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }