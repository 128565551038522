import { createLoadableState } from '@/utils/store/concerns/loadable/state'
import { createMockableState } from '@/utils/store/concerns/mockable/state'

export default () =>
  createLoadableState(
    createMockableState({
      limit: 50,
      page: 1,

      earnedCoupons: [],
      hasMore: null,

      isLoadingMore: false,
      errorOnFetchMore: null
    })
  )
