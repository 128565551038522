export const renewalRate = (state) => (item) => {
  return state.renewalStrategyPredictions.find(
    (spendingPrediction) => spendingPrediction.id === item.id
  ).renewal_rate
}
export const newComersSubscriptionRate = (state) => (item) => {
  return state.renewalStrategyPredictions.find(
    (spendingPrediction) => spendingPrediction.id === item.id
  ).new_comers_subscription_rate
}
