export default () => ({
  isLoading: false,
  isLoaded: false,
  error: null,

  events: [],
  hasMore: null,
  nextPaginationKey: null,

  isfetchingMore: false,

  selectedKinds: [],
  selectedProgramIdentifiers: []
})
