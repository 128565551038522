<template>
  <v-list-item
    :disabled="item.period.disabled"
    :value="item.period.identifier"
    @click="selectPeriod(item.period)"
  >
    <v-list-item-title v-text="text" />
  </v-list-item>
</template>

<script>
import { mapMutations } from 'vuex'
import { formatDateRange } from '@/plugins/filters'
import hasOwnProp from '@/utils/functions/hasOwnProp'
export default {
  props: {
    item: {
      type: Object,
      required: true,
      validator: (o) =>
        hasOwnProp(o, 'is') &&
        hasOwnProp(o, 'title') &&
        hasOwnProp(o, 'period') &&
        hasOwnProp(o.period, 'identifier') &&
        hasOwnProp(o.period, 'startDate') &&
        hasOwnProp(o.period, 'endDate') &&
        true
    }
  },
  computed: {
    text() {
      return (
        this.item.title ||
        formatDateRange(this.item.period.startDate, this.item.period.endDate)
      )
    }
  },
  methods: {
    ...mapMutations('metrics/program', ['selectPeriod'])
  }
}
</script>
