








import Vue from 'vue'
import SlidePage1 from './SlidePage1.vue'
import SlidePage2 from './SlidePage2.vue'
import SlidePage3 from './SlidePage3.vue'
export default Vue.extend({
  components: {
    SlidePage1,
    SlidePage2,
    SlidePage3
  }
})
