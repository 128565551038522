import { findIndex, reject, cloneDeep } from 'lodash-es'

import createDefaultState, { State, TreeNode, Denom, Product } from './state'

export const reset = (state: State) => {
  Object.assign(state, createDefaultState())
}

export const setTree = (state: State, tree: TreeNode) => {
  state.tree = tree
}
export const setErrorNode = (state: State, errorNode: TreeNode) => {
  state.errorNode = errorNode
}

export const setLoadingStateForTree = (state: State, bool: Boolean) => {
  state.isLoading.tree = bool
}
export const setLoadingStateForErrorNode = (state: State, bool: Boolean) => {
  state.isLoading.errorNode = bool
}

export const setLoadedStateForTree = (state: State, bool: Boolean) => {
  state.isLoaded.tree = bool
}
export const setLoadedStateForErrorNode = (state: State, bool: Boolean) => {
  state.isLoaded.errorNode = bool
}

export const setErrorForTree = (state: State, error: any) => {
  state.error.tree = error
}
export const setErrorForErrorNode = (state: State, error: any) => {
  state.error.errorNode = error
}

export const resetErrorForTree = (state: State) => {
  state.error.tree = null
}
export const resetErrorForErrorNode = (state: State) => {
  state.error.errorNode = null
}

export const resetSelectNodeForOrigin = (state: State) => {
  state.selectedOriginNode = null
}
export const selectNodeForOrigin = (state: State, node: TreeNode) => {
  state.selectedOriginNode = node
}
export const setSelectedOriginNodeDenoms = (state: State, denoms: Denom[]) => {
  state.selectedOriginNodeDenoms = denoms
}
export const setSelectedOriginNodeDenomsFetchingState = (
  state: State,
  bool: Boolean
) => {
  state.isFetchingSelectedOriginNodeDenoms = bool
}
export const setErrorForSelectedOriginNodeDenoms = (
  state: State,
  error: any
) => {
  state.errorForSelectedOriginNodeDenoms = error
}
export const resetErrorForSelectedOriginNodeDenoms = (state: State) => {
  state.errorForSelectedOriginNodeDenoms = null
}

export const resetDenomsToMove = (state: State) => {
  state.denomsToMove = []
}
export const selectDenomToMove = (state: State, denom: Denom) => {
  state.denomsToMove.push(denom)
}
export const unselectDenomToMove = (state: State, denom: Denom) => {
  const index = findIndex(state.denomsToMove, (d) => d.id === denom.id)
  if (index === -1) return

  state.denomsToMove.splice(index, 1)
}
export const addDenomsToMove = (state: State, denoms: Denom[]) => {
  state.denomsToMove = state.denomsToMove.concat(denoms)
}

export const resetSelectNodeForTarget = (state: State) => {
  state.selectedTargetNode = null
}
export const selectNodeForTarget = (state: State, node: TreeNode) => {
  state.selectedTargetNode = node
}
export const setSelectedTargetNodeProducts = (
  state: State,
  products: Product[]
) => {
  state.selectedTargetNodeProducts = products
}
export const setSelectedTargetNodeProductsFetchingState = (
  state: State,
  bool: Boolean
) => {
  state.isFetchingSelectedTargetNodeProducts = bool
}
export const setErrorForSelectedTargetNodeProducts = (
  state: State,
  error: any
) => {
  state.errorForSelectedTargetNodeProducts = error
}
export const resetErrorForSelectedTargetNodeProducts = (state: State) => {
  state.errorForSelectedTargetNodeProducts = null
}

export const setMovingState = (state: State, bool: Boolean) => {
  state.isMovingDenoms = bool
}
export const setValidatingState = (state: State, bool: Boolean) => {
  state.isValidatingDenoms = bool
}

export const setFilterOnDenoms = (state: State, text: string) => {
  state.filterOnDenoms = text
}
export const setFilterOnProducts = (state: State, text: string) => {
  state.filterOnProducts = text
}

// called by actions in another module :
// store/program/sources/products/tree/actions.ts

export const replaceRootNodes = (state: State, nodes: TreeNode[]) => {
  if (!state.tree) return

  state.tree.children = cloneDeep(nodes)
}

export const prependChildToNode = (
  _state: State,
  {
    node,
    child
  }: {
    node: TreeNode
    child: TreeNode
  }
) => {
  node.children.unshift(child)
}

function recursivelyExcludeNode(children: TreeNode[], node: TreeNode) {
  return reject(children, (child) => child.id === node.id).map((child) => ({
    ...child,
    children: recursivelyExcludeNode(child.children, node)
  }))
}

export const destroyNode = (state: State, node: TreeNode) => {
  if (!state.tree) return

  state.tree = {
    ...state.tree,
    children: recursivelyExcludeNode(state.tree.children, node)
  }
}

function recursivelyRenameNode(
  currentNode: TreeNode,
  nodeToRename: TreeNode,
  name: string
) {
  if (currentNode.id === nodeToRename.id) {
    currentNode.label = name
    return
  }

  currentNode.children.forEach((child) => {
    recursivelyRenameNode(child, nodeToRename, name)
  })
}

export const renameNode = (
  state: State,
  { node, name }: { node: TreeNode; name: string }
) => {
  if (!state.tree) return

  recursivelyRenameNode(state.tree, node, name)
}

function recursivelyReplaceNode(currentNode, nodeToReplace, nodeToReplaceWith) {
  if (currentNode.id === nodeToReplace.id) return nodeToReplaceWith

  return {
    ...currentNode,
    children: currentNode.children.map((child) =>
      recursivelyReplaceNode(child, nodeToReplace, nodeToReplaceWith)
    )
  }
}

export const replaceNode = (
  state: State,
  {
    nodeToReplace,
    nodeToReplaceWith
  }: {
    nodeToReplace: TreeNode
    nodeToReplaceWith: TreeNode
  }
) => {
  if (!state.tree) return

  state.tree = recursivelyReplaceNode(
    state.tree,
    nodeToReplace,
    nodeToReplaceWith
  )
}

export const setIncludeFinalDenoms = (state: State, bool: Boolean) => {
  state.includeFinalDenoms = bool
}
