import cancellableFetchFromStore from '@/utils/functions/analytics/cancellableFetch/fromStore'
import { TAB_ONBOARDING_FUNNEL } from '@/components/layouts/metrics/canals/Bar.vue'

// import payload from '@/utils/mocked-data/metrics/program/charts/sales'

export const fetch = function(ctx) {
  return cancellableFetchFromStore({
    ctx,
    store: this,
    tab: TAB_ONBOARDING_FUNNEL,
    proxy: {
      method: 'post',
      path:
        '/internal_api/sas_bo_front_end/v1/app_metrics/charts/onboarding_funnel',
      data: ctx.rootGetters['metrics/canals/paramsForBackend']
    }
  })
}

export const refresh = fetch
