import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,[_c(VTextarea,{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"outlined":"","label":_vm.$t('pages.program.catalog.createModal.adminDescription'),"value":_vm.form.adminDescription},on:{"change":_vm.changeAdminDescription}})],1)],1),_vm._v(" "),_c(VRow,[_c(VCol,[_c('ValidationProvider',{attrs:{"name":"rewardFormattedDescription","mode":"eager","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c(VTextarea,{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"outlined":"","label":_vm.$t('pages.program.catalog.createModal.formattedDescription'),"error-messages":errors,"value":_vm.form.formattedDescription},on:{"input":function($event){validate($event)
            _vm.changeRewardFormattedDescription($event)}}})]}}])})],1)],1),_vm._v(" "),_c('client-only',[_c('UploadImageFileOrUrl',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"required":_vm.isCreatingReward,"title":_vm._f("titleize")(_vm.$t('pages.program.catalog.createModal.illustration')),"image-name":"illustration","image-file":_vm.form.illustrationFile,"image-url":_vm.form.illustrationUrl,"image-preview":_vm.illustrationPreview,"image-squared":"","min-size-as-warning":3 * 500},on:{"input:file":function($event){_vm.updateAttribute({ key: 'illustrationUrl', value: null })
        _vm.updateAttribute({ key: 'illustrationFile', value: $event })},"input:url":function($event){_vm.updateAttribute({ key: 'illustrationFile', value: null })
        _vm.updateAttribute({ key: 'illustrationUrl', value: $event })}}}),_vm._v(" "),_c('UploadImageFileOrUrl',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"title":_vm._f("titleize")(_vm.$t('pages.program.catalog.createModal.backgroundImage')),"image-name":"background","image-file":_vm.form.backgroundImageFile,"image-url":_vm.form.backgroundImageUrl,"image-preview":_vm.backgroundImagePreview},on:{"input:file":function($event){_vm.updateAttribute({ key: 'backgroundImageUrl', value: null })
        _vm.updateAttribute({ key: 'backgroundImageFile', value: $event })},"input:url":function($event){_vm.updateAttribute({ key: 'backgroundImageFile', value: null })
        _vm.updateAttribute({ key: 'backgroundImageUrl', value: $event })}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }