import { Store, ActionContext } from 'vuex'

import { State } from './state'
import { RootState } from '~/store/state'

import {
  handleAxiosErrorServerFromStoreV3,
  handleAxiosErrorFrontendFromStoreV4
} from '@/utils/functions/handle-errors'

export async function fetch(
  this: Store<RootState>,
  { state, commit }: ActionContext<State, RootState>
) {
  if (state.isLoading) return

  commit('setLoadingState', true)
  commit('resetError')

  let response
  try {
    response = await this.$axios.get('/analytic_jobs')
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setLoadingState',
      mutationNameForSettingError: 'setError'
    })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStoreV4({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setLoadingState',
      mutationNameForSettingError: 'setError'
    })
    return
  }

  commit('handleResult', response.data.result)
  commit('setLoadingState', false)
  commit('setLoadedState', true)
}

export const retry = fetch

export async function create(
  this: Store<RootState>,
  { state, commit }: ActionContext<State, RootState>,
  { kind, params }: { kind: string; params: any }
) {
  if (state.isCreating) return

  commit('setCreatingState', true)
  commit('setCreateWasSuccessfulState', false)
  commit('resetError')

  let response
  try {
    response = await this.$axios.post('/analytic_jobs', {
      klass_name: kind,
      command_params: params
    })
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setCreatingState',
      errorInToast: true
    })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStoreV4({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setCreatingState',
      errorInToast: true
    })
    return
  }

  commit('addJob', response.data.result.job)
  commit('setCreateWasSuccessfulState', true)
  commit('setCreatingState', false)

  commit(
    'snackbar/addToast',
    {
      text: this.$i18n.t('pages.analyticsJobs.extractionCreatedWithSuccess')
    },
    { root: true }
  )
}

export async function deleteJob(
  this: Store<RootState>,
  { state, commit }: ActionContext<State, RootState>,
  { uuid }: { uuid: string }
) {
  if (state.isDeleting) return

  commit('setDeletingState', true)
  commit('setDeleteWasSuccessfulState', false)
  commit('resetError')

  let response
  try {
    response = await this.$axios.post(`/analytic_jobs/${uuid}/destroy`)
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setDeletingState',
      errorInToast: true
    })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStoreV4({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setDeletingState',
      errorInToast: true
    })
    return
  }

  commit('deleteJob', uuid)
  commit('setDeleteWasSuccessfulState', true)
  commit('setDeletingState', false)

  commit(
    'snackbar/addToast',
    {
      text: this.$i18n.t('pages.analyticsJobs.extractionDeletedWithSuccess')
    },
    { root: true }
  )
}
