




import Vue from 'vue'
import { mapState } from 'vuex'

import Coupon from './SlidePage2/Coupon.vue'
import Donation from './SlidePage2/Donation.vue'
import Fidz from './SlidePage2/Fidz.vue'
import Goody from './SlidePage2/Goody.vue'
import Cashback from './SlidePage2/Cashback.vue'

import {
  KIND_COUPON,
  KIND_FIDZ,
  KIND_CASHBACK,
  KIND_GOODY,
  KIND_DONATION
} from '@/store/program/catalog/state.js'

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('program/catalog', ['form']),

    is() {
      switch (this.form.kind) {
        case KIND_COUPON:
          return Coupon
        case KIND_FIDZ:
          return Fidz
        case KIND_CASHBACK:
          return Cashback
        case KIND_GOODY:
          return Goody
        case KIND_DONATION:
          return Donation
        default:
          return 'div'
      }
    }
  }
})
