<template>
  <div>
    <h3>
      {{ $t('pages.user.profile.pot.modal.transferRequest') }} -
      {{ transferPayment.created_at | momentFormat('date') }}
    </h3>

    <v-list outlined two-line>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            {{
              $t(`pages.user.profile.pot.modal.kind.${transferPayment.type}`)
            }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ transferPayment.formatted_status }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <div :style="{ color }" class="text-h6">
            {{ transferPayment.amount | money }}
          </div>
          <span v-if="transferPayment.sent_at">
            {{ $t('pages.user.profile.pot.modal.transferedOnYourAccount') }}
          </span>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-list v-if="transferPayment.potRefunds" outlined two-line>
      <ListPotRefundsItem
        v-for="potRefund in transferPayment.potRefunds"
        :key="potRefund.id"
        :pot-refund="potRefund"
      />
      <ListPotRefundsOtherAppItem
        v-for="app in transferPayment.otherAppPotRefunds"
        :key="app.id"
        :app="app"
      />
    </v-list>

    <v-list v-else-if="isLast && hasMore" outlined two-line>
      <IntersectionObserver @intersect="fetchNextTransfer" />
      <v-skeleton-loader
        class="mx-auto"
        max-width="800"
        type="list-item-two-line"
      />
      <v-skeleton-loader
        class="mx-auto"
        max-width="800"
        type="list-item-two-line"
      />
    </v-list>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ListPotRefundsItem from './ListPotRefundsItem'
import ListPotRefundsOtherAppItem from './ListPotRefundsOtherAppItem'
import IntersectionObserver from '~/components/layouts/IntersectionObserver'
import MixinPaletteFidmarques from '~/mixins/paletteFidmarques'
import {
  PARTNER_FIDMARQUES_ID,
  PARTNER_SCANOBAR_ID
} from '~/utils/constants/partner-ids'
export default {
  components: {
    IntersectionObserver,
    ListPotRefundsItem,
    ListPotRefundsOtherAppItem
  },
  mixins: [MixinPaletteFidmarques],
  props: {
    transferPayment: {
      type: Object,
      required: true
    },
    isLast: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('user/profile/pot', ['hasMore']),

    color() {
      switch (this.transferPayment.type) {
        case 'BankAccount':
          return this.paletteFidmarques.violet
        case 'PaypalAccount':
          return this.paletteFidmarques.v2.blue
      }
      return null
    }
  },
  methods: {
    ...mapActions('user/profile/pot', ['fetchMore']),

    fetchNextTransfer() {
      const editorId = this.isScanobarAdmin
        ? PARTNER_SCANOBAR_ID
        : PARTNER_FIDMARQUES_ID
      this.fetchMore({ userId: this.$route.params.userId, editorId })
    }
  }
}
</script>
