import { handleErrorAxiosCoreStore } from '@/utils/functions/handle-errors'
import { UNKNOWN_ERROR_USER } from '@/utils/constants/errors'

async function setState(commit, dispatch, result) {
  await dispatch('pushEvents', result.events)
  commit('setHasMore', result.has_more)
  commit('setNextPaginationKey', result.next_pagination_key)
}

export const pushEvents = function({ commit, getters }, events) {
  const newKinds = []
  events.forEach((e) => {
    if (getters.allEventKinds.includes(e.kind)) return
    if (newKinds.includes(e.kind)) return
    newKinds.push(e.kind)
  })
  commit('pushSelectedKinds', newKinds)

  const newProgramIdentifiers = []
  events.forEach((e) => {
    if (!e.data.program) return
    const identifier = e.data.program.identifier
    if (getters.allProgramIdentifiers.includes(identifier)) return
    if (newProgramIdentifiers.includes(identifier)) return
    newProgramIdentifiers.push(identifier)
  })
  commit('pushSelectedProgramIdentifiers', newProgramIdentifiers)

  // must be after all "getters" calls
  commit('pushEvents', events)
}

export const fetch = async function({ commit, dispatch }, { userId }) {
  commit('setLoadingState', true)
  commit('setEvents', [])
  commit('resetError')

  let response
  try {
    response = await this.$axios.get(`/users/${userId}/timeline`)
  } catch (error) {
    handleErrorAxiosCoreStore({ error, commit, store: this })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setError', UNKNOWN_ERROR_USER)
    commit('setLoadingState', false)
    return
  }

  setState(commit, dispatch, response.data.result)

  commit('setLoadedState', true)
  commit('setLoadingState', false)
}

export const fetchMore = async function(
  { state, commit, dispatch },
  { userId }
) {
  if (state.isfetchingMore) return

  commit('setFetchingMoreState', true)
  commit('resetError')

  let response
  try {
    response = await this.$axios.get(`/users/${userId}/timeline`, {
      params: { pagination_key: state.nextPaginationKey }
    })
  } catch (error) {
    handleErrorAxiosCoreStore({ error, commit, store: this })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setError', UNKNOWN_ERROR_USER)
    commit('setLoadingState', false)
    return
  }

  setState(commit, dispatch, response.data.result)

  commit('setFetchingMoreState', false)
}
