<template>
  <div class="pa-3">
    {{
      error
        ? $t(`errors.${error.isServer ? 'server' : 'user'}.${error.code}`)
        : $t('errors.unknown')
    }}

    <br v-if="withLineReturn" />

    <span v-if="doNotWorry" v-text="$t('errors.do-not-worry')" />

    <v-btn v-if="retriable" :loading="loading" small @click="$emit('retry')">
      {{ $t('global.retry') }}
    </v-btn>
  </div>
</template>

<script>
import hasOwnProp from '@/utils/functions/hasOwnProp'
export default {
  props: {
    error: {
      type: Object,
      required: true,
      validator: (o) => hasOwnProp(o, 'isServer') && hasOwnProp(o, 'code')
    },
    withLineReturn: {
      type: Boolean,
      default: false
    },
    doNotWorry: {
      type: Boolean,
      default: false
    },
    retriable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
