import createDefaultState from './state'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export const setLoadingState = (state, boolean) => {
  state.isLoading = boolean
}
export const setLoadedState = (state, boolean) => {
  state.isLoaded = boolean
}
export const setError = (state, text) => {
  state.error = text
}
export const resetError = (state) => {
  state.error = null
}

export const setUser = (state, user) => {
  state.user = user
}
export const setLoyaltyPrograms = (state, programs) => {
  state.loyaltyPrograms = programs
}
export const setLoyaltyRewards = (state, rewardsByType) => {
  state.loyaltyRewards = rewardsByType
}
export const addBanToUser = (state, params) => {
  state.user.bans.unshift(params)
}
export function addEditors(state, editors) {
  state.editors = editors
}

export function setProgramFetchingState(state, boolean) {
  state.isFetchingProgram = boolean
}
