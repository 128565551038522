<template>
  <div />
</template>

<script>
export default {
  props: {
    // https://developer.mozilla.org/fr/docs/Web/API/IntersectionObserver/IntersectionObserver
    options: {
      type: Object,
      default: undefined
    }
  },
  data: () => ({
    observer: null
  }),
  mounted() {
    const options = this.options || {}
    this.observer = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        this.$emit('intersect')
      }
    }, options)

    this.observer.observe(this.$el)
  },
  destroyed() {
    this.observer.disconnect()
  }
}
</script>
