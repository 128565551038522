<template>
  <v-list-item>
    <v-list-item-avatar tile>
      <v-img :src="app.image_url" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ app.app_name }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ app.formatted_status }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="text-h6">
      {{ app.amount | money({ suffix: ' €' }) }}
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: {
    app: {
      type: Object,
      required: true
    }
  }
}
</script>
