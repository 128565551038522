<template>
  <div>
    <h2>
      {{ $t('pages.users.modal.accountActions.selectAccount') | capitalize }}
    </h2>
    <v-chip-group
      v-model="selectedAccount"
      mandatory
      active-class="primary white--text"
    >
      <v-chip v-for="app in user.apps" :key="app.id">
        {{ app.name }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data: () => ({
    selectedAccount: 0
  }),
  computed: {
    ...mapState('user', ['user']),

    selectedApp() {
      return this.user && this.user.apps[this.selectedAccount]
    },
    editorId() {
      return this.selectedApp && this.selectedApp.id
    }
  },
  watch: {
    editorId: {
      immediate: true,
      handler() {
        this.$emit('input', this.editorId)
      }
    }
  }
}
</script>
