import Vue from 'vue'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

import {
  handleAxiosErrorServerCoreFromComponent,
  handleAxiosErrorServerAnalyticsFromComponent,
  handleAxiosErrorFrontendFromComponent
} from '@/utils/functions/handle-errors'

interface CallbackReturn {
  stop: Boolean
}

interface Options {
  $vm: Vue
  isLoadedKey?: string
  isLoadingKey: string
  errorKey?: string
  errorInToast?: boolean
  toastOptions?: Object
  axios: AxiosRequestConfig
  knownPublicErrorCodes?: string[]
  fetchEvenIfLoading?: boolean

  onErrorServer?(
    response: undefined | AxiosResponse
  ): void | boolean | Promise<boolean>
  onErrorFrontend?(response: AxiosResponse): void | boolean | Promise<boolean>
  onError?(
    response: undefined | AxiosResponse
  ): void | boolean | Promise<boolean>

  onSuccess?(result: Object): void | Promise<CallbackReturn>
  onComplete?(): void | Promise<void>
}

// do not use in auth
export const fetchCoreFromComponentAndHandleError = async (
  options: Options
): Promise<void> => {
  const { $vm, errorKey, isLoadingKey } = options

  if (!options.fetchEvenIfLoading) {
    // @ts-expect-error:next-line
    if ($vm[isLoadingKey]) return
  }

  $vm.$set($vm, isLoadingKey, true)
  if (errorKey) {
    $vm.$set($vm, errorKey, null)
  }

  let response
  try {
    response = await $vm.$axios.request(options.axios)
  } catch (error) {
    if (options.onErrorServer) {
      const shouldStop = await options.onErrorServer(response)
      if (shouldStop) return
    }
    await handleAxiosErrorServerCoreFromComponent({
      $vm,
      error,
      errorKey,
      errorInToast: options.errorInToast,
      toastOptions: options.toastOptions
    })
    if (options.onError) {
      const shouldStop = await options.onError(response)
      if (shouldStop) return
    }
    $vm.$set($vm, isLoadingKey, false)

    if (options.onComplete) {
      await options.onComplete()
    }
    return
  }

  if (!response.data.success) {
    if (options.onErrorFrontend) {
      const shouldStop = await options.onErrorFrontend(response)
      if (shouldStop) return
    }
    await handleAxiosErrorFrontendFromComponent({
      $vm,
      response,
      knownPublicErrorCodes: options.knownPublicErrorCodes || [],
      errorKey,
      errorInToast: options.errorInToast,
      toastOptions: options.toastOptions
    })
    if (options.onError) {
      const shouldStop = await options.onError(response)
      if (shouldStop) return
    }
    $vm.$set($vm, isLoadingKey, false)

    if (options.onComplete) {
      await options.onComplete()
    }
    return
  }

  if (options.onSuccess) {
    const res = await options.onSuccess(response.data.result)
    if (res && res.stop) return
  }

  $vm.$set($vm, isLoadingKey, false)
  if (options.isLoadedKey) {
    $vm.$set($vm, options.isLoadedKey, true)
  }

  if (options.onComplete) {
    await options.onComplete()
  }
}

export const fetchAnalyticsFromComponentAndHandleError = async (
  options: Options
): Promise<void> => {
  const { $vm, errorKey, isLoadingKey } = options

  // @ts-expect-error:next-line
  if ($vm[isLoadingKey]) return

  $vm.$set($vm, isLoadingKey, true)
  if (errorKey) {
    $vm.$set($vm, errorKey, null)
  }

  let response
  try {
    response = await $vm.$axios.request({
      method: 'POST',
      url: '/api/proxy/analytics',
      data: options.axios,
      baseURL: '/'
    })
  } catch (error) {
    if (options.onErrorServer) {
      const shouldStop = await options.onErrorServer(response)
      if (shouldStop) return
    }
    await handleAxiosErrorServerAnalyticsFromComponent({
      $vm,
      error,
      errorKey,
      errorInToast: options.errorInToast,
      toastOptions: options.toastOptions
    })
    if (options.onError) {
      const shouldStop = await options.onError(response)
      if (shouldStop) return
    }
    $vm.$set($vm, isLoadingKey, false)

    if (options.onComplete) {
      await options.onComplete()
    }
    return
  }

  if (!response.data.success) {
    if (options.onErrorFrontend) {
      const shouldStop = await options.onErrorFrontend(response)
      if (shouldStop) return
    }
    await handleAxiosErrorFrontendFromComponent({
      $vm,
      response,
      knownPublicErrorCodes: options.knownPublicErrorCodes || [],
      errorKey,
      errorInToast: options.errorInToast,
      toastOptions: options.toastOptions
    })
    if (options.onError) {
      const shouldStop = await options.onError(response)
      if (shouldStop) return
    }
    $vm.$set($vm, isLoadingKey, false)

    if (options.onComplete) {
      await options.onComplete()
    }
    return
  }

  if (options.onSuccess) {
    await options.onSuccess(response.data.result)
  }

  $vm.$set($vm, isLoadingKey, false)
  if (options.isLoadedKey) {
    $vm.$set($vm, options.isLoadedKey, true)
  }

  if (options.onComplete) {
    await options.onComplete()
  }
}
