export default () => ({
  isGalleryShown: false,

  scrollContainer: null,
  scrollAnchor: null,
  isScrolling: false,
  isScrollingDone: false,

  isMenuMinimized: false,

  isDeclarationHeaderRefundsOpen: false
})
