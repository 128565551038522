import Vue from 'vue'
import { cloneDeep } from 'lodash-es'

import createDefaultState, { State, Descriptions, Images } from './state'

export * from '@/utils/store/concerns/loadable/mutations.js'

export const reset = (state: State) => {
  Object.assign(state, createDefaultState())
}

export const handleFetchResult = (
  state: State,
  result: { descriptions: Descriptions; images: Images }
) => {
  state.descriptions = result.descriptions
  state.images = result.images

  state.original = cloneDeep(result.descriptions)
  state.modified = cloneDeep(result.descriptions)
}

export const updateAttribute = (
  state: State,
  { key, value }: { key: string; value: any }
) => {
  Vue.set(state.modified as Descriptions, key, value)
}
