import Vue from 'vue'
import { Notifier } from '@airbrake/browser'

import logger from '@/utils/logger'
import isDev from '@/utils/constants/is-dev'
import {
  createInjecter,
  createPlugin,
  extend,
  instantiate
} from '@/utils/airbrake-nuxt'

class NotifierNuxtClient extends extend(Notifier) {
  constructor(opts) {
    super(opts)

    this.initializeNuxtClientFilters()
  }

  initializeNuxtClientFilters() {
    this.addFilter((notice) => {
      if (!isDev) return notice

      logger.log('AIRBRAKE : ' + notice.errors[0].message)
      logger.log(notice)
      return null
    })
  }
}

export const notifier = instantiate(NotifierNuxtClient)
Vue.use(createPlugin(notifier))

export default createInjecter(notifier)
