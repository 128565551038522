import { createLoadableState } from '@/utils/store/concerns/loadable/state'
import { createCancellableState } from '@/utils/store/concerns/cancellable/state'

export default () =>
  createLoadableState(
    createCancellableState({
      declarations: [],

      selectedAppIds: [],

      hasMore: false,
      nextPaginationKey: null,
      isFetchingMore: false
    })
  )
