















































import Vue from 'vue'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'

import GiftActions from './GiftActions.vue'
import RewardsList from './RewardsList.vue'

const tabs = ['active', 'inactive']
const defaultTabIndex = 0

interface Tab {
  title: string
  key: string
}

export default Vue.extend({
  components: {
    GiftActions,
    RewardsList
  },
  data() {
    return {
      tabs: tabs.map((tab) => ({
        title: this.$t(`pages.program.catalog.reward.${tab}`),
        key: tab
      })) as Tab[],
      currentTabIndex: defaultTabIndex,

      rowToDelete: {},
      newRow: {}
    }
  },
  computed: {
    currentTab(): Tab {
      return this.tabs[this.currentTabIndex]
    },

    ...mapState('program/catalog', ['isLoading', 'isLoaded', 'error']),
    ...mapGetters('program/catalog', ['availableRewards', 'unavailableRewards'])
  },
  mounted() {
    this.fetchRewards()
  },
  destroyed() {
    this.resetRewards()
  },
  methods: {
    ...mapActions('program/catalog', ['fetchRewards']),
    ...mapMutations('program/catalog', ['resetRewards'])
  }
})
