<template>
  <div v-if="noLink" />
  <TheSettingsSingleLink v-else-if="onlyOneLink" :link="links[0]" />
  <TheSettingsSpeedDial v-else :links="links" />
</template>

<script>
import { mapGetters } from 'vuex'
import qs from 'qs'

import TheSettingsSingleLink from '~/components/layouts/TheSettingsSingleLink.vue'
import TheSettingsSpeedDial from '~/components/layouts/TheSettingsSpeedDial.vue'

import { PROGRAM_SELECT_PATH } from '~/plugins/vuex-router-sync'

export default {
  components: {
    TheSettingsSingleLink,
    TheSettingsSpeedDial
  },
  computed: {
    ...mapGetters('auth', [
      'permittedActions',
      'onlyOnePermittedProgram',
      'isPurcheaseAdmin'
    ]),

    links() {
      const links = []

      if (
        !this.onlyOnePermittedProgram &&
        this.$route.path !== PROGRAM_SELECT_PATH
      ) {
        links.push({
          key: 'change-program',
          icon: 'mdi-swap-horizontal',
          tooltip: this.$t('layout.settings.changeProgram'),
          to: this.changeProgramLink
        })
      }
      if (
        this.permittedActions.includes('content_write') &&
        this.$route.path !== '/create-program'
      ) {
        links.push({
          key: 'create-program',
          icon: 'mdi-plus',
          tooltip: this.$t('layout.settings.createProgram'),
          to: '/create-program'
        })
      }
      if (
        this.permittedActions.includes('manager_read') &&
        this.$route.path !== '/administrators/managers'
      ) {
        links.push({
          key: 'manage-admins',
          icon: 'mdi-account-group',
          tooltip: this.$t('layout.settings.manageAdmins'),
          to: '/administrators/managers'
        })
      }
      if (this.isPurcheaseAdmin) {
        links.push({
          key: 'analytics-jobs',
          icon: 'mdi-poll',
          tooltip: this.$t('layout.settings.analyticsJobs'),
          to: '/analytics_jobs'
        })
      }

      return links
    },
    noLink() {
      return this.links.length === 0
    },
    onlyOneLink() {
      return this.links.length === 1
    },

    changeProgramLink() {
      if (this.$route.fullPath === '/') {
        return PROGRAM_SELECT_PATH
      }

      const query = qs.stringify(
        { r: this.$route.fullPath },
        { arrayFormat: 'brackets' }
      )
      return `${PROGRAM_SELECT_PATH}?${query}`
    }
  }
}
</script>
