<template>
  <li
    class="declaration"
    :class="{
      'declaration--selected': isSelected
    }"
  >
    <div
      v-if="isEligible !== null"
      class="declaration__eligibility"
      :class="{
        green: isEligible === true,
        red: isEligible === false
      }"
    />
    <component
      :is="to ? 'nuxt-link' : 'div'"
      :to="to"
      class="declaration__link"
    >
      <v-container fluid>
        <v-row no-gutters>
          <v-col class="declaration__left">
            <div class="wrapper-img">
              <slot name="image" v-bind="{ size: 32, height: 32, width: 32 }" />
            </div>

            <v-tooltip
              v-if="nextTimelineEvent"
              top
              content-class="header__tooltip"
            >
              <template #activator="{ on, attrs }">
                <div class="declaration__timeline" v-bind="attrs" v-on="on">
                  <DeclarationTimelineIcon
                    :event="nextTimelineEvent"
                    :size="32"
                    inverse
                  />
                </div>
              </template>
              <DeclarationTimeline :events="timelineEvents" />
            </v-tooltip>
          </v-col>
          <v-col>
            <slot name="intersect" />

            <h5
              class="declaration__retailer-name"
              :class="{
                'declaration__retailer-name--selected': isSelected
              }"
            >
              <slot name="title" />
            </h5>
            <div
              v-if="$scopedSlots.subtitle"
              class="declaration__datetime"
              :class="{ 'declaration__datetime--selected': isSelected }"
            >
              <slot name="subtitle" />
            </div>
            <div class="declaration__captured-at">
              <slot name="subtitle-2" />
            </div>
            <div class="declaration__id">
              <slot name="id" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </component>

    <v-tooltip v-if="hasZendeskTickets" top>
      <template #activator="{ on, attrs }">
        <v-icon class="declaration__zendesk" v-bind="attrs" v-on="on">
          mdi-account-question
        </v-icon>
      </template>
      <span>{{ $t('pages.declarations.zendeskTicketsOnDeclaration') }}</span>
    </v-tooltip>
  </li>
</template>

<script>
import DeclarationTimeline from '~/components/features/declaration/Timeline'
import DeclarationTimelineIcon from '~/components/features/declaration/TimelineIcon'
export default {
  components: {
    DeclarationTimeline,
    DeclarationTimelineIcon
  },
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    isEligible: {
      type: [Boolean, null],
      default: null
    },
    to: {
      type: String,
      default: undefined
    },
    hasZendeskTickets: {
      type: Boolean,
      default: false
    },
    nextTimelineEvent: {
      type: Object,
      default: null
    },
    timelineEvents: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/variables.scss';

.declaration {
  position: relative;
  border-top: solid 1px #bdbdbd;
}
.declaration:last-child {
  border-bottom: solid 1px #bdbdbd;
}
.declaration--selected {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  position: relative;
}
.declaration--selected::after {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 20px 15px 0;
  border-color: transparent $declaration-background-color transparent
    transparent;
}
.declaration--selected + .declaration {
  border-top: solid 1px transparent;
}

.declaration__eligibility {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
}

.declaration__link {
  display: block;
  color: #000;
  text-decoration: none;
}

.declaration__left {
  flex: 0 0 32px;
  min-width: 32px;
  max-width: 32px;
  margin-right: 12px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wrapper-img {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 5px;
}

.declaration__retailer-name {
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
}
.declaration__retailer-name--selected {
  font-weight: bold;
}
.declaration__datetime {
  font-size: 14px;
  font-weight: normal;
}
.declaration__datetime--selected {
  font-weight: bold;
}

.declaration__captured-at,
.declaration__id {
  font-size: 12px;
  font-weight: bold;
  color: #757575;
}
.declaration__captured-at {
  margin-top: 5px;
}

.declaration__zendesk {
  position: absolute;
  bottom: 5px;
  right: 10px;
}
</style>

<style lang="scss" scoped>
@import '~/assets/variables.scss';
@import 'node_modules/vuetify/src/styles/styles.sass';
@import 'node_modules/vuetify/src/styles/tools/_elevation.sass';

$tooltip-background-color: white;
$tooltip-triangle-side-size: 12px;
$sqrt2: 1.414;

.header__tooltip {
  background-color: $tooltip-background-color;
  color: black;

  @include elevation(5);
}
// // https://css-tricks.com/triangle-with-shadow/
// // https://codepen.io/ryanmcnz/pen/JDLhu
// .header__tooltip::before {
//   position: absolute;
//   top: 0;
//   margin-left: round(-$tooltip-triangle-side-size * $sqrt2 / 2);

//   content: '';
//   width: 0;
//   height: 0;
//   background-color: white;

//   transform-origin: 0 0;
//   transform: rotate(-45deg);

//   box-shadow: 1px -1px 2px rgba(0, 0, 0, 0.2);

//   border: #{$tooltip-triangle-side-size / 2} solid transparent;
//   border-color: $tooltip-background-color $tooltip-background-color transparent
//     transparent;
// }
// .header__tooltip--with-app-image:before {
//   right: 80px;
// }
// .header__tooltip--without-app-image:before {
//   right: 20px;
// }
// .header__tooltip--centered::before {
//   left: 50%;
// }
</style>
