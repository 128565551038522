






import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import ShelfLoading from './ShelfLoading.vue'
import ShelfLoaded from './ShelfLoaded.vue'

export default Vue.extend({
  components: { ShelfLoaded, ShelfLoading },
  computed: {
    ...mapState('program/catalog/shelf', ['isLoading', 'isLoaded'])
  },
  mounted() {
    this.fetchShelves()
  },
  methods: {
    ...mapActions('program/catalog/shelf', ['fetchShelves'])
  }
})
