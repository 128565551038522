import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',[_vm._v("\n    "+_vm._s(_vm.$t('layout.program.importProducts.preview.actionsTahtWillBeTaken'))+"\n  ")]),_vm._v(" "),_c(VDataTable,{attrs:{"headers":_vm.reportsHeaders,"items":_vm.reports,"items-per-page":_vm.itemsPerPage},scopedSlots:_vm._u([{key:"item.is_ean_found",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"lighten-4",class:item.is_ean_found ? 'green' : 'orange'},[_vm._v("\n        "+_vm._s(item.is_ean_found
            ? _vm.$t('layout.program.importProducts.preview.productExists')
            : _vm.$t('layout.program.importProducts.preview.productWillBeCreated'))+"\n      ")])]}},{key:"item.is_ean_dupe",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"lighten-4",class:item.is_ean_dupe ? 'red' : 'green'},[_vm._v("\n        "+_vm._s(item.is_ean_dupe
            ? _vm.$t('layout.program.importProducts.preview.eanOnAPreviousLine')
            : null)+"\n      ")])]}},{key:"item.brand",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"lighten-4",class:item.is_brand_found ? 'green' : 'red'},[_vm._v("\n        "+_vm._s(item.brand)+"\n      ")])]}},{key:"item.is_brand_found",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"lighten-4",class:item.is_brand_found ? 'green' : 'red'},[_vm._v("\n        "+_vm._s(item.is_brand_found
            ? _vm.$t('layout.program.importProducts.preview.brandExists')
            : _vm.$t(
                'layout.program.importProducts.preview.brandMustBeCreatedBefore'
              ))+"\n      ")])]}}])}),_vm._v(" "),_c('h4',[_vm._v("\n    "+_vm._s(_vm.$t('layout.program.importProducts.preview.productGroupsTreeView'))+"\n  ")]),_vm._v(" "),_c(VTreeview,{attrs:{"hoverable":"","dense":"","items":_vm.treeForVuetity,"open":_vm.openNodes},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
              var item = ref.item;
              var open = ref.open;
return [(item.children)?_c(VIcon,[_vm._v("\n        "+_vm._s(open ? 'mdi-folder-open' : 'mdi-folder')+"\n      ")]):_c(VIcon,[_vm._v("\n        mdi-cart\n      ")])]}}])}),_vm._v(" "),_c(VCardActions,[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"text":"","disabled":_vm.isSending},on:{"click":function($event){return _vm.$emit('go-to-prev-step')}}},[_vm._v("\n      "+_vm._s(_vm.$t('global.previous'))+"\n    ")]),_vm._v(" "),_c(VTooltip,{attrs:{"top":"","disabled":!_vm.hasErrors},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var attrs = ref.attrs;
              var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block"},'div',attrs,false),on),[_c(VBtn,{attrs:{"disabled":_vm.hasErrors,"color":"primary","loading":_vm.isSending},on:{"click":_vm.submit}},[_vm._v("\n            "+_vm._s(_vm.$t('layout.program.importProducts.import'))+"\n          ")])],1)]}}])},[_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('layout.program.importProducts.preview.fileHasSomeErrors'))+"\n        "+_vm._s(_vm.$t(
            'layout.program.importProducts.preview.youNeedToCorrectThemToImport'
          ))+"\n      ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }