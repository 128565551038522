import createDefaultState from './state'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export const setLoadingState = (state, boolean) => {
  state.isLoading = boolean
}
export const setLoadedState = (state, boolean) => {
  state.isLoaded = boolean
}
export const setError = (state, text) => {
  state.error = text
}
export const resetError = (state) => {
  state.error = null
}

export const setMessages = (state, messages) => {
  state.messages = messages
}
export const pushMessages = (state, messages) => {
  state.messages = state.messages.concat(messages)
}
export const setHasMore = (state, hasMore) => {
  state.hasMore = hasMore
}
export const setNextPaginationKey = (state, nextPaginationKey) => {
  state.nextPaginationKey = nextPaginationKey
}
export const setFetchingMoreState = (state, boolean) => {
  state.isFetchingMore = boolean
}
export const setExperimental = (state, boolean) => {
  state.isExperimental = boolean
}
