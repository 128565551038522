import moment from 'moment'

export const periodFromExpiredMonthsOfCurrentPeriod = (
  $t,
  currentPeriodStartDate,
  isCurrentMonthStartMonthOfPeriod
) => {
  return {
    identifier: 'expiredMonths',
    disabled: isCurrentMonthStartMonthOfPeriod,
    tooltip: isCurrentMonthStartMonthOfPeriod
      ? $t('layout.metrics.periods.currentMonthIsStartMonthOfPeriod')
      : null,
    title: $t('layout.metrics.periods.expiredMonths'),
    startDate: moment(currentPeriodStartDate).format('YYYY-MM-DD'),
    endDate: moment()
      .subtract(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD')
  }
}

export const periodFromPreviousMonth = ($t) => {
  const oneMonthAgo = moment().subtract(1, 'month')
  return {
    identifier: 'previousMonth',
    title: $t('layout.metrics.periods.previousMonth'),
    startDate: oneMonthAgo
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD'),
    endDate: oneMonthAgo
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD')
  }
}

export const periodFromCurrentMonth = ($t) => {
  return {
    identifier: 'currentMonth',
    title: $t('layout.metrics.periods.currentMonth'),
    startDate: moment()
      .startOf('month')
      .format('YYYY-MM-DD'),
    endDate: moment()
      .subtract(1, 'day')
      .format('YYYY-MM-DD')
  }
}

export const periodFromExpiredTwelveMonths = ($t) => {
  const oneMonthAgo = moment().subtract(1, 'month')
  return {
    identifier: 'expiredTwelveMonths',
    title: $t('layout.metrics.periods.expiredTwelveMonths'),
    startDate: moment()
      .subtract(1, 'year')
      .startOf('month')
      .format('YYYY-MM-DD'),
    endDate: oneMonthAgo
      .clone()
      .endOf('month')
      .format('YYYY-MM-DD')
  }
}
