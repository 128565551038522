import Vue from 'vue'
import { findIndex } from 'lodash-es'

import createDefaultState from './state'

export * from '@/utils/store/concerns/loadable/mutations'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export const setData = (state, data) => {
  if (!data) return

  state.profile = data.profile
}

export const updatePaymentMethod = (state, paymentMethod) => {
  if (!state.profile) return

  const index = findIndex(
    state.profile.payment_methods,
    (p) => p.id === paymentMethod.id
  )
  if (index === -1) return

  Vue.set(state.profile.payment_methods, index, paymentMethod)
}

export const upsertPaymentMethodByAppName = (state, paymentMethod) => {
  if (!state.profile) return

  const index = findIndex(
    state.profile.payment_methods,
    (p) => p.app_name === paymentMethod.app_name
  )
  if (index === -1) {
    state.profile.payment_methods.push(paymentMethod)
  } else {
    Vue.set(state.profile.payment_methods, index, paymentMethod)
  }
}
