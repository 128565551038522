import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c('h3',[_vm._v("\n        "+_vm._s(_vm.$t('crud.offers.edit.fields.groupedRefund.type.title'))+"\n      ")]),_vm._v(" "),_c(VRadioGroup,{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"value":_vm.modified.groupedRefundType},on:{"change":function($event){return _vm.updateAttribute({ key: 'groupedRefundType', value: $event })}}},[_c(VRadio,{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.disabled ||
              _vm.modified.groupedRefundType === _vm.GROUPED_OFFER_TYPE_PERCENT
          ),expression:"\n            !disabled ||\n              modified.groupedRefundType === GROUPED_OFFER_TYPE_PERCENT\n          "}],attrs:{"off-icon":"mdi-percent-outline","on-icon":"mdi-percent","value":_vm.GROUPED_OFFER_TYPE_PERCENT,"label":_vm.$t(
              ("crud.offers.edit.fields.groupedRefund.type.label." + _vm.GROUPED_OFFER_TYPE_PERCENT)
            )}}),_vm._v(" "),_c(VRadio,{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.disabled ||
              _vm.modified.groupedRefundType === _vm.GROUPED_OFFER_TYPE_ABSOLUTE
          ),expression:"\n            !disabled ||\n              modified.groupedRefundType === GROUPED_OFFER_TYPE_ABSOLUTE\n          "}],attrs:{"off-icon":"mdi-currency-eur","on-icon":"mdi-currency-eur","value":_vm.GROUPED_OFFER_TYPE_ABSOLUTE,"label":_vm.$t(
              ("crud.offers.edit.fields.groupedRefund.type.label." + _vm.GROUPED_OFFER_TYPE_ABSOLUTE)
            )}}),_vm._v(" "),_c(VRadio,{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.disabled ||
              _vm.modified.groupedRefundType === _vm.GROUPED_OFFER_TYPE_QUANTITY
          ),expression:"\n            !disabled ||\n              modified.groupedRefundType === GROUPED_OFFER_TYPE_QUANTITY\n          "}],attrs:{"off-icon":"mdi-close","on-icon":"mdi-close","value":_vm.GROUPED_OFFER_TYPE_QUANTITY,"label":_vm.$t(
              ("crud.offers.edit.fields.groupedRefund.type.label." + _vm.GROUPED_OFFER_TYPE_QUANTITY)
            )}})],1)],1),_vm._v(" "),_c(VCol,{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"groupedRefundQuota","mode":"eager","rules":"required|integer|min_value:1","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c(VTextField,{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"label":_vm.$t('crud.offers.edit.fields.groupedRefund.quota.label') + ' *',"hint":_vm.$t('crud.offers.edit.fields.groupedRefund.quota.hint'),"persistent-hint":"","required":"","type":"number","min":"0","error-messages":errors,"value":_vm.modified.groupedRefundQuota},on:{"change":function($event){return _vm.updateAttribute({ key: 'groupedRefundQuota', value: $event })}}})]}}])})],1)],1),_vm._v(" "),_c(VRow,[_c(VCol,[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c(VToolbarTitle,[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v("mdi-poll")]),_vm._v("\n            "+_vm._s(_vm.$t('crud.offers.edit.fields.groupedRefund.variants.label'))+"\n            "),_c(VIcon,{attrs:{"right":"","dark":""},on:{"click":function($event){_vm.isVariantsHintOpen = !_vm.isVariantsHintOpen}}},[_vm._v("\n              mdi-information-outline\n            ")])],1),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(!_vm.readonly && _vm.hasVariants),expression:"!readonly && hasVariants"}],attrs:{"icon":""},on:{"click":_vm.removeLastVariantAndOpenLast}},[_c(VIcon,[_vm._v("mdi-minus")])],1),_vm._v(" "),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(!_vm.readonly),expression:"!readonly"}],attrs:{"icon":""},on:{"click":_vm.addVariantAndOpenIt}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1),_vm._v(" "),_c(VCard,{directives:[{name:"show",rawName:"v-show",value:(_vm.isVariantsHintOpen),expression:"isVariantsHintOpen"}],attrs:{"flat":""}},[_c(VCardText,[_vm._l((2),function(index){return _c('div',{key:index},[_vm._v("\n              "+_vm._s(_vm.$t(
                  ("crud.offers.edit.fields.groupedRefund.variants.hint.line" + index)
                ))+"\n            ")])}),_vm._v(" "),_c('ul',_vm._l((2),function(index){return _c('li',{key:index},[_vm._v("\n                "+_vm._s(_vm.$t(
                    ("crud.offers.edit.fields.groupedRefund.variants.hint.example" + index)
                  ))+"\n              ")])}),0)],2)],1),_vm._v(" "),_c(VTabs,{attrs:{"background-color":"primary","center-active":"","dark":""},model:{value:(_vm.tabSelectedForVariants),callback:function ($$v) {_vm.tabSelectedForVariants=$$v},expression:"tabSelectedForVariants"}},[_c(VTab,[_vm._v("\n            "+_vm._s(_vm.$t('crud.offers.edit.fields.groupedRefund.variants.tabs', {
                index: 1
              }))+"\n          ")]),_vm._v(" "),_vm._l((_vm.tabsForVariants),function(tab){return _c(VTab,{key:tab.index},[_vm._v("\n            "+_vm._s(tab.title)+"\n          ")])})],2),_vm._v(" "),_c(VCardText,[_c(VTabsItems,{model:{value:(_vm.tabSelectedForVariants),callback:function ($$v) {_vm.tabSelectedForVariants=$$v},expression:"tabSelectedForVariants"}},[_c(VTabItem,[_c('GroupedRefundValueAndMinItems',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"refund-value":_vm.modified.groupedRefundValue,"min-items":_vm.modified.groupedRefundMinItems},on:{"change:refund-value":function($event){return _vm.updateAttribute({
                    key: 'groupedRefundValue',
                    value: $event
                  })},"change:min-items":function($event){return _vm.updateAttribute({
                    key: 'groupedRefundMinItems',
                    value: $event
                  })}}})],1),_vm._v(" "),_vm._l((_vm.tabsForVariants),function(tab){return _c(VTabItem,{key:tab.index},[_c('GroupedRefundValueAndMinItems',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"refund-value":_vm.modified.groupedRefundVariants[tab.index].refundValue,"min-items":_vm.modified.groupedRefundVariants[tab.index].minItems},on:{"change:refund-value":function($event){return _vm.updateVariantAttribute({
                    index: tab.index,
                    key: 'refundValue',
                    value: $event
                  })},"change:min-items":function($event){return _vm.updateVariantAttribute({
                    index: tab.index,
                    key: 'minItems',
                    value: $event
                  })}}})],1)})],2)],1)],1)],1)],1),_vm._v(" "),_c(VRow,[_c(VCol,[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_c(VToolbarTitle,[_c(VIcon,{attrs:{"left":"","dark":""}},[_vm._v("mdi-barcode")]),_vm._v("\n            "+_vm._s(_vm.$t('crud.offers.edit.fields.groupedRefund.productsRanges.title'))+"\n            "),_c(VIcon,{attrs:{"right":"","dark":""},on:{"click":function($event){_vm.isProductsRangesHintOpen = !_vm.isProductsRangesHintOpen}}},[_vm._v("\n              mdi-information-outline\n            ")])],1),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(!_vm.readonly && _vm.twoOrMoreProductsRanges),expression:"!readonly && twoOrMoreProductsRanges"}],attrs:{"icon":""},on:{"click":_vm.removeLastProductAndOpenLast}},[_c(VIcon,[_vm._v("mdi-minus")])],1),_vm._v(" "),_c(VBtn,{directives:[{name:"show",rawName:"v-show",value:(!_vm.readonly),expression:"!readonly"}],attrs:{"icon":""},on:{"click":_vm.addProductAndOpenIt}},[_c(VIcon,[_vm._v("mdi-plus")])],1)],1),_vm._v(" "),_c(VCard,{directives:[{name:"show",rawName:"v-show",value:(_vm.isProductsRangesHintOpen),expression:"isProductsRangesHintOpen"}],attrs:{"flat":""}},[_c(VCardText,_vm._l((3),function(index){return _c('div',{key:index},[_vm._v("\n              "+_vm._s(_vm.$t(
                  ("crud.offers.edit.fields.groupedRefund.productsRanges.hint.line" + index)
                ))+"\n            ")])}),0)],1),_vm._v(" "),_c(VTabs,{attrs:{"background-color":"primary","center-active":"","dark":""},model:{value:(_vm.tabSelectedForProductsRanges),callback:function ($$v) {_vm.tabSelectedForProductsRanges=$$v},expression:"tabSelectedForProductsRanges"}},_vm._l((_vm.tabsForProductsRanges),function(tab){return _c(VTab,{key:tab.index},[_vm._v("\n            "+_vm._s(tab.title)+"\n          ")])}),1),_vm._v(" "),_c(VCardText,[_c(VTabsItems,{model:{value:(_vm.tabSelectedForProductsRanges),callback:function ($$v) {_vm.tabSelectedForProductsRanges=$$v},expression:"tabSelectedForProductsRanges"}},_vm._l((_vm.tabsForProductsRanges),function(tab){return _c(VTabItem,{key:tab.index},[_c(VCard,{staticClass:"mt-2",attrs:{"flat":""}},[_c('ListAndVerifyEans',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"eans":_vm.modified.groupedRefundProductsRanges[tab.index],"eans-required":""},on:{"change":function($event){return _vm.changeEansOnGroupedRefunds({
                      index: tab.index,
                      value: $event
                    })}}})],1)],1)}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }