import { VInput } from 'vuetify/lib/components/VInput';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationProvider',{attrs:{"name":"productsEans","mode":"aggressive","rules":_vm.rules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextarea,{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"prepend-inner-icon":"mdi-barcode","label":("" + (_vm.$t('pages.program.catalog.createModal.fillEAN')) + (_vm.eansRequired ? ' *' : '')),"outlined":"","loading":_vm.areProductsLoading,"hide-details":"auto","error-messages":errors},model:{value:(_vm.textareaValue),callback:function ($$v) {_vm.textareaValue=$$v},expression:"textareaValue"}})]}}])}),_vm._v(" "),(_vm.isPurcheaseAdmin && _vm.anyEan && _vm.areEansValid)?_c('ValidationProvider',{attrs:{"name":"productsEansAllExist","mode":"aggressive","rules":_vm.rulesBis,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c(VInput,{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"loading":_vm.areProductsLoading,"error-messages":errors},model:{value:(_vm.productsByEan),callback:function ($$v) {_vm.productsByEan=$$v},expression:"productsByEan"}})]}}],null,false,3406965048)}):_vm._e(),_vm._v(" "),(_vm.isPurcheaseAdmin && _vm.anyEan && _vm.areEansValid)?_c('ProductsList',{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"eans":_vm.eans}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }