import { createLoadableState } from '@/utils/store/concerns/loadable/state'

export default () =>
  createLoadableState({
    currentPeriod: null,
    previousPeriods: [],
    partners: [],

    isPeriodSelected: false,
    refreshedAt: '',
    selectedPartnerIds: [],
    selectedPeriod: null,

    // TODO: get from admin or select
    currentProgramIdentifier: '',

    tabErrors: {}
  })
