import {
  PROGRAM_KIND_POINTS_VIA_PRICE,
  PROGRAM_KIND_STAMPS,
  PROGRAM_KIND_PUZZLE,
  PROGRAM_KINDS_WITH_FIELD_REQUIRED_POINTS,
  PROGRAM_KINDS_WITH_POINTS
} from '~/store/program/state.js'

import { getProgramBaseRoute } from '@/utils/constants/routes'

export const hasError = (state) => !!state.error
export const isLogged = (state) => !!state.admin
export const isScanobarAdmin = (state) => state.admin && state.admin.is_scanobar
export const isManager = (state) => state.admin && state.admin.is_manager
export const isPurcheaseAdmin = (state) =>
  state.admin && state.admin.is_purchease

export const permittedActions = (state) =>
  state.admin ? state.admin.permitted_actions : []
export const permittedApps = (state) =>
  state.admin ? state.admin.permitted_apps : []

export const hasWriteContentRight = (state) =>
  state.admin ? state.admin.permitted_actions.includes('content_write') : false
export const hasReadContentRight = (state) =>
  state.admin ? state.admin.permitted_actions.includes('content_read') : false
export const hasReadAnimationRight = (state) =>
  state.admin ? state.admin.permitted_actions.includes('content_read') : false
export const hasWriteAnimationRight = (state) =>
  state.admin
    ? state.admin.permitted_actions.includes('animation_write')
    : false
export const hasWriteManagerRight = (state) =>
  state.admin ? state.admin.permitted_actions.includes('manager_write') : false
export const hasWriteUserRight = (state) =>
  state.admin ? state.admin.permitted_actions.includes('user_write') : false

export const permittedPrograms = (state) =>
  state.admin ? state.admin.permitted_programs : []

export const onlyOnePermittedProgram = (_state, getters) =>
  getters.permittedPrograms.length === 1
export const hasManyPermittedPrograms = (_state, getters) =>
  getters.permittedPrograms.length > 1
export const admin = (state) => state.admin

export const isProgramSelected = (state) => !!state.selectedProgram

export const selectedProgramIdentifierForAnalytics = (state, getters) => {
  if (!getters.isProgramSelected) return

  const parts = state.selectedProgram.identifier.split('-')
  const id = parts[parts.length - 1]
  return `${parts.slice(0, -1).join('_')}-${id}`
}

export const isSelectedProgramWithPoints = (state) =>
  state.selectedProgram &&
  state.selectedProgram.kind === PROGRAM_KIND_POINTS_VIA_PRICE

export const isSelectedProgramWithStamps = (state) =>
  state.selectedProgram && state.selectedProgram.kind === PROGRAM_KIND_STAMPS

export const isSelectedProgramWithPuzzle = (state) =>
  state.selectedProgram && state.selectedProgram.kind === PROGRAM_KIND_PUZZLE

export const isSelectedProgramWithAllKindsOfPoints = (state) =>
  state.selectedProgram &&
  PROGRAM_KINDS_WITH_POINTS.includes(state.selectedProgram.kind)

export const programBaseRoute = (state) => {
  return (
    state.selectedProgram &&
    getProgramBaseRoute(state.selectedProgram.identifier)
  )
}

export const displayRequiredPointsForSelectedProgram = (state) =>
  state.selectedProgram &&
  PROGRAM_KINDS_WITH_FIELD_REQUIRED_POINTS.includes(state.selectedProgram.kind)

export const useCore = (state) =>
  state.selectedProgram?.is_core_the_source_of_truth_for_analytics // eslint-disable-line camelcase
