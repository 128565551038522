<template>
  <svg
    :viewBox="computedViewBox"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      :transform="computedMainTransform"
    >
      <g>
        <path
          :fill="bleu1"
          d="M10.5511796,78 L3.71217962,78 C3.24417962,78 2.84617962,78.34 2.77317962,78.802 L0.00717961756,96.339 C-0.0478203824,96.685 0.220179618,96.997 0.571179618,96.997 L3.83617962,96.997 C4.30417962,96.997 4.70217962,96.657 4.77517962,96.194 L5.52117962,91.464 C5.59317962,91.001 5.99217962,90.661 6.45917962,90.661 L8.62417962,90.661 C13.1291796,90.661 15.7291796,88.481 16.4081796,84.161 C16.7141796,82.271 16.4211796,80.786 15.5361796,79.746 C14.5641796,78.604 12.8401796,78 10.5511796,78 L10.5511796,78 Z M11.3401796,84.405 C10.9661796,86.859 9.09117962,86.859 7.27817962,86.859 L6.24617962,86.859 L6.97017962,82.276 C7.01317962,81.999 7.25317962,81.795 7.53317962,81.795 L8.00617962,81.795 C9.24117962,81.795 10.4061796,81.795 11.0081796,82.499 C11.3671796,82.919 11.4771796,83.543 11.3401796,84.405 L11.3401796,84.405 Z"
        />
        <path
          :fill="bleu1"
          d="M31.4899717,84.308 L28.2149717,84.308 C27.9359717,84.308 27.6949717,84.512 27.6519717,84.789 L27.5069717,85.705 L27.2779717,85.373 C26.5689717,84.344 24.9879717,84 23.4099717,84 C19.7909717,84 16.6999717,86.741 16.0979717,90.586 C15.7849717,92.504 16.2299717,94.338 17.3179717,95.617 C18.3159717,96.793 19.7439717,97.283 21.4429717,97.283 C24.3589717,97.283 25.9759717,95.408 25.9759717,95.408 L25.8299717,96.318 C25.7749717,96.666 26.0429717,96.978 26.3919717,96.978 L29.3419717,96.978 C29.8109717,96.978 30.2069717,96.638 30.2809717,96.175 L32.0509717,84.966 C32.1069717,84.621 31.8399717,84.308 31.4899717,84.308 L31.4899717,84.308 Z M26.9249717,90.682 C26.6089717,92.553 25.1239717,93.809 23.2299717,93.809 C22.2789717,93.809 21.5189717,93.504 21.0309717,92.926 C20.5469717,92.352 20.3629717,91.535 20.5169717,90.625 C20.8119717,88.77 22.3219717,87.473 24.1869717,87.473 C25.1169717,87.473 25.8729717,87.782 26.3709717,88.365 C26.8699717,88.954 27.0679717,89.776 26.9249717,90.682 L26.9249717,90.682 Z"
        />
        <path
          :fill="bleu1"
          d="M49.2586204,84 L45.9676204,84 C45.6536204,84 45.3586204,84.156 45.1806204,84.417 L40.6416204,91.103 L38.7176204,84.678 C38.5966204,84.276 38.2256204,84 37.8056204,84 L34.5716204,84 C34.1786204,84 33.9056204,84.384 34.0306204,84.754 L37.6556204,95.392 L34.2476204,100.203 C33.9796204,100.582 34.2496204,101.103 34.7126204,101.103 L37.9996204,101.103 C38.3116204,101.103 38.6036204,100.951 38.7806204,100.695 L49.7266204,84.895 C49.9886204,84.517 49.7196204,84 49.2586204,84 L49.2586204,84 Z"
        />
        <path
          :fill="bleu2"
          d="M59.551181,78 L52.711181,78 C52.244181,78 51.846181,78.34 51.773181,78.802 L49.007181,96.339 C48.952181,96.685 49.220181,96.997 49.569181,96.997 L53.079181,96.997 C53.405181,96.997 53.684181,96.759 53.735181,96.435 L54.520181,91.464 C54.592181,91.001 54.991181,90.661 55.458181,90.661 L57.622181,90.661 C62.128181,90.661 64.727181,88.481 65.407181,84.161 C65.714181,82.271 65.419181,80.786 64.534181,79.746 C63.563181,78.604 61.840181,78 59.551181,78 L59.551181,78 Z M60.340181,84.405 C59.967181,86.859 58.092181,86.859 56.278181,86.859 L55.247181,86.859 L55.972181,82.276 C56.015181,81.999 56.253181,81.795 56.534181,81.795 L57.007181,81.795 C58.241181,81.795 59.407181,81.795 60.009181,82.499 C60.368181,82.919 60.477181,83.543 60.340181,84.405 L60.340181,84.405 Z"
        />
        <path
          :fill="bleu2"
          d="M80.4856923,84.308 L77.2126923,84.308 C76.9316923,84.308 76.6926923,84.512 76.6506923,84.789 L76.5056923,85.705 L76.2756923,85.373 C75.5666923,84.344 73.9866923,84 72.4086923,84 C68.7896923,84 65.6996923,86.741 65.0976923,90.586 C64.7856923,92.504 65.2286923,94.338 66.3166923,95.617 C67.3166923,96.793 68.7426923,97.283 70.4416923,97.283 C73.3576923,97.283 74.9746923,95.408 74.9746923,95.408 L74.8286923,96.318 C74.7736923,96.666 75.0416923,96.978 75.3926923,96.978 L78.3416923,96.978 C78.8086923,96.978 79.2066923,96.638 79.2796923,96.175 L81.0506923,84.966 C81.1046923,84.621 80.8366923,84.308 80.4856923,84.308 L80.4856923,84.308 Z M75.9206923,90.682 C75.6066923,92.553 74.1196923,93.809 72.2256923,93.809 C71.2766923,93.809 70.5146923,93.504 70.0266923,92.926 C69.5426923,92.352 69.3606923,91.535 69.5126923,90.625 C69.8096923,88.77 71.3176923,87.473 73.1826923,87.473 C74.1126923,87.473 74.8686923,87.782 75.3666923,88.365 C75.8676923,88.954 76.0656923,89.776 75.9206923,90.682 L75.9206923,90.682 Z"
        />
        <path
          :fill="bleu2"
          d="M83.814181,78.482 L81.007181,96.34 C80.952181,96.686 81.220181,96.998 81.569181,96.998 L84.391181,96.998 C84.860181,96.998 85.258181,96.658 85.330181,96.195 L88.098181,78.659 C88.153181,78.313 87.885181,78 87.536181,78 L84.376181,78 C84.097181,78.001 83.857181,78.205 83.814181,78.482 L83.814181,78.482 Z"
        />
        <path
          :fill="bleu1"
          d="M33.7928425,70 L35.0396848,62.0876644 L32.2623018,62.0233558 L19,62.0233558 L28.2166203,3.63832208 C28.2452286,3.46206897 28.3382053,3.29772485 28.4740945,3.18101671 C28.6099836,3.06430857 28.784017,3 28.9652025,3 L51.3273077,3 C58.7511452,3 63.8744036,4.54340562 66.5492738,7.58972627 C67.8032682,9.01880555 68.6019148,10.5121934 68.988126,12.1556346 C69.3934094,13.8800569 69.4005614,15.9403128 69.0048141,18.4531106 L68.9762059,18.6365091 L68.9762059,20.246605 L70.2302003,20.9563811 C71.286321,21.5161038 72.125496,22.1568077 72.7691813,22.8904017 C73.8419902,24.1122645 74.5357399,25.6651973 74.8289744,27.5063278 C75.1317449,29.3998578 75.031616,31.6530395 74.5357399,34.203946 C73.9635752,37.1383221 73.0385755,39.6939922 71.7893492,41.7852115 C70.6402517,43.7120867 69.1764636,45.3102737 67.4385132,46.5488091 C65.7792354,47.7254177 63.8076511,48.6185923 61.5785926,49.190224 C59.4186707,49.7523285 56.9559783,50.0357625 54.254884,50.0357625 L52.5145496,50.0357625 C51.2700913,50.0357625 50.0613932,50.4835407 49.1125534,51.2862069 C48.1613295,52.1055457 47.5319483,53.2249911 47.3388427,54.4492357 L47.2077216,55.1613935 L45.0048874,69.1068255 L44.9047585,69.6189122 C44.8785343,69.7808745 44.8332379,69.8618557 44.7664854,69.916637 C44.7068849,69.9666548 44.6210602,70 44.5376195,70 L33.7928425,70 L33.7928425,70 Z"
        />
        <path
          :fill="bleu2"
          d="M71.0012092,18 L71.0012092,18 L71.0012092,18 C70.9347764,18.4294092 70.8588533,18.868414 70.7734398,19.3194136 C67.8385362,34.5550422 57.797702,39.818303 44.9738105,39.818303 L38.4444212,39.818303 C36.876134,39.818303 35.554597,40.9697912 35.3102194,42.533896 L35.3102194,42.533896 L35.3102194,42.533896 L31.967229,63.9707685 L31.0205624,70.0472679 C30.8615984,71.0740116 31.6445557,72 32.6695179,72 L44.2501682,72 C45.6215297,72 46.7864753,70.9924478 47.0023817,69.6250555 L47.1162664,69.0301199 L49.2966838,55.039538 L49.4366671,54.2718792 C49.6502009,52.899689 50.8175191,51.8921368 52.1888806,51.8921368 L53.9208771,51.8921368 C65.1408924,51.8921368 73.9242498,47.2861839 76.4914006,33.9577077 C77.5638149,28.3897823 77.008627,23.7406486 74.1709999,20.4709018 C73.3121195,19.48494 72.2468231,18.6669036 71.0012092,18 L71.0012092,18 Z"
        />
        <path
          :fill="bleu3"
          d="M67.9032773,17.071601 C67.4509739,16.9388576 66.9843116,16.8181818 66.5056837,16.7095736 C66.0246626,16.6033789 65.5316759,16.5092518 65.0243303,16.4271923 C63.2486206,16.1375704 61.3029981,16 59.2185734,16 L41.6242106,16 C41.1910523,16 40.7794323,16.0989541 40.4108888,16.2775543 C39.5996144,16.6709574 38.9965432,17.4456959 38.8505617,18.3942076 L35.1076913,42.302494 L35,43 C35.2464934,41.4263878 36.5794722,40.2679002 38.1613375,40.2679002 L44.7472579,40.2679002 C57.6821778,40.2679002 67.8099448,34.9702333 70.7702586,19.6444087 C70.8588048,19.1906677 70.9329921,18.7489944 71,18.3169751 C70.2509473,17.9163315 69.4396729,17.5736122 68.566177,17.2815768 C68.3507944,17.2091714 68.1282324,17.1391794 67.9032773,17.071601 L67.9032773,17.071601 Z"
        />
        <path
          :fill="bleu1"
          d="M38.8074463,18.3651137 C38.9521577,17.4276556 39.5499821,16.6619456 40.3541981,16.2755125 C40.7219075,16.0989937 41.127574,16.0011927 41.5569637,16.0011927 L58.9982514,16.0011927 C61.0645411,16.0011927 62.9932363,16.1371599 64.7534968,16.4234066 C65.2564284,16.5045099 65.7451261,16.5975401 66.2219621,16.7024972 C66.6964259,16.8098397 67.1590281,16.9291092 67.6073963,17.0603056 C67.8303943,17.1270965 68.0510199,17.1962728 68.2669009,17.2654491 C69.1327973,17.5540813 69.9370134,17.8951919 70.6795491,18.2887812 C71.5525624,12.6902721 70.6724322,8.87841968 67.6619596,5.42676109 C64.3430857,1.62683563 58.3529807,0 50.6880187,0 L28.4356686,0 C26.8699382,0 25.5343227,1.14498695 25.2923462,2.70264629 L16.0236967,61.776817 C15.8410281,62.9456578 16.7377646,64 17.9096901,64 L31.6477882,64 L35.0971397,41.994782 L38.8074463,18.3651137 L38.8074463,18.3651137 Z"
        />
      </g>
      <g v-if="withTick" transform="translate(-1.000000, -12.000000)">
        <g>
          <circle fill="#FFFFFF" cx="20" cy="20" r="20" />
          <path
            :fill="computedColorTick"
            d="M20,5 C11.7156,5 5,11.7156 5,20 C5,28.2844 11.7156,35 20,35 C28.2844,35 35,28.2844 35,20 C35,11.7156 28.2844,5 20,5 L20,5 Z M17.6752,26.922 L10.7736,20.0188 L13.4248,17.3672 L17.6752,21.6188 L26.692,12.6032 L29.3436,15.2548 L17.6752,26.922 Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import MixinPaletteFidmarques from '@/mixins/paletteFidmarques'
export default {
  mixins: [MixinPaletteFidmarques],
  props: {
    colorTick: {
      type: String,
      default: null
    },
    withTick: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bleu1: () => '#253B80',
    bleu2: () => '#179BD7',
    bleu3: () => '#222D65',

    computedColorTick() {
      return this.colorTick || this.paletteFidmarques.v2.green
    },
    computedViewBox() {
      return this.withTick ? '0 0 90 110' : '0 0 90 103'
    },
    computedMainTransform() {
      return this.withTick ? 'translate(1, 8)' : 'translate(1, 1)'
    }
  }
}
</script>
