import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user && _vm.user.apps.length > 1)?_c(VSelect,{staticClass:"v-select--one-line",attrs:{"items":_vm.user.apps,"label":_vm.$t('pages.declarations.filterByApp'),"item-text":"name","item-value":"id","multiple":"","chips":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var selected = ref.selected;
return [_c(VChip,_vm._b({attrs:{"input-value":selected,"close":"","outlined":""},on:{"click:close":function($event){return _vm.unselectAppId(item)}}},'v-chip',attrs,false),[(item.image_url)?_c(VAvatar,{attrs:{"size":"36"}},[_c(VImg,{attrs:{"contain":"","src":item.image_url,"alt":item.name,"max-height":"30","max-width":"30"}})],1):_c('p',[_vm._v(_vm._s(item.name))])],1)]}}],null,false,1262341975),model:{value:(_vm.selectedAppIds),callback:function ($$v) {_vm.selectedAppIds=$$v},expression:"selectedAppIds"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }