<template>
  <v-dialog v-model="isDialogOpen" scrollable width="900">
    <template #activator="{ on, attrs }">
      <v-btn text color="primary" dark v-bind="attrs" v-on="on">
        {{ $t('pages.user.profile.pot.seeDetails') }}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>
          <span class="font-weight-bold">
            {{ $t('pages.user.profile.pot.modal.title') }}
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon dark @click="isDialogOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <div class="pt-5 pb-3">
          <h3>
            {{ $t('pages.user.profile.pot.modal.currentlyInMyPot') }}
          </h3>
          <v-list outlined two-line>
            <v-skeleton-loader
              v-if="isPotLoading"
              type="list-item-avatar-two-line"
            />
            <DisplayErrorV2
              v-else-if="error"
              :error="error"
              retriable
              @retry="fetchFirstTransfer"
            />
            <template v-else-if="isPotLoaded">
              <v-list-item v-if="noPotRefunds">
                {{ $t('pages.user.profile.pot.modal.noRefund') }}
              </v-list-item>
              <template v-else>
                <ListPotRefundsItem
                  v-for="potRefund in potRefunds"
                  :key="potRefund.id"
                  :pot-refund="potRefund"
                />
                <ListPotRefundsOtherAppItem
                  v-for="app in otherAppPotRefunds"
                  :key="app.id"
                  :app="app"
                />
              </template>
            </template>
          </v-list>
        </div>

        <template v-if="isPotLoaded">
          <TransferPaymentDetails
            v-for="(transferPayment, index) in transferPayments"
            :key="transferPayment.id"
            :transfer-payment="transferPayment"
            :is-last="index + 1 === transferPayments.length"
            class="pb-3"
          />
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import ListPotRefundsItem from './ListPotRefundsItem'
import ListPotRefundsOtherAppItem from './ListPotRefundsOtherAppItem'
import TransferPaymentDetails from './TransferPaymentDetails'

import DisplayErrorV2 from '~/components/layouts/DisplayErrorV2'

import {
  PARTNER_FIDMARQUES_ID,
  PARTNER_SCANOBAR_ID
} from '~/utils/constants/partner-ids'

export default {
  components: {
    ListPotRefundsItem,
    ListPotRefundsOtherAppItem,
    TransferPaymentDetails,
    DisplayErrorV2
  },
  data: () => ({
    scrollInvoked: 0,
    offsetTop: 0,
    isDialogOpen: false,
    items: []
  }),
  computed: {
    ...mapState('user/profile/pot', [
      'isPotLoading',
      'isPotLoaded',
      'error',
      'potRefunds',
      'otherAppPotRefunds',
      'transferPayments'
    ]),
    ...mapGetters('user/profile/pot', ['noPotRefunds']),
    ...mapGetters('auth', ['isScanobarAdmin'])
  },
  watch: {
    isDialogOpen() {
      if (this.isPotLoaded) return

      this.fetchFirstTransfer()
    }
  },
  methods: {
    ...mapActions('user/profile/pot', ['fetchTransfer']),
    ...mapMutations('user/profile/pot', ['SET_IS_POT_LOADING']),

    fetchFirstTransfer() {
      const editorId = this.isScanobarAdmin
        ? PARTNER_SCANOBAR_ID
        : PARTNER_FIDMARQUES_ID
      this.fetchTransfer({ userId: this.$route.params.userId, editorId })
    }
  }
}
</script>
