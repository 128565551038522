import Vue from 'vue'
import { omit, pick, uniq } from 'lodash-es'

import logger from './logger'
import isDev from './constants/is-dev'

import { extend as extendWithCommon } from './airbrake'
export { instantiate } from './airbrake'

export const createPlugin = (airbrake) => ({
  install(Vue, _options) {
    if (isDev) return

    Vue.config.errorHandler = function(err, vm, info) {
      airbrake.notify({
        error: err,
        params: {
          source: 'Vue.config.errorHandler',
          info,
          componentData: vm && vm.$data
        },
        context: {
          zzz: {
            state: vm && vm.$store && vm.$store.state,
            err,
            vm,
            info
          }
        }
      })
    }

    // Vue.prototype.$airbrake = Vue.airbrake = airbrake
  }
})

export const createInjecter = (notifier) => (ctx, inject) => {
  // ctx.$airbrake = notifier
  inject('airbrake', notifier)

  inject('airbrakeNotify', function(args) {
    // "this" is either a component of vuex store
    const $vm = this instanceof Vue ? this : undefined
    notifier.notifyNuxt({ ...args, $vm, store: ctx.store })
  })

  inject('airbrakeNotifyUnhandledBadRequest', function({
    response,
    params,
    ...args
  }) {
    const $vm = this instanceof Vue ? this : undefined
    const errorCodes = uniq(response.data.errors.map((e) => e.error_code))
    notifier.notifyNuxt({
      ...args,
      error: new Error(`unknown errors : [${errorCodes.join(',')}]`),
      params: {
        ...params,
        response: omit(response, 'data'),
        errors: response.data.errors
      },
      $vm,
      store: ctx.store
    })
  })

  inject('airbrakeNotifyUnhandledErrorCodes', function({
    errorCodes,
    response,
    params,
    ...args
  }) {
    const $vm = this instanceof Vue ? this : undefined
    notifier.notifyNuxt({
      ...args,
      error: new Error(`unhandled error codes : [${errorCodes.join(',')}]`),
      params: {
        ...params,
        response: omit(response, 'data'),
        errors: response.data.errors
      },
      $vm,
      store: ctx.store
    })
  })
}

export const extend = (Notifier) =>
  class NotifierNuxt extends extendWithCommon(Notifier) {
    constructor(opts) {
      super(opts)

      this.initializeNuxtFilters()
    }

    initializeNuxtFilters() {
      this.addFilter((notice) => {
        notice.context.NOTIFIER = 'nuxt'
        notice.context.API_ENV = process.env.API_ENV
        notice.context.NODE_ENV = process.env.NODE_ENV
        notice.context.VUE_ENV = process.env.VUE_ENV
        return notice
      })
    }

    // https://nuxtjs.org/api/context/
    // ommited :
    // - both : ['app', 'store', 'redirect', 'error']
    // - server : ['req', 'res', 'beforeNuxtRender']
    // - client : ['from', 'nuxtState']
    // - injected keys (beginning with $)
    sanitizeNuxtContext(context) {
      const tmp = {
        ...pick(context, ['route', 'params', 'query', 'env', 'isDev', 'isHMR']),
        stateCurrent: context.store && context.store.state
      }
      if (process.server) {
        // https://nodejs.org/api/http.html#http_class_http_incomingmessage
        return {
          ...tmp,
          req: this.sanitizeReq(context.req)
        }
      }
      if (process.client) {
        return {
          ...tmp,
          ...pick(context, ['from', 'stateNuxt'])
        }
      }
      // should not happen
      return tmp
    }

    notifyNuxt({ error, $vm, store, ...rest }) {
      if (!store) {
        logger.warn('notifyNuxt : store must be specified')
      }

      this.notify({
        error,
        params: {
          ...rest.params,
          source: 'notifyNuxt',
          errorObject: error,
          componentData: $vm && $vm.$data,
          axios: this.extractAxiosErrorDetails(error)
        },
        session: rest.session,
        context: {
          ...rest.context,
          state: store && store.state
        }
      })
    }
  }
