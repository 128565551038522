


















import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
export default Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('program/catalog', ['form'])
  },
  methods: {
    ...mapMutations('program/catalog', ['changeCashbackValue'])
  }
})
