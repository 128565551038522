<!-- https://github.com/vuetifyjs/vuetify/blob/master/packages/docs/src/layouts/documentation/Search.vue -->

<template>
  <v-form ref="form" v-model="valid" @submit.prevent="search">
    <v-container>
      <v-row>
        <v-col v-for="field in FIELDS" :key="field">
          <v-text-field
            :value="fields[field]"
            :label="
              field === 'external_id' && isScanobarAdmin
                ? $options.filters.titleize(
                    $t('pages.users.search.keys.facebook_id')
                  )
                : $options.filters.titleize(
                    $t(`pages.users.search.keys.${field}`)
                  )
            "
            @input="setField({ key: field, value: $event })"
          />
        </v-col>
        <v-col>
          <v-btn :disabled="!valid" color="success" class="mr-4" type="submit">
            {{ $t('global.validate') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
          <v-text-field
            v-model="savId"
            :loading="isSearchingBySav"
            :label="$t('pages.users.search.searchBySavId')"
            append-icon="mdi-send"
            @click:append="searchBySav"
            @keydown.enter.exact.stop.prevent
            @keyup.enter.exact.stop.prevent="searchBySav"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { FIELDS } from '@/store/user/search/state'
import { HOME_USERS } from '@/utils/constants/routes'
import MixinAjaxToCore from '@/mixins/ajaxToCore'
export default {
  mixins: [MixinAjaxToCore],
  data: () => ({
    FIELDS,
    valid: true,

    savId: '',
    isSearchingBySav: false
  }),
  computed: {
    ...mapState('user/search', ['fields']),
    ...mapGetters('auth', ['isScanobarAdmin'])
  },
  methods: {
    ...mapMutations('user/search', ['setField', 'reset']),
    ...mapActions('user/search', ['search']),

    searchBySav() {
      if (this.isSearchingBySav) return

      return this.fetchCoreFromComponentAndHandleError({
        isLoadingKey: 'isSearchingBySav',
        errorInToast: true,

        axios: {
          method: 'GET',
          url: '/users/search_by_sav_id',
          params: { sav_id: this.savId }
        },

        knownPublicErrorCodes: [
          'SAV_ID_NOT_FOUND',
          'FB_ID_NOT_FOUND',
          'SERVER_ERROR'
        ],

        onSuccess: (result) => {
          this.$router.push(`${HOME_USERS}/${result.user.id}`)
          this.reset()
        }
      })
    }
  }
}
</script>
