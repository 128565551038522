export const updateProgramHeaders = (headers, program) => {
  headers['X-Program-Identifier'] = program.identifier

  if (program.payload_version) {
    headers['X-Program-Payload-Version'] = program.payload_version
  }
  if (program.payload_time) {
    headers['X-Program-Payload-Time'] = program.payload_time
  }
}

export default (axios, program) => {
  if (program) {
    updateProgramHeaders(axios.defaults.headers.common, program)
  } else {
    delete axios.defaults.headers.common['X-Program-Identifier']
    delete axios.defaults.headers.common['X-Program-Payload-Version']
    delete axios.defaults.headers.common['X-Program-Payload-Time']
  }
}
