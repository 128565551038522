import {
  STATUS_ERROR_CORE,
  STATUS_ERROR_FOR_USER,
  SERVER_NOT_REACHABLE,
  SERVER_ERROR,
  UNKNOWN_ERROR_SERVER
} from '@/utils/constants/errors'

export const handleAuthErrorAxiosWithUserInput = ({ commit, error, store }) => {
  if (!error.response) {
    commit('setError', SERVER_NOT_REACHABLE)
    commit('setIsUserInputError', false)
    return errorHandled(commit)
  }

  const status = error.response.status

  if (status === STATUS_ERROR_CORE) {
    commit('setError', SERVER_ERROR)
    commit('setIsUserInputError', false)
    return errorHandled(commit)
  }
  if (status === STATUS_ERROR_FOR_USER) {
    commit('setError', error.response.data.error)
    commit('setIsUserInputError', true)
    return errorHandled(commit)
  }

  if (status >= 500 && status < 600) {
    commit('setError', SERVER_ERROR)
    commit('setIsUserInputError', false)
    return errorHandled(commit)
  }

  commit('setError', UNKNOWN_ERROR_SERVER)
  commit('setIsUserInputError', false)
  return errorNotHandled(commit, store, error)
}
function stopLoaders(commit) {
  commit('setLoadingState', false)
}
function errorHandled(commit) {
  stopLoaders(commit)
  return true
}
function errorNotHandled(commit, store, error) {
  stopLoaders(commit)
  store.$airbrakeNotify({ error })
  return false
}
