import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 primary white--text"},[_vm._v("\n      "+_vm._s(_vm.capitalize(
          _vm.$t('pages.user.profile.paymentMethods.addOrReplacePaymentMethod')
        ))+"\n    ")]),_vm._v(" "),_c(VCardText,[_c('ValidationProvider',{attrs:{"name":"paymentMethodEditor","mode":"eager","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c(VSelect,{attrs:{"label":_vm.$t('pages.user.profile.paymentMethods.labels.editorId'),"loading":_vm.isLoadingUser,"items":_vm.apps,"item-value":"id","item-text":"name","required":"","error-messages":errors},model:{value:(_vm.form.editorId),callback:function ($$v) {_vm.$set(_vm.form, "editorId", $$v)},expression:"form.editorId"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"paymentMethodUserFirstName","mode":"eager","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":_vm.$t('pages.user.profile.paymentMethods.labels.userFirstName'),"required":"","error-messages":errors},model:{value:(_vm.form.userFirstName),callback:function ($$v) {_vm.$set(_vm.form, "userFirstName", $$v)},expression:"form.userFirstName"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"paymentMethodUserLastName","mode":"eager","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":_vm.$t('pages.user.profile.paymentMethods.labels.userLastName'),"required":"","error-messages":errors},model:{value:(_vm.form.userLastName),callback:function ($$v) {_vm.$set(_vm.form, "userLastName", $$v)},expression:"form.userLastName"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"paymentMethodKind","mode":"eager","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c(VSelect,{attrs:{"label":_vm.$t('pages.user.profile.paymentMethods.labels.kind'),"items":_vm.PAYMENT_KINDS,"required":"","error-messages":errors},model:{value:(_vm.form.kind),callback:function ($$v) {_vm.$set(_vm.form, "kind", $$v)},expression:"form.kind"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"paymentMethodIdentifier","mode":"eager","rules":_vm.paymentMethodIdentifierRules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":_vm.$t(
              _vm.form.kind
                ? ("pages.user.profile.paymentMethods.labels.identifier." + (_vm.form.kind))
                : 'pages.user.profile.paymentMethods.labels.identifier.generic'
            ),"required":"","error-messages":errors},model:{value:(_vm.form.identifier),callback:function ($$v) {_vm.$set(_vm.form, "identifier", $$v)},expression:"form.identifier"}})]}}],null,true)})],1),_vm._v(" "),_c(VDivider),_vm._v(" "),_c(VCardActions,[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"loading":_vm.isSubmitting,"color":"primary"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v("\n        "+_vm._s(_vm.$t('global.submit'))+"\n      ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }