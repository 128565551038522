import { createLoadableState } from '@/utils/store/concerns/loadable/state'

export const SCREEN_MISC = 'MISC'
export const SCREEN_COUPONS = 'COUPONS'
export const SCREEN_TIMELINE = 'TIMELINE'
export const SCREEN_CARDS = 'CARDS'
export const SCREEN_POT = 'POT'

export interface State {
  lockableScreens: string[]
  lockedScreens: string[]
  isTogglingLock: boolean
}

export default () =>
  createLoadableState({
    lockableScreens: [],
    lockedScreens: [],
    isTogglingLock: false
  })
