<template>
  <tr class="earned-coupon">
    <td>
      <ul>
        <li>ID #{{ earnedCoupon.id }}</li>
        <li>
          {{
            $t('pages.user.profile.earnedCoupons.used.headers.status')
              | capitalize
          }}
          :
          <strong>
            {{ earnedCoupon.i18n_status }}
          </strong>
        </li>
        <li>
          {{
            $t('pages.user.profile.earnedCoupons.used.headers.origin')
              | capitalize
          }}
          : {{ earnedCoupon.i18n_origin }}
        </li>
        <li>
          {{
            $t('pages.user.profile.earnedCoupons.used.headers.offer')
              | capitalize
          }}
          :
          <a :href="earnedCoupon.offer_old_bo_url" target="_blank">
            {{ earnedCoupon.offer_name }}
            <v-icon small>mdi-open-in-new</v-icon>
          </a>
        </li>
        <li>
          {{
            $t('pages.user.profile.earnedCoupons.used.headers.acquiredAt', {
              at: momentFormat(earnedCoupon.offer_earned_at, 'human-long')
            }) | capitalize
          }}
          <span v-if="earnedCoupon.origin_earned_coupon_id">
            <br />
            ({{
              $t(
                'pages.user.profile.earnedCoupons.used.headers.afterRejectionOfEarnedCoupon',
                {
                  id: earnedCoupon.origin_earned_coupon_id
                }
              ) | capitalize
            }})
          </span>
        </li>
      </ul>
    </td>
    <td>
      <DisplayCoupon
        :coupon="earnedCoupon.coupon"
        style="min-width: 200px; max-width: 350px;"
      />
    </td>
    <td>
      <ul>
        <li v-if="earnedCoupon.offer_usage_type">
          {{
            $t('pages.user.profile.earnedCoupons.used.headers.validation')
              | capitalize
          }}
          :
          <a :href="earnedCoupon.offer_usage_old_bo_url" target="_blank">
            {{ earnedCoupon.offer_usage_type }}
            #{{ earnedCoupon.offer_usage_id }}
            <v-icon small>mdi-open-in-new</v-icon>
          </a>
        </li>
        <li v-if="earnedCoupon.offer_used_at">
          {{
            $t('pages.user.profile.earnedCoupons.used.headers.usedAt', {
              at: momentFormat(earnedCoupon.offer_used_at, 'human-long')
            }) | capitalize
          }}
        </li>
        <li v-if="earnedCoupon.cancelled_at">
          {{
            $t('pages.user.profile.earnedCoupons.used.headers.cancelledAt', {
              at: momentFormat(earnedCoupon.cancelled_at, 'human-long')
            }) | capitalize
          }}
        </li>
        <li v-if="earnedCoupon.offer_used_on_receipt_id">
          {{
            $t('pages.user.profile.earnedCoupons.used.headers.receipt')
              | capitalize
          }}
          :
          <nuxt-link
            :to="
              `${routeBasePath}/declarations/${earnedCoupon.offer_used_on_declaration_identifier}`
            "
          >
            #{{ earnedCoupon.offer_used_on_receipt_id }}
          </nuxt-link>
        </li>
        <li v-if="earnedCoupon.offer_used_on_purchase_id">
          {{
            $t('pages.user.profile.earnedCoupons.used.headers.purchase', {
              at: momentFormat(earnedCoupon.offer_used_at, 'human-long')
            }) | capitalize
          }}
          :
          <nuxt-link
            :to="
              `${routeBasePath}/declarations/${earnedCoupon.offer_used_on_declaration_identifier}#${earnedCoupon.offer_used_on_purchase_identifier}`
            "
          >
            #{{ earnedCoupon.offer_used_on_purchase_id }}
          </nuxt-link>
        </li>
        <li v-if="earnedCoupon.offer_reselled_on_type">
          {{
            $t('pages.user.profile.earnedCoupons.used.headers.reselledFor', {
              price: earnedCoupon.i18n_reselled_for
            }) | capitalize
          }}
          <br />
          ({{ earnedCoupon.offer_reselled_on_type }} #{{
            earnedCoupon.offer_reselled_on_id
          }})
        </li>
      </ul>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'
import DisplayCoupon from './DisplayCoupon'
import { momentFormat } from '~/plugins/filters'
import hasOwnProp from '@/utils/functions/hasOwnProp'
export default {
  components: { DisplayCoupon },
  props: {
    earnedCoupon: {
      type: Object,
      required: true,
      validator: (o) =>
        hasOwnProp(o, 'id') &&
        hasOwnProp(o, 'i18n_status') &&
        hasOwnProp(o, 'i18n_origin') &&
        hasOwnProp(o, 'offer_name') &&
        hasOwnProp(o, 'offer_type') &&
        hasOwnProp(o, 'offer_id') &&
        hasOwnProp(o, 'offer_old_bo_url') &&
        hasOwnProp(o, 'offer_earned_at') &&
        hasOwnProp(o, 'origin_earned_coupon_id') &&
        hasOwnProp(o, 'offer_usage_type') &&
        hasOwnProp(o, 'offer_usage_id') &&
        hasOwnProp(o, 'offer_used_at') &&
        hasOwnProp(o, 'offer_usage_old_bo_url') &&
        hasOwnProp(o, 'cancelled_at') &&
        hasOwnProp(o, 'offer_used_on_receipt_id') &&
        hasOwnProp(o, 'offer_used_on_declaration_identifier') &&
        hasOwnProp(o, 'offer_used_on_purchase_id') &&
        hasOwnProp(o, 'offer_used_on_purchase_identifier') &&
        hasOwnProp(o, 'offer_reselled_on_type') &&
        hasOwnProp(o, 'offer_reselled_on_id') &&
        hasOwnProp(o, 'i18n_reselled_for') &&
        hasOwnProp(o, 'coupon') &&
        true
    }
  },
  computed: {
    ...mapGetters('user', ['routeBasePath'])
  },
  methods: {
    momentFormat
  }
}
</script>

<style scoped>
.earned-coupon td {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}
</style>
