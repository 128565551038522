export default {
  computed: {
    retailerSubstituteName() {
      switch (this.declaration.type) {
        case 'ProofOfPurchase::ExternalInvalidPicture':
          return this.$t('retailer.name.substitutes.invalid')
        case 'ProofOfPurchase::ExternalGlassPicture':
          return this.$t('retailer.name.substitutes.glass')
        case 'ProofOfPurchase::ExternalReceipt':
        case 'receipt':
          return this.$t('retailer.name.substitutes.unknown')
        default:
          this.$airbrakeNotify({
            error: new Error(`unknown type : ${this.declaration.type}`)
          })
          return this.$t('retailer.name.substitutes.unknown')
      }
    }
  }
}
