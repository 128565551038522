


















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ValidationProvider, validate } from 'vee-validate'

import ProductsList from '~/components/features/crud/offers/steps/step2components/refund/ProductsList.vue'

import { ProductsByEan } from '~/store/products-by-ean/state'

const moduleProductsByEan = namespace('products-by-ean')

@Component({
  components: { ValidationProvider, ProductsList }
})
export default class ListAndVerifyEans extends Vue {
  @(namespace('auth').Getter) isPurcheaseAdmin!: boolean
  @moduleProductsByEan.State('isLoading') areProductsLoading!: boolean
  @moduleProductsByEan.Getter productsForEans!: Function
  @moduleProductsByEan.Action fetchProductsForEans!: Function

  areEansValid: boolean = false

  @Prop({
    type: Boolean,
    default: false
  })
  readonly disabled!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  readonly readonly!: boolean

  @Prop({
    type: Boolean,
    default: false
  })
  readonly eansRequired!: boolean

  @Prop({
    type: Array,
    default: () => []
  })
  readonly eans!: string[]

  @Watch('textareaValue', { immediate: true })
  onTextareaValueChange() {
    validate(this.textareaValue, this.rules as string, {
      name: 'productsEans'
    }).then((result) => {
      this.areEansValid = result.valid
      if (this.areEansValid) {
        this.verifyEans()
      }
    })
  }

  get productsByEan(): ProductsByEan {
    return this.productsForEans(this.eans)
  }

  get textareaValue(): string {
    return this.eans.join(', ')
  }

  set textareaValue(value) {
    this.$emit('change', value)
  }

  get rules(): string {
    return this.eansRequired ? 'eans|required' : 'eans'
  }

  get rulesBis(): string {
    return this.eansRequired
      ? 'all_products_exist|all_products_are_valid|required'
      : 'all_products_exist|all_products_are_valid'
  }

  get anyEan() {
    return this.eans.length > 0
  }

  verifyEans(): undefined | Promise<any> {
    if (!this.anyEan) return
    if (!this.areEansValid) return

    return this.fetchProductsForEans(this.eans)
  }
}
