export default {
  validate: 'Valider',
  errorNodeNotFound: 'noeud "Erreur Reconnaissance" introuvable',

  tree: {
    openAll: 'tout ouvrir',
    closeAll: 'tout fermer'
  },

  selectAGroup: 'sélectionnez un groupe',
  noDenomToDisplay: 'aucun libellé à afficher',
  noProductToDisplay: 'aucun produit à afficher',
  move: 'déplacer',
  selectAll: 'Tout sélectionner',
  unselectAll: 'Tout désélectionner',
  displayValidatedDenoms: 'afficher les libellés validés',
  filterDenoms: 'filtrer les libellés',
  filterProducts: 'filtrer les produits',

  headers: {
    originGroup: 'groupe "origine"',
    denomsInGroup: 'libellés du groupe "origine"',
    selectedDenoms: 'libellés sélectionnés',
    productsInGroup: 'produits du groupe "destination"',
    targetGroup: 'groupe "destination"'
  },
  tooltip: {
    youMustSelectSomeDenoms: 'Vous devez sélectionner des libellés.',
    youMustSelectATarget: 'Vous devez sélectionner un groupe de destination.',

    markDenomsAsMaximumIdentificationLevel:
      'Valider les libellés sélectionnés (i.e. les marquer comme étant dans le groupe le plus précis).',
    theyCanNotBeDeeperInTheTree:
      "Ils ne sont pas assez précis pour un groupe plus profond dans l'arbre.",
    theyWontAppearAnymore: "Ils n'apparaitront plus dans la liste des libellés."
  }
}
