<template>
  <span>
    {{
      $tc(
        `pages.user.profile.table.benefits.${programKind}`,
        amountTranslationIndex,
        { count: formattedValue }
      )
    }}
  </span>
</template>

<script>
import BigNumber from 'bignumber.js'

import { translationIndex } from '@/plugins/i18n.js'

import {
  PROGRAM_KIND_POINTS_VIA_PRICE,
  PROGRAM_KIND_POINTS_BY_PURCHASE,
  PROGRAM_KIND_STAMPS,
  PROGRAM_KIND_PUZZLE,
  PROGRAM_KIND_COLLECTOR,
  PROGRAM_KIND_FIDZ,
  PROGRAM_KIND_CASHBACK
} from '~/store/program/state.js'

export default {
  props: {
    programKind: {
      type: String,
      required: true
    },
    value: {
      type: [Number, String, BigNumber],
      required: true
    }
  },
  computed: {
    amount() {
      return new BigNumber(this.value)
    },
    amountTranslationIndex() {
      return translationIndex(this.amount)
    },
    formattedValue() {
      switch (this.programKind) {
        case PROGRAM_KIND_POINTS_VIA_PRICE:
        case PROGRAM_KIND_POINTS_BY_PURCHASE:
        case PROGRAM_KIND_STAMPS:
        case PROGRAM_KIND_PUZZLE:
        case PROGRAM_KIND_COLLECTOR:
        case PROGRAM_KIND_FIDZ:
          return parseInt(this.value)
        case PROGRAM_KIND_CASHBACK:
          return this.value
        default:
          this.$airbrakeNotify({
            error: new Error(`programKind not handled : ${this.programKind}`)
          })
          return this.value
      }
    }
  }
}
</script>
