<template>
  <nuxt-link
    v-if="detail.record_kind === 'declaration'"
    :to="`${routeBasePath}/declarations/${detail.record_identifier}`"
    target="_blank"
  >
    <BBcode :text="text" />
  </nuxt-link>
  <span v-else>
    <BBcode :text="text" />
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import BBcode from '~/components/shared/BBcode'
export default {
  components: { BBcode },
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('user', ['routeBasePath']),
    text() {
      return this.detail.text_i18n || this.detail.text_fr
    }
  }
}
</script>
