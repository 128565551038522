import Vue from 'vue'
import { cloneDeep } from 'lodash-es'

import createDefaultState from './state'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export function initWithRecord(state, item) {
  state.original = item
  state.modified = cloneDeep(item)
  state.isLoaded = true
}

export function updateAttribute(state, { key, value }) {
  Vue.set(state.modified, key, value)
}

export const setLoadingState = (state, boolean) => {
  state.isLoading = boolean
}
