import cancellableFetchFromStore from '@/utils/functions/analytics/cancellableFetch/fromStore'
import { TAB_GENERAL } from '@/components/layouts/metrics/program/Bar.vue'

// import payload from '@/utils/mocked-data/metrics/program/general'

export const fetch = function(ctx) {
  return cancellableFetchFromStore({
    ctx,
    store: this,
    tab: TAB_GENERAL,
    proxy: {
      method: 'post',
      path: '/internal_api/sas_bo_front_end/v1/programs_metrics/overview',
      data: ctx.rootGetters['metrics/program/paramsForBackend']
    }
  })
}

export const refresh = fetch
