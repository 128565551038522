<template>
  <ListLayout>
    <ListLoadedItem
      v-for="declaration in declarations"
      :key="declaration.id"
      :declaration="declaration"
    />
    <ListLoadingItem v-if="hasMore" key="loader" small fill-dot right>
      <template #intersect>
        <IntersectionObserver
          @intersect="fetchMore({ userId: $route.params.userId })"
        />
      </template>
    </ListLoadingItem>
  </ListLayout>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ListLayout from './ListLayout'
import ListLoadedItem from './ListLoadedItem'
import ListLoadingItem from './ListLoadingItem'
import IntersectionObserver from '@/components/layouts/IntersectionObserver'
export default {
  components: {
    ListLayout,
    ListLoadedItem,
    ListLoadingItem,
    IntersectionObserver
  },
  computed: {
    ...mapState('user/declarations', ['hasMore', 'declarations'])
  },
  methods: {
    ...mapActions('user/declarations', ['fetchMore'])
  }
}
</script>
