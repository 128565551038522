import { findIndex } from 'lodash-es'

import createDefaultState from './state'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export const setLoadingState = (state, boolean) => {
  state.isLoading = boolean
}
export const setLoadedState = (state, boolean) => {
  state.isLoaded = boolean
}
export const setError = (state, text) => {
  state.error = text
}
export const resetError = (state) => {
  state.error = null
}

export const setEvents = (state, events) => {
  state.events = events
}
export const pushEvents = (state, events) => {
  state.events = state.events.concat(events)
}
export const setHasMore = (state, hasMore) => {
  state.hasMore = hasMore
}
export const setNextPaginationKey = (state, nextPaginationKey) => {
  state.nextPaginationKey = nextPaginationKey
}
export const setFetchingMoreState = (state, boolean) => {
  state.isFetchingMore = boolean
}

export const pushSelectedKinds = (state, kinds) => {
  state.selectedKinds = state.selectedKinds.concat(kinds)
}
export const toggleKindSelection = (state, kind) => {
  const index = findIndex(state.selectedKinds, (k) => k === kind)
  if (index === -1) {
    state.selectedKinds.push(kind)
  } else {
    state.selectedKinds.splice(index, 1)
  }
}

export const setSelectedProgramIdentifiers = (state, identifiers) => {
  state.selectedProgramIdentifiers = identifiers
}
export const pushSelectedProgramIdentifiers = (state, identifiers) => {
  state.selectedProgramIdentifiers = state.selectedProgramIdentifiers.concat(
    identifiers
  )
}
export const toggleProgramIdentifierSelection = (state, identifier) => {
  const index = findIndex(
    state.selectedProgramIdentifiers,
    (i) => i === identifier
  )
  if (index === -1) {
    state.selectedProgramIdentifiers.push(identifier)
  } else {
    state.selectedProgramIdentifiers.splice(index, 1)
  }
}
