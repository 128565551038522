































































import Vue from 'vue'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
import VueDraggable from 'vuedraggable'
import ShelfRowNew from './ShelfRowNew.vue'
import RewardItem, { Reward } from './RewardItem.vue'
import ShelfRow, { Row as InterfaceRow } from './ShelfRow.vue'
export default Vue.extend({
  components: {
    ShelfRowNew,
    ShelfRow,
    VueDraggable,
    RewardItem
  },
  data: () => ({
    showInactiveRewards: false
  }),
  computed: {
    ...mapGetters('auth', ['hasWriteContentRight']),
    ...mapState('program/catalog/shelf', ['rewardsWithoutShelf']),

    rewardsWithoutShelf: {
      get(): Array<Reward> {
        return this.$store.state.program.catalog.shelf.rewardsWithoutShelf
      },
      set(value: Array<Reward>): void {
        this.updateRewardsWithoutShelf(value)
      }
    },
    displayedRewardsWithoutShelf(): Array<Reward> {
      if (this.showInactiveRewards) {
        return this.rewardsWithoutShelf
      } else {
        return this.rewardsWithoutShelf.filter((reward) => reward.isActive)
      }
    },
    noDisplayedRewardsWithoutShelf(): boolean {
      return this.displayedRewardsWithoutShelf.length === 0
    },

    loyaltyRewardsRows: {
      get(): Array<InterfaceRow> {
        return this.$store.state.program.catalog.shelf.loyaltyRewardsRows
      },
      set(value: Array<InterfaceRow>): void {
        this.setShelfRowsPriority(value)
        this.updateShelfRows()
      }
    }
  },
  beforeMount() {
    this.queryRewardsWithoutShelf()
  },
  methods: {
    ...mapMutations('program/catalog/shelf', [
      'setShelfRowsPriority',
      'updateRewardsWithoutShelf'
    ]),
    ...mapActions('program/catalog/shelf', [
      'queryRewardsWithoutShelf',
      'updateShelfRows',
      'removeShelfFromReward'
    ]),

    onChange(event: any): void {
      if (event.added) {
        this.removeShelfFromReward(event.added.element.id)
      }
    }
  }
})
