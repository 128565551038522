export default {
  notFound: 'programme introuvable',
  dominantColorUpdated: 'couleur dominante mise à jour',

  assetsEdit: {
    saveInformation:
      'Cliquer sur le bouton de sauvegarde une fois que vous avez ajouté toutes les images à changer',
    whereIcanFindAsset: 'Où trouver cette image sur FidMarques ?',
    replaceFile: 'Remplacer le fichier',

    currentImage: 'image actuelle',
    sizes: 'dimensions',
    recommendedSizes: 'dimensions recommandées',
    imageHasNotRecommendedSizes: "L'image n'a pas les dimensions recommandées",
    imageHasNotRecommendedSizesAndWillBeAutomaticallyResized:
      "L'image n'a pas les dimensions recommandées et sera automatiquement redimensionnée",
    imageChangedWithSuccess: 'image changée avec succès',
    toReplaceWith: 'à remplacer par',
    baseImage: 'image de base',
    noImage: 'aucune image',

    squareRecto: {
      name: 'Recto et Carré'
    },

    squareVersoRecto: {
      name: 'Recto, Verso et Carré',
      location:
        "Image carrée: Logo de la marque et recto/verso de la carte d'un programme"
    },
    squareImage: {
      name: 'Image carrée',
      location: 'Logo de la marque'
    },
    versoImage: {
      name: 'Image Verso',
      location: 'Verso de la carte'
    },
    rectoImage: {
      name: 'Image Recto',
      location: 'Recto de la carte'
    },
    onboardingClubImage: {
      name: 'Onboarding Club Image',
      location: 'Image lors de la découverte/grattage de la carte.'
    },
    onboardingClubImage2x: {
      name: 'Onboarding Club Image 2x',
      location:
        'Image lors de la découverte/grattage de la carte. (dimensions x2)'
    },
    onboardingClubImage3x: {
      name: 'Onboarding Club Image 3x',
      location:
        'Image lors de la découverte/grattage de la carte. (dimensions x3)'
    },
    backgroundImage: {
      name: 'Background Image',
      location: `Image de fond de l'onglet "notre marque" de la carte.`
    },
    roundableImage: {
      name: 'Roundable Image',
      location: 'Coin en haut à gauche des messages de la timeline (app v3)',
      edit: "Créer à partir de l'image carrée",
      marginAroundImage: "marge autour de l'image"
    },
    discoverWhatNowBackground: {
      name: 'Discover What Now Image',
      location:
        "Image utilisée lors de la mise en avant des marques éligibles sur l'écran d'accueil."
    },
    eligibleBrandImage: {
      name: 'Image de marque eligible',
      hint:
        "Attention, cette image s'applique à l'ensemble des programmes enfants de {programName}",

      location: "Image utilisée lors de la sélection d'une carte à ajouter."
    }
  },
  descriptions: {
    tagLine: 'Slogan',
    catchPhrase: "Phrase d'accroche",
    showSquareImageOnTop: "Afficher l'image carrée au dessus de la description",
    updated: 'mise à jour faite',

    description: {
      label: 'Description',
      hint:
        "apparaît dans le message Timeline (app v3) de découverte de programme et en haut de page personnalisée d'un programme ([url=https://api.purchease.com/?with=clubsocopa]exemple[/url])"
    },
    brandDescription: {
      title: 'Page "notre marque"',
      text: {
        label: 'version texte',
        conditionOfDisplay: 'affichée si la version HTML est vide'
      },
      html: {
        label: 'version HTML'
      }
    },
    disclaimerMessage: {
      label: "Message d'avertissement",
      hint:
        'Exemple : Ce programme est réservé aux plus de 18 ans. Confirmez-vous que vous êtes bien majeur ?'
    },
    brandHtmlDescriptionTabs: {
      tab0: {
        name: 'Aperçu',
        icon: 'mdi-eye-outline'
      },
      tab1: {
        name: 'Modifier',
        icon: 'mdi-pencil'
      }
    }
  },
  precisions: {
    loading: 'Chargement en cours',

    hints: {
      aDefaultPrecisionCanExist:
        'Une précision par défaut peut exister sur le programme.',
      toHideItCreateAPrecisionAndCheckTheBox:
        'Pour la faire disparaître, il suffit de créer une précision avec la case "%{checkboxLabel}" cochée.'
    },

    labels: {
      title: "Titre (visible dans l'app)",
      expiredAt: 'Expire le',
      createdAt: 'Crée le',
      image: 'Image',
      displayContext: "Contexte d'affichage",
      overridesDefault: 'Remplace la précision par défaut',
      obstrusiveOnSelection:
        "Affiché à la sélection (dans l'ajout de nouvelles cartes)",
      displayedOnConfirmation:
        "Affiché à la confirmation (dans le recap de l'ajout de nouvelles cartes)",
      displayRank: "Rang d'affichage"
    },

    precisionsOfProgram: 'Précisions du programme',

    newPrecision: 'Nouvelle précision',
    editPrecision: 'Modification précision',

    precisionCreated: 'Précision créée',
    precisionUpdated: 'Précision mise à jour',

    reload: 'Charger à nouveau',
    none: 'Aucune précision'
  },
  maCarte: {
    brandName: 'Nom de votre programme',
    colorPicker: 'Choisissez la couleur dominante'
  },
  month: 'Mois',
  show: 'Rendre visible',
  hide: 'Rendre invisible',
  public: {
    show: 'Rendre visible au public',
    hide: 'Rendre invisible au public'
  },
  hasParent: ' est un programme de façade pour '
}
