<template>
  <v-tabs align-with-title :background-color="paletteScanobar.black">
    <v-tab v-for="tab in tabs" :key="tab.identifier" nuxt :to="tab.to">
      {{ tab.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapActions } from 'vuex'

import { METRICS_PROGRAM } from '@/utils/constants/routes'
import MixinPaletteScanobar from '@/mixins/paletteScanobar'

export const TAB_GENERAL = 'general'
export const TAB_GENEROSITY = 'generosity'
export const TAB_SALES = 'sales'
export const TAB_USAGE = 'usage'
export const TAB_INDIVIDUAL_BEHAVIOUR = 'individual_behaviour'

export const TABS = [
  TAB_GENERAL,
  TAB_GENEROSITY,
  TAB_SALES,
  TAB_USAGE,
  TAB_INDIVIDUAL_BEHAVIOUR
]

export const DEFAULT_TAB = TAB_GENERAL

export default {
  mixins: [MixinPaletteScanobar],
  computed: {
    tabs() {
      return TABS.map((tab) => ({
        identifier: tab,
        to: `${METRICS_PROGRAM}/${tab}`,
        title: this.$t(`layout.metrics.program.tabs.${tab}`)
      }))
    }
  },
  mounted() {
    this.fetchConfig()
  },
  methods: {
    ...mapActions('metrics/program', ['fetchConfig'])
  }
}
</script>
