<template>
  <div>
    <SelectAccount @input="models.editorId = $event" />

    <ValidationObserver v-slot="{ handleSubmit }" tag="div">
      <ValidationProvider
        v-slot="{ errors }"
        name="sendMoneyAmount"
        mode="eager"
        rules="required|double:2"
        slim
      >
        <v-text-field
          v-model="models.amount"
          append-icon="mdi-currency-eur"
          :label="
            $t('pages.users.modal.accountActions.sendMoney.fields.amount') +
              ' *'
          "
          outlined
          :error-messages="errors"
        />
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        name="sendMoneyDescription"
        mode="eager"
        rules="required"
        slim
      >
        <v-textarea
          v-model="models.description"
          clearable
          clear-icon="mdi-close-circle"
          outlined
          :error-messages="errors"
          :label="
            $t(
              'pages.users.modal.accountActions.sendMoney.fields.description'
            ) + ' *'
          "
        />
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        name="sendMoneyZendeskTicketId"
        mode="eager"
        rules="required"
        slim
      >
        <v-text-field
          v-model="models.zendeskTicketId"
          prepend-inner-icon="mdi-pound"
          :label="
            $t(
              'pages.users.modal.accountActions.sendMoney.fields.zendeskTicketId'
            ) + ' *'
          "
          outlined
          :error-messages="errors"
        />
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        name="sendMoneyPassword"
        mode="eager"
        rules="required"
        slim
      >
        <v-text-field
          v-model="models.password"
          type="password"
          :label="
            $t('pages.users.modal.accountActions.sendMoney.fields.password') +
              ' *'
          "
          outlined
          :error-messages="errors"
        />
      </ValidationProvider>

      <v-btn
        :loading="isSending"
        :disabled="isSending"
        color="primary"
        class="white--text ma-1"
        @click="handleSubmit(sendMoney)"
      >
        {{
          $t('pages.users.modal.accountActions.sendMoney.submit') | capitalize
        }}
      </v-btn>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SelectAccount from './SelectAccount'
import MixinAjaxToCore from '~/mixins/ajaxToCore'
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    SelectAccount
  },
  mixins: [MixinAjaxToCore],
  data: () => ({
    isSending: false,
    models: {
      editorId: null,
      amount: null,
      description: null,
      zendeskTicketId: null,
      password: null
    }
  }),
  computed: {
    ...mapState('user', ['user'])
  },
  methods: {
    ...mapMutations('snackbar', ['addToast']),

    sendMoney() {
      return this.fetchCoreFromComponentAndHandleError({
        isLoadingKey: 'isSending',
        errorInToast: true,

        axios: {
          method: 'POST',
          url: `/users/${this.user.id}/send_money_to_user`,
          data: {
            editor_id: this.models.editorId,
            amount: this.models.amount,
            zendesk_ticket_id: this.models.zendeskTicketId,
            description: this.models.description,
            password: this.models.password
          }
        },

        onSuccess: (result) => {
          this.addToast({
            text:
              result.message_i18n ||
              this.$t('pages.users.modal.accountActions.sendMoney.success'),
            timeout: 8 * 1000
          })
          this.$emit('close')
        },

        knownPublicErrorCodes: [
          'AMOUNT_GREATER_THAN_5_EUROS',
          'PASSWORD_INVALID'
        ]
      })
    }
  }
}
</script>
