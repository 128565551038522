import moment from 'moment'
import { filter } from 'lodash-es'

import {
  DISCOUNT_TYPE_PERCENT,
  DISCOUNT_TYPE_ABSOLUTE
} from '@/components/program/catalog/create-modal/SlidePage2/Coupon.vue'

import {
  KIND_COUPON,
  KIND_FIDZ,
  // KIND_DONATION,
  KIND_CASHBACK,
  KIND_GOODY
} from '@/store/program/catalog/state.js'

import { KIND_CATALOG } from '~/i18n/fr/pages/user/declaration.js'

import { PROGRAM_KINDS_WITH_FIELD_REQUIRED_POINTS } from '~/store/program/state.js'

export const availableRewards = (state) =>
  state.loyaltyRewards ? filter(state.loyaltyRewards, 'is_available') : []

export const unavailableRewards = (state) =>
  state.loyaltyRewards.filter((lr) => lr.is_available === false)

export const exclusivePartners = (state) =>
  state.loyaltyRewardExclusivity
    ? filter(state.loyaltyRewardExclusivity, 'is_exclusive')
    : []

export const loyaltyRewardId = (state) => (index) => {
  const reward = state.loyaltyRewards[index]
  if (reward) return reward.loyalty_reward.id
  return undefined
}

export const loyaltyRewardToChangeId = (state) => (index) => {
  const reward = state.loyaltyRewardsToChange[index]
  if (reward) return reward.loyalty_reward.id
  return undefined
}

export const loyaltyRewardsCount = (state) => state.loyaltyRewards.length
export const loyaltyRewardsToChangeCount = (state) =>
  state.loyaltyRewardsToChange.length
export const newLoyaltyRewardsCount = (state) =>
  state.newLoyaltyRewardsModel.length
export const loyaltyRewards = (state) => state.loyaltyRewards || {}
export const loyaltyReward = (state) => (index) => state.loyaltyRewards[index]
export const loyaltyRewardToChange = (state) => (index) =>
  state.loyaltyRewardsToChange[index]

// create loyalty reward modal

function createPreviewer(keyForFile, keyForUrl, keyForBackend) {
  return (state) => {
    if (state.form[keyForFile]) {
      return URL.createObjectURL(state.form[keyForFile])
    }
    if (state.form[keyForUrl]) {
      return state.form[keyForUrl]
    }

    return state.reward && state.reward[keyForBackend]
  }
}

export const illustrationPreview = createPreviewer(
  'illustrationFile',
  'illustrationUrl',
  'image_original_url'
)

export const backgroundImagePreview = createPreviewer(
  'backgroundImageFile',
  'backgroundImageUrl',
  'background_url'
)

export const payloadForBackend = (state, _, rootState) => {
  const payload = {
    visibility: KIND_CATALOG,
    id: state.form.id,
    kind: state.form.kind,
    name: state.form.name,
    priority: state.form.priority,

    admin_description: state.form.adminDescription,
    formatted_description: state.form.formattedDescription,

    illustration_file: state.form.illustrationFile,
    illustration_url: state.form.illustrationUrl,
    background_image_file: state.form.backgroundImageFile,
    background_image_url: state.form.backgroundImageUrl,

    available_at: state.form.availableAt,
    expired_at: state.form.expiredAt
  }
  if (
    PROGRAM_KINDS_WITH_FIELD_REQUIRED_POINTS.includes(
      rootState.auth.selectedProgram.kind
    )
  ) {
    payload.required_points = state.form.requiredPoints
  }

  switch (state.form.kind) {
    case KIND_COUPON:
      payload.refund = {
        value: state.form.refund.value,
        type: state.form.refund.type,
        recommended_price: state.form.refund.recommendedPublicPrice,
        ean_primary_products: state.form.refund.eanPrimaryProducts,
        ean_secondary_products: state.form.refund.eanSecondaryProducts
      }
      break
    case KIND_CASHBACK:
      payload.cashback = {
        value: state.form.cashback.value
      }
      break
    case KIND_GOODY:
      payload.goody = {
        transporter: state.form.goody.transporter,

        exposed_title: state.form.goody.exposedTitle,
        exposed_last_name: state.form.goody.exposedLastName,
        exposed_first_name: state.form.goody.exposedFirstName,
        exposed_address_1: state.form.goody.exposedAddress1,
        exposed_address_2: state.form.goody.exposedAddress2,
        exposed_postal_code: state.form.goody.exposedPostalCode,
        exposed_city: state.form.goody.exposedCity,
        exposed_external_email: state.form.goody.exposedExternalEmail,
        exposed_phone_number: state.form.goody.exposedPhoneNumber,
        exposed_custom_label: state.form.goody.exposedCustomLabel,

        mandatory_title: state.form.goody.mandatoryTitle,
        mandatory_last_name: state.form.goody.mandatoryLastName,
        mandatory_first_name: state.form.goody.mandatoryFirstName,
        mandatory_address_1: state.form.goody.mandatoryAddress1,
        mandatory_address_2: state.form.goody.mandatoryAddress2,
        mandatory_postal_code: state.form.goody.mandatoryPostalCode,
        mandatory_city: state.form.goody.mandatoryCity,
        mandatory_external_email: state.form.goody.mandatoryExternalEmail,
        mandatory_phone_number: state.form.goody.mandatoryPhoneNumber,
        mandatory_custom_label: state.form.goody.mandatoryCustomLabel
      }
      break
    case KIND_FIDZ:
      payload.fidz = {
        value: state.form.fidz.value
      }
      break
  }

  return payload
}

export const isCreatingReward = (state) => !state.form.id
export const isUpdatingReward = (state) => !!state.form.id

export const formattedAvailableAt = (state) =>
  state.form.availableAt
    ? moment(state.form.availableAt).format('DD/MM/YYYY')
    : ''

export const formattedExpiredAt = (state) =>
  state.form.expiredAt ? moment(state.form.expiredAt).format('DD/MM/YYYY') : ''

export const isRefundTypePercent = (state) =>
  state.form.refund.type === DISCOUNT_TYPE_PERCENT
export const isRefundTypeAbsolute = (state) =>
  state.form.refund.type === DISCOUNT_TYPE_ABSOLUTE
