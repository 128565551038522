




















































import Vue from 'vue'

import { momentFormat } from '~/plugins/filters'

/* eslint-disable camelcase */
export interface TargetedDiscount {
  start_on: string
  end_on: string | null
  value: number
  quota: number
  user_count: number
}
/* eslint-enable camelcase */

export default Vue.extend({
  props: {
    discounts: {
      type: Array as () => TargetedDiscount[],
      required: true,
      default: () => []
    }
  },
  methods: {
    momentFormat
  }
})
