import createDefaultState, { State, Config } from './state'

export * from '@/utils/store/concerns/loadable/mutations.js'

export const reset = (state: State) => {
  Object.assign(state, createDefaultState())
}

export const setConfig = (state: State, config: Config) => {
  state.config = config
}
