export default {
  title: 'Stratégie de renouvellement',
  spendingsPrediction: 'Prédiction de dépenses',
  downloadReportInExcel: 'Télécharger rapport excel',
  hint:
    "Les cohortes sont affichées par mois de prise d'actions. Vous retrouvez en premier lieu, les cohortes sur lesquelles une action doit être prise prochainement. Une cohorte correspond à l'ensemble des utilisateurs ayant rejoint le programme durant un mois.",
  overwriteStrategy: 'Écraser la stratégie',
  submit: 'Générer une nouvelle stratégie',
  collection: 'Ensemble des prédictions',
  executionDate: "Date d'exécution",
  spending: 'Dépenses',
  month: 'Mois',
  periodInMonth: 'Période en mois',
  warning:
    "Attention, effectuer les changements provoquera l'abandon de la stratégie en cours et vous devrez paramétrer une nouvelle stratégie à la place.",
  failedRequest: 'Échec de la mise de à jour de la stratégie',
  successedRequest: 'Stratégie mise à jour',

  headers: {
    cohorts: 'Cohortes',
    new_comers: 'Nouveaux arrivants',
    renewalRate: 'Taux de renouvellement (%)',
    executed_at: 'Changé le',
    actions: 'Actions'
  },

  headersCa: {
    new_comers: 'Nouveaux arrivants',
    total: 'Total'
  },
  months: {
    0: 'Janvier',
    1: 'Février',
    2: 'Mars',
    3: 'Avril',
    4: 'Mai',
    5: 'Juin',
    6: 'Juillet',
    7: 'Août',
    8: 'Septembre',
    9: 'Octobre',
    10: 'Novembre',
    11: 'Décembre'
  }
}
