import createDefaultState from './state'

export * from '@/utils/store/concerns/loadable/mutations.js'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export const setProgram = (state, program) => {
  state.program = program
}
