import Vue from 'vue'
import moment from 'moment'
import { capitalize } from 'lodash-es'

import BigNumber from 'bignumber.js'

import { KIND_REFUND, KIND_CASHBACK } from '@/utils/constants/benefits'

let i18n
let locale = 'en'

export function formatBenefitCount(value, benefitKind) {
  if (value === null || value === undefined) return

  switch (benefitKind) {
    case KIND_REFUND:
    case KIND_CASHBACK:
      return formatCurrencyShort(value)
    default:
      return formatCount(value)
  }
}

export function formatCount(value) {
  if (value === null || value === undefined) return

  return new Intl.NumberFormat(locale).format(value)
}

export function formatCurrency(value) {
  if (value === null || value === undefined) return

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR'
  }).format(value)
}

export function formatCurrencyShort(value) {
  if (value === null || value === undefined) return

  const number = new BigNumber(value)
  const res = number.toFixed(number.isInteger() ? 0 : 2)
  return formatCurrency(res)
}

export function formatMoney(value, { suffix = '€' } = {}) {
  if (value === null || value === undefined) return

  const number = new BigNumber(value)

  if (number.isInteger()) {
    return number.toFixed(0) + suffix
  } else {
    return number.toFixed(2) + suffix
  }
}

export const momentFormat = (val, format) => {
  if (!val) return

  if (!format) {
    throw new Error('filter momentFormat : missing format')
  }
  if (!locale) {
    throw new Error('filter momentFormat : missing locale')
  }

  const datetime = moment(val)
  switch (locale) {
    case 'fr':
      switch (format) {
        case 'human-day':
          return `le ${datetime.format('DD/MM/YYYY')}`
        case 'human-long':
          return `le ${datetime.format('DD/MM/YYYY')} à ${datetime.format(
            'HH[h]mm'
          )}`
        case 'date':
          return datetime.format('DD/MM/YYYY')
        case 'time':
          return datetime.format('HH[h]mm')
        case 'date (time)':
          return datetime.format('DD/MM/YYYY (HH[h]mm)')
        default:
          return 'N/A'
      }
    case 'en':
      switch (format) {
        case 'human-day':
          return `on ${datetime.format('DD/MM/YYYY')}`
        case 'human-long':
          return `on ${datetime.format('MM/DD/YYYY')} at ${datetime.format(
            'HH:mm'
          )}`
        case 'date':
          return datetime.format('MM/DD/YYYY')
        case 'time':
          return datetime.format('HH:mm')
        case 'date (time)':
          return datetime.format('MM/DD/YYYY (HH:mm)')
        default:
          return 'N/A'
      }
    default:
      return 'N/A'
  }
}

export const formatDateRange = (startDate, endDate) => {
  if (!startDate) {
    throw new Error('filter formatDateRange : missing startDate to format')
  }
  if (!endDate) {
    throw new Error('filter formatDateRange : missing endDate to format')
  }
  if (!locale) {
    throw new Error('filter formatDateRange : missing locale')
  }

  if (startDate === endDate) {
    return momentFormat(startDate, 'human-day')
  }

  switch (locale) {
    case 'fr':
      return `du ${moment(startDate).format('DD/MM/YYYY')} au ${moment(
        endDate
      ).format('DD/MM/YYYY')}`
    case 'en':
      return `from ${moment(startDate).format('MM/DD/YYYY')} to ${moment(
        endDate
      ).format('MM/DD/YYYY')}`
    default:
      return 'N/A'
  }
}

const oneDay = 60 * 60 * 24
const oneHour = 60 * 60
const oneMinute = 60
export const humanizeDuration = (durationInSeconds) => {
  if (durationInSeconds < 60)
    return i18n.tc('global.durations.seconds', durationInSeconds)

  if (durationInSeconds % oneDay === 0) {
    const durationInDays = parseInt(durationInSeconds / oneDay)
    return i18n.tc('global.durations.days', durationInDays)
  }

  if (durationInSeconds % oneHour === 0) {
    const durationInHours = parseInt(durationInSeconds / oneHour)
    return i18n.tc('global.durations.hours', durationInHours)
  }

  if (durationInSeconds % oneMinute === 0) {
    const durationInMinutes = parseInt(durationInSeconds / oneMinute)
    return i18n.tc('global.durations.minutes', durationInMinutes)
  }

  const days = parseInt(durationInSeconds / oneDay)
  const hours = parseInt((durationInSeconds % oneDay) / oneHour)
  const minutes = parseInt((durationInSeconds % oneHour) / oneMinute)
  const secondes = durationInSeconds % oneMinute
  return `
    ${i18n.tc('global.durations.days', days)}
    ${i18n.tc('global.durations.hours', hours)}
    ${i18n.tc('global.durations.minutes', minutes)}
    ${i18n.tc('global.durations.seconds', secondes)}
  `
    .replace(/\s+/g, ' ')
    .trim()
}

Vue.use({
  install(Vue, _options) {
    Vue.filter('number', (value) => new Intl.NumberFormat(locale).format(value))

    Vue.filter('currency', formatCurrency)
    Vue.filter('currencyShort', formatCurrencyShort)
    Vue.filter('money', formatMoney)
    Vue.filter('capitalize', capitalize)

    // https://github.com/danhper/lodash-inflection/blob/master/lib/lodash-inflection.js#L166
    Vue.filter('titleize', function titleize(words) {
      if (typeof words !== 'string') {
        return words
      }

      return words.replace(/\S+/g, function(word) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
    })

    Vue.filter('timeOnly', (val) => moment(val).format('HH[h]mm'))
    Vue.filter('momentFormat', momentFormat)
    Vue.filter('formatDateRange', formatDateRange)

    Vue.filter('humanizeDuration', humanizeDuration)
  }
})

export default ({ app }) => {
  i18n = app.i18n
  locale = app.i18n.locale
  app.i18n.onLanguageSwitched = (_oldLocale, newLocale) => {
    locale = newLocale
  }
}
