<template>
  <div v-show="isLoaded">
    <v-menu offset-y>
      <template #activator="{ on, attrs }">
        <v-btn
          :loading="isLoading"
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
          v-text="selectedPeriodName"
        />
      </template>
      <v-list dense>
        <v-list-item-group :value="selectedPeriodIdentifier">
          <component
            :is="item.is"
            v-for="item in periodsAsList"
            :key="item.title"
            :item="item"
          />

          <v-subheader v-text="$t('layout.metrics.periods.custom')" />
          <v-list-item
            :value="PERIOD_CUSTOM_IDENTIFIER"
            @click="isRangeSelectionOpen = true"
          >
            <v-list-item-title v-text="$t('layout.metrics.periods.custom')" />
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-menu
      v-model="isRangeSelectionOpen"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          :loading="isLoading"
          color="secondary"
          dark
          v-bind="attrs"
          v-on="on"
          v-text="selectedPeriodRangeAsText"
        />
      </template>
      <v-date-picker
        v-model="dateRange"
        :type="datePickerType"
        range
        scrollable
        light
        :selected-items-text="selectedItemsText"
        :max="formattedEndOfThisMonth"
        :locale="$i18n.locale"
      >
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="isRangeSelectionOpen = false"
          v-text="$t('global.cancel')"
        />
        <v-btn
          text
          color="primary"
          @click="selectPeriodRange(dateRange)"
          v-text="$t('global.validate')"
        />
      </v-date-picker>
    </v-menu>
    <p v-if="refreshedAt.length > 0" class="ma-0 caption text-end">
      {{ refreshDate }}
    </p>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import { sortBy } from 'lodash-es'

import PeriodListSubheader from './PeriodListSubheader'
import PeriodListItem from './PeriodListItem'
import { formatDateRange } from '@/plugins/filters'

import {
  periodFromExpiredMonthsOfCurrentPeriod,
  periodFromPreviousMonth,
  periodFromCurrentMonth,
  periodFromExpiredTwelveMonths
} from '~/utils/constants/metrics/periods'

export const PERIOD_CUSTOM_IDENTIFIER = 'custom'

export default {
  data: () => ({
    PERIOD_CUSTOM_IDENTIFIER,

    dateRange: [],
    isRangeSelectionOpen: false,
    endOfThisMonth: moment()
      .endOf('month')
      .format('YYYY-MM-DD'),
    datePickerType: 'date' // or 'month'
  }),
  computed: {
    ...mapState('metrics/program', [
      'isLoaded',
      'isLoading',
      'selectedPeriod',
      'refreshedAt'
    ]),
    ...mapGetters('metrics/program', [
      'previousPeriodsForFrontend',
      'currentPeriodStartDate',
      'isCurrentMonthStartMonthOfPeriod',
      'selectedPeriodIdentifier',
      'isCustomPeriod'
    ]),
    refreshDate() {
      return this.$t('pages.metrics.program.general.refreshedAt', {
        date: this.refreshedAt
      })
    },
    formattedEndOfThisMonth() {
      return this.formatForDatePicker(this.endOfThisMonth)
    },

    selectedItemsText() {
      if (this.dateRange[0] === this.dateRange[1]) {
        return this.formatForDatePickerTitle(this.dateRange[0])
      }

      // dateStringToMonth(dateStr, this.$i18n.locale))
      const sortedRange = sortBy(
        this.dateRange.map((dateStr) => moment(dateStr))
      )
      return sortedRange
        .map((date) => this.formatForDatePickerTitle(date))
        .join(' ~ ')
    },

    selectedPeriodName() {
      if (!this.selectedPeriod) return
      if (this.isLoading) return ''
      if (this.isCustomPeriod) return this.$t('layout.metrics.periods.custom')
      if (this.selectedPeriod.title) return this.selectedPeriod.title

      return this.$tc('layout.metrics.periods.previous', 1)
    },

    selectedPeriodRangeAsText() {
      if (!this.selectedPeriod) return
      if (this.isLoading) return ''
      return this.periodRangeAsText(this.selectedPeriod)
    },

    currentPeriodChildren() {
      const $t = this.$t.bind(this)

      const list = [
        periodFromPreviousMonth($t),
        periodFromCurrentMonth($t),
        periodFromExpiredTwelveMonths($t)
      ]

      if (!this.currentPeriodStartDate) {
        return list
      }

      return [
        periodFromExpiredMonthsOfCurrentPeriod(
          $t,
          this.currentPeriodStartDate,
          this.isCurrentMonthStartMonthOfPeriod
        ),
        ...list
      ]
    },

    periodsGrouped() {
      const list = []
      list.push({
        title: this.$t('layout.metrics.periods.current'),
        children: this.currentPeriodChildren
      })
      if (this.previousPeriodsForFrontend.length > 0) {
        list.push({
          title: this.$tc('layout.metrics.periods.previous', 2),
          children: this.previousPeriodsForFrontend
        })
      }
      return list
    },
    periodsAsList() {
      const list = []
      this.periodsGrouped.forEach((group) => {
        list.push({
          is: PeriodListSubheader,
          title: group.title
        })
        group.children.forEach((period) => {
          list.push({
            is: PeriodListItem,
            title: period.title,
            period
          })
        })
      })
      return list
    }
  },
  watch: {
    selectedPeriod: {
      immediate: true,
      handler() {
        if (!this.selectedPeriod) {
          this.dateRange = []
          return
        }

        this.dateRange = [
          this.formatForDatePicker(this.selectedPeriod.startDate),
          this.formatForDatePicker(this.selectedPeriod.endDate)
        ]
      }
    }
  },
  methods: {
    ...mapMutations('metrics/program', ['selectPeriod']),

    formatForDatePicker(date) {
      switch (this.datePickerType) {
        case 'date':
          return moment(date).format('YYYY-MM-DD')
        case 'month':
          return moment(date).format('YYYY-MM')
      }
    },
    formatForDatePickerTitle(date) {
      switch (this.datePickerType) {
        case 'date':
          return moment(date).format('D MMM')
        case 'month':
          return moment(date).format('MMM')
      }
      // new Intl.DateTimeFormat(locale, { month: 'short' }).format(
      //   moment(dateStr).format('MMM')
      // )
    },
    offsetPeriodDatesIfNeeded(range) {
      if (this.datePickerType !== 'month') return

      // moment methods mutates variable
      range[0].startOf('month')
      range[1].endOf('month')
    },

    periodRangeAsText(period) {
      if (!period) return

      return formatDateRange(period.startDate, period.endDate)
    },

    selectPeriodRange(dateRange) {
      let sortedRange
      if (dateRange.length === 1) {
        sortedRange = [moment(dateRange[0]), moment(dateRange[0])]
      } else {
        sortedRange = sortBy(dateRange.map((dateStr) => moment(dateStr)))
      }

      this.offsetPeriodDatesIfNeeded(sortedRange)
      this.selectPeriod({
        identifier: PERIOD_CUSTOM_IDENTIFIER,
        startDate: sortedRange[0].format('YYYY-MM-DD'),
        endDate: sortedRange[1].format('YYYY-MM-DD')
      })
      this.isRangeSelectionOpen = false
    }
  }
}
</script>

<style scoped>
.text-end {
  text-align: end;
}
</style>
