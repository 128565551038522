import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{staticClass:"earned-coupon"},[_c('td',[_c('ul',[_c('li',[_vm._v("ID #"+_vm._s(_vm.earnedCoupon.id))]),_vm._v(" "),_c('li',[_vm._v("\n        "+_vm._s(_vm._f("capitalize")(_vm.$t('pages.user.profile.earnedCoupons.used.headers.status')))+"\n        :\n        "),_c('strong',[_vm._v("\n          "+_vm._s(_vm.earnedCoupon.i18n_status)+"\n        ")])]),_vm._v(" "),_c('li',[_vm._v("\n        "+_vm._s(_vm._f("capitalize")(_vm.$t('pages.user.profile.earnedCoupons.used.headers.origin')))+"\n        : "+_vm._s(_vm.earnedCoupon.i18n_origin)+"\n      ")]),_vm._v(" "),_c('li',[_vm._v("\n        "+_vm._s(_vm._f("capitalize")(_vm.$t('pages.user.profile.earnedCoupons.used.headers.offer')))+"\n        :\n        "),_c('a',{attrs:{"href":_vm.earnedCoupon.offer_old_bo_url,"target":"_blank"}},[_vm._v("\n          "+_vm._s(_vm.earnedCoupon.offer_name)+"\n          "),_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)]),_vm._v(" "),_c('li',[_vm._v("\n        "+_vm._s(_vm._f("capitalize")(_vm.$t('pages.user.profile.earnedCoupons.used.headers.acquiredAt', {
            at: _vm.momentFormat(_vm.earnedCoupon.offer_earned_at, 'human-long')
          })))+"\n        "),(_vm.earnedCoupon.origin_earned_coupon_id)?_c('span',[_c('br'),_vm._v("\n          ("+_vm._s(_vm._f("capitalize")(_vm.$t(
              'pages.user.profile.earnedCoupons.used.headers.afterRejectionOfEarnedCoupon',
              {
                id: _vm.earnedCoupon.origin_earned_coupon_id
              }
            )))+")\n        ")]):_vm._e()])])]),_vm._v(" "),_c('td',[_c('DisplayCoupon',{staticStyle:{"min-width":"200px","max-width":"350px"},attrs:{"coupon":_vm.earnedCoupon.coupon}})],1),_vm._v(" "),_c('td',[_c('ul',[(_vm.earnedCoupon.offer_usage_type)?_c('li',[_vm._v("\n        "+_vm._s(_vm._f("capitalize")(_vm.$t('pages.user.profile.earnedCoupons.used.headers.validation')))+"\n        :\n        "),_c('a',{attrs:{"href":_vm.earnedCoupon.offer_usage_old_bo_url,"target":"_blank"}},[_vm._v("\n          "+_vm._s(_vm.earnedCoupon.offer_usage_type)+"\n          #"+_vm._s(_vm.earnedCoupon.offer_usage_id)+"\n          "),_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)]):_vm._e(),_vm._v(" "),(_vm.earnedCoupon.offer_used_at)?_c('li',[_vm._v("\n        "+_vm._s(_vm._f("capitalize")(_vm.$t('pages.user.profile.earnedCoupons.used.headers.usedAt', {
            at: _vm.momentFormat(_vm.earnedCoupon.offer_used_at, 'human-long')
          })))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.earnedCoupon.cancelled_at)?_c('li',[_vm._v("\n        "+_vm._s(_vm._f("capitalize")(_vm.$t('pages.user.profile.earnedCoupons.used.headers.cancelledAt', {
            at: _vm.momentFormat(_vm.earnedCoupon.cancelled_at, 'human-long')
          })))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.earnedCoupon.offer_used_on_receipt_id)?_c('li',[_vm._v("\n        "+_vm._s(_vm._f("capitalize")(_vm.$t('pages.user.profile.earnedCoupons.used.headers.receipt')))+"\n        :\n        "),_c('nuxt-link',{attrs:{"to":(_vm.routeBasePath + "/declarations/" + (_vm.earnedCoupon.offer_used_on_declaration_identifier))}},[_vm._v("\n          #"+_vm._s(_vm.earnedCoupon.offer_used_on_receipt_id)+"\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.earnedCoupon.offer_used_on_purchase_id)?_c('li',[_vm._v("\n        "+_vm._s(_vm._f("capitalize")(_vm.$t('pages.user.profile.earnedCoupons.used.headers.purchase', {
            at: _vm.momentFormat(_vm.earnedCoupon.offer_used_at, 'human-long')
          })))+"\n        :\n        "),_c('nuxt-link',{attrs:{"to":(_vm.routeBasePath + "/declarations/" + (_vm.earnedCoupon.offer_used_on_declaration_identifier) + "#" + (_vm.earnedCoupon.offer_used_on_purchase_identifier))}},[_vm._v("\n          #"+_vm._s(_vm.earnedCoupon.offer_used_on_purchase_id)+"\n        ")])],1):_vm._e(),_vm._v(" "),(_vm.earnedCoupon.offer_reselled_on_type)?_c('li',[_vm._v("\n        "+_vm._s(_vm._f("capitalize")(_vm.$t('pages.user.profile.earnedCoupons.used.headers.reselledFor', {
            price: _vm.earnedCoupon.i18n_reselled_for
          })))+"\n        "),_c('br'),_vm._v("\n        ("+_vm._s(_vm.earnedCoupon.offer_reselled_on_type)+" #"+_vm._s(_vm.earnedCoupon.offer_reselled_on_id)+")\n      ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }