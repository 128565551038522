import createDefaultState from './state'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export * from '@/utils/store/concerns/loadable/mutations'

export const resetPagination = (state) => {
  state.page = 1
}
export const setData = (state, data) => {
  if (!data) return

  state.earnedCoupons = data.earned_coupons
  state.hasMore = data.has_more
  state.page += 1
}

export const setLoadingMoreState = (state, boolean) => {
  state.isLoadingMore = boolean
}
export const setErrorOnFetchMore = (state, error) => {
  state.errorOnFetchMore = error
}
export const appendData = (state, data) => {
  state.earnedCoupons = state.earnedCoupons.concat(data.earned_coupons)
  state.hasMore = data.has_more
  state.page += 1
}
