






























































































































































import Vue from 'vue'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import VueDraggable from 'vuedraggable'

import RewardItem, { Reward } from './RewardItem.vue'
import AddReward from './AddReward.vue'

export interface Row {
  id: string
  name: string
  color: string
  isBlack: boolean
  colorIsNil: boolean
  priority: number
  rewards: Array<Reward>
}

export default Vue.extend({
  components: {
    RewardItem,
    AddReward,
    VueDraggable
  },
  props: {
    row: {
      type: Object as () => Row,
      required: true
    },
    showInactiveRewards: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('auth', { hasWriteContentRight: 'hasWriteContentRight' }),
    ...mapState('auth', { selectedProgram: 'selectedProgram' }),

    rewards: {
      get(): Array<Reward> {
        return this.row.rewards
      },
      set(value: Array<Reward>): void {
        this.updateRewardsInShelfRow({ rowId: this.row.id, rewards: value })
      }
    },
    displayedRewards(): Array<Reward> {
      if (this.showInactiveRewards) {
        return this.rewards
      } else {
        return this.rewards.filter((reward) => reward.isActive)
      }
    },
    noDisplayedRewards(): boolean {
      return this.displayedRewards.length === 0
    },

    color: {
      get(): string {
        return this.row.color || ''
      },
      set(value: string): void {
        this.changeShelfColor({ color: value, rowId: this.row.id })
      }
    },
    name: {
      get(): string {
        return this.row.name
      },
      set(value: string): void {
        this.changeShelfName({ name: value, rowId: this.row.id })
      }
    },
    isBlack: {
      get(): boolean {
        return this.row.isBlack
      },
      set(value: boolean): void {
        this.changeIsBlack({ isBlack: value, rowId: this.row.id })
      }
    },
    colorIsNil: {
      get(): boolean {
        return this.row.colorIsNil
      },
      set(value: boolean): void {
        this.changeColorIsNil({ colorIsNil: value, rowId: this.row.id })
      }
    },

    titleColorText(): string {
      return this.$t(
        `pages.program.catalog.shelf.${this.isBlack ? 'black' : 'white'}`
      ) as string
    },
    backgroundColortext(): string {
      if (this.colorIsNil) {
        return this.$t('pages.program.catalog.shelf.colorOfProgram') as string
      } else {
        return this.$t('pages.program.catalog.shelf.colorCustom') as string
      }
    }
  },
  methods: {
    ...mapMutations('program/catalog/shelf', [
      'changeShelfName',
      'changeShelfColor',
      'changeIsBlack',
      'changeColorIsNil',
      'updateRewardsInShelfRow'
    ]),
    ...mapActions('program/catalog/shelf', [
      'updateShelfRow',
      'removeShelfRow',
      'changeShelfOfReward'
    ]),

    onChange(event: any): void {
      if (event.added) {
        this.changeShelfOfReward({
          rewardId: event.added.element.id,
          rowId: this.row.id
        })
      }
    }
  }
})
