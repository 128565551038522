import Vue from 'vue'

import createDefaultState from './state'
import * as types from './mutation-types'

export default {
  setError(state, error) {
    state.error = error
  },

  [types.RESET](state) {
    Object.assign(state, createDefaultState())
  },

  [types.SET_IS_POT_LOADING](state, boolean) {
    state.isPotLoading = boolean
  },
  [types.SET_IS_POT_LOADED](state, boolean) {
    state.isPotLoaded = boolean
  },
  [types.SET_IS_FETCHING_MORE](state, boolean) {
    state.isFetchingMore = boolean
  },

  [types.SET_POT](state, payload) {
    state.pot = payload
  },
  [types.SET_POT_REFUNDS](state, payload) {
    state.potRefunds = payload
  },
  [types.SET_OTHER_APP_POT_REFUNDS](state, payload) {
    state.otherAppPotRefunds = payload
  },

  [types.ADD_TRANSFER_PAYMENT](state, payload) {
    state.transferPayments.push(payload)
  },
  [types.SET_PREVIOUS_TRANSFER_PAYMENT_ID](state, id) {
    state.previousTransferPaymentId = id
  },

  [types.ATTACH_ALL_REFUNDS](state, { id, potRefunds, otherAppPotRefunds }) {
    const transferPayment = state.transferPayments.find((tp) => tp.id === id)
    Vue.set(transferPayment, 'potRefunds', potRefunds)
    Vue.set(transferPayment, 'otherAppPotRefunds', otherAppPotRefunds)
  }
}
