import createDefaultState from './state'

export * from '@/utils/store/concerns/loadable/mutations.js'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export const setProgramRules = (state, program) => {
  state.program = program
}
export const resetProgram = (state) => {
  state.program = {}
}
