<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <p>
      {{
        $t('layout.program.importProducts.instructions.useThisFileAsReference')
      }}
      :
      <a :href="LINK" target="_blank">
        {{ $t('layout.program.importProducts.instructions.eansList') }}
      </a>
    </p>

    <h4>
      {{ $t('layout.program.importProducts.instructions.steps.title') }}
      :
    </h4>
    <ol>
      <li v-for="n in 3" :key="n">
        {{ $t(`layout.program.importProducts.instructions.steps.${n}`) }}
      </li>
    </ol>

    <ValidationProvider
      v-slot="{ errors }"
      name="email"
      mode="eager"
      rules="required"
      tag="div"
    >
      <v-file-input :value="file" :error-messages="errors" @change="setFile" />
    </ValidationProvider>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" :loading="isSending" @click="handleSubmit(submit)">
        {{ $t('layout.program.importProducts.preview.title') }}
      </v-btn>
    </v-card-actions>
  </ValidationObserver>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import toFormData from '~/utils/functions/toFormData'
import { fetchCoreFromComponentAndHandleError } from '~/utils/functions/fetch-and-handle-errors'

const LINK =
  'https://docs.google.com/spreadsheets/d/1PJ4Q9MbmVHeBMgcSs1XwM4UCKhmkSQKX4p0deWlO580/edit#gid=1165177258'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    LINK,

    isSending: false
  }),
  computed: {
    ...mapState('program/import-file-with-eans', ['file'])
  },
  methods: {
    ...mapMutations('snackbar', ['addToast']),
    ...mapMutations('program/import-file-with-eans', [
      'setFile',
      'handleFileAnalysis'
    ]),

    submit() {
      return fetchCoreFromComponentAndHandleError({
        $vm: this,
        isLoadingKey: 'isSending',
        errorInToast: true,

        axios: {
          method: 'POST',
          url: `/programs/${this.$route.params.programIdentifier}/verify_file_with_eans`,
          data: toFormData({ file: this.file })
        },

        onSuccess: (result) => {
          this.handleFileAnalysis(result)
          this.$emit('go-to-next-step')
        }
      })
    }
  }
}
</script>
