import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"5"}},[_c(VListItem,[_c(VListItemAvatar,{attrs:{"height":"25%","width":"25%"}},[_c(VImg,{attrs:{"aspect-ratio":"1","contain":"","src":_vm.reward.loyalty_reward.image_url}})],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(("ID: " + (_vm.reward.loyalty_reward.id)))}}),_vm._v(" "),_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.reward.loyalty_reward.name)}}),_vm._v(" "),_c(VListItemTitle,{domProps:{"textContent":_vm._s(((_vm.reward.loyalty_reward.value) + " points"))}})],1),_vm._v(" "),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.canDelete)?_c(VIcon,_vm._g(_vm._b({attrs:{"medium":""},on:{"click":function($event){return _vm.$emit('delete-reward')}}},'v-icon',attrs,false),on),[_vm._v("\n          mdi-delete\n        ")]):_vm._e()]}}])},[_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(_vm.$t('pages.program.catalog.changeRewards.deleteRewardTooltip'))+"\n      ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }