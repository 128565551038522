export interface Admin {
  identifier: string
}

export interface Program {
  id: number
  identifier: string
  name: string
  dominant_color: string // eslint-disable-line camelcase
}

export interface State {
  isLoading: boolean
  error: null | string
  isUserInputError: null | boolean

  admin: null | Admin
  selectedProgram: null | Program

  isRefreshing: boolean
}

export default () => ({
  isLoading: false,

  error: null,
  isUserInputError: null,

  admin: null,

  selectedProgram: null,

  isRefreshing: false
})
