import { Store, ActionContext } from 'vuex'

import { State } from './state'
import { RootState } from '~/store/state'

import {
  handleAxiosErrorServerFromStoreV3,
  handleAxiosErrorFrontendFromStoreV3
} from '@/utils/functions/handle-errors'

export const fetch = async function(
  this: Store<State>,
  { commit, rootState }: ActionContext<State, RootState>
) {
  if (!rootState.auth.selectedProgram) {
    commit('setError', this.$i18n.t('errors.program.notSelected'))
    return
  }

  commit('setLoadingState', true)
  commit('resetError')

  let response
  try {
    response = await this.$axios.get(
      `/users/${rootState.route.params.userId}/app`
    )
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({ error, commit, store: this })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStoreV3({ response, commit, store: this })
    return
  }

  commit('setData', response.data.result)
  commit('setLoadedState', true)
  commit('setLoadingState', false)
}

export const unlockScreen = async function(
  this: Store<State>,
  { commit, rootState }: ActionContext<State, RootState>,
  { screen }: { screen: string }
) {
  commit('setLockLoadingState', true)
  commit('resetError')

  let response
  try {
    response = await this.$axios.post(
      `/users/${rootState.route.params.userId}/unlock_screen`,
      {
        screen
      }
    )
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setLockLoadingState',
      errorInToast: true
    })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStoreV3({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setLockLoadingState',
      errorInToast: true
    })
    return
  }

  commit('setData', response.data.result)
  commit('setLockLoadingState', false)
}

export const lockScreen = async function(
  this: Store<State>,
  { commit, rootState }: ActionContext<State, RootState>,
  { screen }: { screen: string }
) {
  commit('setLockLoadingState', true)
  commit('resetError')

  let response
  try {
    response = await this.$axios.post(
      `/users/${rootState.route.params.userId}/lock_screen`,
      {
        screen
      }
    )
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setLockLoadingState',
      errorInToast: true
    })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStoreV3({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setLockLoadingState',
      errorInToast: true
    })
    return
  }

  commit('setData', response.data.result)
  commit('setLockLoadingState', false)
}
