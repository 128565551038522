<template>
  <v-btn
    :color="isSelected ? 'primary' : undefined"
    :class="isSelected ? 'white--text' : undefined"
    @click="$emit('selected', scope.identifier)"
  >
    {{ scope.title_i18n }}
  </v-btn>
</template>

<script>
import hasOwnProp from '@/utils/functions/hasOwnProp'
export default {
  props: {
    scope: {
      type: Object,
      required: true,
      validator: (o) =>
        hasOwnProp(o, 'title_i18n') &&
        hasOwnProp(o, 'identifier') &&
        hasOwnProp(o, 'is_default')
    },
    isSelected: {
      type: Boolean,
      required: true
    }
  }
}
</script>
