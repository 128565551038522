import Vue from 'vue'
import { mapMutations } from 'vuex'

import { fetchCoreFromComponentAndHandleError } from '~/utils/functions/fetch-and-handle-errors'

export default Vue.extend({
  methods: {
    ...mapMutations('snackbar', ['addToast']),

    fetchCoreFromComponentAndHandleError(options: any) {
      return fetchCoreFromComponentAndHandleError({
        $vm: this,
        ...options
      })
    }
  }
})
