<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <SelectAccount @input="editorId = $event" />

    <v-radio-group v-model="kind" :disabled="isLoading" row>
      <v-radio label="Jaune" color="yellow darken-3" value="yellow" />
      <v-radio label="Rouge" color="red darken-3" value="red" />
    </v-radio-group>

    <ValidationProvider
      v-slot="{ errors }"
      name="banNotes"
      mode="eager"
      rules="required"
      slim
    >
      <v-textarea
        v-model="notes"
        clearable
        clear-icon="mdi-close-circle"
        outlined
        :disabled="isLoading"
        :label="$t('pages.users.modal.accountState.notes')"
        :error-messages="errors"
      />
    </ValidationProvider>

    <div>
      <v-btn
        :loading="isLoading"
        :disabled="isLoading"
        color="primary"
        class="white--text ma-1"
        @click="handleSubmit(addBan)"
      >
        {{
          $t('pages.users.modal.accountActions.moderations.submit') | capitalize
        }}
      </v-btn>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SelectAccount from './SelectAccount'
import MixinAjaxToCore from '~/mixins/ajaxToCore'
import { momentFormat } from '@/plugins/filters'
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    SelectAccount
  },
  mixins: [MixinAjaxToCore],
  data: () => ({
    editorId: null,

    kind: null,
    notes: '',
    isLoading: false
  }),
  computed: {
    ...mapState('user', ['user'])
  },
  methods: {
    momentFormat,
    ...mapMutations('user', ['addBanToUser']),
    ...mapMutations('snackbar', ['addToast']),

    addBan() {
      return this.fetchCoreFromComponentAndHandleError({
        isLoadingKey: 'isLoading',
        errorInToast: true,

        axios: {
          method: 'POST',
          url: '/bans/create',
          data: {
            user_id: this.user.id,
            kind: this.kind,
            notes: this.notes,
            origin_partner_id: this.editorId
          }
        },

        onSuccess: (result) => {
          this.addBanToUser(result)
          this.addToast({
            text: this.$t(
              'pages.users.modal.accountActions.moderations.success'
            )
          })
          this.$emit('close')
        }
      })
    }
  }
}
</script>
