import { Store, ActionContext } from 'vuex'

import { TreeNode } from '../../../classification-v2/state'

import { State } from './state'
import { RootState } from '~/store/state'

import {
  handleAxiosErrorServerFromStoreV3,
  handleAxiosErrorFrontendFromStoreV4,
  handleAxiosProxyAnalyticsErrorServerFromStoreV3
} from '@/utils/functions/handle-errors'

export const selectNodeForOriginById = function(
  this: Store<State>,
  { rootGetters, commit, dispatch }: ActionContext<State, RootState>,
  id: number
) {
  const node = rootGetters['program/classification-v2/nodesFlatten'].find(
    (node) => node.id === id
  )
  commit('program/classification-v2/selectNodeForOrigin', node, { root: true })
  return dispatch(
    'program/classification-v2/fetchSelectedOriginNodeDenoms',
    null,
    { root: true }
  )
}
export const selectNodeForTargetById = function(
  this: Store<State>,
  { rootGetters, commit, dispatch }: ActionContext<State, RootState>,
  id: number
) {
  const node = rootGetters['program/classification-v2/nodesFlatten'].find(
    (node) => node.id === id
  )
  commit('program/classification-v2/selectNodeForTarget', node, { root: true })
  return dispatch(
    'program/classification-v2/fetchSelectedTargetNodeProducts',
    null,
    { root: true }
  )
}

export const deleteNode = async function(
  this: Store<State>,
  { commit, dispatch }: ActionContext<State, RootState>,
  node: TreeNode
) {
  commit('setDeletingNodeId', node.id)

  if (node.id < 0) {
    // destroy only on frontend
    commit('program/classification-v2/destroyNode', node, { root: true })
  } else {
    // destroy in backend and frontend
    await dispatch('destroyNode', node)
  }

  commit('setDeletingNodeId', null)
}

export const destroyNode = async function(
  this: Store<State>,
  { commit, rootState, rootGetters }: ActionContext<State, RootState>,
  node: TreeNode
) {
  if (!rootState.auth.selectedProgram) {
    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t('errors.program.notSelected')
      },
      { root: true }
    )
    return
  }

  commit('setDeletingNodeState', true)

  let response
  try {
    response = rootGetters['auth/useCore']
      ? await this.$axios.post(
          `/programs/${rootState.auth.selectedProgram.identifier}/products_group_tree_node/${node.id}/destroy`
        )
      : await this.$axios.post(
          '/api/proxy/analytics',
          {
            method: 'POST',
            path: `/internal_api/sas_bo_front_end/v1/programs/${rootState.auth.selectedProgram.id}/products-group/${node.id}/destroy`
          },
          { baseURL: '/' }
        )
  } catch (error) {
    const method = rootGetters['auth/useCore']
      ? handleAxiosErrorServerFromStoreV3
      : handleAxiosProxyAnalyticsErrorServerFromStoreV3
    method({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setDeletingNodeState',
      errorInToast: true
    })
    return
  }

  if (rootGetters['auth/useCore'] && !response.data.success) {
    handleAxiosErrorFrontendFromStoreV4({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setDeletingNodeState',
      errorInToast: true
    })
    return
  }

  commit('program/classification-v2/destroyNode', node, { root: true })
  commit('setDeletingNodeState', false)
}

export const saveNode = async function(
  this: Store<State>,
  { state, commit, dispatch }: ActionContext<State, RootState>,
  node: TreeNode
) {
  if (!state.editedNodeName) return

  if (node.id < 0) {
    await dispatch('createNode', { node, name: state.editedNodeName })
  } else {
    await dispatch('renameNode', { node, name: state.editedNodeName })
  }

  if (state.lastBackendCallFailed) {
    commit('setLastBackendCallFailedState', false)
  } else {
    commit('resetEditedNode')
  }
}

export const createNode = async function(
  this: Store<State>,
  { commit, rootState, rootGetters }: ActionContext<State, RootState>,
  { node, name }: { node: TreeNode; name: string }
) {
  if (!rootState.auth.selectedProgram) {
    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t('errors.program.notSelected')
      },
      { root: true }
    )
    return
  }

  commit('setCreatingNodeState', true)

  let response
  try {
    response = rootGetters['auth/useCore']
      ? await this.$axios.post(
          `programs/${rootState.auth.selectedProgram.identifier}/products_group_tree_node/${node.parent_id}/create_child`,
          { name }
        )
      : await this.$axios.post(
          '/api/proxy/analytics',
          {
            method: 'POST',
            path: '/internal_api/sas_bo_front_end/v1/create-node-child-to',
            data: { parent_id: node.parent_id, name }
          },
          { baseURL: '/' }
        )
  } catch (error) {
    const method = rootGetters['auth/useCore']
      ? handleAxiosErrorServerFromStoreV3
      : handleAxiosProxyAnalyticsErrorServerFromStoreV3
    method({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setCreatingNodeState',
      errorInToast: true
    })
    commit('setLastBackendCallFailedState', true)
    return
  }

  if (rootGetters['auth/useCore'] && !response.data.success) {
    handleAxiosErrorFrontendFromStoreV4({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setCreatingNodeState',
      errorInToast: true
    })
    return
  }

  const data = rootGetters['auth/useCore']
    ? response.data.result
    : response.data

  commit(
    'program/classification-v2/replaceNode',
    {
      nodeToReplace: node,
      nodeToReplaceWith: data.node
    },
    { root: true }
  )
  commit('setCreatingNodeState', false)
}

export const renameNode = async function(
  this: Store<State>,
  { commit, rootState, rootGetters }: ActionContext<State, RootState>,
  { node, name }: { node: TreeNode; name: string }
) {
  if (!rootState.auth.selectedProgram) {
    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t('errors.program.notSelected')
      },
      { root: true }
    )
    return
  }

  commit('setRenamingNodeState', true)

  let response
  try {
    response = rootGetters['auth/useCore']
      ? await this.$axios.post(
          `programs/${rootState.auth.selectedProgram.identifier}/products_group_tree_node/${node.id}/rename`,
          { name }
        )
      : await this.$axios.post(
          '/api/proxy/analytics',
          {
            method: 'POST',
            path: `/internal_api/sas_bo_front_end/v1/programs/${rootState.auth.selectedProgram.id}/products-group/${node.id}/rename`,
            data: { name }
          },
          { baseURL: '/' }
        )
  } catch (error) {
    const method = rootGetters['auth/useCore']
      ? handleAxiosErrorServerFromStoreV3
      : handleAxiosProxyAnalyticsErrorServerFromStoreV3
    method({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setRenamingNodeState',
      errorInToast: true
    })
    commit('setLastBackendCallFailedState', true)
    return
  }

  if (rootGetters['auth/useCore'] && !response.data.success) {
    handleAxiosErrorFrontendFromStoreV4({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setRenamingNodeState',
      errorInToast: true
    })
    return
  }

  commit('program/classification-v2/renameNode', { node, name }, { root: true })
  commit('setRenamingNodeState', false)
}

interface MoveResult {
  parent: TreeNode
  child: TreeNode
}

function findMovedNode(currentNode: TreeNode, newNodes: TreeNode[]) {
  const results: MoveResult[] = []
  const oldNodes = currentNode.children

  newNodes.forEach((newNode) => {
    const index = oldNodes.findIndex((oldNode) => oldNode.id === newNode.id)

    if (index === -1) {
      // newNode not found in oldNodes
      // which means it is the one that has moved
      // the one we are looking for
      results.push({ parent: currentNode, child: newNode })
    } else {
      const oldNode = currentNode.children[index]
      results.push(...findMovedNode(oldNode, newNode.children))
    }
  })

  return results
}

export const replaceRootNodes = async function(
  this: Store<State>,
  { rootState, commit, dispatch }: ActionContext<State, RootState>,
  nodes: TreeNode[]
) {
  if (!rootState.program['classification-v2'].tree) return

  // needs to be done before `replaceRootNodes`
  const results = findMovedNode(
    rootState.program['classification-v2'].tree,
    nodes
  )

  commit('program/classification-v2/replaceRootNodes', nodes, { root: true })

  switch (results.length) {
    case 0:
      // move inside siblings (no change of parent)
      break
    case 1:
      await dispatch('moveNode', {
        node: results[0].child,
        parentId: results[0].parent.id
      })
      break
    default:
      throw new Error('more than 1 moved node ?')
  }
}

export const moveNode = async function(
  this: Store<State>,
  { commit, dispatch, rootState, rootGetters }: ActionContext<State, RootState>,
  { node, parentId }: { node: TreeNode; parentId: number }
) {
  if (!rootState.auth.selectedProgram) {
    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t('errors.program.notSelected')
      },
      { root: true }
    )
    return
  }

  commit('setMovingNodeState', true)

  let response
  try {
    response = rootGetters['auth/useCore']
      ? await this.$axios.post(
          `programs/${rootState.auth.selectedProgram.identifier}/products_group_tree_node/${node.id}/make_child_of`,
          { parent_node_id: parentId }
        )
      : await this.$axios.post(
          '/api/proxy/analytics',
          {
            method: 'POST',
            path: `/internal_api/sas_bo_front_end/v1/programs/${rootState.auth.selectedProgram.id}/products-group/${node.id}/make_child_of`,
            data: { parent_id: parentId }
          },
          { baseURL: '/' }
        )
  } catch (error) {
    const method = rootGetters['auth/useCore']
      ? handleAxiosErrorServerFromStoreV3
      : handleAxiosProxyAnalyticsErrorServerFromStoreV3
    method({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setMovingNodeState',
      errorInToast: true
    })
    dispatch('program/classification-v2/fetchTree', null, { root: true })
    return
  }

  if (rootGetters['auth/useCore'] && !response.data.success) {
    handleAxiosErrorFrontendFromStoreV4({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setMovingNodeState',
      errorInToast: true
    })
    return
  }

  const data = rootGetters['auth/useCore']
    ? response.data.result
    : response.data

  commit(
    'program/classification-v2/replaceNode',
    {
      nodeToReplace: node,
      nodeToReplaceWith: data.node
    },
    { root: true }
  )

  commit('setMovingNodeState', false)
}
