import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"400","height":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onFromDialog = ref.on;
var attrsFromDialog = ref.attrs;
return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var onFromTooltip = ref.on;
        var attrsFromTooltip = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"disabled":_vm.disabled,"icon":"","dark":""}},'v-btn',Object.assign({}, attrsFromDialog, attrsFromTooltip),false),Object.assign({}, onFromDialog, onFromTooltip)),[_c(VIcon,[_vm._v("mdi-plus")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('pages.program.catalog.shelf.addReward')))])])]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_vm._v(" "),_c(VCard,{attrs:{"height":"500","width":"400"}},[_c(VToolbar,{attrs:{"dark":"","color":"primary"}},[_c(VToolbarTitle,[_vm._v("\n        "+_vm._s(_vm.$t('pages.program.catalog.shelf.addReward'))+"\n      ")]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VToolbarItems,[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.showModal = false}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_vm._v(" "),_c(VRow,{staticClass:"mx-2",attrs:{"justify":"center"}},[_c(VCol,{staticClass:"mx-5",attrs:{"align-self":"center"}},[_c(VAutocomplete,{attrs:{"loading":_vm.isSearching,"items":_vm.rewards,"item-text":"name","item-value":"id","label":_vm.$t('pages.program.catalog.shelf.selectReward'),"placeholder":_vm.$t('pages.program.catalog.shelf.searchReward'),"append-icon":"mdi-toy-brick-search","dense":"","solo":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('img',{attrs:{"width":"95px","src":item.imageUrl}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(item.name))])]}}]),model:{value:(_vm.selectedReward),callback:function ($$v) {_vm.selectedReward=$$v},expression:"selectedReward"}})],1)],1),_vm._v(" "),_c(VRow,{staticClass:"mx-2",attrs:{"align":"center","justify":"center"}},[_c(VSpacer),_vm._v(" "),_c(VCol,{staticClass:"mx-5",attrs:{"align-self":"center"}},[_c(VBtn,{staticClass:"mx-3",attrs:{"color":"white","elevation":"2","rounded":""},on:{"click":function($event){return _vm.addRewardToShelfRow({ rewardId: _vm.selectedReward, rowId: _vm.rowId })}}},[_vm._v("\n          "+_vm._s(_vm.$t('pages.program.catalog.validate'))+"\n        ")])],1),_vm._v(" "),_c(VSpacer)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }