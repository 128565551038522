import Vue from 'vue'
import { mapMutations } from 'vuex'
import { debounce } from 'lodash-es'

import cancellableFetchFromComponent from '~/utils/functions/cancellableFetch/fromComponent.js'

const DEBOUNCE_DELAY = 250

export default Vue.extend({
  props: {
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    noDataText() {
      if (this.isLoading) {
        return this.$t(
          'crud.offers.edit.products.update.fields.brandName.loading'
        )
      }
      if (!this.isLoaded) {
        return this.$t('crud.offers.edit.products.update.fields.brandName.hint')
      }

      return this.$t('crud.offers.edit.products.update.fields.brandName.noData')
    }
  },
  watch: {
    text: {
      immediate: true,
      handler: 'fetch'
    }
  },
  methods: {
    ...mapMutations('snackbar', ['addToast']),

    reset() {
      this.isLoaded = false
      this.items = []
    },

    fetch: debounce(async function() {
      if (!this.text || this.text.length < 3) {
        this.isLoading = false
        return
      }
      if (this.selectedItem && this.selectedItem.name === this.text) {
        this.isLoading = false
        return
      }

      await cancellableFetchFromComponent({
        $vm: this,
        isLoadingKey: 'isLoading',
        errorInToast: true,

        axios: {
          url: '/trademark_compositions/search',
          params: {
            query: this.text
          }
        },

        reset: () => {
          this.reset()
        },
        handleResult: (result) => {
          this.items = result.brands
          this.isLoaded = true
        }
      })
    }, DEBOUNCE_DELAY)
  }
})
