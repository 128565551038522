<template>
  <div>
    <h2 class="header__line-1">
      {{ user.identifiers.last_name }}
      {{ user.identifiers.first_name }}
    </h2>
    <span class="header__line-1__subtitle">
      <span v-if="user.identifiers.email">
        {{ user.identifiers.email }}
      </span>
      <span>
        -
        <a :href="oldBoUrl" target="_blank" class="white--text">
          #{{ user.id
          }}<!--
        --></a>
        -
      </span>
      <span class="header__line-2__subtitle">
        <v-icon size="14">mdi-calendar-account</v-icon>
        {{ seniority }}
      </span>
    </span>
  </div>
</template>

<script>
import originBo from '~/utils/constants/origin-bo'
import hasOwnProp from '~/utils/functions/hasOwnProp'
export default {
  props: {
    user: {
      type: Object,
      required: true,
      validator: (o) =>
        hasOwnProp(o, 'id') && hasOwnProp(o, 'identifiers') && true
    },
    seniority: {
      type: String,
      required: true
    }
  },
  computed: {
    oldBoUrl() {
      return `${originBo}/admin/users/${this.user.id}`
    }
  }
}
</script>

<style lang="scss" scoped>
.header__line-1 {
  font-size: 20px;
}
.header__line-2 {
  font-size: 12px;
}
.header__line-1__subtitle {
  font-size: 12px;
}
</style>
