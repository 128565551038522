import { Store, ActionContext } from 'vuex'

import { State } from './state'
import { RootState } from '~/store/state'

import cancelPreviousRequestAndGetNewCancelToken from '@/utils/functions/cancelPreviousRequestAndGetNewCancelToken/forStore'

import {
  handleAxiosErrorServerFromStoreV3,
  handleAxiosErrorFrontendFromStoreV3
} from '@/utils/functions/handle-errors'

export async function fetchProductsForEans(
  this: Store<RootState>,
  { state, commit }: ActionContext<State, RootState>,
  eans: string[]
) {
  commit('addEansBeingFetched', eans)

  commit('setLoadingState', true)
  commit('resetError')

  const cancelToken = cancelPreviousRequestAndGetNewCancelToken({
    state,
    commit
  })

  let response
  try {
    response = await this.$axios.get('/products/from_eans', {
      params: { eans: state.eansBeingFetched },
      cancelToken
    })
  } catch (error) {
    if (this.$axios.isCancel(error)) return

    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setLoadingState',
      mutationNameForSettingError: 'setError'
    })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStoreV3({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setLoadingState',
      mutationNameForSettingError: 'setError'
    })
    return
  }

  commit('resetEansBeingFetched')
  commit('addProductsByEan', response.data.result.products_by_ean)
  commit('setLoadingState', false)
  commit('setLoadedState', true)
}
