<template>
  <SlidePage4 />
</template>

<script>
import { mapMutations } from 'vuex'
import SlidePage4 from './SlidePage4.vue'
export default {
  components: { SlidePage4 },
  props: {
    reward: {
      type: Object,
      required: true
    }
  },
  created() {
    this.initializeWithReward(this.reward)
  },
  methods: {
    ...mapMutations('program/catalog', ['initializeWithReward'])
  }
}
</script>
