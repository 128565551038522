import Vue from 'vue'

import { PROGRAM_KIND_PUZZLE } from '../state'

import { createInitialForm, createEmptyBoard, createEmptyPiece } from './state'

function updatePuzzlePieces(state, { value }) {
  const puzzleTotalPiecesCount = parseInt(value || 0)
  state.form.puzzleBoards.forEach((board) => {
    const boardPiecesCount = board.pieces.length
    if (boardPiecesCount === puzzleTotalPiecesCount) return

    if (boardPiecesCount > puzzleTotalPiecesCount) {
      board.pieces.splice(puzzleTotalPiecesCount)
    } else {
      const missingPiecesCount = puzzleTotalPiecesCount - boardPiecesCount
      board.pieces.push(
        ...Array(missingPiecesCount)
          .fill() // `map` won't do anything without this
          .map((_) => createEmptyPiece())
      )
    }
  })
}

export * from '@/utils/store/concerns/loadable/mutations.js'

export const initializeForm = (state) => {
  state.form = createInitialForm()
}
export const resetForm = initializeForm

export const initializeFormWithPuzzleRecord = (state, config) => {
  state.form = {
    kind: PROGRAM_KIND_PUZZLE,
    images: {},

    puzzleTotalPiecesCount: config.pieces_count,
    isRenewable: config.is_renewable,
    puzzleBoards: config.boards.map((board) => ({
      rank: board.rank,
      width: board.width,
      height: board.height,
      margin: board.margin,
      emptyImageUrl: board.empty_image_url,
      emptyImageFile: null,
      completedImageUrl: board.completed_image_url,
      completedImageFile: null,
      pieces: board.pieces.map((piece) => ({
        x: piece.x,
        y: piece.y,
        width: piece.width,
        height: piece.height,
        ownedImageUrl: piece.owned_image_url,
        ownedImageFile: null
      }))
    }))
  }
}

export const setCreatingState = (state, boolean) => {
  state.isCreating = boolean
}

export const updateFormAttribute = (state, { key, value }) => {
  Vue.set(state.form, key, value)

  if (key === 'puzzleTotalPiecesCount') {
    updatePuzzlePieces(state, { value })
  }
}

export const updateImage = (state, { key, value }) => {
  Vue.set(state.form.images, key, value)
}

export function updatePuzzleBoardAttribute(state, { index, key, value }) {
  Vue.set(state.form.puzzleBoards[index], key, value)
}

export function addPuzzleBoard(state) {
  state.form.puzzleBoards.push(
    createEmptyBoard(
      state.form.puzzleBoards.length + 1,
      state.form.puzzleTotalPiecesCount
    )
  )
}

export function removeLastPuzzleBoard(state) {
  state.form.puzzleBoards.splice(state.form.puzzleBoards.length - 1, 1)
}

export const updatePuzzleBoardPiece = (
  state,
  { boardIndex, pieceIndex, key, value }
) => {
  Vue.set(state.form.puzzleBoards[boardIndex].pieces[pieceIndex], key, value)
}

export const setParentsLoadingState = (state, boolean) => {
  state.isLoadingParents = boolean
}
export const setErrorOnParents = (state, text) => {
  state.errorsOnParents = [text]
}
export const resetErrorOnParents = (state) => {
  state.errorsOnParents = []
}
export const setParents = (state, parents) => {
  state.parents = parents
}

export const setPartnersLoadingState = (state, boolean) => {
  state.isLoadingPartners = boolean
}
export const setErrorOnPartners = (state, text) => {
  state.errorsOnPartners = [text]
}
export const resetErrorOnPartners = (state) => {
  state.errorsOnPartners = []
}
export const setPartners = (state, partners) => {
  state.partners = partners
}

export const setClientsLoadingState = (state, boolean) => {
  state.isLoadingClients = boolean
}
export const setErrorOnClients = (state, text) => {
  state.errorsOnClients = [text]
}
export const resetErrorOnClients = (state) => {
  state.errorsOnClients = []
}
export const setClients = (state, clients) => {
  state.clients = clients
}
