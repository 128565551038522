<template>
  <v-subheader v-text="item.title" />
</template>

<script>
import hasOwnProp from '@/utils/functions/hasOwnProp'
export default {
  props: {
    item: {
      type: Object,
      required: true,
      validator: (o) => hasOwnProp(o, 'is') && hasOwnProp(o, 'title')
    }
  }
}
</script>
