<template>
  <ListLayoutItem>
    <template #image="{ height, width }">
      <v-skeleton-loader type="image" :height="height" :width="width" />
    </template>
    <template #title>
      <v-skeleton-loader type="heading" class="mb-2" />
    </template>
    <template #subtitle>
      <v-skeleton-loader type="text" />
    </template>
    <template #subtitle-2>
      <v-skeleton-loader type="text" />
    </template>
    <template #id>
      <v-skeleton-loader type="text" width="50px" />
    </template>

    <template #intersect>
      <slot name="intersect" />
    </template>
  </ListLayoutItem>
</template>

<script>
import ListLayoutItem from './ListLayoutItem'
export default {
  components: { ListLayoutItem }
}
</script>
