import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('h2',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t('pages.users.modal.personalInfo.title'))))]),_vm._v(" "),_c('p',[_vm._v("\n    Purchease id: #"+_vm._s(_vm.user.id)+"\n    "),(_vm.user.facebook_id)?_c('span',[_vm._v(" / Facebook: "+_vm._s(_vm.user.facebook_id))]):_vm._e()]),_vm._v(" "),_c(VTextField,{attrs:{"label":_vm.$t('pages.users.modal.personalInfo.lastName')},model:{value:(_vm.models.lastname),callback:function ($$v) {_vm.$set(_vm.models, "lastname", $$v)},expression:"models.lastname"}}),_vm._v(" "),_c(VTextField,{attrs:{"label":_vm.$t('pages.users.modal.personalInfo.firstName')},model:{value:(_vm.models.firstname),callback:function ($$v) {_vm.$set(_vm.models, "firstname", $$v)},expression:"models.firstname"}}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"sendMoneyZendeskTicketId","mode":"eager","rules":"email","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"label":_vm.$t('pages.users.modal.personalInfo.email'),"error-messages":errors},model:{value:(_vm.models.email),callback:function ($$v) {_vm.$set(_vm.models, "email", $$v)},expression:"models.email"}})]}}],null,true)}),_vm._v(" "),_c('h4',[_vm._v("\n    "+_vm._s(_vm._f("capitalize")(_vm.$t('pages.users.modal.accountState.vip.title')))+"\n  ")]),_vm._v(" "),(_vm.isPurcheaseAdmin)?_c(VSwitch,{staticClass:"mt-0",attrs:{"inset":"","label":_vm.models.isVip
        ? _vm.$t('pages.users.modal.accountState.vip.yes')
        : _vm.$t('pages.users.modal.accountState.vip.no')},scopedSlots:_vm._u([{key:"prepend",fn:function(){return undefined},proxy:true}],null,true),model:{value:(_vm.models.isVip),callback:function ($$v) {_vm.$set(_vm.models, "isVip", $$v)},expression:"models.isVip"}}):_vm._e(),_vm._v(" "),_c(VTextarea,{attrs:{"clearable":"","clear-icon":"mdi-close-circle","outlined":"","label":_vm.$t('pages.users.modal.accountState.notes')},model:{value:(_vm.models.notes),callback:function ($$v) {_vm.$set(_vm.models, "notes", $$v)},expression:"models.notes"}}),_vm._v(" "),_c(VBtn,{attrs:{"color":"primary","dark":"","rounded":""},on:{"click":function($event){return handleSubmit(_vm.updateUserInformations)}}},[_vm._v("\n    "+_vm._s(_vm.$t('global.save'))+"\n  ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }