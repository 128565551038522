










































import Vue from 'vue'
import ListDiscountsTargeted from './ListDiscountsTargeted.vue'
import ListDiscountsPublic from './ListDiscountsPublic.vue'
export default Vue.extend({
  components: {
    ListDiscountsTargeted,
    ListDiscountsPublic
  },
  props: {
    publicDiscounts: {
      type: Array,
      required: true,
      default: () => []
    },
    targetedDiscounts: {
      type: Array,
      required: true,
      default: () => []
    },
    rewardId: {
      type: Number,
      required: true
    }
  },
  computed: {
    anyPublicDiscount(): boolean {
      return this.publicDiscounts.length > 0
    },
    anyTargetedDiscount(): boolean {
      return this.targetedDiscounts.length > 0
    }
  }
})
