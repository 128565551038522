import { omit } from 'lodash-es'

import createDefaultState from './state'

export * from '@/utils/store/concerns/loadable/mutations.js'
export * from '@/utils/store/concerns/cancellable/mutations.js'

export const reset = (state) => {
  Object.assign(state, omit(createDefaultState(), ['cancelToken']))
}

export const handleResult = (state, result) => {
  state.purchasesCount = result.avg_acts
  state.purchasesTotal = result.avg_spendings
  state.productsCount = result.avg_quantities
}
