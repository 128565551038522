import { createTester } from '~/utils/functions/adminHasRights'

export default (ctx) => {
  // authorizations for people not logged are done in "authenticated"
  if (!ctx.store.getters['auth/isLogged']) return

  const tester = createTester({
    isPurcheaseAdmin: ctx.store.getters['auth/isPurcheaseAdmin'],
    isScanobarAdmin: ctx.store.getters['auth/isScanobarAdmin'],
    permittedActions: ctx.store.getters['auth/permittedActions']
  })

  if (!tester.test(ctx.route.path)) {
    ctx.redirect('/error/403')
  }
}
