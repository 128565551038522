










































































































































































import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import { ValidationProvider } from 'vee-validate'

import {
  KINDS,
  KIND_COUPON,
  KIND_FIDZ,
  KIND_DONATION,
  KIND_CASHBACK,
  KIND_GOODY,
  DEFAULT_FIDZ_REWARD_NAME
} from '@/store/program/catalog/state.js'

export default Vue.extend({
  components: { ValidationProvider },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isMenuOpenAvailableAt: false,
    isMenuOpenExpiredAt: false,
    availableAtDate: '',
    expiredAtDate: ''
  }),
  computed: {
    ...mapState('program/catalog', { form: 'form' }),
    ...mapGetters('auth', {
      displayRequiredPointsForSelectedProgram:
        'displayRequiredPointsForSelectedProgram'
    }),
    ...mapGetters('program/catalog', {
      isUpdatingReward: 'isUpdatingReward',
      formattedAvailableAt: 'formattedAvailableAt',
      formattedExpiredAt: 'formattedExpiredAt'
    }),

    kinds() {
      return KINDS.map((kind) => ({
        text: this.$t(`pages.program.catalog.createModal.kinds.${kind}`),
        value: kind
      }))
    }
  },
  watch: {
    'form.requiredPoints': 'updateDefautFidzValue',
    'form.kind'() {
      switch (this.form.kind) {
        case KIND_FIDZ:
          this.changeName(DEFAULT_FIDZ_REWARD_NAME)
          this.updateDefautFidzValue()
          break
        case KIND_COUPON:
          if (this.form.name === DEFAULT_FIDZ_REWARD_NAME) {
            this.changeName('')
          }
          break
        case KIND_DONATION:
        case KIND_CASHBACK:
        case KIND_GOODY:
          if (this.form.name === DEFAULT_FIDZ_REWARD_NAME) {
            this.changeName('')
            this.updateDefautFidzValue()
          }
          break
      }
    },
    availableAtDate(newVal: string) {
      this.changeAvailableAt(newVal)
    },
    expiredAtDate(newVal: string) {
      this.changeExpiredAt(newVal)
    }
  },
  created() {
    if (this.form.availableAt) {
      this.availableAtDate = moment(this.form.availableAt).format('YYYY-MM-DD')
    }
    if (this.form.expiredAt) {
      this.expiredAtDate = moment(this.form.expiredAt).format('YYYY-MM-DD')
    }
  },
  methods: {
    ...mapMutations('program/catalog', [
      'changeKind',
      'changeName',
      'changePriority',
      'changeRequiredPoints',
      'changeExpiredAt',
      'changeAvailableAt',
      'updateDefautFidzValue'
    ])
  }
})
