import cancellableFetchFromStore from '@/utils/functions/analytics/cancellableFetch/fromStore'
import { TAB_GENERAL } from '@/components/layouts/metrics/canals/Bar.vue'

export const fetch = function(ctx) {
  return cancellableFetchFromStore({
    ctx,
    store: this,
    tab: TAB_GENERAL,
    proxy: {
      method: 'post',
      path: '/internal_api/sas_bo_front_end/v1/app_metrics/charts/overview',
      data: ctx.rootGetters['metrics/canals/paramsForBackend']
    }
  })
}

export const refresh = fetch
