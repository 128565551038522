import {
  createLoadableState,
  LoadableState
} from '~/utils/store/concerns/loadable/state'

/* eslint-disable camelcase */
export interface Precision {
  id: null | number
  title: string
  image_url: null | string
  image_file: null | Blob
  display_rank: number
  expired_at: string
  context_display: string
  is_displayed_on_confirmation: boolean
  is_obstrusive_on_selection: boolean
  overrides_default: boolean
}
/* eslint-enable camelcase */

export interface NotLoadableState {
  precisions: Precision[]
  original: null | Precision
  modified: null | Precision
}
export interface State extends NotLoadableState, LoadableState {}

export default () =>
  createLoadableState({
    precisions: [],

    original: null,
    modified: null
  })
