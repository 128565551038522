import createDefaultState from './state'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export const setLoadingState = (state, boolean) => {
  state.isLoading = boolean
}
export const setRefreshingState = (state, boolean) => {
  state.isRefreshing = boolean
}

export const setError = (state, text) => {
  state.error = text
}
export const setIsUserInputError = (state, bool) => {
  state.isUserInputError = bool
}

export const resetError = (state) => {
  state.error = null
  state.isUserInputError = null
}

export const setAdmin = (state, admin) => {
  state.admin = admin
}

export const setProgram = (state, program) => {
  state.selectedProgram = program
}
