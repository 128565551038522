import { CANCEL_TOKEN_FOR_REPEATED_CALL } from '@/utils/constants/cancel-tokens'
import cancelPreviousRequestAndGetNewCancelToken from '@/utils/functions/cancelPreviousRequestAndGetNewCancelToken/forStore'

import { handleErrorAxiosCoreStore } from '@/utils/functions/handle-errors'
import { UNKNOWN_ERROR_USER } from '@/utils/constants/errors'

export const search = async function({ state, commit }, context) {
  commit('setSearchingState', true)
  commit('setPrograms', [])
  commit('resetError')

  const cancelToken = cancelPreviousRequestAndGetNewCancelToken({
    state,
    commit
  })
  const params = {
    query: state.text,
    context
  }

  commit('setBlankSearchState')

  let response
  try {
    response = await this.$axios.get('/programs', {
      params,
      cancelToken
    })
  } catch (error) {
    if (error.message === CANCEL_TOKEN_FOR_REPEATED_CALL) return
    handleErrorAxiosCoreStore({
      error,
      commit,
      store: this,
      loadingMutationName: 'setSearchingState'
    })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setError', UNKNOWN_ERROR_USER)
    commit('setSearchingState', false)
    return
  }

  commit('setData', response.data.result)

  commit('setSearchingState', false)
  commit('setSearchedState', true)
}

export const blankSearch = async function({ state, commit, dispatch }) {
  if (state.isSearching) return

  commit('setText', '')
  await dispatch('search')
}

export const blankSearchIfNotBlankSearch = async function({
  state,
  commit,
  dispatch
}) {
  if (state.isSearching) return
  if (state.hasBeenSearched && state.isBlankSearch) return

  commit('setText', '')
  await dispatch('search')
}

export const refreshPrograms = async function({ state, commit, dispatch }) {
  if (state.isSearching) return

  commit('setSearchingState', true)
  commit('setPrograms', [])
  commit('resetError')

  await dispatch('auth/refreshAdmin', null, {
    root: true
  })
  await dispatch('blankSearch')

  commit('setSearchingState', false)
  commit('setSearchedState', true)
}
