import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VToolbar,{attrs:{"dark":"","color":"primary"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c(VTabs,{attrs:{"align-with-title":""},model:{value:(_vm.tabSelected),callback:function ($$v) {_vm.tabSelected=$$v},expression:"tabSelected"}},[_c(VTabsSlider,{attrs:{"color":"yellow"}}),_vm._v(" "),_vm._l((_vm.tabs),function(tab){return _c(VTab,{key:tab.key},[_vm._v("\n          "+_vm._s(tab.text)+"\n        ")])})],2)]},proxy:true}])},[_vm._v(" "),_c(VToolbarTitle,[_vm._v("\n      "+_vm._s(_vm.$t('pages.users.modal.title', {
          userId: _vm.user.id,
          userEmail: _vm.user.email
        }))+"\n    ")]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c(VToolbarItems,[_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_vm._v(" "),_c(VTabsItems,{model:{value:(_vm.tabSelected),callback:function ($$v) {_vm.tabSelected=$$v},expression:"tabSelected"}},[_c(VTabItem,{key:0},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('EditInformation',{on:{"close":function($event){return _vm.$emit('close')}}})],1)],1)],1),_vm._v(" "),_c(VTabItem,{key:1},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('ChangePassword',{on:{"close":function($event){return _vm.$emit('close')}}})],1)],1)],1),_vm._v(" "),_c(VTabItem,{key:2},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('BanForm',{on:{"close":function($event){return _vm.$emit('close')}}})],1)],1)],1),_vm._v(" "),_c(VTabItem,{key:3},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('DeleteAccount',{on:{"close":function($event){return _vm.$emit('close')}}})],1)],1)],1),_vm._v(" "),_c(VTabItem,{key:4},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('SendMoney',{on:{"close":function($event){return _vm.$emit('close')}}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }