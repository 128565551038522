import {
  createLoadableState,
  LoadableState
} from '~/utils/store/concerns/loadable/state'

export interface BackendConfig {}

export interface NotLoadableState {
  config: null | BackendConfig
}
export interface State extends NotLoadableState, LoadableState {}

export default () =>
  createLoadableState({
    config: null
  })
