<template>
  <LayoutDefault :title="capitalize($t('layout.metrics.canals.title'))">
    <template #app-bar__right>
      <WarningIfProgramHasNoPeriod
        v-if="!isManager && selectedProgram && !selectedProgram.has_parent"
      />

      <client-only>
        <LayoutMetricProgramPeriodSelect />
      </client-only>
    </template>

    <template #app-bar__extension>
      <LayoutMetricCanalBar />
    </template>

    <template #default>
      <slot v-if="$scopedSlots.default" />
      <nuxt v-else />
    </template>
  </LayoutDefault>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { capitalize } from 'lodash-es'
import LayoutDefault from '../../default'
import LayoutMetricCanalBar from '@/components/layouts/metrics/canals/Bar'
import LayoutMetricProgramPeriodSelect from '@/components/layouts/metrics/program/PeriodSelect'
import WarningIfProgramHasNoPeriod from '@/components/layouts/WarningIfProgramHasNoPeriod.vue'
export default {
  components: {
    LayoutDefault,
    LayoutMetricCanalBar,
    LayoutMetricProgramPeriodSelect,
    WarningIfProgramHasNoPeriod
  },
  computed: {
    ...mapState('auth', ['selectedProgram']),
    ...mapGetters('auth', ['isManager'])
  },
  methods: {
    capitalize
  }
}
</script>
