import { omit } from 'lodash-es'
import BigNumber from 'bignumber.js'

import createDefaultState from './state'

export * from '@/utils/store/concerns/loadable/mutations.js'
export * from '@/utils/store/concerns/cancellable/mutations.js'

export const reset = (state) => {
  Object.assign(state, omit(createDefaultState(), ['cancelToken']))
}

export const handleResult = (state, result) => {
  state.categories = result.categories
  state.series = result.series
  state.total = result.total
}

export const addToTotal = (state, increment) => {
  const total = new BigNumber(state.total)
  state.total = total.plus(increment).toFixed(2)
}
