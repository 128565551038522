import { filter, groupBy, some } from 'lodash-es'

import originBo from '~/utils/constants/origin-bo'
import { HOME_USERS } from '~/utils/constants/routes'

export const oldBoUrl = (state) =>
  state.user && `${originBo}/admin/users/${state.user.id}`

export const routeBasePath = (_, __, rootState) => {
  const userId = rootState.route.params.userId
  return userId && `${HOME_USERS}/${userId}`
}

export const availableRewards = (state) =>
  state.loyaltyRewards ? filter(state.loyaltyRewards, 'is_available') : []

export const availableRewardsForProgram = (_, getters) => (programIdentifier) =>
  getters.availableRewards
    ? filter(
        getters.availableRewards,
        (reward) => reward.program_identifier === programIdentifier
      )
    : []

export const availableRewardsForReceipt = (_, getters) =>
  getters.availableRewards
    ? filter(getters.availableRewards, 'is_attachable_on_receipt')
    : []

export const hasAvailableRewardsForReceipt = (_, getters) =>
  getters.availableRewardsForReceipt.length > 0
export const noAvailableRewardsForReceipt = (_, getters) =>
  getters.availableRewardsForReceipt.length === 0

export const availableRewardsForReceiptForProgram = (_, getters) => (
  programIdentifier
) =>
  getters.availableRewardsForReceipt
    ? filter(
        getters.availableRewardsForReceipt,
        (reward) => reward.program_identifier === programIdentifier
      )
    : []

export const availableRewardsForPurchase = (_, getters) =>
  getters.availableRewards
    ? filter(getters.availableRewards, 'is_attachable_on_purchase')
    : []

export const availableRewardsForPurchaseForProgram = (_, getters) => (
  programIdentifier
) =>
  getters.availableRewardsForPurchase
    ? filter(
        getters.availableRewardsForPurchase,
        (reward) => reward.program_identifier === programIdentifier
      )
    : []

export const availableGroupedOffers = (_, getters) =>
  getters.availableRewards
    ? filter(getters.availableRewards, (r) =>
        [r.kind, r.subkind].includes('grouped_offers')
      )
    : []

export const hasAvailableGroupedOffers = (_, getters) =>
  getters.availableGroupedOffers.length > 0

export const noAvailableGroupedOffers = (_, getters) =>
  getters.availableGroupedOffers.length === 0

export const availableProgramsWithOffers = (_, getters, __, rootGetters) => {
  const programIdentifiers = getters.availableRewards.map(
    (reward) => reward.program_identifier
  )
  return filter(rootGetters['user/declaration/availablePrograms'], (program) =>
    programIdentifiers.includes(program.identifier)
  )
}

export const currentBans = (state) =>
  state.user ? filter(state.user.bans, (ban) => ban.active) : []
export const currentBansCount = (_, getters) => getters.currentBans.length
export const hasCurrentBans = (_, getters) => getters.currentBansCount > 0

export const oldBans = (state) =>
  state.user ? filter(state.user.bans, (ban) => !ban.active) : []
export const oldBansCount = (_, getters) => getters.oldBans.length
export const hasOldBans = (_, getters) => getters.oldBansCount > 0
export const oldBansByEditorName = (_, getters) =>
  groupBy(getters.oldBans, 'editor_name')

const FIDMARQUES_ID = 9
export const hasFidmarques = (state) =>
  !!state.user && some(state.user.apps, (app) => app.id === FIDMARQUES_ID)
