import Vue from 'vue'
import { cloneDeep } from 'lodash-es'

import createDefaultState from './state'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export function initWithNewProductsCollection(state) {
  state.original = {
    name: null,
    segment: null,
    segmentNodes: []
  }
  state.modified = cloneDeep(state.original)
  state.isLoaded = true
}

export function initWithEditProductsCollection(state, item) {
  state.modified = cloneDeep(item)
  state.isLoaded = true
}

export function initShowProductsCollection(state, item) {
  state.modified = cloneDeep(item)
  state.isLoaded = true
}

export function updateProgramId(state, value) {
  state.programId = value
}

export function updateAttribute(state, { key, value }) {
  Vue.set(state.modified, key, value)
}
