<template>
  <v-list-item
    :class="{ 'green lighten-4': isValid, 'red lighten-4': !isValid }"
  >
    <v-list-item-avatar tile size="100px">
      <v-img
        :alt="$t('crud.offers.edit.products.imageAlt')"
        :src="product.image_url"
      />
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        <strong>{{ $t('crud.offers.edit.products.list.item.ean') }} :</strong>
        {{ ean }}
      </v-list-item-title>
      <v-list-item-subtitle>
        <strong v-if="!product.name" class="float-right font-italic red--text">
          {{ $t('crud.offers.edit.products.list.item.missingName') }}
        </strong>
        <strong>
          {{ $t('crud.offers.edit.products.list.item.name') | capitalize }} :
        </strong>
        {{ product.name }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <strong
          v-if="!product.brand_identifier"
          class="float-right font-italic red--text"
        >
          {{ $t('crud.offers.edit.products.list.item.missingBrand') }}
        </strong>
        <strong>
          {{ $t('crud.offers.edit.products.list.item.brand') | capitalize }} :
        </strong>
        {{ product.brand_name }}
      </v-list-item-subtitle>

      <ProductEditModal
        v-if="isEditing"
        v-model="isEditing"
        :ean="ean"
        :product="product"
        @product-saved="$emit('product-updated', $event)"
      />
    </v-list-item-content>

    <v-list-item-action v-if="!disabled && !readonly">
      <v-icon :disabled="isEditing" @click="isEditing = true">
        mdi-pencil
      </v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapMutations } from 'vuex'
import ProductEditModal from './ProductEditModal'
import hasOwnProp from '@/utils/functions/hasOwnProp'
export default {
  components: { ProductEditModal },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    ean: {
      type: String,
      required: true
    },
    product: {
      type: Object,
      required: true,
      validator: (o) =>
        hasOwnProp(o, 'name') && hasOwnProp(o, 'brand_identifier')
    }
  },
  data: () => ({
    isEditing: false
  }),
  computed: {
    ...mapMutations('products-by-ean', ['setProduct']),

    isValid() {
      return this.product.name && this.product.brand_identifier
    }
  }
}
</script>
