import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasScope)?_c('ScopesSelect',{attrs:{"scopes":_vm.config.scopes,"current-scope-identifier":_vm.currentScopeIdentifier},on:{"change":function($event){_vm.currentScopeIdentifier = $event}}}):_vm._e(),_vm._v(" "),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.config.headers,"items":_vm.items,"loading":_vm.areItemsLoading,"options":_vm.options,"server-items-length":_vm.itemsTotalCount,"multi-sort":true,"selectable-key":'is_editable',"show-select":false,"no-data-text":_vm.noDataText},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_vm._l((_vm.actions),function(action){return [(action === 'show')?_c(VIcon,{key:(action + "-show"),staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('open-show-modal', item)}}},[_vm._v("\n            mdi-eye\n          ")]):(action === 'edit')?_c(VIcon,{directives:[{name:"show",rawName:"v-show",value:(_vm.hasEditRight),expression:"hasEditRight"}],key:(action + "-edit"),staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('open-edit-modal', item)}}},[_vm._v("\n            mdi-pencil\n          ")]):_vm._e(),_vm._v(" "),(action === 'duplicate')?_c(VIcon,{directives:[{name:"show",rawName:"v-show",value:(_vm.hasEditRight),expression:"hasEditRight"}],key:(action + "-duplicate"),staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('open-duplicate-modal', item)}}},[_vm._v("\n            mdi-content-copy\n          ")]):_c('div',{key:action},[_vm._t(("item.action." + action),null,{"item":item})],2)]})],2)]}},{key:"no-data",fn:function(){return [(_vm.errorOnFetchItems)?_c('div',{staticClass:"d-flex justify-center align-center"},[_vm._v("\n        "+_vm._s(_vm.errorOnFetchItems)+"\n        "),_c(VBtn,{staticClass:"ml-5",attrs:{"x-small":""},domProps:{"textContent":_vm._s(_vm.$t('global.retry'))},on:{"click":_vm.retry}})],1):(_vm.$scopedSlots['data-table.no-data'])?_vm._t("data-table.no-data"):_vm._e()]},proxy:true},_vm._l((_vm.scopedSlotsForDataTable),function(ref){
var nameComplete = ref[0];
var nameForVuetify = ref[1];
return {key:nameForVuetify,fn:function(slotData){return [_vm._t(nameComplete,null,null,slotData)]}}})],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }