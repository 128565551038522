export const payloadForBackend = (state, _getters, _rootState) => {
  const res = {
    name: state.modified.name,
    segment_nodes: state.modified.segmentNodes,
    program_id: state.programId
  }
  if (state.modified.id) {
    res.id = state.modified.id
    res.segment = state.modified.segment
    res.action = 'update'
  } else {
    res.action = 'new'
  }
  return res
}

export const modified = (state) => {
  return state.modified
}
