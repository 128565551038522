import { fromPairs, sumBy } from 'lodash-es'

import hasOwnProp from '@/utils/functions/hasOwnProp'

export const totalsByCategory = (state) =>
  fromPairs(
    state.categories.map((category, index) => [
      category,
      sumBy(state.series, (serie) => {
        const point = serie.data[index]
        if (hasOwnProp(point, 'y')) {
          return point.y
        } else {
          return point
        }
      })
    ])
  )
