import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showInactiveRewards ? true : _vm.reward.isActive),expression:"showInactiveRewards ? true : reward.isActive"}]},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VHover,{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,_vm._g(_vm._b({staticClass:"ma-3",attrs:{"elevation":hover ? 10 : 2,"width":"200","height":"200"}},'v-card',attrs,false),on),[_c(VToolbar,{attrs:{"dense":"","elevation":"0"}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.reward.name))]),_vm._v(" "),_c(VSpacer),_vm._v(" "),(_vm.inShelf || _vm.reward.isSaving || _vm.reward.wasSaved)?_c(VToolbarItems,{staticClass:"align-center"},[(_vm.reward.isSaving)?_c(VBtn,{attrs:{"icon":""}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"24"}})],1):(_vm.reward.wasSaved)?_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{attrs:{"color":"green"}},[_vm._v("mdi-check-circle-outline")])],1):_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.removeShelfFromReward(_vm.reward.id)}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1):_vm._e()],1),_vm._v(" "),_c(VCardText,[_c(VImg,{staticClass:"mx-auto",attrs:{"width":"110","height":"110","src":_vm.reward.imageUrl}})],1)],1)]}}],null,true)})]}}])},[_vm._v(" "),_c('div',[_vm._v("\n      "+_vm._s(_vm.$tc(
          ("pages.program.catalog.reward.value." + (_vm.selectedProgram.kind)),
          _vm.reward.points
        ))+"\n      "),_c('br'),_vm._v("\n      "+_vm._s(_vm.availabilityText)+"\n    ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }