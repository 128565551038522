<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <h2>{{ $t('pages.users.modal.personalInfo.title') | capitalize }}</h2>

    <p>
      Purchease id: #{{ user.id }}
      <span v-if="user.facebook_id"> / Facebook: {{ user.facebook_id }}</span>
    </p>

    <v-text-field
      v-model="models.lastname"
      :label="$t('pages.users.modal.personalInfo.lastName')"
    />
    <v-text-field
      v-model="models.firstname"
      :label="$t('pages.users.modal.personalInfo.firstName')"
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="sendMoneyZendeskTicketId"
      mode="eager"
      rules="email"
      slim
    >
      <v-text-field
        v-model="models.email"
        :label="$t('pages.users.modal.personalInfo.email')"
        :error-messages="errors"
      />
    </ValidationProvider>

    <h4>
      {{ $t('pages.users.modal.accountState.vip.title') | capitalize }}
    </h4>
    <v-switch
      v-if="isPurcheaseAdmin"
      v-model="models.isVip"
      class="mt-0"
      inset
      :label="
        models.isVip
          ? $t('pages.users.modal.accountState.vip.yes')
          : $t('pages.users.modal.accountState.vip.no')
      "
    >
      <template #prepend> </template>
    </v-switch>

    <v-textarea
      v-model="models.notes"
      clearable
      clear-icon="mdi-close-circle"
      outlined
      :label="$t('pages.users.modal.accountState.notes')"
    />

    <v-btn
      color="primary"
      dark
      rounded
      @click="handleSubmit(updateUserInformations)"
    >
      {{ $t('global.save') }}
    </v-btn>
  </ValidationObserver>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import MixinAjaxToCore from '~/mixins/ajaxToCore'
export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mixins: [MixinAjaxToCore],
  data: () => ({
    models: {
      email: '',
      firstname: '',
      lastname: '',
      notes: '',
      isVip: null
    },

    isUpdating: false
  }),
  computed: {
    ...mapGetters('auth', ['isPurcheaseAdmin']),
    ...mapState('user', ['user'])
  },
  watch: {
    user: {
      immediate: true,
      deep: true,
      handler() {
        this.models.email = this.user.identifiers.email
        this.models.firstname = this.user.identifiers.first_name
        this.models.lastname = this.user.identifiers.last_name
        this.models.notes = this.user.notes
        this.models.isVip = this.user.is_vip
      }
    }
  },
  methods: {
    ...mapMutations('snackbar', ['addToast']),
    ...mapActions('user', ['refreshUserFromRoute']),

    updateUserInformations() {
      return this.fetchCoreFromComponentAndHandleError({
        isLoadingKey: 'isUpdating',
        errorInToast: true,

        axios: {
          method: 'POST',
          url: `/users/${this.user.id}/update_personal_informations`,
          data: {
            email: this.models.email,
            firstname: this.models.firstname,
            lastname: this.models.lastname,
            notes: this.models.notes,
            notifying_level: this.models.isVip ? 2 : 1
          }
        },

        onSuccess: (_result) => {
          this.addToast({
            text: this.$t(
              'pages.users.modal.accountActions.updateUserInformations.success'
            )
          })
          this.$emit('close')
          this.refreshUserFromRoute()
        }
      })
    }
  }
}
</script>
