// imposed by core

export const KIND_FIZ = 'fiz'
export const KIND_FIDZ = 'fidz'
export const KIND_POINTS = 'points'
export const KIND_STAMPS = 'stamps'
export const KIND_REFUND = 'refund'
export const KIND_COUPONS = 'coupons'
export const KIND_COLLECTOR_PIECE = 'collector_piece'
export const KIND_COLLECTOR_JOKER = 'collector_joker'
export const KIND_PUZZLE_PIECES = 'puzzle_pieces'
export const KIND_CASHBACK = 'cashback'

export const SUBKIND_FOR_DECLARATION = 'for_declaration'
export const SUBKIND_FOR_SCANS = 'for_scans'

export const VALID_KINDS = [
  KIND_FIZ,
  KIND_FIDZ,
  KIND_POINTS,
  KIND_STAMPS,
  KIND_REFUND,
  KIND_COUPONS,
  KIND_COLLECTOR_PIECE,
  KIND_COLLECTOR_JOKER,
  KIND_PUZZLE_PIECES,
  KIND_CASHBACK
]

export const TYPES_WITH_ICONS = [
  // KIND_FIZ,
  // KIND_FIDZ,
  KIND_POINTS,
  KIND_REFUND,
  KIND_COUPONS,
  KIND_COLLECTOR_PIECE,
  KIND_COLLECTOR_JOKER,
  KIND_PUZZLE_PIECES
]
