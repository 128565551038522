import Vue from 'vue'
import { forEach, uniq } from 'lodash-es'

import createDefaultState, { State, ProductsByEan, Product } from './state'

export * from '@/utils/store/concerns/loadable/mutations.js'
export * from '@/utils/store/concerns/cancellable/mutations.js'

export const reset = (state: State) => {
  Object.assign(state, createDefaultState())
}

export const setProductsByEan = (
  state: State,
  productsByEan: ProductsByEan
) => {
  state.productsByEan = productsByEan
}

export const addProductsByEan = (
  state: State,
  productsByEan: ProductsByEan
) => {
  if (!state.productsByEan) {
    state.productsByEan = {}
  }

  forEach(productsByEan, (product, ean) => {
    Vue.set(state.productsByEan!, ean, product)
  })
}

export const setProduct = (
  state: State,
  { ean, product }: { ean: string; product: Product }
) => {
  if (!state.productsByEan) {
    state.productsByEan = {}
  }

  Vue.set(state.productsByEan, ean, product)
}

export const addEansBeingFetched = (state: State, eans: string[]) => {
  state.eansBeingFetched = uniq(state.eansBeingFetched.concat(eans))
}

export const resetEansBeingFetched = (state: State) => {
  state.eansBeingFetched = []
}
