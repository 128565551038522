import * as benefits from '../../../../utils/constants/benefits'
import squish from '../../../../utils/functions/squish'

import {
  PROGRAM_KIND_POINTS_VIA_PRICE,
  PROGRAM_KIND_POINTS_BY_PURCHASE,
  PROGRAM_KIND_STAMPS,
  PROGRAM_KIND_COLLECTOR,
  PROGRAM_KIND_PUZZLE,
  PROGRAM_KIND_CASHBACK
} from '~/store/program/state.js'

export const STATUS_PENDING = 'PENDING'
export const STATUS_PENDING_SYSTEM_REVIEW = 'PENDING_SYSTEM_REVIEW'
export const STATUS_PENDING_USER_RECEIPT_REVIEW = 'PENDING_USER_RECEIPT_REVIEW'
export const STATUS_ACCEPTED = 'ACCEPTED'
export const STATUS_REJECTED = 'REJECTED'

export const KIND_GROUPED_OFFER = 'GROUPED_OFFER'
export const KIND_SPECIAL_OFFER = 'SPECIAL_OFFER'
export const KIND_CATALOG = 'CATALOG'
export const KIND_COUPON = 'COUPON'

export const CLAIM_KIND_USER_SCAN = 'USER_SCAN'
export const CLAIM_KIND_TRUSTING_LOYALTY_CARD = 'TRUSTING_LOYALTY_CARD'
export const CLAIM_KIND_ASSISTANCE = 'ASSISTANCE'

export const MODALITY_SHARE = 'SHARE'
export const MODALITY_CAPTURE = 'CAPTURE'

export const CHANNEL_ALFRED = 'ALFRED'
export const CHANNEL_APP_ANDROID = 'APP_ANDROID'
export const CHANNEL_APP_IOS = 'APP_IOS'
export const CHANNEL_CHAT_BOT = 'CHAT_BOT'
export const CHANNEL_EXTERNAL = 'EXTERNAL'
export const CHANNEL_EMAIL = 'EMAIL'
export const CHANNEL_HELPDESK_ANDROID = 'HELPDESK_ANDROID'
export const CHANNEL_MANDALAMA = 'MANDALAMA'
export const CHANNEL_WEB = 'WEB'
export const CHANNELS = {
  CHANNEL_ALFRED,
  CHANNEL_APP_ANDROID,
  CHANNEL_APP_IOS,
  CHANNEL_CHAT_BOT,
  CHANNEL_EXTERNAL,
  CHANNEL_EMAIL,
  CHANNEL_HELPDESK_ANDROID,
  CHANNEL_MANDALAMA,
  CHANNEL_WEB
}

export default {
  subpictureImageTitle: 'photo n°{rank}',
  subpictureImageAlt: 'photo n°{rank} (#{name})',

  noAvailableProgram: 'aucun programme disponible',
  noAvailableOffer: 'aucune offre disponible',

  validateWithJoker: 'joker',

  header: {
    at: 'le',
    anomalyReportedOnRetailer: "erreur sur l'enseigne détectée signalée",
    reportAnomalyOnRetailer: "signaler une erreur sur l'enseigne détectée",
    missingRejectionReason: 'raison de rejet manquante',
    loyaltyAppliedOnPrograms:
      '| Les bénéfices du programme suivant | Les bénéfices des programmes suivant',
    loyaltyAppliedOnDupe:
      '| ont été appliqués sur un doublon | ont été appliqués sur des doublons',

    hasSharedPointsPools: 'partage en terrasse activé',
    declarationModality: {
      [MODALITY_SHARE]: 'via partage de fichier',
      [MODALITY_CAPTURE]: 'via shooter'
    },
    channel: {
      acquisitionChannel: "canal d'acquisition",

      [CHANNEL_ALFRED]: 'alfred',
      [CHANNEL_APP_ANDROID]: 'app android',
      [CHANNEL_APP_IOS]: 'app iOS',
      [CHANNEL_CHAT_BOT]: 'chat bot',
      [CHANNEL_EXTERNAL]: 'externe',
      [CHANNEL_EMAIL]: 'email',
      [CHANNEL_HELPDESK_ANDROID]: 'app assistance externe',
      [CHANNEL_MANDALAMA]: 'mandalama',
      [CHANNEL_WEB]: 'web'
    },
    total: 'total',
    totalNotFound: 'non identifié'
  },

  alerts: {
    isArchived: 'Cette déclaration est archivée.',
    imagesAreNotAvailable: 'Ses images ne sont plus disponibles.',
    unarchivationRequestedAt: 'Son désarchivage à été demandé %{at}.',
    ifNeedYouCanUnarchive: 'Si besoin, vous pouvez la désarchiver :',
    unarchive: 'désarchiver',
    keepInMindItCanTakeUpTOADayAndItWillBeArchivedAgainInAWeek:
      "attention, cela peut prendre jusqu'à un jour ; la déclaration sera de nouveau archivée après une semaine",
    declarationWillBeUnarchived: 'la déclaration va être désarchivée'
  },

  purchases: {
    noneExtracted: 'Aucun achat extrait sur le ticket',
    tooltips: {
      noAvailableOffer: 'aucune offre disponible',
      replaceWithOffer: 'remplacer par une offre',
      replaceWithPoints: 'remplacer par des points'
    }
  },

  refunds: {
    title: {
      generic:
        'Aucun remboursement | Un remboursement | {count} remboursements',

      withoutArticle: {
        [KIND_CATALOG]: 'offre catalogue %{adjective}',
        [KIND_SPECIAL_OFFER]: 'offre spéciale %{adjective}',
        [KIND_GROUPED_OFFER]: 'offre groupée %{adjective}',
        [KIND_COUPON]: 'remboursement %{adjective}'
      }
    },

    status: {
      [STATUS_PENDING_SYSTEM_REVIEW]: {
        m: 'en attente de validation par notre système',
        f: 'en attente de validation par notre système'
      },
      [STATUS_PENDING_USER_RECEIPT_REVIEW]: {
        m: "en attente de validation de la revue de ticket par l'utilisateur",
        f: "en attente de validation de la revue de ticket par l'utilisateur"
      },
      [STATUS_ACCEPTED]: {
        m: 'accepté | accepté | acceptés',
        f: 'acceptée | acceptée | acceptées'
      },
      [STATUS_REJECTED]: {
        m: 'rejeté | rejeté | rejetés',
        f: 'rejetée | rejetée | rejetées'
      }
    },

    creditedAmount: 'montant remboursé',
    acceptedAmount: 'montant remboursé (après validation du ticket)',

    onLine: 'sur la ligne',
    onLines: 'sur les lignes',

    reviewPendingRefund: 'traiter la demande'
  },

  zendesk: {
    title:
      'Aucune réclamation (Zendesk) | Une réclamation (Zendesk) | {count} réclamations (Zendesk)',
    status: {
      new: 'en cours',
      open: 'ouverte | ouverte | ouvertes',
      pending: 'en attente',
      hold: 'en pause',
      solved: 'résolue | résolue | résolues',
      closed: 'close | close | closes',

      null: 'avec statut inconnu'
    }
  },

  purchase: {
    userClaim: {
      tooltip: {
        kind: {
          [CLAIM_KIND_USER_SCAN]:
            "L'utilisateur a scanné un code barre (cliquez pour plus de détail)",
          [CLAIM_KIND_TRUSTING_LOYALTY_CARD]: squish(`
            On fait confiance à la carte {programName} de l'utilisateur
            après de nombreux scan sur ce même libellé
          `),
          [CLAIM_KIND_ASSISTANCE]:
            "L'utilisateur a rapporté une erreur sur la ligne"
        }
      },
      modal: {
        userScanned: "L'utilisateur a scanné",
        ean: 'EAN : {ean}',
        productPictureAsked: 'Une photo du produit a été demandée.',
        productPictureProvidedAt: 'Photo envoyée le {at}.',
        pictureNotReceivedSoFar: "Aucune photo n'a encore été reçue.",
        hasBeenValidated: 'Le scan a été validé.',
        hasBeenInvalidated: 'Le scan a été invalidé.',

        correspondingProduct: 'produit correspondant',
        noCorrespondingProduct: 'aucun produit ne correspond',
        productBrand: 'marque'
      }
    }
  },

  extraActions: {
    title: 'actions exceptionnelles',

    addBenefitsTitle: 'ajouter des bénéfices',
    messagesToUser: "messages à destination de l'utilisateur",
    misc: 'autre',

    addPoints: 'ajouter des points',
    addCashback: 'ajouter du cashback',
    addStamps: 'ajouter des tampons',
    addPuzzlePieces: 'ajouter des pièces de puzzle',
    benefitsAdded: 'bénéfices ajoutés',

    addBenefits: {
      noProgram: 'aucun programme de fidélité corrrespondant'
    },

    addPuzzleMission: {
      cta: 'ajouter une mission de puzzle',
      selectMission: 'sélectionnez la mission',
      purchasesLines: "lignes d'achats validant la mission",
      orphanPurchases: 'achats orphelins validant la mission',
      selectALine: 'sélectionnez une ligne',
      missionAdded: 'mission ajoutée'
    },

    addGroupedOffer: {
      cta: 'ajouter une offre groupée',
      noAvailableOffer: 'aucune offre disponible',
      selectOffer: "choisir l'offre",
      eligibleProductsList: 'liste des produits éligibles',
      missingLine: 'ligne manquante',
      addProduct: 'ajouter un produit',
      quantity: 'quantité',
      unitPrice: 'prix unitaire',
      groupedOfferAdded: 'offre groupée ajoutée',

      eligibleProductsTotal: 'nombre total de produits éligibles'
    },

    attachFidz: {
      cta: 'attacher des Fidz',
      noExpiredPrograms: 'aucun programme expiré pouvant donner des Fidz'
    },
    fidzAttached: 'fidz attachés',

    updateTotal: {
      cta: 'mettre à jour le total',
      label: 'total',
      successMessage: 'total mis à jour'
    },
    updatePaidAt: {
      cta: 'mettre à jour la date',
      labels: {
        date: 'date',
        time: 'heure',
        timeIsMissing: "l'heure n'est pas visible sur le ticket"
      },
      successMessage: 'date mise à jour'
    },

    addOffer: {
      cta: 'ajouter une offre',
      noAvailableOffer: 'aucune offre disponible',
      previousCards: 'anciennes cartes'
    },
    offerAdded: 'offre ajoutée',

    addGlassCode: 'ajouter un code',
    glassCodeAdded: 'code ajouté',

    addPurchase: "ajouter une ligne d'achat",
    purchaseAdded: "ligne d'achat ajoutée",

    addCollectorItem: 'ajouter une pièce de collector (ancienne version)',
    collectorItemAdded: 'pièce ajoutée',

    notifyUserAboutHandwriting: "arrêtez d'écrire sur vos tickets !",
    userNotified: 'utilisateur notifié',

    forceIneligible: 'rejeter cette déclaration',
    areYouSureToRejectThisDeclaration:
      'Êtes-vous sûr de vouloir rejeter cette déclaration ?',
    ineligibilityForced: 'déclaration rejetée',

    reEvaluateAsEligible: 'rendre éligible',
    declarationIsNotRejected: "cette déclaration n'est pas rejetée",
    warnOnEligibilityBypass:
      "Vous allez rendre ce ticket éligible, ce qui désactivera tous les tests d'éligibilités. Êtes vous sûr ?",
    eligibilityForcedAndDeclarationWillBeProcessed:
      'La déclaration va être marquée comme éligible et traitée de nouveau',
    selectRetailer: "sélectionner l'enseigne",

    labels: {
      denomination: 'libellé',
      quantity: 'quantité',
      unitPrice: 'prix unitaire',
      discount: 'remise',
      code: 'code',
      brand: 'marque',
      purchaseLine: "ligne d'achat",

      fidzToCredit: 'Nombre de Fidz à donner (nombre de points x 10)'
    }
  },

  editQuantityPriceDiscount: {
    edited: 'denom/quantité/prix/remise édités',
    labels: {
      denomination: 'libellé',
      quantity: 'quantité',
      unitPrice: 'prix unitaire',
      discount: 'remise'
    }
  },

  // TODO: move all the things below in "purchases.benefits" instead of "benefits"
  benefits: {
    kinds: {
      [benefits.KIND_FIZ]: 'aucun Fiz | {count} Fiz | {count} Fiz',
      [benefits.KIND_FIDZ]: 'aucun Fidz | {count} Fidz | {count} Fidz',
      [benefits.KIND_POINTS]: '0 pt | {count} pt | {count} pts',
      [benefits.KIND_STAMPS]: '0 tampon | {count} tampon | {count} tampons',
      [benefits.KIND_PUZZLE_PIECES]:
        'aucune pièce | {count} pièce | {count} pièces',
      [benefits.KIND_REFUND]: 'remboursé | remboursé | remboursés',
      [benefits.KIND_COUPONS]: 'aucun bon | {count} bon | {count} bons',
      [benefits.KIND_COLLECTOR_PIECE]:
        'aucune pièce | {count} pièce | {count} pièces',
      [benefits.KIND_COLLECTOR_JOKER]:
        'aucun joker | {count}% de joker | {count}% de joker',
      [benefits.KIND_CASHBACK]: '0 € | {count} € | {count} €'
    },

    linkedToLine: 'lié à la ligne',
    linkedToLines: 'lié aux lignes',

    refund: 'remboursement',
    pendingValidation: 'en attente de validation',

    refundedInGroup: 'remboursement groupé',
    refundedQuantity: 'quantité remboursée',
    total: 'total',

    capping: {
      withoutCapping: 'sans capping'
    },

    selectProgram: 'Sélectionnez le programme lié :',
    programImageAlt: 'Logo du programme {name}',
    linkDenomToProgram: 'associer ce libellé au programme',
    chooseCollectorItem: 'Sélectionnez la pièce :',

    chooseReward: 'Sélectionnez la récompense à ajouter :',

    programKind: {
      [PROGRAM_KIND_POINTS_VIA_PRICE]: 'Programmes à points',
      [PROGRAM_KIND_POINTS_BY_PURCHASE]: 'Programmes à points (1 pt par achat)',
      [PROGRAM_KIND_STAMPS]: 'Programmes à tampons',
      [PROGRAM_KIND_PUZZLE]: 'Programmes à puzzle',
      [PROGRAM_KIND_COLLECTOR]: 'Collectors (ancienne version)',
      [PROGRAM_KIND_CASHBACK]: 'Programmes à cashback',

      pointsExpired: "Programmes expirés à points (et leurs bons d'achat)"
    }
  },

  refund: {
    catalogRewards: 'cadeaux catalogue',
    specialOffers: 'offres spéciales',
    groupedOffers: 'offres groupées',
    earnedCoupons: "bons d'achats",
    collectorRewards: 'cadeaux collector',

    unknown: 'autres'
  }
}
