import localeca920940 from '../../i18n/fr-FR.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"fr"},
  vueI18nLoader: false,
  locales: [{"code":"en","file":"en-US.js"},{"code":"fr","file":"fr-FR.js"}],
  defaultLocale: "fr",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/home/deploy/sas-bo-frontend/i18n",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  locale: "fr",
  normalizedLocales: [{"code":"en","file":"en-US.js"},{"code":"fr","file":"fr-FR.js"}],
  localeCodes: ["en","fr"],
}

export const localeMessages = {
  'fr-FR.js': () => Promise.resolve(localeca920940),
  'en-US.js': () => import('../../i18n/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
}
