import createDefaultState from './state'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export * from '@/utils/store/concerns/loadable/mutations'

export const setData = (state, data) => {
  if (!data) return

  state.earnedCoupons = data.earned_coupons
}
