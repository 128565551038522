






















































































































































































































































import mixins from 'vue-typed-mixins'
import { mapState, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'

import ShowReward from './create-modal/Show.vue'
import ListDiscounts from './ListDiscounts.vue'
import ListExclusivePartners from './ListExclusivePartners.vue'

import CreateNewRewardForm from './create-modal/CreateNewRewardForm.vue'
import EditDiscount from './EditDiscount.vue'

import MixinAjaxToCore from '~/mixins/ajaxToCore'

const MODAL_REWARD_SHOW = 'reward-show'
const MODAL_DISCOUNTS_LIST = 'discounts-list'
const MODAL_LIST_PARTNER_EXCLUSIVITIES = 'list-partner-exclusivities'

const MODAL_REWARD_EDIT = 'reward-edit'
const MODAL_REWARD_DUPLICATE = 'reward-duplicate'
const MODAL_DISCOUNT_CREATE = 'discount-create'

export default mixins(MixinAjaxToCore).extend({
  components: {
    ShowReward,
    ListDiscounts,
    ListExclusivePartners,

    CreateNewRewardForm,
    EditDiscount
  },
  props: {
    reward: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    MODAL_REWARD_SHOW,
    MODAL_DISCOUNTS_LIST,
    MODAL_LIST_PARTNER_EXCLUSIVITIES,

    MODAL_REWARD_EDIT,
    MODAL_REWARD_DUPLICATE,
    MODAL_DISCOUNT_CREATE,

    openModalName: '',

    isTogglingReward: false
  }),
  computed: {
    ...mapState('auth', { selectedProgram: 'selectedProgram' }),
    ...mapGetters('auth', { hasWriteContentRight: 'hasWriteContentRight' }),

    isModalOpen: {
      get(): boolean {
        return !!this.openModalName
      },
      set(val) {
        if (!val) {
          this.openModalName = ''
        }
      }
    },
    modalTitle(): string {
      switch (this.openModalName) {
        case MODAL_REWARD_SHOW:
          return this.$t(
            'pages.program.catalog.reward.modal.title.showDetails'
          ) as string
        case MODAL_DISCOUNTS_LIST:
          return this.$t(
            'pages.program.catalog.reward.modal.title.listDiscounts'
          ) as string
        case MODAL_LIST_PARTNER_EXCLUSIVITIES:
          return this.$t(
            'pages.program.catalog.reward.modal.title.listExclusivePartners'
          ) as string

        case MODAL_REWARD_EDIT:
          return this.$t(
            'pages.program.catalog.reward.modal.title.editReward'
          ) as string
        case MODAL_DISCOUNT_CREATE:
          return this.$t(
            'pages.program.catalog.reward.modal.title.addDiscount'
          ) as string
        default:
          return ''
      }
    },
    textPoints(): string {
      return this.$tc(
        `pages.program.catalog.reward.value.${this.selectedProgram.kind}`,
        this.reward.loyalty_reward.value
      ) as string
    },
    textAvailableAt(): string {
      const {
        available_at: availableAt,
        expired_at: expiredAt
      } = this.reward.loyalty_reward
      if (expiredAt && expiredAt !== '' && new Date(expiredAt) < new Date())
        return this.$t('pages.program.catalog.reward.hasExpiredSince', {
          date: moment(expiredAt).format('DD/MM/YYYY'),
          time: moment(expiredAt).format('HH[h]mm')
        }) as string
      return this.$t('pages.program.catalog.reward.availableSince', {
        date: moment(availableAt).format('DD/MM/YYYY'),
        time: moment(availableAt).format('HH[h]mm')
      }) as string
    }
  },
  methods: {
    ...mapMutations('program/catalog', ['upsertDiscount']),

    closeModal(): void {
      this.openModalName = ''
    },

    ...mapMutations('snackbar', ['addToast']),
    ...mapMutations('program/catalog', ['updateReward']),

    toggleRewardAfterConfirmation() {
      if (
        !confirm(
          this.$t(
            `pages.program.catalog.reward.confirm.${
              this.reward.is_available ? 'expiration' : 'activation'
            }`
          ) as string
        )
      )
        return

      return this.fetchCoreFromComponentAndHandleError({
        isLoadingKey: 'isTogglingReward',
        errorInToast: true,

        axios: {
          method: 'POST',
          url: `/loyalty_rewards/${this.reward.loyalty_reward.identifier}/${
            this.reward.is_available ? 'expire' : 'activate'
          }`
        },

        onSuccess: (result: any) => {
          this.updateReward(result.loyalty_reward)
          this.addToast({
            text: this.$t(
              `pages.program.catalog.reward.successfully.${
                this.reward.is_available ? 'expired' : 'activated'
              }`
            ) as string
          })
        }
      })
    }
  }
})
