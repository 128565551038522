import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,[_c('ValidationProvider',{attrs:{"name":"discountValue","mode":"eager","rules":"required|integer|min_value:0","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"type":"number","min":"0","prepend-icon":"mdi-brightness-percent","label":_vm.$t('pages.program.catalog.discount.form.points') + ' *',"error-messages":errors},model:{value:(_vm.modified.value),callback:function ($$v) {_vm.$set(_vm.modified, "value", $$v)},expression:"modified.value"}})]}}],null,true)})],1),_vm._v(" "),_c(VCol,[_c('ValidationProvider',{attrs:{"name":"discountQuota","mode":"eager","rules":"required|integer|min_value:0","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"type":"number","min":"0","prepend-icon":"mdi-restart","label":_vm.$t('pages.program.catalog.discount.form.quota') + ' *',"error-messages":errors},model:{value:(_vm.modified.quota),callback:function ($$v) {_vm.$set(_vm.modified, "quota", $$v)},expression:"modified.quota"}})]}}],null,true)})],1)],1),_vm._v(" "),(_vm.isNew)?_c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c(VRadioGroup,{staticClass:"mt-0",model:{value:(_vm.mode),callback:function ($$v) {_vm.mode=$$v},expression:"mode"}},[_c(VRadio,{attrs:{"on-icon":"mdi-account-group","off-icon":"mdi-account-group-outline","value":_vm.MODE_PUBLIC,"label":_vm.$t('pages.program.catalog.discount.form.mode.public')}}),_vm._v(" "),_c(VRadio,{attrs:{"on-icon":"mdi-account","off-icon":"mdi-account-outline","value":_vm.MODE_TARGETED,"label":_vm.$t('pages.program.catalog.discount.form.mode.targeted')}})],1)],1),_vm._v(" "),_c(VCol,{directives:[{name:"show",rawName:"v-show",value:(!_vm.modified.is_public),expression:"!modified.is_public"}],attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"discountUserId","mode":"eager","rules":_vm.modified.is_public ? null : 'required',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VAutocomplete,{attrs:{"items":_vm.users,"loading":_vm.isSearching,"search-input":_vm.text,"hide-no-data":!_vm.text || _vm.isSearching,"hide-selected":"","item-text":"user.email","item-value":"user.id","label":_vm.$t('pages.program.catalog.discount.form.searchUser') + ' *',"placeholder":_vm.$t('pages.program.catalog.discount.form.searchUserPlaceholder'),"no-data-text":_vm.$t('pages.program.catalog.discount.form.noSearchResults'),"prepend-icon":"mdi-account-search","return-object":"","error-messages":errors},on:{"update:searchInput":function($event){_vm.text=$event},"update:search-input":function($event){_vm.text=$event}},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),_c(VRow,[_c(VCol,[_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"discountStartedAt","mode":"eager","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm.formatedStartedAt,"label":_vm.$t('pages.program.catalog.discount.form.startedAt') + ' *',"dense":"","outlined":"","readonly":"","error-messages":errors,"prepend-icon":"mdi-calendar-start"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.isMenuOpenForStartedAt),callback:function ($$v) {_vm.isMenuOpenForStartedAt=$$v},expression:"isMenuOpenForStartedAt"}},[_vm._v(" "),_c(VDatePicker,{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.isMenuOpenForStartedAt = false}},model:{value:(_vm.modified.started_at),callback:function ($$v) {_vm.$set(_vm.modified, "started_at", $$v)},expression:"modified.started_at"}})],1)],1),_vm._v(" "),_c(VCol,[_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"value":_vm.formatedEndedAt,"label":_vm.$t('pages.program.catalog.discount.form.endedAt'),"dense":"","outlined":"","readonly":"","clearable":"","prepend-icon":"mdi-calendar-end"},on:{"click:clear":function($event){_vm.modified.ended_at = null}}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.isMenuOpenForEndedAt),callback:function ($$v) {_vm.isMenuOpenForEndedAt=$$v},expression:"isMenuOpenForEndedAt"}},[_vm._v(" "),_c(VDatePicker,{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.isMenuOpenForEndedAt = false}},model:{value:(_vm.modified.ended_at),callback:function ($$v) {_vm.$set(_vm.modified, "ended_at", $$v)},expression:"modified.ended_at"}})],1)],1)],1),_vm._v(" "),_c(VRow,[_c(VCol,[_c('em',[_vm._v("* "+_vm._s(_vm._f("capitalize")(_vm.$t('global.mandatoryFields'))))])])],1),_vm._v(" "),_c(VRow,[_c(VCol,[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.isDestroying,"loading":_vm.isSaving},on:{"click":function($event){return handleSubmit(_vm.save)}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v("\n          "+_vm._s(_vm.$t('global.update'))+"\n        ")],1),_vm._v(" "),(
            _vm.discount &&
              (_vm.discount.can_be_destroyed ||
                _vm.discount.can_not_be_destroyed_reason_i18n)
          )?_c(VTooltip,{attrs:{"disabled":_vm.discount.can_be_destroyed ||
              !_vm.discount.can_not_be_destroyed_reason_i18n,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var onTooltip = ref.on;
return [_c('div',_vm._g({staticClass:"d-inline-block"},onTooltip),[_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.discount.can_be_destroyed,"loading":_vm.isDestroying},on:{"click":_vm.destroy}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v("\n                "+_vm._s(_vm.$t('global.destroy'))+"\n              ")],1)],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.discount.can_not_be_destroyed_reason_i18n)+"\n          ")])]):_vm._e()],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }