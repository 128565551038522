export default {
  data: () => ({
    paletteFidmarques: {
      orange: '#FF9113',
      yellow: '#FFBC00',
      fuchsia: '#FF5A5C', // iOS name : WildWatermelon
      blue: '#3498DB',
      black: '#4A4A4A',
      red: '#FF114A',
      green: '#42C977',
      violet: '#9B59B6',
      pink: '#ED70A3',

      v2: {
        blue: '#3498DB',
        green: '#2ECC71',
        yellow: '#F1C40F'
      }
    }
  })
}
