export const setIsLoading = (state, boolean) => {
  state.isLoading = boolean
}
export const setIsLoaded = (state, boolean) => {
  state.isLoaded = boolean
}

export const setError = (state, text) => {
  state.error = text
}

export const setRenewalStrategy = (state, payload) => {
  state.renewalStrategy = payload.strategy
  state.renewalStrategyPredictions = payload.predictions
}

export const setNewComersSubscriptionRate = (state, event) => {
  state.renewalStrategyPredictions.find(
    (prediction) => prediction.id === event.item.id
  ).new_comers_subscription_rate = parseFloat(event.value)
}

export const setRenewalRate = (state, event) => {
  state.renewalStrategyPredictions.find(
    (prediction) => prediction.id === event.item.id
  ).renewal_rate = parseFloat(event.value)
}

export const setSpendingPredictionsSums = (state) => {
  const entries = []
  const totals = {
    month_key: 'Total',
    total: 0
  }

  for (
    let index = 0;
    index < state.renewalStrategyPredictions.length;
    index++
  ) {
    const entry = {
      month_key: state.renewalStrategyPredictions[index].month_key,
      cohort: state.renewalStrategyPredictions[index].cohort,
      total: Math.round(
        state.renewalStrategyPredictions[index].predictions.reduce(
          (sum, current) => sum + current
        )
      )
    }

    for (
      let x = 0;
      x < state.renewalStrategyPredictions[index].predictions.length;
      x++
    ) {
      entry[(x + 1).toString()] = Math.round(
        state.renewalStrategyPredictions[index].predictions[x]
      )
      if (index === 0) {
        totals[(x + 1).toString()] = Math.round(
          state.renewalStrategyPredictions[index].predictions[x]
        )
      } else {
        totals[(x + 1).toString()] += Math.round(
          state.renewalStrategyPredictions[index].predictions[x]
        )
      }
    }

    totals.total += entry.total
    entries.push(entry)
  }

  entries.push(totals)
  state.allSpendingsPredictions = entries
}
