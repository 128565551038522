import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onFromDialog = ref.on;
var attrsFromDialog = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({},'v-btn',attrsFromDialog,false),onFromDialog),[_c(VIcon,[_vm._v("mdi-plus")]),_vm._v("\n        "+_vm._s(_vm.$t('pages.program.catalog.shelf.createNewRow'))+"\n      ")],1)]}}]),model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_vm._v(" "),_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dark":""}},[_vm._v("\n        "+_vm._s(_vm.$t('pages.program.catalog.shelf.createNewRow'))+"\n      ")]),_vm._v(" "),_c(VCardText,{staticClass:"pt-3"},[_c(VTextField,{attrs:{"label":_vm.$t('pages.program.catalog.shelf.rowName')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_vm._v(" "),_c('h3',[_vm._v("\n          "+_vm._s(_vm.$t('pages.program.catalog.shelf.textColor'))+"\n        ")]),_vm._v(" "),_c(VSwitch,{attrs:{"color":"#000000","inset":"","label":_vm.colorIsNil
              ? _vm.$t('pages.program.catalog.shelf.colorIsNilOn')
              : _vm.$t('pages.program.catalog.shelf.colorIsNilOff')},model:{value:(_vm.colorIsNil),callback:function ($$v) {_vm.colorIsNil=$$v},expression:"colorIsNil"}}),_vm._v(" "),(!_vm.colorIsNil)?_c(VColorPicker,{attrs:{"disabled":_vm.colorIsNil,"dot-size":"11","hide-mode-switch":"","mode":"hexa","swatches-max-height":"100"},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}}):_vm._e(),_vm._v(" "),_c('h3',[_vm._v("\n          "+_vm._s(_vm.$t('pages.program.catalog.shelf.textColor'))+"\n        ")]),_vm._v(" "),_c('div',[_c(VSwitch,{attrs:{"color":"#000000","inset":"","label":_vm.isBlack
                ? _vm.$t('pages.program.catalog.shelf.textColorBlack')
                : _vm.$t('pages.program.catalog.shelf.textColorWhite')},model:{value:(_vm.isBlack),callback:function ($$v) {_vm.isBlack=$$v},expression:"isBlack"}})],1),_vm._v(" "),_c('h3',[_vm._v("\n          "+_vm._s(_vm.$t('pages.program.catalog.shelf.preview'))+"\n        ")]),_vm._v(" "),_c(VChip,{attrs:{"color":_vm.colorIsNil ? _vm.selectedProgram.dominant_color : _vm.color,"dark":!_vm.isBlack}},[_vm._v("\n          "+_vm._s(_vm.name || _vm.$t('pages.program.catalog.shelf.rowName'))+"\n        ")])],1),_vm._v(" "),_c(VCardActions,[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"loading":_vm.isCreatingShelf},on:{"click":_vm.beforeCreateShelfRow}},[_vm._v("\n          "+_vm._s(_vm.$t('pages.program.catalog.validate'))+"\n        ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }