import { createLoadableState } from '@/utils/store/concerns/loadable/state'

export const KIND_COUPON = 'coupon'
export const KIND_FIDZ = 'fidz'
export const KIND_DONATION = 'donation'
export const KIND_CASHBACK = 'cashback'
export const KIND_GOODY = 'goody'
export const KINDS = [
  KIND_COUPON,
  KIND_FIDZ,
  KIND_DONATION,
  KIND_CASHBACK,
  KIND_GOODY
]

export const DEFAULT_FIDZ_REWARD_NAME = 'Faire le plein de Fidz'

export default () =>
  createLoadableState({
    // create/edit reward
    reward: null, // initial record
    form: null, // modified record

    // from config_catalog
    transporters: [],
    loyaltyRewards: [],

    // catalog switch
    newLoyaltyRewardsModel: [],
    loyaltyRewardsToChange: [],
    newLoyaltyRewards: [],
    catalogPreview: null,

    // catalog shelves
    loyaltyRewardsRows: [],

    discountSelected: null,
    loyaltyRewardExclusivity: null
  })
