import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"declaration",class:{
    'declaration--selected': _vm.isSelected
  }},[(_vm.isEligible !== null)?_c('div',{staticClass:"declaration__eligibility",class:{
      green: _vm.isEligible === true,
      red: _vm.isEligible === false
    }}):_vm._e(),_vm._v(" "),_c(_vm.to ? 'nuxt-link' : 'div',{tag:"component",staticClass:"declaration__link",attrs:{"to":_vm.to}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"declaration__left"},[_c('div',{staticClass:"wrapper-img"},[_vm._t("image",null,null,{ size: 32, height: 32, width: 32 })],2),_vm._v(" "),(_vm.nextTimelineEvent)?_c(VTooltip,{attrs:{"top":"","content-class":"header__tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"declaration__timeline"},'div',attrs,false),on),[_c('DeclarationTimelineIcon',{attrs:{"event":_vm.nextTimelineEvent,"size":32,"inverse":""}})],1)]}}],null,false,2566171508)},[_vm._v(" "),_c('DeclarationTimeline',{attrs:{"events":_vm.timelineEvents}})],1):_vm._e()],1),_vm._v(" "),_c(VCol,[_vm._t("intersect"),_vm._v(" "),_c('h5',{staticClass:"declaration__retailer-name",class:{
              'declaration__retailer-name--selected': _vm.isSelected
            }},[_vm._t("title")],2),_vm._v(" "),(_vm.$scopedSlots.subtitle)?_c('div',{staticClass:"declaration__datetime",class:{ 'declaration__datetime--selected': _vm.isSelected }},[_vm._t("subtitle")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"declaration__captured-at"},[_vm._t("subtitle-2")],2),_vm._v(" "),_c('div',{staticClass:"declaration__id"},[_vm._t("id")],2)],2)],1)],1)],1),_vm._v(" "),(_vm.hasZendeskTickets)?_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"declaration__zendesk"},'v-icon',attrs,false),on),[_vm._v("\n        mdi-account-question\n      ")])]}}],null,false,2682910996)},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('pages.declarations.zendeskTicketsOnDeclaration')))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }