import cancellableFetchFromStore from '@/utils/functions/analytics/cancellableFetch/fromStore'
import { TAB_INDIVIDUAL_BEHAVIOUR } from '@/components/layouts/metrics/program/Bar.vue'

// import payload from '@/utils/mocked-data/metrics/program/purchases'

export const fetch = function(ctx) {
  return cancellableFetchFromStore({
    ctx,
    store: this,
    tab: TAB_INDIVIDUAL_BEHAVIOUR,
    proxy: {
      method: 'post',
      path:
        '/internal_api/sas_bo_front_end/v1/programs_metrics/yearly_sales/bars',
      data: ctx.rootGetters['metrics/program/paramsForBackend']
    }
  })
}

export const refresh = fetch
