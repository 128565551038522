export default {
  unknown: 'Une erreur inconnue est survenue. Nous en avons été informés.',
  purchases: {
    requestPoints: {
      canNotAddPoints: "impossible d'ajouter les points"
    },
    requestOffer: {
      cardBecomesNegativeWhenDestroyingLinePoints:
        "la carte de l'utilisateur devient négative en supprimant les points de cette ligne"
    }
  }
}
