export default (ctx) => {
  if (['/auth/login', '/auth/resetting_password'].includes(ctx.route.path)) {
    if (ctx.store.getters['auth/isLogged']) {
      return ctx.store.dispatch('auth/redirectToHome')
    }
    return
  }

  if (ctx.route.path.startsWith('/error')) return

  if (ctx.store.getters['auth/isLogged']) return

  if (ctx.route.fullPath === '/') {
    ctx.redirect('/auth/login')
  } else {
    ctx.redirect(`/auth/login?r=${encodeURIComponent(ctx.route.fullPath)}`)
  }
}
