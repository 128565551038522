import { HOME_PROGRAMS } from '@/utils/constants/routes'

export default (ctx) => {
  const program = ctx.store.state.auth.selectedProgram
  if (!program) return

  const programIdentifier = program.identifier
  if (!program.has_parent) return

  const path = `${HOME_PROGRAMS}/${programIdentifier}/assets`
  if (ctx.route.path === path) return

  ctx.redirect(path)
}
