import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{staticClass:"pt-0",attrs:{"dense":""}},[_c(VListItem,[_c(VListItemContent,[(_vm.selectedProgram.logo_image_url)?_c(VImg,{attrs:{"src":_vm.selectedProgram.logo_image_url,"height":"40","contain":""}}):_c('span',[_vm._v("\n        "+_vm._s(_vm.selectedProgram.name)+"\n      ")])],1),_vm._v(" "),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1)],1),_vm._v(" "),_c(VDivider),_vm._v(" "),_vm._l((_vm.displayedGroups),function(group,i){return _c(VListGroup,{key:i,attrs:{"prepend-icon":group.icon,"value":group.isOpen,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(group.title))])],1)]},proxy:true}],null,true)},[_vm._v(" "),_vm._l((group.children),function(item,j){return _c(VListItem,{key:j,attrs:{"nuxt":"","to":item.to,"two-line":!!item.subtitle}},[(item.icon)?_c(VListItemAvatar,[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1):_vm._e(),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.title)}}),_vm._v(" "),(item.subtitle)?_c(VListItemSubtitle,[_vm._v("\n          "+_vm._s(item.subtitle)+"\n        ")]):_vm._e()],1)],1)})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }