import mockedData from '@/utils/mocked-data/user/profile'
import { fetchFromApi } from '@/utils/store/concerns/loadable/actions'
import { fetchFromApiMocked } from '@/utils/store/concerns/mockable/actions'

export const fetch = function({ dispatch }, { userId }) {
  dispatch('retry', { userId })
  dispatch('user/profile/earnsAndBurns/fetch', { userId }, { root: true })
}

export const retry = function({ state, commit }, { userId }) {
  if (state.isMocked) {
    fetchFromApiMocked(commit, mockedData)
  } else {
    fetchFromApi(this, commit, `/users/${userId}/profile`)
  }
}
