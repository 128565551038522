import moment from 'moment'

import { PERIOD_CUSTOM_IDENTIFIER } from '~/components/layouts/metrics/program/PeriodSelect.vue'
import { PROGRAM_SCANOBAR_IDENTIFIED_FOR_ANALYTICS } from '~/utils/constants/program-ids'

export const selectedPartners = (state) =>
  state.partners.filter((partner) =>
    state.selectedPartnerIds.includes(partner.id)
  )

export const currentPeriodStartDate = (state) =>
  state.currentPeriod && state.currentPeriod.start_date

export const previousPeriodsForFrontend = (state) =>
  state.previousPeriods.map((period) => {
    const startDate = moment(period.start_date).format('YYYY-MM-DD')
    const endDate = moment(period.end_date).format('YYYY-MM-DD')
    return {
      identifier: `${startDate}--${endDate}`,
      startDate,
      endDate
    }
  })

export const selectedPeriodIdentifier = (state) =>
  state.selectedPeriod && state.selectedPeriod.identifier
export const isCustomPeriod = (_, getters) =>
  getters.selectedPeriodIdentifier === PERIOD_CUSTOM_IDENTIFIER

export const selectedPeriodStartDateFormatted = (state) =>
  state.selectedPeriod &&
  moment(state.selectedPeriod.startDate).format('YYYY-MM-DD')

export const selectedPeriodEndDateFormatted = (state) =>
  state.selectedPeriod &&
  moment(state.selectedPeriod.endDate).format('YYYY-MM-DD')

export const isScanobarProgram = (state) => {
  return (
    state.currentProgramIdentifier === PROGRAM_SCANOBAR_IDENTIFIED_FOR_ANALYTICS
  )
}

export const paramsForBackend = (state, getters) =>
  state.selectedPeriod && {
    loyalty_program_identifiers: [state.currentProgramIdentifier],
    start_date: getters.selectedPeriodStartDateFormatted,
    end_date: getters.selectedPeriodEndDateFormatted,
    partner_ids: state.selectedPartnerIds
  }

export const isCurrentMonthStartMonthOfPeriod = (_, getters) => {
  if (!getters.currentPeriodStartDate) return

  const thisMonth = moment().format('YYYY-MM')
  const currentPeriodStartMonth = moment(getters.currentPeriodStartDate).format(
    'YYYY-MM'
  )
  return thisMonth === currentPeriodStartMonth
}
