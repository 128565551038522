<template>
  <v-list-item>
    <v-list-item-avatar tile>
      <v-img :src="potRefund.image_url" />
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ potRefund.name }}
      </v-list-item-title>
      <v-list-item-subtitle
        :class="potRefund.is_credited ? 'success--text' : 'danger--text'"
      >
        {{ potRefund.formatted_status }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action class="text-h6">
      {{ potRefund.amount | money({ suffix: ' €' }) }}
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: {
    potRefund: {
      type: Object,
      required: true
    }
  }
}
</script>
