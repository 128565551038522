import createDefaultState from './state'

import { CANCEL_TOKEN_FOR_REPEATED_CALL } from '@/utils/constants/cancel-tokens'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export const setSearchingState = (state, boolean) => {
  state.isSearching = boolean
}
export const setSearchedState = (state, boolean) => {
  state.hasBeenSearched = boolean
}

export const setError = (state, text) => {
  state.error = text
}
export const resetError = (state) => {
  state.error = null
}

export const setText = (state, text) => {
  state.text = text
}

export const focus = (state) => {
  state.isFocused = true
}

export const cancelPreviousRequest = (state) => {
  state.cancelToken.cancel(CANCEL_TOKEN_FOR_REPEATED_CALL)
  state.cancelToken = null
}

export const setCancelToken = (state, source) => {
  state.cancelToken = source
}

export const setData = (state, data) => {
  state.programs = state.programs.concat(data.programs)
  state.needRefresh = data.need_refresh
  // state.hasMore = data.has_more
  // state.nextPaginationKey = data.next_pagination_key
  // state.matchStart = data.match_start
  // state.matchEnd = data.match_end
}
export const setPrograms = (state, programs) => {
  state.programs = programs
}

export const setBlankSearchState = (state) => {
  state.isBlankSearch = state.text === ''
}
