export default {
  title: 'Périodes',

  headers: {
    index: 'numéro',
    id: 'ID',
    startDate: 'date de début',
    endDate: 'date de fin',
    destroy: 'supprimer',
    generosity: 'générosité'
  },

  noPeriods: "aucune période n'existe",
  thisPeriod: 'cette période',
  glances: {
    dataNotSpecificToPeriod:
      'Les données ci-dessous ne sont pas spécifiques à la période sélectionnée mais sont tirées de toute la durée du programme',
    sales: {
      title: 'Ventes et générosité',
      saveButton: 'Sauvegarder',
      recalculateButton: 'Recalculer',
      depthLevel: 'Profondeur actuelle : ',
      depthSelectLabel: 'Profondeur de détail des groupes',
      signedGenerosity: 'Générosité signée',
      activeBoost: 'Boost Actifs',
      chartTitle: 'Ventes et générosité',
      detailTitle: 'Détail'
    },
    mixity: {
      title: 'Mixité',
      chartTitle: 'Mixité',
      detailTitle: 'Détail'
    },
    explicitMixity: {
      title: 'Mixité Explicite',
      warning:
        'La mixité explicite de cette collection ne peut pas être visualisée, je vous invite à télécharger les données sous forme de fichier excel.'
    },
    refunds: {
      title: 'Remboursements Catalogue',
      chartTitle: 'Aperçu',
      detailTitle: 'Détail'
    },
    retailerShare: {
      title: 'Biais par enseigne',
      chartTitle: 'Part enseigne',
      detailTitle: 'Détail'
    },
    purchasesByRegion: {
      title: 'Achats par région',
      regionSelectorLabel: 'Découpage',
      startDateLabel: 'Depuis*',
      endDateLabel: "Jusqu'à*",
      datePrecision: "* pendant l'ouverture du programme",
      nationalShareTitle: 'Part du programme dans les ventes nationales',
      allPurchasesLabel: 'Achats totaux',
      programPurchasesLabel: 'Achats programme',
      nationalShareLabel: 'Part marché nationale',
      refreshButton: 'Valider',
      exportButton: 'Export Excel',
      moreDetails: "plus d'informations"
    },
    userProfile: {
      title: 'Profil utilisateurs'
    },
    spendingsQuantiles: {
      title: 'Percentile des dépenses annuelles',
      detailTitle: 'Détail'
    },
    upliftReports: {
      title: "Rapports d'Uplift",
      detailTitle: "Rapports d'Uplift"
    },
    widget: {
      title: 'Applet(s)',
      warningNoAppletAvailable: "Ce programme n'a pas d'applet",
      warning:
        'La statistiques des applets de cette collection ne peut pas être visualisée sous forme de graphique, je vous invite à télécharger les données sous forme de fichier excel.'
    },
    inAppOperation: {
      title: 'Animation(s)',
      warningNoInAppOperationAvailable: "Ce programme n'a pas d'animation",
      warning:
        'Les statistiques des animaions de cette collection ne peut pas être visualisée sous forme de graphique, je vous invite à télécharger les données sous forme de fichier excel.'
    }
  }
}
