<template>
  <bbob-bbcode container="span" :plugins="plugins" :options="options">
    {{ text }}
  </bbob-bbcode>
</template>

<script>
import preset from '@bbob/preset-vue'
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data: () => ({
    plugins: [preset()],
    options: { enableEscapeTags: true }
  })
}
</script>
