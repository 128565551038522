<template>
  <div>
    <SelectAccount @input="editorId = $event" />

    <h4>
      {{
        $t(
          'pages.users.modal.accountActions.changePassword.howDoYouWantToProceed'
        )
      }}
    </h4>

    <div>
      <v-btn
        :loading="isSendingEmail"
        color="primary"
        class="white--text ma-1"
        @click="sendEmail"
      >
        {{
          $t(
            'pages.users.modal.accountActions.changePassword.sentEmailToUserToResetPassword'
          )
        }}
      </v-btn>
    </div>

    <div>
      <v-btn
        :loading="isRetrievingResetLink"
        color="primary"
        class="white--text ma-1"
        @click="retrievingResetLink"
      >
        {{
          $t(
            'pages.users.modal.accountActions.changePassword.getLinkToResetPassword'
          )
        }}
      </v-btn>

      <div v-if="resetLink" class="d-flex">
        <v-text-field ref="textToCopy" hide-details :value="resetLink">
          <template #prepend>
            <v-btn @click="copyText">
              <v-icon left>mdi-content-copy</v-icon>
              {{ $t('global.copy') }}
            </v-btn>
          </template>
        </v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import SelectAccount from './SelectAccount'
import MixinAjaxToCore from '~/mixins/ajaxToCore'
export default {
  components: { SelectAccount },
  mixins: [MixinAjaxToCore],
  data: () => ({
    editorId: null,

    isSendingEmail: false,
    isRetrievingResetLink: false,
    resetLink: null
  }),
  computed: {
    ...mapState('user', ['user'])
  },
  methods: {
    ...mapMutations('snackbar', ['addToast']),

    copyText() {
      this.$refs.textToCopy.$el.querySelector('input').select()
      document.execCommand('copy')
      this.addToast({
        text: this.$t(
          'pages.users.modal.accountActions.changePassword.linkCopiedToClipboard'
        )
      })
    },

    sendEmail() {
      return this.fetchCoreFromComponentAndHandleError({
        isLoadingKey: 'isSendingEmail',
        errorInToast: true,

        axios: {
          method: 'POST',
          url: `/users/${this.user.id}/send_email_reset_password`,
          data: { partner_id: this.editorId }
        },

        onSuccess: (_) => {
          this.addToast({
            text: this.$t(
              'pages.users.modal.accountActions.changePassword.emailSentWithSuccess'
            )
          })
          this.isSendingEmail = false
        }
      })
    },

    retrievingResetLink() {
      return this.fetchCoreFromComponentAndHandleError({
        isLoadingKey: 'isRetrievingResetLink',
        errorInToast: true,

        axios: {
          method: 'GET',
          url: `/users/${this.user.id}/${this.editorId}/reset_password`
        },

        onSuccess: (result) => {
          this.resetLink = result.reset_link
          this.isRetrievingResetLink = false
        }
      })
    }
  }
}
</script>
