<template>
  <div>
    <v-dialog v-model="showModal" width="400">
      <template #activator="{ on: onFromDialog, attrs: attrsFromDialog }">
        <v-btn v-bind="attrsFromDialog" v-on="onFromDialog">
          <v-icon>mdi-plus</v-icon>
          {{ $t('pages.program.catalog.shelf.createNewRow') }}
        </v-btn>
      </template>

      <v-card>
        <v-toolbar color="primary" dark>
          {{ $t('pages.program.catalog.shelf.createNewRow') }}
        </v-toolbar>

        <v-card-text class="pt-3">
          <v-text-field
            v-model="name"
            :label="$t('pages.program.catalog.shelf.rowName')"
          />

          <h3>
            {{ $t('pages.program.catalog.shelf.textColor') }}
          </h3>
          <v-switch
            v-model="colorIsNil"
            color="#000000"
            inset
            :label="
              colorIsNil
                ? $t('pages.program.catalog.shelf.colorIsNilOn')
                : $t('pages.program.catalog.shelf.colorIsNilOff')
            "
          />

          <v-color-picker
            v-if="!colorIsNil"
            v-model="color"
            :disabled="colorIsNil"
            dot-size="11"
            hide-mode-switch
            mode="hexa"
            swatches-max-height="100"
          />

          <h3>
            {{ $t('pages.program.catalog.shelf.textColor') }}
          </h3>
          <div>
            <v-switch
              v-model="isBlack"
              color="#000000"
              inset
              :label="
                isBlack
                  ? $t('pages.program.catalog.shelf.textColorBlack')
                  : $t('pages.program.catalog.shelf.textColorWhite')
              "
            />
          </div>

          <h3>
            {{ $t('pages.program.catalog.shelf.preview') }}
          </h3>
          <v-chip
            :color="colorIsNil ? selectedProgram.dominant_color : color"
            :dark="!isBlack"
          >
            {{ name || $t('pages.program.catalog.shelf.rowName') }}
          </v-chip>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn :loading="isCreatingShelf" @click="beforeCreateShelfRow">
            {{ $t('pages.program.catalog.validate') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations, mapActions } from 'vuex'
import MixinResetData from '@/mixins/resetData'
export default Vue.extend({
  mixins: [MixinResetData],
  data: () => ({
    name: '',
    isBlack: false,
    colorIsNil: true,
    color: '#000000'
  }),
  computed: {
    ...mapState('auth', { selectedProgram: 'selectedProgram' }),
    ...mapState('program/catalog/shelf', [
      'isCreatingShelf',
      'isModalOpenForAddingShelf'
    ]),

    showModal: {
      get() {
        return this.isModalOpenForAddingShelf
      },
      set(value) {
        this.setIsModalOpenForAddingShelf(value)
      }
    }
  },
  watch: {
    showModal(value) {
      if (value) return

      this.resetData()
    }
  },
  methods: {
    ...mapMutations('program/catalog/shelf', ['setIsModalOpenForAddingShelf']),
    ...mapActions('program/catalog/shelf', ['createShelfRow']),

    beforeCreateShelfRow() {
      this.createShelfRow({
        name: this.name,
        color: this.colorIsNil ? null : this.color,
        isBlack: this.isBlack,
        colorIsNil: this.colorIsNil
      })
    }
  }
})
</script>
