import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCardText,{staticClass:"pt-5"},[_c('div',{staticClass:"d-flex"},[_vm._l((_vm.partnerExclusivities),function(partner){return _c('PartnerCheckbox',{key:partner.identifier,staticClass:"mr-5 mb-3",attrs:{"disabled":!_vm.hasWriteContentRight,"text":partner.name,"is-selected":_vm.exclusivitiesByPartnerIdentifier[partner.identifier]},on:{"click":function($event){return _vm.toggleExclusivity(partner.identifier)}}})}),_vm._v(" "),(_vm.errorOnReset)?_c(VChip,{staticClass:"ml-auto mb-3",attrs:{"label":"","outlined":"","color":"red"}},[_c(VAvatar,{attrs:{"left":""}},[_c(VIcon,[_vm._v("mdi-close")])],1),_vm._v("\n      "+_vm._s(_vm.$t('errors.unknown'))+"\n    ")],1):(_vm.isSaving)?_c(VChip,{staticClass:"ml-auto mb-3",attrs:{"label":"","outlined":""}},[_c(VAvatar,{attrs:{"left":""}},[_c(VIcon,[_vm._v("mdi-loading mdi-spin")])],1),_vm._v("\n      "+_vm._s(_vm.$t('global.saveInProgress'))+"\n    ")],1):(_vm.successfullySaved)?_c(VChip,{staticClass:"ml-auto mb-3",attrs:{"label":"","outlined":"","color":"green"}},[_c(VAvatar,{attrs:{"left":""}},[_c(VIcon,[_vm._v("mdi-check")])],1),_vm._v("\n      "+_vm._s(_vm.$t('global.saved'))+"\n    ")],1):_vm._e()],2),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_vm._v("\n    "+_vm._s(_vm.$t(
        _vm.noExclusivities
          ? 'pages.program.catalog.modalExclusivePartners.noneSelectedAvailableEverywhere'
          : 'pages.program.catalog.modalExclusivePartners.onlyOnSelectedApps'
      ))+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }