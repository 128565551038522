export const updateAdminHeaders = (headers, admin) => {
  const key = admin.is_manager ? 'Manager' : 'Admin'
  headers[`X-${key}-Email`] = admin.email
  headers[`X-${key}-Token`] = admin.authentication_token

  if (admin.payload_version) {
    headers[`X-${key}-Payload-Version`] = admin.payload_version
  }
  if (admin.payload_time) {
    headers[`X-${key}-Payload-Time`] = admin.payload_time
  }
}

export default (axios, admin) => {
  if (admin) {
    updateAdminHeaders(axios.defaults.headers.common, admin)
  } else {
    delete axios.defaults.headers.common['X-Admin-Email']
    delete axios.defaults.headers.common['X-Admin-Token']
    delete axios.defaults.headers.common['X-Admin-Payload-Version']
    delete axios.defaults.headers.common['X-Admin-Payload-Time']

    delete axios.defaults.headers.common['X-Manager-Email']
    delete axios.defaults.headers.common['X-Manager-Token']
    delete axios.defaults.headers.common['X-Manager-Payload-Version']
    delete axios.defaults.headers.common['X-Manager-Payload-Time']
  }
}
