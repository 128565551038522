import Vue from 'vue'
import { forEach, uniq } from 'lodash-es'

import createDefaultState, {
  State,
  BrandsById,
  Brand,
  BrandWithIndentifier
} from './state'

import identifierToId from '@/utils/functions/identifierToId.ts'

export * from '@/utils/store/concerns/loadable/mutations.js'
export * from '@/utils/store/concerns/cancellable/mutations.js'

export const reset = (state: State) => {
  Object.assign(state, createDefaultState())
}

export const setBrandsById = (state: State, brandsById: BrandsById) => {
  state.brandsById = brandsById
}

export const addBrandsWithIdentifier = (
  state: State,
  brands: BrandWithIndentifier[]
) => {
  if (!state.brandsById) {
    state.brandsById = {}
  }

  forEach(brands, (brand) => {
    const id = identifierToId(brand.identifier)
    Vue.set(state.brandsById!, id, {
      id,
      name: brand.name,
      identifier: brand.identifier
    })
  })
}
export const addBrands = (state: State, brands: Brand[]) => {
  if (!state.brandsById) {
    state.brandsById = {}
  }

  forEach(brands, (brand) => {
    Vue.set(state.brandsById!, brand.id, brand)
  })
}

export const addBrandsById = (state: State, brandsById: BrandsById) => {
  if (!state.brandsById) {
    state.brandsById = {}
  }

  forEach(brandsById, (brand, id) => {
    Vue.set(state.brandsById!, id, brand)
  })
}

export const setBrand = (
  state: State,
  { id, brands }: { id: number; brands: Brand }
) => {
  if (!state.brandsById) {
    state.brandsById = {}
  }

  Vue.set(state.brandsById, id, brands)
}

export const addIdsBeingFetched = (state: State, ids: number[]) => {
  state.idsBeingFetched = uniq(state.idsBeingFetched.concat(ids))
}

export const resetIdsBeingFetched = (state: State) => {
  state.idsBeingFetched = []
}
