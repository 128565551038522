import { Store, ActionContext } from 'vuex'
import { find } from 'lodash-es'

import { State, TreeNode } from './state'
import { RootState } from '~/store/state'

import {
  handleAxiosErrorServerFromStoreV3,
  handleAxiosErrorFrontendFromStoreV4,
  handleAxiosProxyAnalyticsErrorServerFromStoreV3
} from '@/utils/functions/handle-errors'

export const fetch = function(
  this: Store<State>,
  { commit, dispatch }: ActionContext<State, RootState>
) {
  commit('reset')
  return Promise.all([dispatch('fetchTree'), dispatch('fetchErrorNode')])
}

export const retry = function(
  this: Store<State>,
  { state, dispatch }: ActionContext<State, RootState>
) {
  const promises: Promise<any>[] = []
  if (!state.isLoaded.tree) {
    promises.push(dispatch('fetchTree'))
  }
  if (!state.isLoaded.errorNode) {
    promises.push(dispatch('fetchErrorNode'))
  }
  return Promise.all(promises)
}

export const fetchTree = async function(
  this: Store<State>,
  { getters, commit, rootState }: ActionContext<State, RootState>
) {
  if (!rootState.auth.selectedProgram) {
    commit('setErrorForTree', this.$i18n.t('errors.program.notSelected'))
    return
  }

  commit('setLoadingStateForTree', true)
  commit('resetErrorForTree')

  let response
  try {
    response = getters.useCore
      ? await this.$axios.get(
          `/programs/${rootState.auth.selectedProgram.identifier}/products_group_tree`
        )
      : await this.$axios.post(
          '/api/proxy/analytics',
          {
            method: 'GET',
            path: `/internal_api/sas_bo_front_end/v1/programs/${rootState.auth.selectedProgram.id}/products-group-tree`
          },
          { baseURL: '/' }
        )
  } catch (error) {
    const method = getters.useCore
      ? handleAxiosErrorServerFromStoreV3
      : handleAxiosProxyAnalyticsErrorServerFromStoreV3
    method({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setLoadingStateForTree',
      mutationNameForSettingError: 'setErrorForTree'
    })
    return
  }

  if (getters.useCore && !response.data.success) {
    handleAxiosErrorFrontendFromStoreV4({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setLoadingStateForTree',
      mutationNameForSettingError: 'setErrorForTree'
    })
    return
  }

  const data = getters.useCore ? response.data.result : response.data

  commit('setTree', data)
  commit('setLoadedStateForTree', true)
  commit('setLoadingStateForTree', false)
}

export const fetchErrorNode = async function(
  this: Store<State>,
  { getters, commit, rootState }: ActionContext<State, RootState>
) {
  if (!rootState.auth.selectedProgram) {
    commit('setErrorForErrorNode', this.$i18n.t('errors.program.notSelected'))
    return
  }

  commit('setLoadingStateForErrorNode', true)
  commit('resetErrorForErrorNode')

  let response
  try {
    response = getters.useCore
      ? await this.$axios.get(
          `/programs/${rootState.auth.selectedProgram.identifier}/products_group_tree_node`
        )
      : await this.$axios.post(
          '/api/proxy/analytics',
          {
            method: 'GET',
            path: `/internal_api/sas_bo_front_end/v1/programs/${rootState.auth.selectedProgram.id}/products-group-tree-node`
          },
          { baseURL: '/' }
        )
  } catch (error) {
    const method = getters.useCore
      ? handleAxiosErrorServerFromStoreV3
      : handleAxiosProxyAnalyticsErrorServerFromStoreV3
    method({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setLoadingStateForErrorNode',
      mutationNameForSettingError: 'setErrorForErrorNode'
    })
    return
  }

  if (getters.useCore && !response.data.success) {
    handleAxiosErrorFrontendFromStoreV4({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setLoadingStateForErrorNode',
      mutationNameForSettingError: 'setErrorForErrorNode'
    })
    return
  }

  const data = getters.useCore ? response.data.result : response.data

  const errorNode = find(
    data.children,
    (node) => node.label === 'Erreur Reconnaissance' // TODO: i18n ?
  )
  if (!errorNode) {
    const text = this.$i18n.t(
      'pages.classification.errorNodeNotFound'
    ) as string
    this.$airbrakeNotify({ error: new Error(text) })
    commit('setErrorForErrorNode', text)
    commit('setLoadingStateForErrorNode', false)
    return
  }

  commit('setErrorNode', errorNode)
  commit('setLoadedStateForErrorNode', true)
  commit('setLoadingStateForErrorNode', false)
}

export const selectNodeForOrigin = function(
  this: Store<State>,
  { commit, dispatch }: ActionContext<State, RootState>,
  node: TreeNode
) {
  commit('selectNodeForOrigin', node)
  return dispatch('fetchSelectedOriginNodeDenoms')
}
export const selectNodeForTarget = function(
  this: Store<State>,
  { commit, dispatch }: ActionContext<State, RootState>,
  node: TreeNode
) {
  commit('selectNodeForTarget', node)
  return dispatch('fetchSelectedTargetNodeProducts')
}

export const fetchSelectedOriginNodeDenoms = async function(
  this: Store<State>,
  { state, getters, commit, rootState }: ActionContext<State, RootState>
) {
  if (!rootState.auth.selectedProgram) {
    commit(
      'setErrorForSelectedOriginNodeDenoms',
      this.$i18n.t('errors.program.notSelected')
    )
    return
  }
  if (!state.selectedOriginNode) {
    commit(
      'setErrorForSelectedOriginNodeDenoms',
      this.$i18n.t('errors.program.classification.originGroupNotSelected')
    )
    return
  }

  commit('setSelectedOriginNodeDenomsFetchingState', true)
  commit('resetErrorForSelectedOriginNodeDenoms')

  let response
  try {
    response = getters.useCore
      ? await this.$axios.get(
          getters.selectedOriginNodeIsSpecialDenoms
            ? `/programs/${rootState.auth.selectedProgram.identifier}/products_group_tree_node/all_non_final_denoms`
            : `/programs/${
                rootState.auth.selectedProgram.identifier
              }/products_group_tree_node/${
                state.selectedOriginNode.id
              }/denoms?include_final=${
                state.includeFinalDenoms ? 'true' : 'false'
              }`
        )
      : await this.$axios.post(
          '/api/proxy/analytics',
          {
            method: 'GET',
            path: getters.selectedOriginNodeIsSpecialDenoms
              ? `/internal_api/sas_bo_front_end/v1/programs/${rootState.auth.selectedProgram.id}/unlinked-denoms`
              : `/internal_api/sas_bo_front_end/v1/programs/${rootState.auth.selectedProgram.id}/products-group/${state.selectedOriginNode.id}/denoms`
          },
          { baseURL: '/' }
        )
  } catch (error) {
    const method = getters.useCore
      ? handleAxiosErrorServerFromStoreV3
      : handleAxiosProxyAnalyticsErrorServerFromStoreV3
    method({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setSelectedOriginNodeDenomsFetchingState',
      mutationNameForSettingError: 'setErrorForSelectedOriginNodeDenoms'
    })
    return
  }

  if (getters.useCore && !response.data.success) {
    handleAxiosErrorFrontendFromStoreV4({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setSelectedOriginNodeDenomsFetchingState',
      mutationNameForSettingError: 'setErrorForSelectedOriginNodeDenoms'
    })
    return
  }

  const data = getters.useCore ? response.data.result : response.data

  commit('setSelectedOriginNodeDenoms', data)
  commit('setSelectedOriginNodeDenomsFetchingState', false)
}

export const fetchSelectedTargetNodeProducts = async function(
  this: Store<State>,
  { state, getters, commit, rootState }: ActionContext<State, RootState>
) {
  if (!rootState.auth.selectedProgram) {
    commit(
      'setErrorForSelectedTargetNodeProducts',
      this.$i18n.t('errors.program.notSelected')
    )
    return
  }
  if (!state.selectedTargetNode) {
    commit(
      'setErrorForSelectedTargetNodeProducts',
      this.$i18n.t('errors.program.classification.targetGroupNotSelected')
    )
    return
  }

  if (getters.selectedTargetNodeIsSpecialDenoms) {
    commit('setSelectedTargetNodeProducts', [])
    return
  }

  commit('setSelectedTargetNodeProductsFetchingState', true)
  commit('resetErrorForSelectedTargetNodeProducts')

  let response
  try {
    response = getters.useCore
      ? await this.$axios.get(
          `/programs/${rootState.auth.selectedProgram.identifier}/products_group_tree_node/${state.selectedTargetNode.id}/all_products_in_subtree`
        )
      : await this.$axios.post(
          '/api/proxy/analytics',
          {
            method: 'GET',
            path: `/internal_api/sas_bo_front_end/v1/programs/${rootState.auth.selectedProgram.id}/products-group/${state.selectedTargetNode.id}/all_products_in_subtree`
          },
          { baseURL: '/' }
        )
  } catch (error) {
    const method = getters.useCore
      ? handleAxiosErrorServerFromStoreV3
      : handleAxiosProxyAnalyticsErrorServerFromStoreV3
    method({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setSelectedTargetNodeProductsFetchingState',
      mutationNameForSettingError: 'setErrorForSelectedTargetNodeProducts'
    })
    return
  }

  if (getters.useCore && !response.data.success) {
    handleAxiosErrorFrontendFromStoreV4({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setSelectedTargetNodeProductsFetchingState',
      mutationNameForSettingError: 'setErrorForSelectedTargetNodeProducts'
    })
    return
  }

  const data = getters.useCore ? response.data.result : response.data

  commit('setSelectedTargetNodeProducts', data)
  commit('setSelectedTargetNodeProductsFetchingState', false)
}

export const moveDenoms = async function(
  this: Store<State>,
  {
    state,
    getters,
    rootState,
    commit,
    dispatch
  }: ActionContext<State, RootState>
) {
  if (getters.noDenomsToMove) {
    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t('errors.program.classification.noDenomsSelected')
      },
      { root: true }
    )
    return
  }
  if (!state.selectedOriginNode) {
    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t(
          'errors.program.classification.originGroupNotSelected'
        )
      },
      { root: true }
    )
    return
  }
  if (!state.selectedTargetNode) {
    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t(
          'errors.program.classification.targetGroupNotSelected'
        )
      },
      { root: true }
    )
    return
  }

  if (!rootState.auth.selectedProgram) {
    commit('snackbar/addToast', {
      text: this.$i18n.t('errors.program.notSelected')
    })
    return
  }

  if (
    getters.selectedTargetNodeIsSpecialDenoms &&
    getters.selectedOriginNodeIsSpecialDenoms
  ) {
    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t(
          'errors.program.classification.groupsForOriginAndTargetMustBeDifferent'
        )
      },
      { root: true }
    )
    return
  }

  commit('setMovingState', true)

  let response
  try {
    response = getters.useCore
      ? await this.$axios.post(
          `/programs/${rootState.auth.selectedProgram.identifier}/products_group_tree_node/${state.selectedTargetNode.id}/move_denoms_to_group`,
          {
            denomination_ids: state.denomsToMove.map((d) => d.id),
            is_final: false
          }
        )
      : getters.selectedTargetNodeIsSpecialDenoms
      ? await this.$axios.post(
          '/api/proxy/analytics',
          {
            method: 'POST',
            path: '/internal_api/sas_bo_front_end/v1/unlink-denoms-from-group',
            data: {
              denomination_ids: state.denomsToMove.map((d) => d.id),
              from_node_id: state.selectedOriginNode.id
            }
          },
          { baseURL: '/' }
        )
      : await this.$axios.post(
          '/api/proxy/analytics',
          {
            method: 'POST',
            path: getters.selectedOriginNodeIsSpecialDenoms
              ? '/internal_api/sas_bo_front_end/v1/add-denoms-to-group'
              : '/internal_api/sas_bo_front_end/v1/move-denoms-to-group',
            data: {
              denominations: state.denomsToMove,
              target_node_id: state.selectedTargetNode.id
            }
          },
          { baseURL: '/' }
        )
  } catch (error) {
    const method = getters.useCore
      ? handleAxiosErrorServerFromStoreV3
      : handleAxiosProxyAnalyticsErrorServerFromStoreV3
    method({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setMovingState',
      errorInToast: true
    })
    return
  }

  if (getters.useCore && !response.data.success) {
    handleAxiosErrorFrontendFromStoreV4({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setMovingState',
      errorInToast: true
    })
    return
  }

  commit('resetDenomsToMove')
  commit('setMovingState', false)
  return dispatch('fetchSelectedOriginNodeDenoms')
}

export const validateDenoms = async function(
  this: Store<State>,
  {
    state,
    getters,
    rootState,
    commit,
    dispatch
  }: ActionContext<State, RootState>
) {
  if (getters.noDenomsToMove) {
    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t('errors.program.classification.noDenomsSelected')
      },
      { root: true }
    )
    return
  }

  if (!rootState.auth.selectedProgram) {
    commit('snackbar/addToast', {
      text: this.$i18n.t('errors.program.notSelected')
    })
    return
  }

  if (getters.useCore && !state.selectedOriginNode) {
    commit('snackbar/addToast', {
      text: this.$i18n.t('errors.program.classification.originGroupNotSelected')
    })
    return
  }

  commit('setValidatingState', true)

  let response
  try {
    response = getters.useCore
      ? await this.$axios.post(
          `/programs/${rootState.auth.selectedProgram.identifier}/products_group_tree_node/${state.selectedOriginNode.id}/move_denoms_to_group`,
          {
            denomination_ids: state.denomsToMove.map((d) => d.id),
            is_final: true
          }
        )
      : await this.$axios.post(
          '/api/proxy/analytics',
          {
            method: 'POST',
            path:
              '/internal_api/sas_bo_front_end/v1/end-transition-denoms-group',
            data: {
              denominations: state.denomsToMove
            }
          },
          { baseURL: '/' }
        )
  } catch (error) {
    const method = getters.useCore
      ? handleAxiosErrorServerFromStoreV3
      : handleAxiosProxyAnalyticsErrorServerFromStoreV3
    method({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setValidatingState',
      errorInToast: true
    })
    return
  }

  if (getters.useCore && !response.data.success) {
    handleAxiosErrorFrontendFromStoreV4({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setValidatingState',
      errorInToast: true
    })
    return
  }

  commit('resetDenomsToMove')
  commit('setValidatingState', false)
  return dispatch('fetchSelectedOriginNodeDenoms')
}

export const setIncludeFinalDenoms = function(
  this: Store<State>,
  { commit, dispatch }: ActionContext<State, RootState>,
  value: Boolean
) {
  commit('setIncludeFinalDenoms', value)
  return dispatch('fetchSelectedOriginNodeDenoms')
}

export const selectAllDenoms = function(
  this: Store<State>,
  { commit, getters }: ActionContext<State, RootState>
) {
  commit('addDenomsToMove', getters.displayedDenoms)
}

export const resetSelectNodeForOrigin = function(
  this: Store<State>,
  { commit }: ActionContext<State, RootState>
) {
  commit('resetSelectNodeForOrigin')
  commit('setSelectedOriginNodeDenoms', [])
}

export const resetSelectNodeForTarget = function(
  this: Store<State>,
  { commit }: ActionContext<State, RootState>
) {
  commit('resetSelectNodeForTarget')
  commit('setSelectedTargetNodeProducts', [])
}
