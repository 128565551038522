import Vue from 'vue'
import { findIndex } from 'lodash-es'

import createDefaultState from './state'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export const setLoadingState = (state, boolean) => {
  state.isLoading = boolean
}
export const setLoadedState = (state, boolean) => {
  state.isLoaded = boolean
}
export const setError = (state, text) => {
  state.error = text
}
export const resetError = (state) => {
  state.error = null
}

export const setDeclaration = (state, declaration) => {
  state.declaration = declaration
}

export const updatePurchaseWithIdentifier = (
  state,
  { identifier, purchase }
) => {
  // can happen when switching declaration page
  // quickly after taking an action
  if (!state.declaration) return

  const index = findIndex(
    state.declaration.details.purchases,
    (p) => p.identifier === identifier
  )
  if (index === -1) return

  Vue.set(state.declaration.details.purchases, index, purchase)
}

export const destroyPurchase = (state, purchaseIdentifier) => {
  // can happen when switching declaration page
  // quickly after taking an action
  if (!state.declaration) return

  const index = findIndex(
    state.declaration.details.purchases,
    (p) => p.identifier === purchaseIdentifier
  )
  if (index === -1) return

  state.declaration.details.purchases.splice(index, 1)
}

export const updatePurchases = (state, purchases) => {
  if (!state.declaration) return

  purchases.forEach((purchase) => {
    const index = findIndex(
      state.declaration.details.purchases,
      (p) => p.identifier === purchase.identifier
    )
    if (index === -1) return

    Vue.set(state.declaration.details.purchases, index, purchase)
  })
}

export const updateAvailableSpecialOffersAtDeclaration = (state, offers) => {
  state.declaration.details.available_special_offers_at_declaration = offers
}

export const reportAnomalyOnRetailer = (state) => {
  state.declaration.details.is_anomaly_on_retailer_reported = true
}
