<template>
  <v-tabs align-with-title :background-color="paletteScanobar.black">
    <v-tab v-for="tab in tabs" :key="tab.identifier" nuxt :to="tab.to">
      {{ tab.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import MixinPaletteScanobar from '~/mixins/paletteScanobar'
import { METRICS_CANALS } from '~/utils/constants/routes'
import { PROGRAM_SCANOBAR_IDENTIFIED_FOR_ANALYTICS } from '~/utils/constants/program-ids'

export const TAB_GENERAL = 'general'
export const TAB_ONBOARDING_FUNNEL = 'onboarding_funnel'
export const TAB_POINTS_OF_SALE = 'points_of_sale'

export const TABS = [TAB_GENERAL, TAB_ONBOARDING_FUNNEL]

export const DEFAULT_TAB = TAB_GENERAL

export default {
  mixins: [MixinPaletteScanobar],
  computed: {
    ...mapState('metrics/program', ['currentProgramIdentifier']),

    tabs() {
      const tabs = []

      const onboarding = {
        identifier: TAB_ONBOARDING_FUNNEL,
        to: `${METRICS_CANALS}/${TAB_ONBOARDING_FUNNEL}`,
        title: this.$t(`layout.metrics.canals.tabs.${TAB_ONBOARDING_FUNNEL}`)
      }
      const pointsOfSale = {
        identifier: TAB_POINTS_OF_SALE,
        to: `${METRICS_CANALS}/${TAB_POINTS_OF_SALE}`,
        title: this.$t(`layout.metrics.canals.tabs.${TAB_POINTS_OF_SALE}`)
      }
      const general = {
        identifier: TAB_GENERAL,
        to: `${METRICS_CANALS}/${TAB_GENERAL}`,
        title: this.$t(`layout.metrics.canals.tabs.${TAB_GENERAL}`)
      }

      tabs.push(general)
      tabs.push(onboarding)
      if (
        this.currentProgramIdentifier ===
        PROGRAM_SCANOBAR_IDENTIFIED_FOR_ANALYTICS
      ) {
        tabs.push(pointsOfSale)
      }

      return tabs
    }
  },
  mounted() {
    this.fetchConfig()
  },
  methods: {
    ...mapActions('metrics/program', ['fetchConfig'])
  }
}
</script>
