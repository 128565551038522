import { partition } from 'lodash-es'

import coreOrigin from '~/utils/constants/origin-core'
import {
  HOME_METRICS_READ,
  HOME_CONTENT_READ,
  HOME_ANIMATION_READ,
  HOME_USER_READ,
  HOME_MANAGER_READ,
  LOGIN
} from '~/utils/constants/routes'
import {
  UNKNOWN_EMAIL_OR_PASSWORD,
  ACCESS_REVOKED,
  UNKNOWN_ERROR_USER
} from '~/utils/constants/errors'
import colorSwitcher, { resetTheme } from '~/utils/functions/colorSwitcher'
import { handleAxiosErrorServerFromStoreV3 } from '~/utils/functions/handle-errors'
import { handleAuthErrorAxiosWithUserInput } from '~/utils/functions/handle-auth-errors'
import setAxiosAdminHeaders from '~/utils/functions/setAxiosAdminHeaders'
import setAxiosProgramHeaders from '~/utils/functions/setAxiosProgramHeaders'

const knownErrors = [UNKNOWN_EMAIL_OR_PASSWORD, ACCESS_REVOKED]

// "this.app" exists but is not the same as "this.$router.app"
// in particular "this.app.$vuetify" is undefined
function getVuetifyFromStore(store) {
  return store.$router.app.$vuetify
}

export const login = async function(
  { commit, dispatch, state },
  { email, password, stayConnected, redirect }
) {
  if (state.isLoading) return

  commit('setLoadingState', true)
  await dispatch('setAdmin', { admin: null, skipSession: true })
  commit('resetError')

  let response
  try {
    response = await this.$axios.post(
      '/api/auth/login',
      {
        email,
        password,
        stayConnected
      },
      { baseURL: '/' }
    )
  } catch (error) {
    handleAuthErrorAxiosWithUserInput({ error, commit, store: this })
    return
  }

  if (!response.data.success) {
    handleErrorUser(response, commit, this)
    commit('setIsUserInputError', true)
    commit('setLoadingState', false)
    return
  }

  await dispatch('setAdmin', { admin: response.data.result.admin })
  commit('setLoadingState', false)

  return dispatch('redirectToHome', { redirect })
}

export function redirectToHome({ state }, { redirect } = {}) {
  if (redirect) {
    this.$router.push(redirect)
  } else if (state.admin.permitted_actions.includes('metrics_read')) {
    this.$router.push(HOME_METRICS_READ)
  } else if (state.admin.permitted_actions.includes('content_read')) {
    this.$router.push(HOME_CONTENT_READ)
  } else if (state.admin.permitted_actions.includes('animation_read')) {
    this.$router.push(HOME_ANIMATION_READ)
  } else if (state.admin.permitted_actions.includes('user_read')) {
    this.$router.push(HOME_USER_READ)
  } else if (state.admin.permitted_actions.includes('manager_read')) {
    this.$router.push(HOME_MANAGER_READ)
  } else {
    this.$router.push('/')
  }
}

export async function setAdmin(
  { commit, dispatch, state, getters },
  { admin, skipSession }
) {
  commit('setAdmin', admin)
  setAxiosAdminHeaders(this.$axios, admin)

  if (getters.permittedPrograms.length !== 1) return

  const permittedProgramIdentifier = getters.permittedPrograms[0]
  if (
    !state.selectedProgram ||
    state.selectedProgram.identifier !== permittedProgramIdentifier
  ) {
    await dispatch('getDetailsAndSelectProgramFromIdentifier', {
      programIdentifier: permittedProgramIdentifier,
      skipSession
    })
  }
}

// TODO: better handle errors
// if they have an "error_message_i18n", then display it
// (and do not display the generic error message "UNKNOWN_ERROR_USER")
function handleErrorUser(response, commit, store) {
  const [knowns, unknowns] = partition(response.data.errors, (error) =>
    knownErrors.includes(error.error_code)
  )

  if (unknowns.length > 0) {
    store.$airbrakeNotifyUnhandledBadRequest({ response })
  }

  if (knowns.length > 0) {
    // TODO: handle multiples
    commit('setError', knowns[0].error_code)
    return
  }

  commit('setError', UNKNOWN_ERROR_USER)
}

export const logout = async function({ commit, dispatch }, { redirect } = {}) {
  commit('setLoadingState', true)
  commit('resetError')

  try {
    await this.$axios.delete('/api/auth/logout', { baseURL: '/' })
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({ error, commit, store: this })
    return
  }

  commit('setLoadingState', false)
  resetTheme(getVuetifyFromStore(this))
  await dispatch('setAdmin', { admin: null })
  this.$router.push({ path: '/auth/login', query: { r: redirect } })
  commit('reset')
}

export const resetPassword = async function({ commit }, { password, token }) {
  commit('setLoadingState', true)
  let response
  try {
    response = await this.$axios.post(
      `/managers/reset_password`,
      {
        password,
        token
      },
      { baseURL: coreOrigin }
    )
  } catch (error) {
    handleAuthErrorAxiosWithUserInput({ error, commit, store: this })
    return
  }

  if (!response.data.success) {
    handleErrorUser(response, commit, this)
    commit('setIsUserInputError', true)
    commit('setLoadingState', false)
  }

  commit('setLoadingState', false)
  this.$router.push(LOGIN)
}

export const getDetailsAndSelectProgramFromId = function(
  { dispatch },
  programId
) {
  return dispatch('getDetailsAndSelectProgramFromIdentifier', {
    programIdentifier: `loyalty-program-${programId}`
  })
}

export const getDetailsAndSelectProgramFromIdentifier = async function(
  { commit, dispatch, rootState },
  { programIdentifier, skipSession }
) {
  await dispatch('program/search/search', null, { root: true })

  const program = rootState.program.search.programs.find(
    (p) => p.identifier === programIdentifier
  )

  // MOKEYPATCH
  // SSR serialization of the state corrupts cancel token and we get the error
  // "state.cancelToken.cancel is not a function"
  if (process.server) {
    commit('program/search/reset', null, { root: true })
  }

  if (skipSession) {
    return dispatch('setProgramInStores', program)
  } else {
    return dispatch('setProgramEverywhere', program)
  }
}

export const setProgramEverywhere = async function({ dispatch }, program) {
  await dispatch('setProgramInSession', program)
  await dispatch('setProgramInStores', program)
}

export const setProgramInSession = async function({ commit }, program) {
  // when setting admin from nuxtServerInit
  if (process.server) return

  try {
    await this.$axios.post(
      '/api/auth/select_program',
      { program },
      { baseURL: '/' }
    )
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this,
      errorInToast: true
    })
  }
}

export const setProgramInStores = function({ commit }, program) {
  // TODO: use same identifiers everywhere
  commit('setProgram', program)
  commit(
    'metrics/program/selectCurrentProgramIdentifier',
    `loyalty_program-${program.id}`,
    { root: true }
  )
  commit('crud/products-collections/updateProgramId', program.id, {
    root: true
  })
  setAxiosProgramHeaders(this.$axios, program)

  colorSwitcher(getVuetifyFromStore(this), program.dominant_color)
}

export const refreshAdmin = async function({ state, commit, dispatch }) {
  if (state.isRefreshing) return

  commit('setRefreshingState', true)
  commit('resetError')

  let response
  try {
    response = await this.$axios.get('/accounts/refresh')
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this
    })
    return
  }

  if (!response.data.success) {
    handleErrorUser(response, commit, this)
    commit('setIsUserInputError', true)
    commit('setRefreshingState', false)
    return
  }

  await dispatch('setAdmin', { admin: response.data.result.admin })
  await dispatch('refreshAdminSession', response.data.result.admin)

  commit('setRefreshingState', false)
}

export const refreshAdminSession = async function({ commit }, admin) {
  try {
    await this.$axios.post(
      '/api/auth/refresh_admin',
      { admin },
      { baseURL: '/' }
    )
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this,
      errorInToast: true
    })
  }
}

export const refreshProgram = async function({ state, commit, dispatch }) {
  if (state.isRefreshing) return

  commit('setRefreshingState', true)
  commit('resetError')

  let response
  try {
    response = await this.$axios.get('/programs/refresh')
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this
    })
    return
  }

  if (!response.data.success) {
    handleErrorUser(response, commit, this)
    commit('setIsUserInputError', true)
    commit('setRefreshingState', false)
    return
  }

  await dispatch('setProgramEverywhere', response.data.result.program)

  commit('setRefreshingState', false)
}
