// https://logaretm.github.io/vee-validate/guide/localization.html#using-other-i18n-libraries

// https://github.com/logaretm/vee-validate/blob/0a4075075d62476158fad1ecf7811fa71e08efe3/locale/fr.json
// import validation from 'vee-validate/dist/locale/fr'

export default {
  rules: {
    // ...validation.messages,
    // alpha: 'Ce champ ne peut contenir que des lettres',
    // alpha_num:
    //   'Ce champ ne peut contenir que des caractères alpha-numériques',
    // alpha_dash:
    //   'Ce champ ne peut contenir que des caractères alpha-numériques, tirets ou soulignés',
    // alpha_spaces:
    //   'Ce champ ne peut contenir que des lettres ou des espaces',
    between: 'Ce champ doit être compris entre {min} et {max}',
    confirmed: 'Ce champ ne correspond pas',
    // digits:
    //   'Ce champ doit être un nombre entier de {length} chiffres',
    // dimensions:
    //   'Ce champ doit avoir une taille de {width} pixels par {height} pixels',
    email: 'Ce champ doit être une adresse e-mail valide',
    // excluded: 'Ce champ doit être une valeur valide',
    // ext: 'Ce champ doit être un fichier valide',
    // image: 'Ce champ doit être une image',
    integer: 'Ce champ doit être un entier',
    // length: 'Ce champ doit contenir {length} caractères',
    max_value: 'Ce champ doit avoir une valeur de {max} ou moins',
    max: 'Ce champ ne peut pas contenir plus de {length} caractères',
    // mimes: 'Ce champ doit avoir un type MIME valide',
    min_value: 'Ce champ doit avoir une valeur de {min} ou plus',
    min: 'Ce champ doit contenir au minimum {length} caractères',
    numeric: 'Ce champ ne peut contenir que des chiffres',
    // oneOf: 'Ce champ doit être une valeur valide',
    // regex: 'Ce champ est invalide',
    required: 'Ce champ est obligatoire',
    // required_if:
    //   'Ce champ est obligatoire lorsque {target} possède cette valeur',
    // size: 'Ce champ doit avoir un poids inférieur à {size}KB',
    double: 'Ce champ doit être une décimale valide',

    // custom verifications
    eans:
      "Ce champ doit contenir une liste d'EANs (donc ne contiennent que des numéros)",
    at_least_one_product_required: 'Au moins un produit est nécessaire',
    all_products_exist: "Certains produits n'existent pas, à vous de les créer",
    all_products_are_valid:
      'Certains produits ne sont pas valides (nom et/ou marque manquants) à vous de les mettre à jour',
    xor:
      "L'un ou l'autre des champs doit être rempli (et pas les deux en même temps)",
    image_squared: "Cette image n'est pas carrée",
    image_min_size: 'Cette image doit faire au minimum {min} pixels',
    image_max_width: 'Cette image doit faire au maximum {max} pixels de large',
    image_width: 'Cette image doit faire {width} pixels de large',
    image_height: 'Cette image doit faire {height} pixels de haut',

    iban: 'Ce champ doit contenir un IBAN valide',
    json: 'Ce champ doit contenir du JSON'
  },
  fields: {
    email: 'email',
    password: 'mot de passe',

    productsEans: 'EANs',
    productsEansAllExist: 'tous les produits existent', // hidden field

    offerName: "nom de l'offre",
    offerType: "type d'offre",

    bonusValue: 'valeur du bonus',
    bonusDescriptionForUserReview:
      "description du bonus affichée à l'utilisateur pendant la revue de ticket",
    bonusProductsEans: 'EANs',
    bonusTitleForWhatNow: 'titre',
    bonusDescriptionForWhatNow: 'description',
    bonusTitleForCoupon: 'titre',
    bonusDescriptionForCoupon: 'description',
    bonusFormattedDescription: 'description',
    // bonusMessageIdentifier: '',

    refundValue: 'montant du remboursement',
    refundBonusPoints: 'nombre (fixe) de points bonus',
    refundRecommendedPrice: 'prix public recommandé',
    refundProductsEans: 'EANs',

    groupedRefundQuota: 'quota',
    groupedRefundValue: 'montant du remboursement',
    groupedRefundMinItems: 'nombre minimum de produits à acheter',

    productName: 'nom du produit',
    productNameShort: 'nom court du produit',
    productBrand: 'marque du produit',
    productImageFile: 'image du produit',
    productImageUrl: "URL de l'image du produit",

    bonusProductsEansAllExist: 'tous les produits existent', // hidden field
    refundProductsEansAllExist: 'tous les produits existent', // hidden field

    programPeriodGenerosity: 'générosité',

    messageKind: 'un genre',

    messageTitle: 'titre du message',
    messageContent: 'contenu',
    messageImageHeight: "hauteur de l'image",
    messageInternalDesc: 'description interne',
    messageNotifContent: 'contenu de la notification',
    messageImagePreview: 'aperçu',
    messageObjectForAction: "objet de l'action",
    messageEditor: 'partenaire',

    rewardKind: 'type',
    rewardName: 'nom',
    rewardAvailableAt: 'date de lancement',
    rewardRequiredPoints: 'points requis',
    rewardFormattedDescription: 'description formattée',
    rewardImagePreview: 'illustration',
    rewardPriority: 'priorité',

    rewardInternalDescription: 'description interne',
    rewardIllustration: 'illustration',

    emailInternalDescription: 'description interne',
    emailEditorId: 'partenaire',
    emailSubject: 'sujet',
    emailCategories: 'catégories',
    // emailParametersDescription: 'description des paramètres',
    emailFrom: 'email',
    emailReplyTo: 'email',
    emailContent: "contenu de l'email",

    requestBenefitsProgram: 'programme',
    requestBenefitsQuantity: 'quantité',
    requestBenefitsUnitPrice: 'prix unitaire',
    requestBenefitsDiscount: 'remise',

    attachFidzProgram: 'programme expiré',
    attachFidzQuantity: 'quantité',
    attachFidzUnitPrice: 'prix unitaire',
    attachFidzDiscount: 'remise',

    programName: 'nom',
    programKind: 'Type',
    programClient: 'client',
    programPartners: 'applications partenaires',
    programDescription: 'description',
    programBrandDescription: 'page "notre marque"',
    programBrandHtmlDescription: 'page "notre marque" (version HTML)',

    programWelcomingPoints: 'points de bienvenue',
    programMaxPointsPerMonth: 'points maximum par mois',
    programMaxPointsPerYear: 'points maximum par an',
    programCentsForPoints: 'nombre de centimes par point',
    programCreditCoefficient: 'coefficient de crédit',

    programStampsMaxCount: 'nombre total de tampons',
    programStampsByLine: 'nombre de tampons par ligne',

    programPuzzleTotalPiecesCount: 'nombre total de pièces',
    programPuzzleBoardWidth: 'largeur du puzzle',
    programPuzzleBoardHeight: 'hauteur du puzzle',
    programPuzzleBoardMargin: "marge dans l'image autour puzzle",
    programPuzzlePieceX: "position latérale de l'image par rapport au puzzle",
    programPuzzlePieceY: "position verticale de l'image par rapport au puzzle",
    programPuzzlePieceWidth: "largeur de l'image de la pièce",
    programPuzzlePieceHeight: "hauteur de l'image de la pièce",
    programPuzzlePieceOwnedImage: 'image de la pièce si obtenue',
    imagePuzzleBoardRequired: 'image du puzzle',

    programBrand: 'marque',
    programBrandNotGivingPoints: 'marque',
    programBrandNotGivingPointsReason: 'raison',

    catalogChangeDate: 'date de changement',
    catalogChangeTime: 'heure de changement',

    purchaseDenomination: 'libellé',
    purchaseQuantity: 'quantité',
    purchaseUnitPrice: 'prix unitaire',
    purchaseDiscount: 'réduction',

    sendMoneyAmount: 'montant',
    sendMoneyDescription: 'description',
    sendMoneyZendeskTicketId: 'ID du ticket zendesk',
    sendMoneyPassword: 'mot de passe',

    addGroupedOfferGroupedOffer: 'offre groupée',
    addGroupedOfferEligibleProductsCount: 'produits éligibles',
    addGroupedOfferPurchase: 'achat',
    addGroupedOfferPurchaseQuantity: 'quantité',
    addGroupedOfferPurchaseUnitPrice: 'prix unitaire',

    discountValue: 'montant de la remise',
    discountQuota: "nombre d'utilisation",
    discountStartedAt: 'début',
    discountUserId: "ID de l'utilisateur",

    imageRequired: 'image',
    imageSizes: 'image',
    imageUrl: 'image',

    updateTotalTotal: 'total',

    precisionTitle: "titre (visible dans l'app)",
    precisionDisplayContext: "Contexte d'affichage",

    paymentMethodEditor: 'app',
    paymentMethodUserFirstName: "prénom de l'utilisateur",
    paymentMethodUserLastName: "nom de famille de l'utilisateur",
    paymentMethodKind: 'type de méthode',
    paymentMethodIdentifier: 'identifiant',

    importFileWithEans: 'fichier des EANs'
  }
}
