<template>
  <div class="text-center">
    <v-dialog
      v-model="showModal"
      transition="dialog-bottom-transition"
      hide-overlay
      max-width="800"
      @keydown.stop
    >
      <template #activator="{ on, attrs }">
        <v-btn
          block
          tile
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
          @click="getPreview"
        >
          {{ $t('pages.program.catalog.changeRewards.previewChanges') }}
          <v-icon right>
            mdi-eye-outline
          </v-icon>
        </v-btn>
      </template>

      <v-card v-if="!isValid" color="error" dark>
        <v-card-title>
          {{ $t('pages.program.catalog.changeRewards.modal.warning') }}
        </v-card-title>
      </v-card>

      <v-card v-else-if="!isLoaded && isLoading" color="primary" dark>
        <v-card-text>
          {{ $t('pages.program.catalog.changeRewards.modal.loading') }}
          <v-progress-linear indeterminate color="white" class="mb-0" />
        </v-card-text>
      </v-card>

      <ValidationObserver
        v-else-if="isLoaded"
        v-slot="{ handleSubmit }"
        tag="form"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>
              {{ $t('pages.program.catalog.changeRewards.modalTitle') }}
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn icon dark @click="showModal = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text>
            <pre class="pre" v-text="catalogPreview" />
            <v-radio-group v-model="updateDate" hide-details>
              <v-radio
                :label="
                  $t('pages.program.catalog.changeRewards.modal.updateNow')
                "
                :value="UPDATE_NOW"
              />
              <v-radio
                :label="
                  $t('pages.program.catalog.changeRewards.modal.updateLater')
                "
                :value="UPDATE_LATER"
              />
            </v-radio-group>
            <v-row v-if="updateDate === UPDATE_LATER">
              <v-col cols="6">
                <v-menu
                  v-model="isMenuDateOpen"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="catalogChangeDate"
                      mode="eager"
                      rules="required"
                      slim
                    >
                      <v-text-field
                        :value="dateFormatted"
                        prepend-icon="mdi-calendar-blank"
                        clearable
                        color="primary"
                        readonly
                        :label="
                          $t(
                            'pages.program.catalog.changeRewards.modal.launchDate'
                          )
                        "
                        :error-messages="errors"
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="date = null"
                      />
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="date"
                    locale="fr"
                    full-width
                    @input="isMenuDateOpen = false"
                  />
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu
                  ref="menu"
                  v-model="isMenuTimeOpen"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="catalogChangeTime"
                      mode="eager"
                      rules="required"
                      slim
                    >
                      <v-text-field
                        v-model="time"
                        clearable
                        color="primary"
                        prepend-icon="mdi-clock-outline"
                        readonly
                        :label="
                          $t(
                            'pages.program.catalog.changeRewards.modal.launchHour'
                          )
                        "
                        :error-messages="errors"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </ValidationProvider>
                  </template>
                  <v-time-picker
                    v-model="time"
                    format="24hr"
                    full-width
                    @click:minute="$refs.menu.save(time)"
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn outlined color="indigo" dark @click="showModal = false">
              {{ $t('pages.program.catalog.changeRewards.modal.back') }}
            </v-btn>
            <v-btn
              :loading="isLoading"
              color="error"
              @click="handleSubmit(executeChanges)"
            >
              {{ $t('pages.program.catalog.changeRewards.modal.submit') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

const UPDATE_NOW = 'UPDATE_NOW'
const UPDATE_LATER = 'UPDATE_LATER'

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    showModal: false,
    date: null,
    isMenuDateOpen: false,
    time: null,
    isMenuTimeOpen: false,
    updateDate: UPDATE_NOW,

    UPDATE_NOW,
    UPDATE_LATER
  }),
  computed: {
    ...mapState('program/catalog', [
      'isLoading',
      'isLoaded',
      'newLoyaltyRewards',
      'loyaltyRewardsToChange',
      'catalogPreview'
    ]),
    dateFormatted() {
      return this.date ? moment(this.date).format('L') : ''
    },
    datetimeForBackend() {
      if (this.updateDate === UPDATE_NOW) {
        return null
      } else {
        return moment(
          `${this.date} ${this.time}`,
          'YYYY-MM-DD hh:mm'
        ).toISOString()
      }
    },
    isValid() {
      return (
        this.loyaltyRewardsToChange.length === this.newLoyaltyRewards.length
      )
    }
  },

  methods: {
    ...mapActions('program/catalog', ['updateCatalog']),

    getPreview() {
      if (this.isValid) {
        this.updateCatalog({ isPreview: true })
      }
    },
    executeChanges() {
      this.updateCatalog({
        isPreview: false,
        datetime: this.datetimeForBackend
      })
    }
  }
}
</script>

<style scoped>
.pre {
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;

  font-size: 85%;
  font-weight: 900;

  background-color: #fbe5e1;
  color: #c0341d;
  padding: 0.2rem 0.4rem;
  margin: 0 0 24px;
}
</style>
