import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,[_c(VListItemAvatar,{attrs:{"tile":"","size":"100px"}},[_c(VIcon,{attrs:{"size":"80px"}},[_vm._v("mdi-loading mdi-spin")])],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_c('strong',[_vm._v(_vm._s(_vm.$t('crud.offers.edit.products.list.item.ean'))+" :")]),_vm._v("\n      "+_vm._s(_vm.ean)+"\n    ")]),_vm._v(" "),_c(VListItemSubtitle,[_c(VSkeletonLoader,{attrs:{"width":"300","type":"text"}})],1),_vm._v(" "),_c(VListItemTitle,[_c(VSkeletonLoader,{attrs:{"width":"350","type":"text"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }