import { forEach, isPlainObject } from 'lodash-es'

export default function toFormData(payload, formData = new FormData(), path) {
  forEach(payload, (value, key) => {
    if (value === null) return
    if (value === undefined) return

    const completePath = path ? `${path}[${key}]` : key

    if (isPlainObject(value)) {
      toFormData(value, formData, completePath)
      return
    }

    if (Array.isArray(value)) {
      value.forEach((item) => {
        formData.append(`${completePath}[]`, item)
      })
      return
    }

    formData.append(completePath, value)
  })
  return formData
}

// deprecated for toFormDataWithArrayIndexV2
// this function is not safe with arrays containing anything other than hashes
export function toFormDataWithArrayIndex(
  payload,
  formData = new FormData(),
  path
) {
  forEach(payload, (value, key) => {
    if (value === null) return
    if (value === undefined) return

    const completePath = path ? `${path}[${key}]` : key

    if (isPlainObject(value)) {
      toFormDataWithArrayIndex(value, formData, completePath)
      return
    }

    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        toFormDataWithArrayIndex(item, formData, `${completePath}[${index}]`)
      })
      return
    }

    formData.append(completePath, value)
  })
  return formData
}

export function toFormDataWithArrayIndexV2(
  payload,
  formData = new FormData(),
  path
) {
  if (payload === null) return formData
  if (payload === undefined) return formData

  if (isPlainObject(payload)) {
    forEach(payload, (value, key) => {
      toFormDataWithArrayIndexV2(
        value,
        formData,
        path ? `${path}[${key}]` : key
      )
    })
    return formData
  }

  if (Array.isArray(payload)) {
    payload.forEach((item, index) => {
      toFormDataWithArrayIndexV2(
        item,
        formData,
        path ? `${path}[${index}]` : index
      )
    })
    return formData
  }

  formData.append(path, payload)

  return formData
}
