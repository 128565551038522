<!-- https://github.com/vuetifyjs/vuetify/blob/master/packages/docs/src/layouts/documentation/Search.vue -->

<template>
  <v-responsive
    class="mr-0 mr-md-3 hidden-xs-only transition-swing"
    :max-width="isFocused ? 300 : 250"
  >
    <v-text-field
      ref="search"
      v-model="text"
      :label="$t('pages.programs.searchLabel')"
      color="primary"
      dense
      flat
      hide-details
      prepend-inner-icon="mdi-magnify"
      rounded
      solo-inverted
      @input="onInput"
      @focus="onFocus"
      @keydown.enter="onEnter"
      @keydown.esc="onEsc"
    />
  </v-responsive>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { PROGRAM_SELECT_PATH } from '@/plugins/vuex-router-sync'
const TIME_WITHOUT_INPUT_BEFORE_SEARCHING_IN_MILLISECONDS = 500
export default {
  data: () => ({
    timeout: null
  }),
  computed: {
    ...mapState('program/search', ['isFocused', 'isSearching']),

    text: {
      get() {
        return this.$store.state.program.search.text
      },
      set(value) {
        this.$store.commit('program/search/setText', value)
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown)
  },
  methods: {
    ...mapActions('program/search', ['search']),
    ...mapMutations('program/search', ['focus']),
    // ...mapMutations('program/search', ['focus', 'setAdvanceSearch']),

    onKeyDown(e) {
      e = e || window.event
      if (
        e.keyCode === 191 && // Forward Slash '/'
        e.target !== this.$refs.search.$refs.input
      ) {
        e.preventDefault()
        this.$refs.search.focus()
      }
    },
    onInput() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      // this.setAdvanceSearch(false)

      if (this.$route.path !== PROGRAM_SELECT_PATH) return

      this.timeout = setTimeout(() => {
        this.search()
      }, TIME_WITHOUT_INPUT_BEFORE_SEARCHING_IN_MILLISECONDS)
    },
    onEnter() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      // this.setAdvanceSearch(false)

      if (this.$route.path !== PROGRAM_SELECT_PATH) {
        this.$router.push(PROGRAM_SELECT_PATH)
      }

      this.search()
    },
    onEsc() {
      this.$refs.search.blur()
    },
    onFocus() {
      this.focus()
    }
  }
}
</script>
