

































































import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import Vue from 'vue'
import RowSwitchReward from './switch/RowSwitchReward.vue'
import SelectReward from './switch/SelectReward.vue'
import PreviewButtonAndModal from './switch/PreviewButtonAndModal.vue'
export default Vue.extend({
  components: {
    RowSwitchReward,
    SelectReward,
    PreviewButtonAndModal
  },
  data: () => ({
    message: 'Ajouter une récompense au catalogue',
    rowToDelete: {},
    newRow: {}
  }),
  computed: {
    ...mapState('program/catalog', [
      'newLoyaltyRewardsModel',
      'loyaltyRewardsToChange',
      'loyaltyRewardsRows'
    ]),
    ...mapGetters('program/catalog', ['loyaltyRewardsToChangeCount'])
  },
  mounted() {
    this.fetchRewards()
  },
  destroyed() {
    this.resetRewards()
  },
  methods: {
    ...mapActions('program/catalog', ['fetchRewards']),
    ...mapMutations('program/catalog', ['resetRewards']),

    deleteRow(loyaltyRewardToChange: Object) {
      this.rowToDelete = loyaltyRewardToChange
      this.$store.commit(
        'program/catalog/deleteRow',
        this.loyaltyRewardsToChange.indexOf(this.rowToDelete)
      )
      this.$nextTick(() => {
        this.rowToDelete = Object.assign({}, {})
      })
    },
    addRow(rewardId: number) {
      const index = this.loyaltyRewardsRows.findIndex(
        (r: any) => r.loyalty_reward.id === rewardId
      )
      this.newRow = this.loyaltyRewardsRows[index]
      this.$store.commit(
        'program/catalog/addLoyaltyRewardsToChange',
        this.newRow
      )
    }
  }
})
