<template>
  <v-dialog v-model="isOpen" scrollable @click:outside="isOpen = false">
    <v-card>
      <v-card-title>
        {{ $t('pages.user.profile.earnedCoupons.title') | capitalize }}
      </v-card-title>

      <v-divider />

      <v-card-text>
        <div>
          <h2 class="title">
            {{
              $t('pages.user.profile.earnedCoupons.pending.title') | capitalize
            }}
          </h2>
          <div v-if="isLoadingPending">
            {{ $t('global.loading') | capitalize }}...
          </div>
          <div v-else-if="errorOnPending">
            <DisplayError
              :error="errorOnPending"
              retriable
              @retry="fetchEarnedCouponsPending({ userId })"
            />
          </div>
          <div v-else-if="!isLoadedPending" />
          <div v-else-if="earnedCouponsPending.length === 0">
            {{ $t('pages.user.profile.earnedCoupons.pending.none') }}
          </div>
          <ul v-else class="list list--inline list--no-style">
            <li
              v-for="earnedCoupon in earnedCouponsPending"
              :key="earnedCoupon.identifier"
            >
              <EarnedCouponPending :earned-coupon="earnedCoupon" />
            </li>
          </ul>
        </div>

        <div>
          <h2 class="title">
            {{ $t('pages.user.profile.earnedCoupons.used.title') | capitalize }}
          </h2>
          <div v-if="isLoadingUsed">
            {{ $t('global.loading') | capitalize }}...
          </div>
          <div v-else-if="errorOnUsed">
            <DisplayError
              :error="errorOnUsed"
              retriable
              @retry="fetchEarnedCouponsUsed({ userId })"
            />
          </div>
          <div v-else-if="!isLoadedUsed" />
          <table v-else class="table table--striped">
            <tr>
              <th>
                {{
                  $t('pages.user.profile.earnedCoupons.used.headers.status')
                    | capitalize
                }}
              </th>
              <th>
                {{
                  $t('pages.user.profile.earnedCoupons.used.headers.coupon')
                    | capitalize
                }}
              </th>
              <th>
                {{
                  $t(
                    'pages.user.profile.earnedCoupons.used.headers.progression'
                  ) | capitalize
                }}
              </th>
            </tr>
            <EarnedCouponUsed
              v-for="earnedCoupon in earnedCouponsUsed"
              :key="earnedCoupon.identifier"
              :earned-coupon="earnedCoupon"
            />
          </table>

          <EarnedCouponUsedFetchMore
            v-if="isLoadedUsed && hasMoreEarnedCouponUsed"
          />
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn color="blue darken-1" text @click="isOpen = false">
          {{ $t('global.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import EarnedCouponPending from './EarnedCouponPending'
import EarnedCouponUsed from './EarnedCouponUsed'
import EarnedCouponUsedFetchMore from './EarnedCouponUsedFetchMore'
import DisplayError from '@/components/layouts/DisplayError'
export default {
  components: {
    EarnedCouponPending,
    EarnedCouponUsed,
    EarnedCouponUsedFetchMore,
    DisplayError
  },
  data: () => ({
    isOpen: true
  }),
  computed: {
    ...mapState('user/profile/wallet/pending', {
      isLoadingPending: 'isLoading',
      isLoadedPending: 'isLoaded',
      errorOnPending: 'error',
      earnedCouponsPending: 'earnedCoupons'
    }),
    ...mapState('user/profile/wallet/used', {
      isLoadingUsed: 'isLoading',
      isLoadedUsed: 'isLoaded',
      errorOnUsed: 'error',
      earnedCouponsUsed: 'earnedCoupons',
      hasMoreEarnedCouponUsed: 'hasMore'
    }),
    userId() {
      return this.$route.params.userId
    }
  },
  watch: {
    isOpen(val) {
      if (!val) {
        this.$emit('close')
      }
    }
  },
  mounted() {
    const userId = this.userId
    this.fetchEarnedCouponsPending({ userId })
    this.fetchEarnedCouponsUsed({ userId })
  },
  methods: {
    ...mapActions('user/profile/wallet/pending', {
      fetchEarnedCouponsPending: 'fetch'
    }),
    ...mapActions('user/profile/wallet/used', {
      fetchEarnedCouponsUsed: 'fetch'
    })
  }
}
</script>

<style scoped>
.title {
  margin: 20px 10px;
}

.list--inline li {
  display: inline-block;
}
.list--no-style {
  list-style: none;
}

.table {
  width: 100%;
}
.table th {
  padding: 8px;
  line-height: 20px;
  text-align: left;
  vertical-align: bottom;
  border: 0;
}
.table--striped tr:nth-child(odd) > td {
  background-color: #f9f9f9;
}
</style>
