import { handleAxiosErrorServerFromStore } from '@/utils/functions/handle-errors'

export async function fetchProgramRules({ commit }, programIdentifier) {
  let response
  try {
    commit('setLoadingState', true)
    response = await this.$axios.get(`/programs/${programIdentifier}/rules`)
  } catch (error) {
    handleAxiosErrorServerFromStore({
      error,
      commit,
      store: this
    })
    return
  }
  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setLoadingState', false)
    return
  }
  commit('setProgramRules', response.data.result)
  commit('setLoadingState', false)
}
