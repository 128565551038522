export default {
  title: 'Connexion',
  password_reset_success: 'Mot de passe réinitaliser avec succès',
  labels: {
    email: 'Adresse Email',
    password: 'Mot de passe',
    stay_connected: 'Rester connecté',
    submit: 'Se connecter'
  }
}
