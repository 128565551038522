import Vue from 'vue'
import { cloneDeep, findIndex } from 'lodash-es'

import createDefaultState, { State, Precision } from './state'

export * from '@/utils/store/concerns/loadable/mutations.js'

export const reset = (state: State) => {
  Object.assign(state, createDefaultState())
}

export const handleFetchResult = (state: State, result: any) => {
  state.precisions = result.precisions
}

const createDefaultPrecision = (): Precision => ({
  id: null,
  title: '',
  image_url: null,
  image_file: null,
  display_rank: 0,
  expired_at: '',
  context_display: '',
  overrides_default: false,
  is_displayed_on_confirmation: false,
  is_obstrusive_on_selection: false
})

export const initWithDefaultPrecision = (state: State) => {
  state.original = createDefaultPrecision()
  state.modified = createDefaultPrecision()
}

export const initWithPrecision = (state: State, precision: Precision) => {
  state.original = cloneDeep(precision)
  state.modified = cloneDeep(precision)
  Vue.set(state.modified, 'image_url', null)
  Vue.set(state.modified, 'image_file', null)
}

export function updateAttribute(
  state: State,
  { key, value }: { key: string; value: any }
) {
  Vue.set(state.modified as Precision, key, value)
}

export const upsertPrecision = (state: State, precision: Precision) => {
  const index = findIndex(state.precisions, (p) => p.id === precision.id)

  if (index === -1) {
    state.precisions.push(precision)
  } else {
    Vue.set(state.precisions, index, precision)
  }
}
