import Vue from 'vue'
import moment from 'moment'
import { filter, uniq, findIndex } from 'lodash-es'

import eansTexteareaToArray from '@/utils/functions/forms/eansTexteareaToArray'
import { KIND_COUPON, KIND_FIDZ } from '@/store/program/catalog/state.js'

import { DISCOUNT_TYPE_PERCENT } from '@/components/program/catalog/create-modal/SlidePage2/Coupon.vue'

// TODO: get from backend
const DEFAULT_CONVERSION_RATE_POINTS_TO_FIDZ = 10

export * from '@/utils/store/concerns/loadable/mutations.js'

export const updateAttribute = (state, { key, value }) => {
  Vue.set(state.form, key, value)
}

export const setCatalog = (state, value) => {
  state.transporters = value.transporters
  state.loyaltyRewards = [...value.loyalty_rewards]
  state.loyaltyRewardsToChange = filter(value.loyalty_rewards, 'is_available')
  state.newLoyaltyRewardsModel = value.loyalty_rewards.filter(
    (lr) => lr.is_available === false
  )
  state.loyaltyRewardsRows = filter(value.loyalty_rewards, 'is_available')
}

export const updateReward = (state, reward) => {
  const index = findIndex(
    state.loyaltyRewards,
    (lr) => lr.loyalty_reward.identifier === reward.loyalty_reward.identifier
  )
  if (index === -1) return

  Vue.set(state.loyaltyRewards, index, reward)
}

export const setloyaltyRewardExclusivity = (state, payload) => {
  state.loyaltyRewardExclusivity = payload
}

export const resetRewards = (state) => {
  state.loyaltyRewards = []
}

// TODO: get from backend
const createDefaultGoody = () => ({
  transporter: '',

  exposedTitle: false,
  exposedLastName: false,
  exposedFirstName: false,
  exposedAddress1: false,
  exposedAddress2: false,
  exposedPostalCode: false,
  exposedCity: false,
  exposedExternalEmail: false,
  exposedPhoneNumber: false,
  exposedCustomLabel: false,

  mandatoryTitle: false,
  mandatoryLastName: false,
  mandatoryFirstName: false,
  mandatoryAddress1: false,
  mandatoryAddress2: false,
  mandatoryPostalCode: false,
  mandatoryCity: false,
  mandatoryExternalEmail: false,
  mandatoryPhoneNumber: false,
  mandatoryCustomLabel: false
})

export const initializeWithReward = (state, reward) => {
  state.reward = reward

  state.form = {
    id: reward.id,
    kind: reward.kind,

    name: reward.name,
    requiredPoints: reward.value,
    fidz: reward.fidz && {
      value: reward.fidz.value
    },
    cashback: reward.cashback && {
      value: reward.cashback.value
    },
    goody: reward.goody
      ? {
          transporter: reward.goody.transporter,

          exposedTitle: reward.goody.exposed_title,
          exposedLastName: reward.goody.exposed_last_name,
          exposedFirstName: reward.goody.exposed_first_name,
          exposedAddress1: reward.goody.exposed_address_1,
          exposedAddress2: reward.goody.exposed_address_2,
          exposedPostalCode: reward.goody.exposed_postal_code,
          exposedCity: reward.goody.exposed_city,
          exposedExternalEmail: reward.goody.exposed_external_email,
          exposedPhoneNumber: reward.goody.exposed_phone_number,
          exposedCustomLabel: reward.goody.exposed_custom_label,

          mandatoryTitle: reward.goody.mandatory_title,
          mandatoryLastName: reward.goody.mandatory_last_name,
          mandatoryFirstName: reward.goody.mandatory_first_name,
          mandatoryAddress1: reward.goody.mandatory_address_1,
          mandatoryAddress2: reward.goody.mandatory_address_2,
          mandatoryPostalCode: reward.goody.mandatory_postal_code,
          mandatoryCity: reward.goody.mandatory_city,
          mandatoryExternalEmail: reward.goody.mandatory_external_email,
          mandatoryPhoneNumber: reward.goody.mandatory_phone_number,
          mandatoryCustomLabel: reward.goody.mandatory_custom_label
        }
      : createDefaultGoody(),
    refund: reward.refund && {
      value: reward.refund.value,
      type: reward.refund.type,
      recommendedPublicPrice: reward.refund.recommended_public_price,
      eanPrimaryProducts: reward.refund.primary_product_eans,
      eanSecondaryProducts: reward.refund.secondary_product_eans
    },
    priority: reward.priority,

    illustrationFile: null,
    illustrationUrl: null,
    backgroundImageFile: null,
    backgroundImageUrl: null,

    adminDescription: reward.internal_description,
    formattedDescription: reward.formatted_description || reward.description,
    availableAt: reward.available_at,
    expiredAt: reward.expired_at
  }

  // in order to pass validation
  if (reward.refund && reward.refund.is_euros) {
    state.form.refund.value = parseInt(state.form.refund.value)
  }
}

export const initializeWithDefault = (state) => {
  state.form = {
    id: null,
    kind: KIND_COUPON,

    name: '',
    requiredPoints: null,
    fidz: {
      value: null
    },
    cashback: {
      value: null
    },
    refund: {
      value: null,
      type: DISCOUNT_TYPE_PERCENT,
      recommendedPublicPrice: null,
      eanPrimaryProducts: [],
      eanSecondaryProducts: []
    },
    goody: createDefaultGoody(),
    priority: null,
    illustrationFile: null,
    illustrationUrl: null,
    backgroundImageFile: null,
    backgroundImageUrl: null,
    adminDescription: '',
    formattedDescription: '',
    availableAt: '',
    expiredAt: ''
  }
}

export const duplicateReward = (state, reward) => {
  initializeWithReward(state, reward)
  state.form.id = null
  state.form.illustrationUrl =
    reward.image_original_url ||
    reward.image_3x_url ||
    reward.image_2x_url ||
    reward.image_1x_url ||
    reward.image_url
}

export const resetForm = initializeWithDefault

export const changeKind = (state, kind) => {
  state.form.kind = kind
}

export const changeName = (state, name) => {
  state.form.name = name
}

export const changeAvailableAt = (state, dateText) => {
  state.form.availableAt = moment(dateText).toISOString()
}

export const changeExpiredAt = (state, dateText) => {
  state.form.expiredAt = moment(dateText).toISOString()
}

export const changePriority = (state, priority) => {
  if (priority === '') {
    state.form.priority = null
    return
  }
  if (priority < 0) {
    state.form.priority = 0
    return
  }
  state.form.priority = priority
}

export const changeRequiredPoints = (state, points) => {
  if (points < 0) {
    state.form.requiredPoints = 0
    return
  }
  state.form.requiredPoints = points
}

export const changeRecommendedPublicPrice = (state, value) => {
  if (value < 0) {
    state.form.refund.recommendedPublicPrice = 0
    return
  }
  state.form.refund.recommendedPublicPrice = value
}

export const changeDiscountValue = (state, value) => {
  let newValue = value
  if (state.form.refund.type === DISCOUNT_TYPE_PERCENT) {
    newValue = parseInt(value)

    if (value > 100) {
      state.form.refund.value = 100
      return
    }
  }

  if (newValue < 0) {
    state.form.refund.newValue = 0
    return
  }

  state.form.refund.value = newValue
}

export const changeDiscountType = (state, type) => {
  state.form.refund.type = type
}

export const changePrimaryEanProducts = (state, value) => {
  state.form.refund.eanPrimaryProducts = uniq(eansTexteareaToArray(value))
}
export const changeSecondaryEanProducts = (state, value) => {
  state.form.refund.eanSecondaryProducts = uniq(eansTexteareaToArray(value))
}

export function changeEanProducts(state, { key, value }) {
  Vue.set(state.form.refund, key, uniq(eansTexteareaToArray(value)))
}

export const changeAdminDescription = (state, value) => {
  state.form.adminDescription = value
}

export const changeRewardFormattedDescription = (state, value) => {
  state.form.formattedDescription = value
}

export const illustrationSelect = (state, file) => {
  state.form.illustrationFile = file
  state.form.illustrationUrl = null
}

export const backgroundImageSelect = (state, file) => {
  state.form.backgroundImage = file
  state.form.backgroundImageUrl = null
}

export const initializeDiscountSelected = (state, loyaltyRewardId) => {
  state.discountSelected = {
    value: null,
    quota: 1,
    user_id: null,
    is_public: false,
    loyalty_reward_id: loyaltyRewardId,
    started_at: new Date(Date.now()).toISOString(),
    ended_at: new Date(Date.now()).toISOString(),
    created_at: new Date(Date.now()).toISOString(),
    updated_at: new Date(Date.now()).toISOString()
  }
}

export const setDiscountSelected = (state, discount) => {
  state.discountSelected = { ...discount }
}

export const resetDiscountSelected = (state) => {
  state.discountSelected = null
}

export const onChangeRewardDiscountValue = (state, points) => {
  if (points < 0) {
    state.discountSelected.value = 0
    return
  }

  state.discountSelected.value = parseInt(points)
}

export const onClickRewardDiscountPublic = (state, bool) => {
  state.discountSelected.is_public = bool
}

export const onSelectRewardDiscountUser = (state, id) => {
  state.discountSelected.user_id = id
}

export const onChangeRewardDiscountEndedAt = (state, dateText) => {
  state.discountSelected.ended_at = new Date(dateText).toISOString()
}

export const onChangeRewardDiscountStartedAt = (state, dateText) => {
  state.discountSelected.started_at = new Date(dateText).toISOString()
}

export const onChangeRewardDiscountQuota = (state, quota) => {
  state.discountSelected.quota = parseInt(quota)
}

export const deleteRow = function(state, rewardId) {
  state.loyaltyRewardsToChange.splice(rewardId, 1)
}

export const resetNewRewards = function(state) {
  state.newLoyaltyRewards = []
}

export const addNewLoyaltyReward = function(state, newReward) {
  state.newLoyaltyRewards.push(newReward)
}

export const addLoyaltyRewardsToChange = function(state, newReward) {
  state.loyaltyRewardsToChange.push(newReward)
}

export const deleteNewLoyaltyReward = function(state, newLoyaltyRewardIndex) {
  state.newLoyaltyRewards.splice(newLoyaltyRewardIndex, 1)
}

export const setCatalogPreview = function(state, value) {
  state.catalogPreview = value
}

export const updateDefautFidzValue = (state) => {
  if (state.form.kind !== KIND_FIDZ) return

  state.form.fidz.value =
    state.form.requiredPoints * DEFAULT_CONVERSION_RATE_POINTS_TO_FIDZ
}

export const changeFidzValue = (state, value) => {
  if (value < 0) {
    state.form.fidz.value = 0
    return
  }
  state.form.fidz.value = value
}
export const changeCashbackValue = (state, value) => {
  if (value < 0) {
    state.form.cashback.value = 0
    return
  }
  state.form.cashback.value = value
}

export function updateGoodyAttribute(state, { key, value }) {
  Vue.set(state.form.goody, key, value)
}

// in core console :
// FidzLoyaltyReward.all.uniq(&:fidz_value).map { |x| [x.fidz_value, x.image_url] }.sort_by(&:first).to_h
const imageUrlsByFidzValue = {
  300: 'http://skerou-com-assets.s3.amazonaws.com/images/fidzs/production/fidz-1855-6b33208a2f2c56535fb766de2ba2bc29.png',
  350: 'http://skerou-com-assets.s3.amazonaws.com/images/fidzs/production/fidz-2476-8964f008693632365bee553e7dd4920e.png',
  400: 'http://skerou-com-assets.s3.amazonaws.com/images/fidzs/production/fidz-2497-c4fb4c55528f709a942fefd1c25297c8.png',
  500: 'http://skerou-com-assets.s3.amazonaws.com/images/fidzs/production/fidz-2525-281c809573cf6908e180aa3b313e449b.png',
  600: 'http://skerou-com-assets.s3.amazonaws.com/images/fidzs/production/fidz-1598-61a6f96f3dc845b481b723163125ebf2.png',
  700: 'http://skerou-com-assets.s3.amazonaws.com/images/fidzs/production/fidz-2828-774ca59a027391737d94f6d80d7b3735.png',
  750: 'http://skerou-com-assets.s3.amazonaws.com/images/fidzs/production/fidz-1870-da885541479d554a90b1945b0afca336.png',
  800: 'http://skerou-com-assets.s3.amazonaws.com/images/fidzs/production/fidz-2457-10d9edbd2f44e1b1f3c36891460408e0.png',
  900: 'http://skerou-com-assets.s3.amazonaws.com/images/fidzs/production/fidz-2422-20385a96549ffd8ae9cc324680ce8138.png',
  1000: 'http://skerou-com-assets.s3.amazonaws.com/images/fidzs/production/fidz-1454-042ddfcc2b26a2b3fd4f52c3339055cf.png',
  1600: 'http://skerou-com-assets.s3.amazonaws.com/images/fidzs/production/fidz-2580-6d185853066ceb0ca99e9624e61498e5.png',
  2000: 'http://skerou-com-assets.s3.amazonaws.com/images/fidzs/production/fidz-2788-ab043a7e2b8c33b6dd0b015d06876f80.png'
}

export const updateDefaultFidzImageUrl = (state) => {
  state.form.illustrationUrl =
    imageUrlsByFidzValue[String(state.form.fidz.value)]
}

export const upsertDiscount = (state, { rewardId, discount }) => {
  const index = state.loyaltyRewards.findIndex(
    (reward) => reward.loyalty_reward.id === rewardId
  )
  if (index === -1) throw new Error('reward not found')

  if (!discount.is_public) {
    state.loyaltyRewards[index].targeted_discounts.push(discount)
    return
  }

  const index2 = state.loyaltyRewards[index].public_discounts.findIndex(
    (d) => d.id === discount.id
  )
  if (index2 === -1) {
    state.loyaltyRewards[index].public_discounts.push(discount)
  } else {
    Vue.set(state.loyaltyRewards[index].public_discounts, index2, discount)
  }
}

export const updatePartnerExclusivitiesOnReward = (
  state,
  { rewardIdentifier, selectedPartnerIdentifiers }
) => {
  const index = state.loyaltyRewards.findIndex(
    (reward) => reward.loyalty_reward.identifier === rewardIdentifier
  )
  if (index === -1) throw new Error('reward not found')

  state.loyaltyRewards[index].has_partner_exclusivities =
    selectedPartnerIdentifiers.length > 0
  state.loyaltyRewards[index].partner_exclusivities.forEach((partner) => {
    partner.has_exclusivity = selectedPartnerIdentifiers.includes(
      partner.identifier
    )
  })
}
