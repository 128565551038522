


























































































































































































































import Vue from 'vue'
import { mapMutations, mapActions, mapState } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import moment from 'moment'
import { capitalize, pick } from 'lodash-es'

import Discount from './interfaces/discount'

import { fetchCoreFromComponentAndHandleError } from '@/utils/functions/fetch-and-handle-errors'

import { momentFormat } from '~/plugins/filters'

const MODE_PUBLIC = 'public'
const MODE_TARGETED = 'targeted'

const createDiscount = (rewardId: Number, discount: Discount) => {
  if (!discount) {
    return {
      loyalty_reward_id: rewardId,

      value: null,
      quota: null,
      is_public: true,
      user_id: null,

      started_at: null,
      ended_at: null
    }
  }

  return {
    ...pick(discount, ['value', 'quota', 'is_public', 'user_id']),

    started_at:
      discount.started_at && moment(discount.started_at).format('YYYY-MM-DD'),
    ended_at:
      discount.ended_at && moment(discount.ended_at).format('YYYY-MM-DD')
  }
}

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider
  },
  props: {
    discount: {
      type: Object as () => Discount,
      default: null
    },
    rewardId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      MODE_PUBLIC,
      MODE_TARGETED,

      mode:
        !this.discount || this.discount.is_public ? MODE_PUBLIC : MODE_TARGETED,

      selectedUser: null as null | any,

      isMenuOpenForStartedAt: false,
      isMenuOpenForEndedAt: false,

      isNew: !this.discount,
      modified: createDiscount(this.rewardId, this.discount),

      isSaving: false,

      isDestroying: false
    }
  },
  computed: {
    ...mapState('user/search', ['users', 'isSearching']),

    text: {
      get(): string {
        return this.$store.state.user.search.text
      },
      set(val) {
        if (
          this.selectedUser &&
          this.selectedUser.user &&
          this.selectedUser.user.email === val
        ) {
          return
        }

        this.setText(val || '')
      }
    },

    formatedStartedAt(): string | null {
      return (
        this.modified.started_at &&
        momentFormat(this.modified.started_at, 'date')
      )
    },
    formatedEndedAt(): string | null {
      return (
        this.modified.ended_at && momentFormat(this.modified.ended_at, 'date')
      )
    }
  },
  watch: {
    mode() {
      this.modified.is_public = this.mode === MODE_PUBLIC
    },
    text: {
      immediate: true,
      handler() {
        this.search()
      }
    },
    selectedUser: {
      immediate: true,
      handler() {
        if (!this.selectedUser) return
        if (!this.selectedUser.user) return

        this.modified.user_id = this.selectedUser.user.id
      }
    }
  },
  methods: {
    ...mapActions('user/search', ['search', 'setText']),

    ...mapMutations('snackbar', ['addToast']),
    ...mapMutations('program/catalog', ['updateReward']),

    save() {
      return fetchCoreFromComponentAndHandleError({
        $vm: this,
        isLoadingKey: 'isSaving',
        errorInToast: true,

        axios: {
          method: 'POST',
          url: this.isNew
            ? '/loyalty_reward_discounts'
            : `/loyalty_reward_discounts/${this.discount.id}/update`,
          data: this.modified
        },

        onSuccess: (result: any) => {
          this.addToast({
            text: capitalize(
              this.$t(
                this.isNew
                  ? 'pages.program.catalog.discount.form.createdWithSuccess'
                  : 'pages.program.catalog.discount.form.updatedWithSuccess'
              ) as string
            ),
            timeout: 4000
          })
          this.$emit(this.isNew ? 'created' : 'updated', result.discount)
          this.updateReward(result.loyalty_reward)
        }
      })
    },

    destroy() {
      if (this.isDestroying) return

      if (
        !confirm(
          this.$t('global.confirmIrreversibleDestroy', {
            item: this.$t('pages.program.catalog.discount.thisPeriod')
          }) as string
        )
      ) {
        return
      }

      return fetchCoreFromComponentAndHandleError({
        $vm: this,
        isLoadingKey: 'isDestroying',
        errorInToast: true,

        axios: {
          method: 'POST',
          url: `/loyalty_reward_discounts/${this.discount.id}/destroy`
        },

        onSuccess: (result: any) => {
          this.addToast({
            text: capitalize(
              this.$t(
                'pages.program.catalog.discount.form.destroyedWithSuccess'
              ) as string
            ),
            timeout: 4000
          })
          this.$emit('destroyed')
          this.updateReward(result.loyalty_reward)
        }
      })
    }
  }
})
