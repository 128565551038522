export default {
  title: 'Rechercher un programme',
  searchLabel: 'Tapez "/" pour focus',
  noMatchingProgram: 'Aucun programme ne correspond à votre recherche'

  // search: {
  //   advanceSearch: 'Recherche avancée',

  //   keys: {
  //     external_id: 'ID externe',
  //     facebook_id: 'ID Facebook',

  //     external_email: 'email externe',

  //     email: 'email',
  //     first_name: 'prénom',
  //     last_name: 'nom',
  //     address_1: 'adresse',
  //     address_2: 'adresse (2)',
  //     postal_code: 'code postal',
  //     city: 'ville',
  //     phone_number: 'numéro de téléphone'
  //   }
  // }
}
