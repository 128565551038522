import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoaded),expression:"isLoaded"}]},[_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"loading":_vm.isLoading,"color":"secondary","dark":""},domProps:{"textContent":_vm._s(_vm.selectedPeriodName)}},'v-btn',attrs,false),on))]}}])},[_vm._v(" "),_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"value":_vm.selectedPeriodIdentifier}},[_vm._l((_vm.periodsAsList),function(item){return _c(item.is,{key:item.title,tag:"component",attrs:{"item":item}})}),_vm._v(" "),_c(VSubheader,{domProps:{"textContent":_vm._s(_vm.$t('layout.metrics.periods.custom'))}}),_vm._v(" "),_c(VListItem,{attrs:{"value":_vm.PERIOD_CUSTOM_IDENTIFIER},on:{"click":function($event){_vm.isRangeSelectionOpen = true}}},[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.$t('layout.metrics.periods.custom'))}})],1)],2)],1)],1),_vm._v(" "),_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"loading":_vm.isLoading,"color":"secondary","dark":""},domProps:{"textContent":_vm._s(_vm.selectedPeriodRangeAsText)}},'v-btn',attrs,false),on))]}}]),model:{value:(_vm.isRangeSelectionOpen),callback:function ($$v) {_vm.isRangeSelectionOpen=$$v},expression:"isRangeSelectionOpen"}},[_vm._v(" "),_c(VDatePicker,{attrs:{"type":_vm.datePickerType,"range":"","scrollable":"","light":"","selected-items-text":_vm.selectedItemsText,"max":_vm.formattedEndOfThisMonth,"locale":_vm.$i18n.locale},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c(VSpacer),_vm._v(" "),_c(VBtn,{attrs:{"text":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('global.cancel'))},on:{"click":function($event){_vm.isRangeSelectionOpen = false}}}),_vm._v(" "),_c(VBtn,{attrs:{"text":"","color":"primary"},domProps:{"textContent":_vm._s(_vm.$t('global.validate'))},on:{"click":function($event){return _vm.selectPeriodRange(_vm.dateRange)}}})],1)],1),_vm._v(" "),(_vm.refreshedAt.length > 0)?_c('p',{staticClass:"ma-0 caption text-end"},[_vm._v("\n    "+_vm._s(_vm.refreshDate)+"\n  ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }