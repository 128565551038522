export default {
  headers: {
    tree: 'Arborescence',
    groupProducts: 'produits du groupe',
    groupDenoms: 'libellés du groupe'
  },
  addChild: 'ajouter un enfant',
  addTopLevelGroup: 'ajouter un groupe à la racine',
  confirmDeletion: 'Êtes vous sûr de vouloir supprimer ce groupe ?'
}
