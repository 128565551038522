import Vue from 'vue'
import { fromPairs, toPairs } from 'lodash-es'

import createDefaultState, { FIELDS } from './state'

import { CANCEL_TOKEN_FOR_REPEATED_CALL } from '@/utils/constants/cancel-tokens'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export const setSearchingState = (state, boolean) => {
  state.isSearching = boolean
}
export const setSearchedState = (state, boolean) => {
  state.hasBeenSearched = boolean
}

export const setError = (state, text) => {
  state.error = text
}
export const resetError = (state) => {
  state.error = null
}

export const setText = (state, text) => {
  state.text = text
}

export const resetPagination = (state) => {
  state.hasMore = null
  state.nextPaginationKey = null
  state.page = 1
}

export const focus = (state) => {
  state.isFocused = true
}

export const cancelPreviousRequest = (state) => {
  state.cancelToken.cancel(CANCEL_TOKEN_FOR_REPEATED_CALL)
  state.cancelToken = null
}

export const setCancelToken = (state, source) => {
  state.cancelToken = source
}

export const setData = (state, data) => {
  state.users = state.users.concat(data.users)
  state.hasMore = data.has_more
  state.nextPaginationKey = data.next_pagination_key
  state.matchStart = data.match_start
  state.matchEnd = data.match_end
}
export const setKeywords = (state, params) => {
  if (state.isAdvanceSearch) {
    state.keywords = fromPairs(
      toPairs(state.fields).map(([key, value]) => [key, value.split(/\s/)])
    )
  } else {
    state.keywords = fromPairs(FIELDS.map((f) => [f, params.query.split(/\s/)]))
  }
}

export const setUsers = (state, users) => {
  state.users = users
}
export const setPage = (state, page) => {
  state.page = page
}

export const setfetchingMoreState = (state, boolean) => {
  state.isfetchingMore = boolean
}

export const setAdvanceSearch = (state, boolean) => {
  state.isAdvanceSearch = boolean
}
export const toggleAdvanceSearch = (state) => {
  state.isAdvanceSearch = !state.isAdvanceSearch

  if (state.isAdvanceSearch) {
    Object.assign(state, createDefaultState())
    state.isAdvanceSearch = true
  }
}
export const setField = (state, { key, value }) => {
  Vue.set(state.fields, key, value)
}
