
























































import Vue from 'vue'
import { mapMutations } from 'vuex'
import Discount from './interfaces/discount'
import EditDiscount from './EditDiscount.vue'
import { momentFormat } from '~/plugins/filters'
export default Vue.extend({
  components: {
    EditDiscount
  },
  props: {
    discounts: {
      type: Array as () => Discount[],
      required: true,
      default: () => []
    },
    rewardId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    panel: -1
  }),
  methods: {
    momentFormat,

    ...mapMutations('program/catalog', ['upsertDiscount'])
  }
})
