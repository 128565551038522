<template>
  <DeclarationsListLayout>
    <DeclarationsListLoadingItem v-for="int in [0, 1, 2]" :key="int" />
  </DeclarationsListLayout>
</template>

<script>
import DeclarationsListLayout from './ListLayout'
import DeclarationsListLoadingItem from './ListLoadingItem'
export default {
  components: { DeclarationsListLayout, DeclarationsListLoadingItem }
}
</script>
