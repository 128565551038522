<template>
  <!--
    can be improve with a queue system
    https://github.com/ajanes93/vuetify-snackbar-queue/blob/master/src/components/VSnackbarQueue.vue
    /!\ do not copy it, it uses "underscore" and there is a problem with timeouts
    https://github.com/ajanes93/vuetify-snackbar-queue/issues/3
    we can copy it and improve it
  -->
  <v-snackbar
    v-model="active"
    :bottom="y === 'bottom'"
    :color="color"
    :left="x === 'left'"
    :multi-line="mode === 'multi-line'"
    :right="x === 'right'"
    :timeout="timeout"
    :top="y === 'top'"
    :vertical="mode === 'vertical'"
  >
    {{ text }}
    <v-btn class="white--text" text @click="active = false">
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('snackbar', ['text', 'color', 'mode', 'x', 'y', 'timeout']),

    active: {
      get() {
        return this.$store.state.snackbar.active
      },
      set(newValue) {
        if (newValue) return

        this.$store.commit('snackbar/reset')
      }
    }
  }
}
</script>
