<template>
  <div v-if="error">
    <DisplayError :error="error" retriable @retry="fetchConfigIfNotLoaded" />
  </div>
  <div v-else-if="isLoaded && !currentPeriod">
    <LinkOrDiv
      :condition="!$route.path.includes('periods')"
      :to="`${HOME_PROGRAMS}/${selectedProgram.identifier}/periods`"
    >
      <v-alert
        dense
        border="bottom"
        colored-border
        type="warning"
        elevation="2"
        class="ma-0 mr-5"
      >
        {{ $t('layout.programHasNoCurrentPeriod') }}
      </v-alert>
    </LinkOrDiv>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import LinkOrDiv from '~/components/layouts/LinkOrDiv'
import DisplayError from '~/components/layouts/DisplayError'
import { HOME_PROGRAMS } from '~/utils/constants/routes'

export default {
  components: { DisplayError, LinkOrDiv },
  data: () => ({
    HOME_PROGRAMS
  }),
  computed: {
    ...mapState('auth', ['selectedProgram']),
    ...mapGetters('auth', ['selectedProgramIdentifierForAnalytics']),

    ...mapState('metrics/program', ['isLoaded', 'error', 'currentPeriod'])
  },
  mounted() {
    this.fetchConfigIfNotLoaded()
  },
  methods: {
    ...mapActions('metrics/program', ['fetchConfigIfNotLoaded'])
  }
}
</script>
