<template>
  <v-autocomplete
    v-model="selected"
    :items="items"
    :loading="isLoading"
    :search-input.sync="text"
    :label="$t('global.brand') | capitalize"
    :no-data-text="noDataText"
    item-value="identifier"
    item-text="name"
    :error-messages="errorMessages"
    outlined
    @blur="onBlur"
  />
</template>

<script>
import Vue from 'vue'
import { find } from 'lodash-es'

import MixinSelectBrand from '@/mixins/selectBrand.js'
import hasOwnProp from '@/utils/functions/hasOwnProp'

export default Vue.extend({
  mixins: [MixinSelectBrand],
  props: {
    value: {
      type: String,
      default: ''
    },
    initialBrand: {
      type: Object,
      validator: (o) => hasOwnProp(o, 'identifier') && hasOwnProp(o, 'name'),
      default: null
    }
  },
  data() {
    return {
      text: this.initialBrand && this.initialBrand.name,
      isLoaded: false,
      isLoading: false,
      items: this.getDefaultItems(),
      selected: this.value,
      cancelToken: null
    }
  },
  computed: {
    selectedItem() {
      if (!this.selected) return

      return find(this.items, (item) => item.identifier === this.selected)
    }
  },
  watch: {
    value(val) {
      if (val === this.selected) return

      this.selected = val
    },
    selected() {
      this.$emit('input', this.selected)
    }
  },
  methods: {
    getDefaultItems() {
      return this.initialBrand ? [this.initialBrand] : []
    },

    onBlur() {
      if (
        !this.text ||
        (this.selectedItem && this.selectedItem.name !== this.text)
      ) {
        this.selected = null
        this.reset()
      }
    }
  }
})
</script>
