







import Vue from 'vue'
import SlidePage1 from '../SlidePage1.vue'
import SlidePage2 from '../SlidePage2.vue'
export default Vue.extend({
  components: {
    SlidePage1,
    SlidePage2
  }
})
