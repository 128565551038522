import { CancelToken } from 'axios'
export default function cancelPreviousRequestAndGetNewCancelToken({
  state,
  commit
}) {
  if (state.cancelToken) {
    commit('cancelPreviousRequest')
  }
  const source = CancelToken.source()
  commit('setCancelToken', source)
  return source.token
}
