











































































import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import { Reward } from './RewardItem.vue'
export default Vue.extend({
  props: {
    rowId: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    showModal: false,
    selectedReward: null
  }),
  computed: {
    ...mapState('program/catalog/shelf', [
      'isSearching',
      'rewardsWithoutShelf'
    ]),
    rewards: {
      get(): Array<Reward> {
        return this.rewardsWithoutShelf
      }
    }
  },
  beforeDestroy() {
    this.selectedReward = null
  },
  methods: {
    ...mapActions('program/catalog/shelf', ['addRewardToShelfRow'])
  }
})
