export default {
  permissions: {
    user_read: {
      label: 'Visualiser les informations liées aux clients',
      description:
        "Permet de visualiser les informations concernants les clients (preuves d'achats, virements, fraudes...) "
    },
    user_write: {
      label: "Actions d'interaction avec les clients",
      description:
        "Permet d'interagir avec les preuves d'achats des clients du programme"
    },
    content_read: {
      label: "Visualiser les différents contenus d'un programme",
      description: 'Permet de visualiser le catalogue, images du programme'
    },
    content_write: {
      label: 'Actions de modification du contenu du programme',
      description: 'Permet de modifier les informations du programme'
    },
    animation_read: {
      label: "Visualiser les éléments d'animation du programme",
      description:
        "Permet de visualiser les différents évènements d'animation du programme (segment, messages, emails...)"
    },
    animation_write: {
      label: "Actions d'animation du programme",
      description:
        "Permet de créer/modifier les différents évènements d'animation du programme (segment, messages, emails...)"
    },
    metrics_read: {
      label: 'Voir les différentes métriques du programme et de ses canaux',
      description: ''
    },
    manager_read: {
      label: 'Visualiser les informations des administrateurs du programme',
      description:
        "Permet d'accéder aux informations des administrateurs du programme"
    },
    manager_write: {
      label: 'Modification des administrateurs',
      description:
        "Permet d'éditer les droits/demander un changement de mot de passe des administrateurs du programme"
    }
  }
}
