import createDefaultState from './state'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export const setFile = (state, file) => {
  state.file = file
}
export const handleFileAnalysis = (state, result) => {
  state.reports = result.reports
  state.reportsHeaders = result.reports_header
  state.tree = result.tree
}
