import { some, flatMap } from 'lodash-es'

function recursiveTransform(object) {
  if (Array.isArray(object)) {
    return object.map((productName) => ({
      id: self.crypto.randomUUID(),
      name: productName
    }))
  }

  return Object.entries(object).map(([key, value]) => ({
    id: self.crypto.randomUUID(),
    name: key,
    children: recursiveTransform(value)
  }))
}

export const treeForVuetity = (state) => {
  if (!state.tree) return []

  return recursiveTransform(state.tree)
}

function recursiveGetIds(array) {
  return flatMap(array, (node) => {
    const ids = [node.id]
    if (node.children) ids.push(...recursiveGetIds(node.children))
    return ids
  })
}

export const allNodeIds = (_, getters) => {
  return recursiveGetIds(getters.treeForVuetity)
}

export const hasErrors = (state) => {
  return some(
    state.reports,
    (report) => report.is_ean_dupe || !report.is_brand_found
  )
}
