import { TAB_POINTS_OF_SALE } from '~/components/layouts/metrics/canals/Bar.vue'
import cancellableFetchFromStore from '~/utils/functions/analytics/cancellableFetch/fromStore'
import { PROGRAM_SCANOBAR_IDENTIFIED_FOR_ANALYTICS } from '~/utils/constants/program-ids'
export const fetch = function(ctx) {
  return cancellableFetchFromStore({
    ctx,
    store: this,
    tab: TAB_POINTS_OF_SALE,
    proxy: {
      method: 'post',
      path:
        'internal_api/sas_bo_front_end/v1/app_metrics/activated_points_of_sale',
      data: {
        loyalty_program_identifiers: [
          PROGRAM_SCANOBAR_IDENTIFIED_FOR_ANALYTICS
        ],
        start_date:
          ctx.rootGetters['metrics/program/selectedPeriodStartDateFormatted'],
        end_date:
          ctx.rootGetters['metrics/program/selectedPeriodEndDateFormatted']
      }
    }
  })
}

export const refresh = fetch
