export async function scrollContainerTo({ state, commit }, anchor) {
  commit('setScrollingDoneState', false)
  commit('setScrollAnchor', anchor)
  commit('setScrollingState', true)

  await this.$router.app.$vuetify.goTo(anchor, {
    container: state.scrollContainer
  })

  commit('setScrollingDoneState', true)
  commit('setScrollingState', false)

  setTimeout(() => {
    commit('setScrollAnchor', null)
    commit('setScrollingDoneState', false)
  }, 1000)
}
