import { createLoadableState } from '@/utils/store/concerns/loadable/state'

export const PROGRAM_KIND_POINTS_VIA_PRICE = 'points'
export const PROGRAM_KIND_POINTS_BY_PURCHASE = 'one_point_by_purchase'
export const PROGRAM_KIND_STAMPS = 'stamps'
export const PROGRAM_KIND_PUZZLE = 'puzzle'
export const PROGRAM_KIND_COLLECTOR = 'collector'
export const PROGRAM_KIND_FIDZ = 'fidz'
export const PROGRAM_KIND_CASHBACK = 'cashback'

export const ALL_PROGRAM_KINDS = [
  PROGRAM_KIND_POINTS_VIA_PRICE,
  PROGRAM_KIND_POINTS_BY_PURCHASE,
  PROGRAM_KIND_STAMPS,
  PROGRAM_KIND_PUZZLE,
  PROGRAM_KIND_COLLECTOR,
  PROGRAM_KIND_FIDZ,
  PROGRAM_KIND_CASHBACK
]

export const ALL_PROGRAM_GENERIC_KINDS = [
  PROGRAM_KIND_POINTS_VIA_PRICE,
  PROGRAM_KIND_POINTS_BY_PURCHASE,
  PROGRAM_KIND_STAMPS,
  PROGRAM_KIND_PUZZLE,
  PROGRAM_KIND_CASHBACK
]

export const PROGRAM_KINDS_WITH_FIELD_REQUIRED_POINTS = [
  PROGRAM_KIND_POINTS_VIA_PRICE,
  PROGRAM_KIND_POINTS_BY_PURCHASE,
  PROGRAM_KIND_FIDZ
]

export const PROGRAM_KINDS_WITH_POINTS = [
  PROGRAM_KIND_POINTS_VIA_PRICE,
  PROGRAM_KIND_POINTS_BY_PURCHASE
]

export default () =>
  createLoadableState({
    program: null
  })
