import {
  handleAxiosErrorServerFromStore,
  handleAxiosErrorFrontendFromStore
} from '@/utils/functions/handle-errors'
import mockedHeader from '@/utils/mocked-data/program/header'

export const fetchProgramByIdentifier = async function(
  { commit },
  { programIdentifier }
) {
  const isMocked = false

  commit('setLoadingState', true)
  commit('setProgram', null)
  await commit('resetError')

  let response
  try {
    if (isMocked) {
      response = mockedHeader
    } else {
      response = await this.$axios.get(
        `/programs/${programIdentifier}/config_header`
      )
    }
  } catch (error) {
    handleAxiosErrorServerFromStore({ error, commit, store: this })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStore({ response, commit, store: this })
    return
  }

  commit('setProgram', response.data.result)
  commit('setLoadedState', true)
  commit('setLoadingState', false)
}
