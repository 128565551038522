<template functional>
  <nuxt-link
    v-if="props.condition && props.to"
    :to="props.to"
    :target="props.target"
    class="text-decoration-none"
  >
    <slot />
  </nuxt-link>
  <a
    v-else-if="props.condition && props.href"
    :href="props.href"
    :target="props.target"
    class="text-decoration-none"
  >
    <slot />
  </a>
  <div v-else>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    condition: {
      type: Boolean,
      required: true
    },
    to: {
      type: String,
      default: undefined
    },
    href: {
      type: String,
      default: undefined
    },
    target: {
      type: String,
      default: undefined
    }
  }
}
</script>
