// inspired by https://github.com/lluvio/vue-logger
const Logger = {}
const logger = {
  dev: process.env.NODE_ENV !== 'production',
  prefix: '', // () => new Date(),
  levels: ['log', 'warn', 'debug', 'error', 'dir', 'info']
}
for (const level of logger.levels) {
  Logger[level] = function() {
    if (!logger.dev || typeof console === 'undefined') return

    // eslint-disable-next-line no-console
    const polyfill = console[level] || console.log
    const args = Array.prototype.slice.apply(arguments)
    const prefix =
      logger.prefix &&
      (typeof logger.prefix === 'function' ? logger.prefix() : logger.prefix)
    args.unshift(
      (prefix ? `[${prefix} :: ${level}]` : `[${level}]`).toUpperCase()
    )
    polyfill.apply(console, args)
  }
}
export default Logger
