export const nuxtServerInit = async function({ dispatch }, { req }) {
  if (!req.session) return

  // program must be set before admin
  // (cause admin with only one permitted program will reset it)
  if (req.session.program) {
    await dispatch('auth/setProgramInStores', req.session.program)
  }
  if (req.session.admin) {
    await dispatch('auth/setAdmin', {
      admin: req.session.admin,
      skipSession: true
    })
  }
}
