import { CANCEL_TOKEN_FOR_REPEATED_CALL } from '@/utils/constants/cancel-tokens'
import cancelPreviousRequestAndGetNewCancelToken from '@/utils/functions/cancelPreviousRequestAndGetNewCancelToken/forStore'

import { handleErrorAxiosCoreStore } from '@/utils/functions/handle-errors'
import { UNKNOWN_ERROR_USER } from '@/utils/constants/errors'
import { HOME_USERS } from '@/utils/constants/routes'

export const setText = function({ commit }, text) {
  commit('resetPagination')
  commit('setText', text)
}

export const search = async function({ state, getters, commit }) {
  commit('setSearchingState', true)
  commit('setUsers', [])
  commit('resetError')

  const cancelToken = cancelPreviousRequestAndGetNewCancelToken({
    state,
    commit
  })

  const params = { ...getters.paramsForBackend, use_v2: true }
  // const params = { ...getters.paramsForBackend }

  if (!state.text) {
    commit('setData', { users: [] })
    commit('setKeywords', params)
    commit('setSearchingState', false)
    commit('setSearchedState', true)
    return
  }

  let response
  try {
    response = await this.$axios.get('/users', {
      params,
      cancelToken
    })
  } catch (error) {
    if (error.message === CANCEL_TOKEN_FOR_REPEATED_CALL) return
    handleErrorAxiosCoreStore({
      error,
      commit,
      store: this,
      loadingMutationName: 'setSearchingState'
    })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setError', UNKNOWN_ERROR_USER)
    commit('setSearchingState', false)
    return
  }

  commit('setData', response.data.result)
  commit('setKeywords', params)
  commit('setSearchingState', false)
  commit('setSearchedState', true)
}

export const fetchMore = async function({ state, getters, commit }) {
  if (state.isfetchingMore) return

  commit('setfetchingMoreState', true)
  commit('resetError')

  const params = {
    ...getters.paramsForBackend,
    use_v2: true,
    page: state.page,
    pagination_key: state.nextPaginationKey
  }

  let response
  try {
    response = await this.$axios.get('/users', { params })
  } catch (error) {
    handleErrorAxiosCoreStore({
      error,
      commit,
      store: this,
      loadingMutationName: 'setfetchingMoreState'
    })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setError', UNKNOWN_ERROR_USER)
    commit('setfetchingMoreState', false)
    return
  }

  commit('setData', response.data.result)
  commit('setPage', state.page + 1)
  commit('setfetchingMoreState', false)
}

export const toggleAdvanceSearch = function({ state, commit }) {
  if (!state.isAdvanceSearch) {
    commit('reset')
  }

  commit('setAdvanceSearch', !state.isAdvanceSearch)

  if (this.$router.currentRoute.path !== HOME_USERS) {
    this.$router.push(HOME_USERS)
  }
}
