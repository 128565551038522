<template>
  <LayoutDefault :title="title" :extension-height="extensionHeight">
    <template v-if="programIsSelected" #app-bar__extension class="ma-0">
      <LayoutProgramBar
        v-if="programIsSelected"
        :height="heightProgramBarHeader"
      />
    </template>

    <template #default>
      <slot v-if="$scopedSlots.default" />
      <nuxt v-else />
    </template>
  </LayoutDefault>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import LayoutDefault from '../default'
import LayoutProgramBar from '~/components/layouts/program/Bar'

export default {
  components: {
    LayoutDefault,
    LayoutProgramBar
  },

  computed: {
    ...mapState('program', ['isLoaded', 'program']),

    programIsSelected() {
      return !!this.$route.params.programIdentifier
    },
    title() {
      return this.programIsSelected
        ? this.$t('pages.program.title')
        : this.$t('pages.programs.title')
    },

    heightProgramBarHeader() {
      return 78
    },
    heightProgramBarTabs() {
      return 48
    },
    extensionHeight() {
      if (this.programIsSelected) {
        return this.heightProgramBarHeader + this.heightProgramBarTabs
      }

      return 0
    }
  },
  watch: {
    program: 'fetchProgramIfNeeded',
    $route: 'fetchProgramIfNeeded'
  },
  mounted() {
    this.fetchProgramIfNeeded()
  },
  methods: {
    ...mapActions('program', ['fetchProgramByIdentifier']),

    fetchProgramIfNeeded() {
      if (!this.programIsSelected) return

      const programIdentifier = this.$route.params.programIdentifier
      if (this.program && this.program.identifier === programIdentifier) return

      this.fetchProgramByIdentifier({ programIdentifier })
    }
  }
}
</script>
