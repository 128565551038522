<template>
  <v-dialog
    :value="value"
    scrollable
    max-width="500px"
    @input="$emit('input', $event)"
  >
    <ValidationObserver v-slot="{ handleSubmit }" slim>
      <v-card>
        <v-card-title>
          {{ $t(`crud.offers.edit.products.${action}.title`) }}
          <v-spacer />
          <v-btn
            :href="`https://fr.openfoodfacts.org/produit/${ean}`"
            target="_blank"
          >
            OpenFoodFacts
            <v-icon right>mdi-open-in-new</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider />

        <v-card-text style="height: 300px;" class="pt-3">
          <v-text-field
            disabled
            :label="$t('crud.offers.edit.products.update.fields.ean.label')"
            :value="ean"
            outlined
          />

          <ValidationProvider
            v-slot="{ errors }"
            name="productName"
            mode="eager"
            rules="required"
            slim
          >
            <v-text-field
              v-model="fields.name"
              :label="$t('crud.offers.edit.products.update.fields.name.label')"
              :error-messages="errors"
              outlined
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            name="productNameShort"
            mode="eager"
            rules="required|max:14"
            slim
          >
            <v-text-field
              v-model="fields.nameShort"
              :counter="14"
              :label="
                $t('crud.offers.edit.products.update.fields.nameShort.label')
              "
              :hint="
                $t('crud.offers.edit.products.update.fields.nameShort.hint')
              "
              :error-messages="errors"
              outlined
            />
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            name="productBrand"
            mode="eager"
            rules="required"
            slim
          >
            <SelectBrand
              v-model="fields.brandIdentifier"
              :initial-brand="
                product &&
                  product.brand_identifier && {
                    identifier: product.brand_identifier,
                    name: product.brand_name
                  }
              "
              :error-messages="errors"
            />
          </ValidationProvider>

          <v-container>
            <v-row>
              <v-col>
                <h3 class="mb-2">
                  {{
                    $t(
                      product && product.image_url
                        ? 'crud.offers.edit.products.update.illustration.current'
                        : 'crud.offers.edit.products.update.illustration.noCurrent'
                    )
                  }}
                </h3>
                <v-sheet
                  v-if="product && product.image_url"
                  outlined
                  tile
                  height="200"
                  width="200"
                  class="mb-3"
                >
                  <v-img :src="product.image_url" />
                </v-sheet>
              </v-col>

              <v-col>
                <h3 class="mb-2 d-flex">
                  {{
                    $t(
                      product && product.image_url
                        ? 'crud.offers.edit.products.update.illustration.change'
                        : 'crud.offers.edit.products.update.illustration.add'
                    )
                  }}

                  <template v-if="product && imageKind">
                    <v-spacer />
                    <v-icon small @click="imageKind = null">mdi-close</v-icon>
                  </template>
                </h3>

                <v-radio-group
                  v-model="imageKind"
                  column
                  class="mt-0"
                  :mandatory="!product"
                  @change="resetImage"
                >
                  <v-radio
                    :label="
                      $t(
                        'crud.offers.edit.products.update.illustration.with.file'
                      )
                    "
                    :value="IMAGE_KIND_FILE"
                  />
                  <v-radio
                    :label="
                      $t(
                        'crud.offers.edit.products.update.illustration.with.remoteUrl'
                      )
                    "
                    :value="IMAGE_KIND_URL"
                  />
                </v-radio-group>

                <ValidationProvider
                  v-if="imageKind === IMAGE_KIND_FILE"
                  v-slot="{ errors }"
                  name="productImageFile"
                  mode="eager"
                  rules="required"
                  slim
                >
                  <v-file-input
                    v-model="illustration.file"
                    accept="image/*"
                    clearable
                    :error-messages="errors"
                  />
                </ValidationProvider>
                <ValidationProvider
                  v-if="imageKind === IMAGE_KIND_URL"
                  v-slot="{ errors }"
                  name="productImageUrl"
                  mode="eager"
                  rules="required"
                  slim
                >
                  <v-text-field
                    v-model="illustration.remoteUrl"
                    prepend-icon="mdi-open-in-new"
                    clearable
                    :error-messages="errors"
                  />
                </ValidationProvider>

                <v-sheet
                  v-if="newImageUrl"
                  outlined
                  tile
                  height="200"
                  width="200"
                >
                  <v-img :src="newImageUrl" />
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />

          <v-btn @click="close">
            {{ $t('global.cancel') }}
          </v-btn>
          <v-btn
            :loading="isSaving"
            color="blue darken-1 white--text"
            @click="handleSubmit(save)"
          >
            {{ $t('global.validate') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import { capitalize } from 'lodash-es'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import SelectBrand from '~/components/shared/SelectBrand.vue'
import MixinAjaxToCore from '@/mixins/ajaxToCore'

import toFormData from '~/utils/functions/toFormData'

const IMAGE_KIND_FILE = 'file'
const IMAGE_KIND_URL = 'url'

export default {
  components: { SelectBrand, ValidationObserver, ValidationProvider },
  mixins: [MixinAjaxToCore],
  props: {
    value: {
      type: Boolean,
      required: true
    },
    ean: {
      type: String,
      required: true
    },
    product: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      IMAGE_KIND_FILE,
      IMAGE_KIND_URL,

      isSaving: false,
      imageKind: null,

      fields: {
        name: this.product && this.product.name,
        nameShort: this.product && this.product.name_short,
        brandIdentifier: this.product && this.product.brand_identifier
      },
      illustration: {
        file: null,
        remoteUrl: null
      }
    }
  },
  computed: {
    imageFileUrl() {
      return (
        this.illustration.file && URL.createObjectURL(this.illustration.file)
      )
    },
    newImageUrl() {
      return this.imageFileUrl || this.illustration.remoteUrl
    },

    action() {
      return this.product ? 'update' : 'create'
    }
  },
  methods: {
    ...mapMutations('snackbar', ['addToast']),
    ...mapMutations('products-by-ean', ['setProduct']),

    resetImage() {
      this.illustration.file = undefined
      this.illustration.remoteUrl = undefined
    },
    close() {
      this.$emit('input', false)
    },
    save() {
      return this.fetchCoreFromComponentAndHandleError({
        isLoadingKey: 'isSaving',
        errorInToast: true,

        axios: {
          url: `/products/${this.ean}/${this.action}`,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: toFormData({
            name: this.fields.name,
            name_short: this.fields.nameShort,
            brand_identifier: this.fields.brandIdentifier,
            illustration: {
              file: this.illustration.file,
              remote_url: this.illustration.remoteUrl
            }
          })
        },

        onSuccess: (result) => {
          this.addToast({
            text: capitalize(
              this.$t(`crud.offers.edit.products.${this.action}.saved`)
            ),
            timeout: 4000
          })
          this.close()
          this.setProduct({
            ean: this.ean,
            product: result.product
          })
        }
      })
    }
  }
}
</script>
