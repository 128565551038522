export default {
  general: {
    new: {
      sales: 'CA généré',
      purchases: 'Produits vendus',
      declarants: "Nombre d'acheteurs",
      subscribers: "Nombre d'inscrits",
      activated: "Nombre d'activés",
      uniqActs: "Preuves d'achat distinctes",

      channels: {
        gsa: 'en GSA',
        chd: 'en bar',
        both_surfaces: 'multi-réseaux'
      }
    },

    sinceBeginning: 'Depuis le {date}',
    refreshedAt: 'Données mises à jour le {date}',
    selectedPeriod: 'Période sélectionnée',
    periodTooltip: 'Mettre en avant les données de la periode personnalisée',
    everTooltip: 'Mettre en avant les données',
    generatedSales: 'CA généré',
    purchasesInGSA: 'Achats GSA',
    purchasesInBars: 'Achats Bars',
    glassScanned: 'Verres scannés',

    subscribers: 'Inscrits',
    actives: 'Actifs',
    activated: 'Activés',
    uniqActs: "Nombre de preuves d'achat distinctes",
    allActs: "Nombre d'achats",
    activatedActives: 'Activés/actifs',
    activatedGSA: 'Activés GSA',
    activatedCHD: 'Activés Bar',
    activatedBoth: 'Activés toutes surfaces',

    newConversations: 'Nouvelles conversations',
    acceptationsCgu: 'Acceptations cgus',
    providedEmails: 'Emails',
    gsaUniqDeclarants: 'Acheteurs unique GSA',
    gsaSubscribers: 'Première déclaration en GSA',
    chdSubscribers: 'Première déclaration en bars',
    chdUniqDeclarants: 'Acheteurs unique Bar',
    both_declarants: 'Acheteurs sur toutes les surfaces',
    optinsNl: 'Optins NL',
    purchasesSurface: 'Nombre de produits achetés en {surface}',
    allPurchases: 'Nombre de produits achetés',
    scanoSpecificHeader: 'Données Messenger',

    sales: 'Ventes',
    purchases: 'Ventes',
    declarants: 'Acheteurs',

    legends: {
      header:
        'La valeur en haut correspond à celle sélectionnée, cliquer sur le boutons avec les flèches pour intervertir de période',
      uniqActsSurface: "Nombre de preuves d'achat distinctes en {surface}",
      allPurchases: "Cumul des achats sur l'ensemble des surfaces",
      purchasesSurface:
        "L'ensemble des achats réalisés en {surface} sur la période donnée",
      declarants: 'Acheteurs ayant réalisé au moins un achat sur la période',
      declacrantsUniqSurface:
        "Acheteurs ayant réalisé au moins un achat en {purchaseSurface} et n'ayant réalisé aucun en {otherSurface} sur la période",
      generatedSales:
        'CA généré : montant total dépensé, mesuré par les déclarations.',
      generatedSalesScano:
        'Dans le cas des achats déclarés par scan de verre, un montant forfaitaire est appliqué',
      purchasesInGSA: 'Achats GSA : nombre de produits achetés',
      activatedUniqInGSA:
        "Activés GSA: nombre d'utilisateurs ayant fait leur première déclaration en GMS et aucune en BAR sur la période ",
      activatedUniqInCHD:
        "Activés Bar: nombre d'utilisateurs ayant fait leur première déclaration en BAR et aucune en GMS sur la période",
      activatedBoth:
        "Activés toutes surfaces: nombre d'utilisateurs ayant fait leur première déclaration et déclaré sur toutes les surfaces sur la période",

      purchasesInBars:
        'Achats Bars : nombre de boissons consommées. 1 par verre scanné, possiblement plusieurs par ticket',
      subscribers:
        "Personnes inscrites, qu'elles aient ou non gagné des points",
      activated:
        "Activés : nombre d'utilisateurs ayant fait leur première déclaration (avec points) pendant la période considérée",
      actives:
        "Actifs Période : nombre d'utilisateurs ayant fait au moins une déclaration (avec points) pendant la période considérée",
      declarantsBoth:
        "Nombre d'utilisateurs ayant déclaré sur toutes les surfaces pendant la période considérée"
    }
  },
  generosity: {
    spent: 'dépense générosité',
    total: 'total',

    scanobar: {
      pointsStock: 'Stock points Messenger',
      pointsStockValue: 'Valeur'
    }
  },
  sales: {
    gms: 'GMS',
    bars: 'Bars',
    totalOnPeriod: 'Total période',

    shareByBrand: 'Répartition par marque',
    shareByRetailerTypeAndRetailer:
      "Répartition par type d'enseigne et enseigne",
    shareByRetailerTypeAndRetailerLegend:
      "cliquez sur un type d'enseigne pour avoir le détail par enseigne",
    shareByRegion: 'Répartition par région'
  },
  usage: {
    shares: {
      title: 'Répartition des déclarations',
      legend: 'Proportion des déclarations en GMS et en Bars'
    },
    retention: {
      title: 'Rétention',
      legend: `Graphes par cohortes. Les graphes suivants représentent l'activité des utilisateurs qui se sont inscrits un mois donné ("cohorte").`,

      notes: {
        1: "(1) Chaque mois représente les utilisateurs inscrits ce mois là, et comment ils ont évolué dans le temps. Permet de voir si les inscrits du mois de mars ont mieux été convertis, sur la durée, que les inscrits du mois d'avril.",
        2: "(2) Affiche le nombre d'actifs chaque mois, répartis en fonction de leur date d'inscription initiale. Permet de voir si l'audience actuelle est une audience ancienne ou récente."
      }
    }
  },
  individualBehavior: {
    annualAverages: 'moyennes annualisées',
    mixity: 'Mixité',

    actsOfPurchase: "actes d'achats",
    products: 'produits',
    ofExpense: 'de dépense',

    legends: {
      shares:
        "pourcentage des utilisateurs, actifs sur la période, qui ont consommé sur un canal, l'autre ou les deux (1)",
      annualAverages: 'achats et dépenses ramenés à des années pleines (1)',
      mixity: "pourcentage d'utilisateurs consommant plusieurs gammes (1)"
    },
    notes: {
      1: "(1) l'historique sur 12 mois, quand il existe, est considéré comme base. Les utilisateurs sélectionnés sont ceux actifs pendant la période choisie, et les calculs sont faits sur la base de leur historique d'un an quand il existe. Chaque utilisateur compte en proportion de sa durée d'activité (un utilisateur actif 3 mois compte 4 fois moins dans la moyenne qu'un utilisateur actif les 12 derniers mois)."
    }
  },

  charts: {
    generosity: {
      chartTitle: 'Répartition par moyen de paiement',
      xAxisTitle: 'Dates',
      yAxisTitle: 'Générosité'
    },

    sales: {
      chartTitle: 'Répartition par marque',
      xAxisTitle: 'Dates',
      yAxisTitle: "Chiffre d'affaire"
    },
    salesPerRetailer: {
      chartTitle: "Répartition par type d'enseigne et enseigne",
      xAxisTitle: 'Pourcentages'
    },
    salesPerRegion: {
      chartTitle: 'Répartition par région',
      xAxisTitle: 'Pourcentages',
      seriesName: 'Régions'
    },

    activityShares: {
      chartTitle: 'Répartition des déclarations',
      xAxisTitle: 'Origine'
    },
    activity: {
      chartTitle: 'Activité',
      chartSubtitle: "Conversion des inscrits, par mois d'inscription (1)",
      xAxisTitle: 'Dates',
      yAxisTitle: 'Pourcentages des utilisateurs'
    },
    retention: {
      chartTitle: 'Actifs par cohorte',
      chartSubtitle: "Actifs par mois, répartis par mois d'inscription (2)",
      xAxisTitle: 'Dates',
      yAxisTitle: "Nombre d'actifs"
    },

    mixity: {
      chartTitle: 'Mixité',
      xAxisTitle: 'Nombre de groupes'
    },
    purchasesShares: {
      chartTitle: 'Répartition',
      xAxisTitle: 'Origine'
    }
  },
  canals: {
    onboarding: {
      chartTitle: 'Onboarding',
      legend1:
        'Ces mesures concernent exclusivement les utilisateurs sur Messenger.',
      legend2:
        'Sont comptées comme déclarations les jours distincts où une déclaration à points a été envoyée.'
    }
  }
}
