import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{staticClass:"timeline",attrs:{"dense":"","two-line":""}},_vm._l((_vm.events),function(event,index){return _c(VListItem,{key:event.identifier,staticClass:"timeline__item"},[_c(VListItemAvatar,{staticClass:"timeline__item__avatar",attrs:{"size":"24"}},[_c('TimelineIcon',{attrs:{"event":event}})],1),_vm._v(" "),(index !== 0)?_c('div',{staticClass:"timeline__item__connector"}):_vm._e(),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(event.header_i18n)}}),_vm._v(" "),(event.at)?_c(VListItemSubtitle,[_vm._v("\n        "+_vm._s(_vm._f("momentFormat")(event.at,'human-long'))+"\n      ")]):_vm._e()],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }