import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SelectAccount',{on:{"input":function($event){_vm.editorId = $event}}}),_vm._v(" "),_c('h4',[_vm._v("\n    "+_vm._s(_vm.$t(
        'pages.users.modal.accountActions.changePassword.howDoYouWantToProceed'
      ))+"\n  ")]),_vm._v(" "),_c('div',[_c(VBtn,{staticClass:"white--text ma-1",attrs:{"loading":_vm.isSendingEmail,"color":"primary"},on:{"click":_vm.sendEmail}},[_vm._v("\n      "+_vm._s(_vm.$t(
          'pages.users.modal.accountActions.changePassword.sentEmailToUserToResetPassword'
        ))+"\n    ")])],1),_vm._v(" "),_c('div',[_c(VBtn,{staticClass:"white--text ma-1",attrs:{"loading":_vm.isRetrievingResetLink,"color":"primary"},on:{"click":_vm.retrievingResetLink}},[_vm._v("\n      "+_vm._s(_vm.$t(
          'pages.users.modal.accountActions.changePassword.getLinkToResetPassword'
        ))+"\n    ")]),_vm._v(" "),(_vm.resetLink)?_c('div',{staticClass:"d-flex"},[_c(VTextField,{ref:"textToCopy",attrs:{"hide-details":"","value":_vm.resetLink},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VBtn,{on:{"click":_vm.copyText}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-content-copy")]),_vm._v("\n            "+_vm._s(_vm.$t('global.copy'))+"\n          ")],1)]},proxy:true}],null,false,3257506035)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }