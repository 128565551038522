<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <ValidationProvider
          v-slot="{ errors }"
          name="refundValue"
          mode="eager"
          :rules="refundMaxRules"
          slim
        >
          <v-text-field
            :disabled="disabled"
            :readonly="readonly"
            :label="$t('crud.offers.edit.fields.refundValue.label') + ' *'"
            required
            type="number"
            min="0"
            :max="refundMax"
            :error-messages="errors"
            :value="modified.refundValue"
            :append-outer-icon="refundTypeIcon"
            @change="updateAttribute({ key: 'refundValue', value: $event })"
          />
        </ValidationProvider>
      </v-col>
      <v-col>
        <v-radio-group
          v-show="!disabled"
          row
          :value="modified.refundType"
          @change="updateAttribute({ key: 'refundType', value: $event })"
        >
          <v-radio
            off-icon="mdi-percent-outline"
            on-icon="mdi-percent"
            :value="REFUND_TYPE_PERCENT"
          />
          <v-radio
            off-icon="mdi-currency-eur"
            on-icon="mdi-currency-eur"
            :value="REFUND_TYPE_ABSOLUTE"
          />
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <ValidationProvider
          v-slot="{ validate, errors }"
          name="refundBonusPoints"
          mode="eager"
          rules="integer|min_value:0"
          slim
        >
          <v-text-field
            :disabled="disabled"
            :readonly="readonly"
            :label="$t('crud.offers.edit.fields.refundBonusPoints.label')"
            :hint="$t('crud.offers.edit.fields.refundBonusPoints.hint')"
            required
            type="number"
            min="0"
            :error-messages="errors"
            :value="modified.refundBonusPoints"
            @change="
              validate($event)
              updateAttribute({ key: 'refundBonusPoints', value: $event })
            "
          />
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <ValidationProvider
          v-slot="{ errors }"
          name="refundRecommendedPrice"
          mode="eager"
          rules="required"
          slim
        >
          <v-text-field
            :disabled="disabled"
            :readonly="readonly"
            :label="
              $t('crud.offers.edit.fields.refundRecommendedPrice.label') + ' *'
            "
            type="number"
            min="0"
            append-outer-icon="mdi-currency-eur"
            :error-messages="errors"
            :value="modified.refundRecommendedPrice"
            @change="
              updateAttribute({ key: 'refundRecommendedPrice', value: $event })
            "
          />
        </ValidationProvider>
      </v-col>
    </v-row>

    <ListAndVerifyEans
      :disabled="disabled"
      :readonly="readonly"
      :eans="modified.refundProductsEans"
      eans-required
      @change="updateRefundProductsEans($event)"
    />
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ValidationProvider } from 'vee-validate'

import ListAndVerifyEans from '~/components/program/catalog/create-modal/ListAndVerifyEans.vue'

export const REFUND_TYPE_ABSOLUTE = 'ABSOLUTE'
export const REFUND_TYPE_PERCENT = 'PERCENTAGE'

const MAX_REFUND = 100

export default {
  components: {
    ValidationProvider,
    ListAndVerifyEans
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    REFUND_TYPE_ABSOLUTE,
    REFUND_TYPE_PERCENT
  }),
  computed: {
    ...mapState('crud/offer', ['modified']),

    isRefundInPercentage() {
      return this.modified && this.modified.refundType === REFUND_TYPE_PERCENT
    },
    refundMax() {
      return this.isRefundInPercentage ? MAX_REFUND : undefined
    },
    refundMaxRules() {
      if (this.isRefundInPercentage) {
        return `required|min_value:0|max_value:${MAX_REFUND}`
      } else {
        return `required|min_value:0`
      }
    },

    refundTypeIcon() {
      if (!this.disabled) {
        return undefined
      }

      return this.isRefundInPercentage ? 'mdi-percent' : 'mdi-currency-eur'
    }
  },
  methods: {
    ...mapMutations('crud/offer', [
      'updateAttribute',
      'updateRefundProductsEans'
    ])
  }
}
</script>
