import { getProgramBaseRoute } from '@/utils/constants/routes'

export default (ctx) => {
  if (ctx.route.query.force === 't') return

  if (
    ctx.store.state.auth.selectedProgram
      .is_core_the_source_of_truth_for_analytics === false
  ) {
    return
  }

  // ctx.store.getters['program/routeBasePath'] is not yet ready
  // when switching program cause we are still in the context of the
  // page for switching program
  ctx.redirect(
    getProgramBaseRoute(ctx.store.state.auth.selectedProgram.identifier)
  )
}
