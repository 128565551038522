export const imagePreview = (state) =>
  state.modified.image_file
    ? URL.createObjectURL(state.modified.image_file)
    : state.modified.image_url

export const pushNotifImagePreview = (state) =>
  state.modified.push_notif_image_file
    ? URL.createObjectURL(state.modified.push_notif_image_file)
    : state.modified.push_notif_image_url

export const editor = (state) => {
  return state.config.editors.find(
    (editor) => state.modified.editor_id === editor.id
  )
}

export const backendAction = (state) =>
  state.modified && state.modified.id ? 'update' : 'create'

function emailHeader(name, email) {
  if (!name) return email

  return `${name} <${email}>`
}

export const backendPayloadForUsecase = (state) => (useCase) => {
  let res
  switch (useCase) {
    case 'messages':
      res = {
        internal_description: state.modified.internal_description,
        content: state.modified.content,
        author_type: state.modified.author_type,
        author_id: state.modified.author_id,
        object_for_action_id: state.modified.object_for_action_id,
        object_for_action_type: state.modified.object_for_action_type,
        button_action: state.modified.action,
        is_modal: state.modified.is_modal,
        push_notif_content: state.modified.push_notif_content,
        kind: state.modified.kind,
        editor_id: state.modified.editor_id,
        displayed_action: state.modified.displayed_action,
        push_notif_title: state.modified.push_notif_title,
        push_notif_image_file: state.modified.push_notif_image_file,
        push_notif_image_url: state.modified.push_notif_image_url,
        loyalty_program_for_author_id:
          state.modified.loyalty_program_for_author_id,
        title: state.modified.title,
        image_url: state.modified.image_url,
        image_file: state.modified.image_file,
        image_height: state.modified.image_height
      }
      if (state.modified.id) {
        res.id = state.modified.id
      }
      return res

    case 'emails':
      res = {
        is_behavioral: state.modified.is_behavioral
          ? state.modified.is_behavioral
          : false,
        categories: state.modified.categories,
        // unsubscribe_group_id: state.modified.unsubscribe_group_id,
        // unsubscribe_group_ids: state.modified.unsubscribe_group_ids,
        from: emailHeader(state.modified.fromName, state.modified.fromEmail),
        reply_to: emailHeader(
          state.modified.replyToName,
          state.modified.replyToEmail
        ),
        subject: state.modified.subject,
        body: state.modified.body,
        builder: 'CUSTOM',
        editor_id: state.modified.editor_id,
        layout: false,
        footer_purchease: false,
        internal_description: state.modified.internal_description,
        parameters_description: state.modified.parameters_description
      }
      if (state.modified.id) {
        res.id = state.modified.id
      }
      return res

    default:
  }
}
