export default {
  title: 'Points de Ventes',
  upsertWithFile: 'Importer / Modifier par fichier',

  activatedPointsOfSale: '{count} points de ventes Scanobar activés:',
  activatedPointsOfSaleInfo:
    'Seuls les points de ventes avec plus de 5 achats sont affichés',

  noDataText: {
    generic: 'Aucun point de vente disponible',
    bars: 'Aucun bar disponible',
    gsa: 'Aucune enseigne GSA disponible'
  },
  noResults: {
    generic: 'Aucun point de vente trouvé',
    bars: 'Aucun bar trouvé',
    gsa: 'Aucune enseigne GSA trouvée'
  },
  tabs: {
    bars: 'Bars',
    gsa: 'GSA'
  },
  dialog: {
    fileInput: 'Fichier tableur',
    hintForFile:
      "Ce fichier doit comporter les colonnes et entête de colonnes suivants (l'ordre des colonnes n'a pas d'importance)",
    uploadingFile: 'Envoi du fichier',
    processingFile: 'Traitement du fichier'
  },
  table_headers: {
    pid: 'PID',
    retailer_name: 'Enseigne',
    name: 'Nom',
    full_address: 'Adresse complète',
    address: 'Adresse',
    address2: 'Adresse 2',
    zipcode: 'Code postal',
    city: 'Ville',
    phone: 'Téléphone'
  },
  errors: {
    missingFile: 'Merci de sélectionner un fichier'
  }
}
