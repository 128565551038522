export default (ctx) => {
  if (ctx.store.getters['auth/isPurcheaseAdmin']) return

  if (process.server) {
    // TODO: open toast after redirect
    ctx.redirect(ctx.store.state.route.from.fullPath)
  }
  if (process.client) {
    ctx.store.commit('snackbar/addToast', {
      text: ctx.app.i18n.t('errors.auth.unauthorizedToViewPage')
    })
    ctx.redirect(ctx.store.state.route.fullPath)
  }
}
