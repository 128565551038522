<template>
  <div>
    <v-alert border="top" colored-border type="info" elevation="2">
      {{
        $t('pages.program.catalog.shelf.orderInAShelfIsNotImportantNorSaved')
      }}
    </v-alert>

    <h1>
      {{ $t('pages.program.catalog.shelf.rewardsWithoutShelves') }}
    </h1>

    <v-card class="mb-5">
      <div class="d-flex flex-nowrap overflow-x-auto">
        <RewardItemLoading v-for="index in [1, 2]" :key="index" />
      </div>
    </v-card>

    <h1>
      {{ $t('pages.program.catalog.shelf.shelves') }}
    </h1>

    <ShelfRowLoading v-for="index in [1, 2]" :key="index" class="mb-3" />
  </div>
</template>

<script>
import RewardItemLoading from './RewardItemLoading.vue'
import ShelfRowLoading from './ShelfRowLoading.vue'
export default {
  components: {
    RewardItemLoading,
    ShelfRowLoading
  }
}
</script>
