export const TAB_EDIT_INFORMATIONS = 'EDIT_INFORMATIONS'
export const TAB_CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const TAB_BAN = 'BAN'
export const TAB_DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const TAB_SEND_MONEY = 'SEND_MONEY'

export default {
  title: 'Rechercher un utilisateur',
  searchLabel: 'Tapez "/" pour focus',
  noMatchingUser: 'Aucun utilisateur ne correspond à votre recherche',

  search: {
    advanceSearch: 'Recherche avancée',
    searchBySavId: 'Recherche par identifiant SAV',

    keys: {
      id: 'ID',

      external_id: 'ID externe',
      facebook_id: 'ID Facebook',

      external_email: 'email externe',

      email: 'email',
      first_name: 'prénom',
      last_name: 'nom',
      address_1: 'adresse',
      address_2: 'adresse (2)',
      postal_code: 'code postal',
      city: 'ville',
      phone_number: 'numéro de téléphone'
    }
  },

  modal: {
    title: "Actions sur l'utilisateur #{userId} ({userEmail})",
    tabs: {
      [TAB_EDIT_INFORMATIONS]: 'éditer ses informations',
      [TAB_CHANGE_PASSWORD]: 'changer son mot de passe',
      [TAB_BAN]: 'le bannir',
      [TAB_DELETE_ACCOUNT]: 'supprimer un compte',
      [TAB_SEND_MONEY]: "envoyer de l'argent"
    },

    personalInfo: {
      title: 'Informations personnelles',
      email: 'email',
      lastName: 'Nom',
      firstName: 'Prénom'
    },
    ban: {
      previouslyBlockAtOnEditor: 'Précédemment bloqué le {date} sur {editor}',
      previouslyBlockAt: 'Précédemment bloqué le {date}',
      currentlyBlockAtEditor: 'Bloqué depuis le {date} sur {editor}',
      currentlyBlockAt: 'Bloqué depuis le {date}',
      banAtOnEditor: 'Précédemment banni le {date} sur {editor}',
      banAt: 'Précédemment banni le {date}'
    },
    accountState: {
      title: 'état du compte',
      deleted: 'supprimé',
      previousBans: 'anciens bans',
      vip: {
        title: 'utilisateur VIP ?',
        yes: 'oui',
        no: 'non'
      },
      notes: 'Notes internes'
    },
    accountActions: {
      selectAccount: 'Choix du compte',

      updateUserInformations: {
        success: 'informations mise à jour'
      },
      changePassword: {
        howDoYouWantToProceed: 'Comment voulez-vous changer le mot de passe ?',
        sentEmailToUserToResetPassword:
          "Envoyer un mail de réinitialisation de mot de passe à l'utilisateur",
        emailSentWithSuccess: 'Email envoyé avec succès',
        getLinkToResetPassword:
          'Récupérer le lien de réinitialisation de mot de passe',
        linkCopiedToClipboard: 'lien copié'
      },
      moderations: {
        submit: 'Confirmer',
        success: 'ban créé avec succès'
      },
      deleteAccount: {
        cancel: 'Annuler',
        delete: 'Supprimer',
        timeDown:
          'Attention le compte sera supprimé dans 0 seconde...|Attention le compte sera supprimé dans 1 seconde...|Attention le compte sera supprimé dans {count} secondes...',
        success: 'Le compte a bien été supprimé'
      },
      sendMoney: {
        fields: {
          amount: 'Montant',
          description: 'Description',
          zendeskTicketId: 'ID du ticket zendesk',
          password: 'Mot de passe'
        },
        submit: 'envoyer',
        success: 'argent envoyé'
      }
    }
  },

  segments: {
    createNew: 'Créer un segment'
  }
}
