export default {
  stepper: {
    createPartner: 'Créer le(s) partenaire(s)',
    partnerCreated: 'Partenaire créé | Partenaire créé | Partenaires créés',
    configureApplet:
      "Configurer l'applet | Configurer l'applet | Configurer les applets"
  },

  askADeveloperToCreateIt: 'Demandez à un développeur de le(s) créer',

  labels: {
    online: 'mise en ligne',
    offline: 'mise hors ligne',

    notAvailableYet: 'pas encore en ligne',
    available: 'mis en ligne',
    notClosedYet: 'pas encore fermé',
    closed: 'fermé',

    on: 'le',
    at: 'à',

    imageForCardWhenNotLogged:
      'image de la carte (non connecté) ({width} x {height})',
    imageForCardWhenLogged: 'image de la carte (connecté) ({width} x {height})',
    imageForHomeHeader:
      "image d'entête de la page d'accueil ({width} x {height})",
    imageForSignupMailHeader:
      "image d'entête du mail d'inscription ({width} x {height})",
    imageForSignupMailCard:
      "image de la carte dans le mail d'inscription ({width} x {height})",

    dominantColor: 'couleur dominante',
    colorCustom: 'couleur personalisée',
    colorOfProgram: 'couleur du programme',

    emailForDigitalReceipts: 'email pour les tickets digitaux',
    signupMailLoyaltyReward:
      "récompense mise en avant dans le mail d'inscription",
    whenLeftEmptyItWillBeTheFirstOfTheCatalog:
      'si laissé vide, la 1e récompense du catalogue est affichée'
  },

  settingsSuccessfullyUpdated: 'paramètres mis à jour',
  settingsSuccessfullyCreated: 'paramètres créés'
}
