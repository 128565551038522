import { sync } from 'vuex-router-sync'

import { HOME_USERS } from '@/utils/constants/routes'

export const PROGRAM_SELECT_PATH = '/select-program'

export default ({ app }) => {
  sync(app.store, app.router)

  // program needed everywhere
  app.router.beforeEach((to, _from, next) => {
    if (process.server) return next() // wait program to be loaded (via nuxtServerInit)
    if (app.store.state.auth.selectedProgram) return next()
    if (to.path.startsWith('/auth')) return next()
    if (to.path === PROGRAM_SELECT_PATH) return next()

    next({ path: PROGRAM_SELECT_PATH, query: { r: to.fullPath } })
  })

  // redirect to loaded user
  app.router.beforeEach((to, _from, next) => {
    if (to.path !== HOME_USERS) return next()
    if (!app.store.state.user.isLoaded) return next()

    // searching for a user should be allowed
    if (app.store.state.user.search.text) return next()

    // toggling advance search should be allowed
    if (app.store.state.user.search.isAdvanceSearch) return next()

    next(`${HOME_USERS}/${app.store.state.user.user.id}`)
  })

  // reset user when going to new user
  app.router.beforeEach(async (to, _from, next) => {
    if (!to.path.startsWith(HOME_USERS)) return next()
    if (!to.params.userId) return next()

    if (!app.store.state.user.isLoaded) return next()

    const userId = parseInt(to.params.userId)
    if (userId === app.store.state.user.user.id) return next()

    await app.store.dispatch('user/reset')
    next()
  })

  // reset user search when entering user space
  app.router.beforeEach((to, _from, next) => {
    if (!to.path.startsWith(HOME_USERS)) return next()
    if (!to.params.userId) return next()
    // if (from.path !== HOME_USERS) return next()

    app.store.commit('user/search/reset')
    next()
  })

  // reset user search when exiting user space
  app.router.beforeEach((to, from, next) => {
    if (!from.path.startsWith(HOME_USERS)) return next()
    if (to.path.startsWith(HOME_USERS)) return next()

    app.store.commit('user/search/reset')
    next()
  })

  // make sure edited program is the one selected
  app.router.beforeEach(async (to, _from, next) => {
    // on server side, this runs before nuxtServerInit is called
    // => no admin assigned and no call to core can be performed
    if (process.server) return next()

    // if page not found, params are not parsed
    // and "to.params.programIdentifier" does not exist
    // even if it's a path like
    // "/programs/management/:programIdentifier/*"
    if (to.matched.length === 0) return next()

    if (!to.path.startsWith('/programs/management/')) return next()
    if (
      app.store.state.auth.selectedProgram &&
      app.store.state.auth.selectedProgram.identifier ===
        to.params.programIdentifier
    ) {
      return next()
    }

    await app.store.dispatch('auth/getDetailsAndSelectProgramFromIdentifier', {
      programIdentifier: to.params.programIdentifier
    })
    next()
  })
}
