import { createCancellableState } from '@/utils/store/concerns/cancellable/state'
import {
  createLoadableState,
  LoadableState
} from '~/utils/store/concerns/loadable/state'

export interface BrandWithIndentifier {
  identifier: string
  name: string
}

export interface Brand {}

export interface BrandsById {
  [key: number]: Brand
}

export interface NotLoadableState {
  idsBeingFetched: number[]
  brandsById: null | BrandsById
}
export interface State extends NotLoadableState, LoadableState {}

export default () =>
  createLoadableState(
    createCancellableState({
      idsBeingFetched: [],
      brandsById: null
    })
  )
