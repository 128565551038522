import { Store, ActionContext } from 'vuex'

import { State } from './state'
import { RootState } from '~/store/state'

import {
  handleAxiosErrorServerFromStoreV3,
  handleAxiosErrorFrontendFromStoreV3
} from '@/utils/functions/handle-errors'
import mockedResponse from '~/utils/mocked-data/program/config_precisions'

export const fetch = async function(
  this: Store<State>,
  { state, commit }: ActionContext<State, RootState>
) {
  if (state.isLoading) return

  const isMocked = false

  commit('setLoadedState', false)
  commit('setLoadingState', true)
  commit('resetError')

  let response
  try {
    if (isMocked) {
      response = mockedResponse
    } else {
      response = await this.$axios.get(
        `/programs/${this.$router.currentRoute.params.programIdentifier}/config_precisions`
      )
    }
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({ error, commit, store: this })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStoreV3({ response, commit, store: this })
    return
  }

  commit('handleFetchResult', response.data.result)

  commit('setLoadedState', true)
  commit('setLoadingState', false)
}
