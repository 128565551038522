import Vue from 'vue'
import { findIndex, omit } from 'lodash-es'

import createDefaultState from './state'

import { CANCEL_TOKEN_FOR_REPEATED_CALL } from '@/utils/constants/cancel-tokens'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export const setLoadingState = (state, boolean) => {
  state.isLoading = boolean
}
export const setLoadedState = (state, boolean) => {
  state.isLoaded = boolean
}

export const setSelectedAppIds = (state, apps) => {
  state.selectedAppIds = apps
}
export const unselectAppId = (state, app) => {
  const index = state.selectedAppIds.indexOf(app.id)
  if (index === -1) return

  state.selectedAppIds.splice(index, 1)
}

export const setError = (state, text) => {
  state.error = text
}
export const resetError = (state) => {
  state.error = null
}

export const setDeclarations = (state, declarations) => {
  state.declarations = declarations
}
export const pushDeclarations = (state, declarations) => {
  state.declarations = state.declarations.concat(declarations)
}
export const setHasMore = (state, hasMore) => {
  state.hasMore = hasMore
}
export const setNextPaginationKey = (state, nextPaginationKey) => {
  state.nextPaginationKey = nextPaginationKey
}
export const setFetchingMoreState = (state, boolean) => {
  state.isFetchingMore = boolean
}

export const setCancelToken = (state, source) => {
  state.cancelToken = source
}
export const cancelPreviousRequest = (state) => {
  state.cancelToken.cancel(CANCEL_TOKEN_FOR_REPEATED_CALL)
  state.cancelToken = null
}

export const updateDeclaration = (state, declaration) => {
  const index = findIndex(
    state.declarations,
    (d) => d.identifier === declaration.identifier
  )

  if (index === -1) return

  Vue.set(state.declarations, index, omit(declaration, ['details']))
}
