






























import Vue from 'vue'
import CreateNewRewardForm from '@/components/program/catalog/create-modal/CreateNewRewardForm.vue'
export default Vue.extend({
  components: { CreateNewRewardForm },
  data: () => ({
    showModal: false
  })
})
