import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"groupedRefundValue","mode":"aggressive","rules":_vm.groupedRefundMaxRules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"label":_vm.$t(
            ("crud.offers.edit.fields.groupedRefund.value.label." + (_vm.modified.groupedRefundType))
          ) + ' *',"required":"","type":"number","min":"0","max":_vm.groupedRefundMax,"error-messages":errors,"value":_vm.refundValue,"append-outer-icon":_vm.groupedRefundTypeIcon},on:{"change":function($event){return _vm.$emit('change:refund-value', $event)}}})]}}])})],1),_vm._v(" "),_c(VCol,{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"groupedRefundMinItems","mode":"aggressive","rules":"required|integer|min_value:1","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c(VTextField,{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"label":_vm.$t('crud.offers.edit.fields.groupedRefund.minItems.label') + ' *',"hint":_vm.$t('crud.offers.edit.fields.groupedRefund.minItems.hint'),"persistent-hint":"","required":"","type":"number","min":"0","error-messages":errors,"value":_vm.minItems},on:{"change":function($event){return _vm.$emit('change:min-items', $event)}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }