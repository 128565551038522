























































import Vue from 'vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { debounce, filter, toPairs } from 'lodash-es'

import PartnerCheckbox from '@/components/shared/PartnerCheckbox.vue'

import { fetchCoreFromComponentAndHandleError } from '@/utils/functions/fetch-and-handle-errors'

interface Partner {
  identifier: string
  name: string
  has_exclusivity: boolean // eslint-disable-line camelcase
}

function buildExclusivities(partnerExclusivities: Partner[]) {
  const exclusivitiesByPartnerIdentifier = {} as { [key: string]: boolean }
  partnerExclusivities.forEach((p) => {
    exclusivitiesByPartnerIdentifier[p.identifier] = p.has_exclusivity
  })
  return exclusivitiesByPartnerIdentifier
}

export default Vue.extend({
  components: { PartnerCheckbox },
  props: {
    rewardIdentifier: {
      type: String,
      required: true
    },
    partnerExclusivities: {
      type: Array as () => Partner[],
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      exclusivitiesByPartnerIdentifier: buildExclusivities(
        this.partnerExclusivities
      ),
      debouncedSaveExclusivities: null as null | Function,
      isSaving: false,
      successfullySaved: false,
      isResetting: false,
      errorOnReset: null
    }
  },
  computed: {
    ...mapGetters('auth', ['hasWriteContentRight']),

    noExclusivities(): boolean {
      return this.selectedPartnerIdentifiers.length === 0
    },
    selectedPartnerIdentifiers(): string[] {
      return filter(
        toPairs(this.exclusivitiesByPartnerIdentifier),
        ([_, isSelected]) => isSelected
      ).map(([identifier, _]) => identifier)
    }
  },
  watch: {
    exclusivitiesByPartnerIdentifier: {
      deep: true,
      handler() {
        if (this.errorOnReset) return

        if (this.debouncedSaveExclusivities) {
          this.debouncedSaveExclusivities()
        }
      }
    }
  },
  created() {
    this.debouncedSaveExclusivities = debounce(this.saveExclusivities, 500)
  },
  methods: {
    ...mapMutations('snackbar', ['addToast']),
    ...mapMutations('program/catalog', ['updatePartnerExclusivitiesOnReward']),
    ...mapActions('program/catalog', ['fetchRewards']),

    toggleExclusivity(identifier: string) {
      this.$set(
        this.exclusivitiesByPartnerIdentifier,
        identifier,
        !this.exclusivitiesByPartnerIdentifier[identifier]
      )
    },

    saveExclusivities(): Promise<void> {
      return fetchCoreFromComponentAndHandleError({
        $vm: this,
        isLoadingKey: 'isSaving',
        errorKey: 'errorOnReset',

        axios: {
          method: 'POST',
          url: `/loyalty_rewards/${this.rewardIdentifier}/set_partner_exclusivities`,
          data: { partner_identifiers: this.selectedPartnerIdentifiers }
        },

        onSuccess: (_result: any) => {
          this.updatePartnerExclusivitiesOnReward({
            rewardIdentifier: this.rewardIdentifier,
            selectedPartnerIdentifiers: this.selectedPartnerIdentifiers
          })

          this.successfullySaved = true
          setTimeout(() => {
            this.successfullySaved = false
          }, 2 * 1000)
        },

        onError: () => {
          this.exclusivitiesByPartnerIdentifier = buildExclusivities(
            this.partnerExclusivities
          )
          setTimeout(() => {
            this.errorOnReset = null
          }, 2 * 1000)
        }
      })
    }
  }
})
