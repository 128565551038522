import { handleAxiosProxyAnalyticsErrorServerFromStore } from '@/utils/functions/handle-errors'

// import payload from '@/utils/mocked-data/metrics/program/config'

import {
  periodFromExpiredMonthsOfCurrentPeriod,
  periodFromPreviousMonth,
  periodFromCurrentMonth,
  periodFromExpiredTwelveMonths
} from '~/utils/constants/metrics/periods'

export async function fetchConfig({ state, commit, dispatch }) {
  // commit('reset')
  commit('setLoadingState', true)

  let response
  try {
    response = await this.$axios.post(
      '/api/proxy/analytics',
      {
        method: 'post',
        path:
          '/internal_api/sas_bo_front_end/v1/programs_metrics/programs_context',
        data: {
          loyalty_program_identifiers: [state.currentProgramIdentifier]
        }
      },
      { baseURL: '/' }
    )
  } catch (error) {
    handleAxiosProxyAnalyticsErrorServerFromStore({
      error,
      commit,
      store: this
    })
    return
  }

  // await new Promise((resolve) => setTimeout(resolve, 2000))
  // commit('handleResult', payload)

  commit('handleResult', response.data.result[state.currentProgramIdentifier])

  dispatch('selectDefaultPeriod')

  commit('setLoadedState', true)
  commit('setLoadingState', false)
}

export function fetchConfigIfNotLoaded({ state, dispatch }) {
  if (state.isLoaded) return

  dispatch('fetchConfig')
}

export function selectDefaultPeriod({ state, getters, dispatch }) {
  if (getters.isCurrentMonthStartMonthOfPeriod) {
    return dispatch('selectPreviousMonth')
  }

  if (state.currentPeriod) {
    return dispatch('selectExpiredMonthsOfCurrentPeriod')
  } else {
    return dispatch('selectPreviousMonth')
  }
}

export function selectExpiredMonthsOfCurrentPeriod({ state, getters, commit }) {
  commit(
    'selectPeriod',
    periodFromExpiredMonthsOfCurrentPeriod(
      this.$i18n.t.bind(this.$i18n),
      state.currentPeriod.start_date,
      getters.isCurrentMonthStartMonthOfPeriod
    )
  )
}
export function selectPreviousMonth({ commit }) {
  commit('selectPeriod', periodFromPreviousMonth(this.$i18n.t.bind(this.$i18n)))
}
export function selectCurrentMonth({ commit }) {
  commit('selectPeriod', periodFromCurrentMonth(this.$i18n.t.bind(this.$i18n)))
}
export function selectExpiredTwelveMonths({ commit }) {
  commit(
    'selectPeriod',
    periodFromExpiredTwelveMonths(this.$i18n.t.bind(this.$i18n))
  )
}
