export const payloadForBackend = (state, _getters, _rootState) => {
  const res = {
    permissions: state.modified.permissions
  }
  if (state.modified.id) {
    res.id = state.modified.id
  } else {
    res.email = state.modified.email
  }
  return res
}

export const modified = (state) => {
  return state.modified
}
