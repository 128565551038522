<template>
  <LayoutUser>
    <div class="layout-declarations d-flex fill-height">
      <v-btn
        v-show="canShowGallery"
        color="primary"
        class="layout-declarations__fab white--text"
        fab
        small
        fixed
        bottom
        right
        @click="toggleGalleryColumn"
      >
        <v-icon>mdi-receipt</v-icon>
      </v-btn>

      <aside
        class="layout-declarations__aside layout-declarations__aside--left grey lighten-2"
        :style="{
          flex: `0 0 ${leftSideWidth}px`,
          width: `${leftSideWidth}px`
        }"
      >
        <DeclarationsListFilter class="white pa-3 pt-5 pr-2 mr-1" />

        <DisplayError
          v-if="error"
          :error="error"
          with-line-return
          retriable
          @retry="fetchAll({ userId: $route.params.userId })"
        />

        <DeclarationsListLoading v-else-if="isLoading" />

        <div v-else-if="!isLoaded" />

        <div v-else-if="noDeclarations" class="ma-3">
          <v-alert type="error">
            {{ $t('pages.declarations.noDeclaration') | capitalize }}
          </v-alert>
        </div>

        <DeclarationsListLoaded v-else />
      </aside>

      <main ref="scroll-container" class="layout-declarations__main">
        <nuxt />
      </main>

      <!-- not "v-show" in order to load lib vanilla-js-wheel-zoom correclty -->
      <v-dialog
        content-class="layout-declarations__gallery"
        width="500px"
        :value="isGalleryShown"
        @input="setIsGalleryShown"
      >
        <DeclarationImage
          v-if="hasOneSubpicture"
          :subpicture="subpictures[0]"
        />
        <DeclarationGallery
          v-if="hasMoreThanOneSubpicture"
          :subpictures="subpictures"
        />
      </v-dialog>
    </div>
  </LayoutUser>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import LayoutUser from '../user'
import DeclarationsListFilter from '@/components/features/user/declarations/ListFilter'
import DeclarationsListLoading from '@/components/features/user/declarations/ListLoading'
import DeclarationsListLoaded from '@/components/features/user/declarations/ListLoaded'
import DeclarationGallery from '@/components/features/user/declarations/DeclarationGallery'
import DeclarationImage from '@/components/features/user/declarations/DeclarationImage'

import MixinError from '@/mixins/error'

export default {
  components: {
    LayoutUser,
    DeclarationsListFilter,
    DeclarationsListLoading,
    DeclarationsListLoaded,
    DeclarationGallery,
    DeclarationImage
  },
  mixins: [MixinError],

  data: () => ({
    leftSideWidth: 240
  }),
  computed: {
    ...mapState('user/declarations', ['isLoaded', 'isLoading', 'error']),
    ...mapGetters('user/declarations', ['noDeclarations']),

    ...mapState('layout/user', ['isGalleryShown']),
    ...mapGetters('layout/user', [
      'canShowGallery',
      'subpictures',
      'hasOneSubpicture',
      'hasMoreThanOneSubpicture'
    ])
  },
  watch: {
    isLoaded: 'fetchDeclarationsFromRoute',
    $route: 'fetchDeclarationsFromRoute'
  },
  mounted() {
    this.fetchDeclarationsFromRoute()
    this.setScrollContainer(this.$refs['scroll-container'])
  },
  methods: {
    ...mapActions('user/declarations', ['fetchAll']),
    ...mapMutations('layout/user', [
      'toggleGalleryColumn',
      'setIsGalleryShown',
      'setScrollContainer'
    ]),

    fetchDeclarationsFromRoute() {
      if (this.isLoaded) return
      if (this.isLoading) return

      this.fetchAll({ userId: this.$route.params.userId })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~/assets/variables.scss';

.layout-declarations {
  height: 100%;
}
.layout-declarations__aside--left,
.layout-declarations__main {
  height: 100%;
  overflow-y: auto;
}

.layout-declarations__main {
  flex: 1;
}

.layout-declarations__fab {
  margin-bottom: 40px; // bottom bar
}

.layout-declarations__main {
  background-color: $declaration-background-color;
}
</style>
