<template>
  <v-list dense two-line class="timeline">
    <v-list-item
      v-for="(event, index) in events"
      :key="event.identifier"
      class="timeline__item"
    >
      <v-list-item-avatar size="24" class="timeline__item__avatar">
        <TimelineIcon :event="event" />
      </v-list-item-avatar>

      <div v-if="index !== 0" class="timeline__item__connector" />

      <v-list-item-content>
        <v-list-item-title v-text="event.header_i18n" />
        <v-list-item-subtitle v-if="event.at">
          {{ event.at | momentFormat('human-long') }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import TimelineIcon from './TimelineIcon'
export default {
  components: { TimelineIcon },
  props: {
    events: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
$list-item-height: 50px;
$list-item-padding-x: 8px;
$list-item-avatar-size: 24px;
$list-item-icon-size: 16px;
$list-item-icon-margin-x: 8px;
$list-item-icon-margin-y: 8px;
$list-item-connector-width: 2px;

.timeline {
  padding: 0;

  // nesting needed to override vuetify defaults
  .timeline__item {
    min-height: $list-item-height;
    padding: 0 $list-item-padding-x;

    .timeline__item__avatar {
      margin-bottom: $list-item-icon-margin-y;
      margin-top: $list-item-icon-margin-y;
      margin-right: $list-item-icon-margin-x;
    }
  }
}

.timeline__item__connector {
  position: absolute;
  left: $list-item-padding-x + ($list-item-avatar-size / 2) -
    ($list-item-connector-width / 2);
  top: -(($list-item-height - $list-item-avatar-size) / 2 +
        $list-item-avatar-size / 2);

  width: $list-item-connector-width;
  height: 100%;

  background: #cccccc;
}
.timeline__item__avatar {
  z-index: 1;
}
</style>
