<template>
  <v-select
    v-if="user && user.apps.length > 1"
    v-model="selectedAppIds"
    :items="user.apps"
    :label="$t('pages.declarations.filterByApp')"
    item-text="name"
    item-value="id"
    multiple
    chips
    hide-details
    class="v-select--one-line"
  >
    <template #selection="{ attrs, item, selected }">
      <v-chip
        v-bind="attrs"
        :input-value="selected"
        close
        outlined
        @click:close="unselectAppId(item)"
      >
        <v-avatar v-if="item.image_url" size="36">
          <v-img
            contain
            :src="item.image_url"
            :alt="item.name"
            max-height="30"
            max-width="30"
          />
        </v-avatar>
        <p v-else>{{ item.name }}</p>
      </v-chip>
    </template>
  </v-select>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('user', ['user']),

    selectedAppIds: {
      get() {
        return this.$store.state.user.declarations.selectedAppIds
      },
      set(value) {
        this.setSelectedAppIds(value)
      }
    }
  },
  watch: {
    selectedAppIds() {
      this.fetchAll({ userId: this.$route.params.userId })
    }
  },
  methods: {
    ...mapMutations('user/declarations', [
      'setSelectedAppIds',
      'unselectAppId'
    ]),
    ...mapActions('user/declarations', ['fetchAll'])
  }
}
</script>
