





















































































import Vue from 'vue'
import { mapGetters, mapMutations } from 'vuex'

import { PAYMENT_KINDS } from '../Loaded.vue'

import hasOwnProp from '@/utils/functions/hasOwnProp'

import IconSvgLydia from '@/assets/icons/payment-methods/lydia.svg.vue'
import IconSvgPayPal from '@/assets/icons/payment-methods/paypal.svg.vue'
import IconSvgIBAN from '@/assets/icons/payment-methods/bank.svg.vue'

import { fetchCoreFromComponentAndHandleError } from '@/utils/functions/fetch-and-handle-errors'

export default Vue.extend({
  components: {
    IconSvgIBAN,
    IconSvgLydia,
    IconSvgPayPal
  },
  props: {
    paymentMethod: {
      type: Object,
      required: true,
      validator: (o) =>
        hasOwnProp(o, 'id') &&
        hasOwnProp(o, 'identifier') &&
        hasOwnProp(o, 'app_name') &&
        hasOwnProp(o, 'type') &&
        hasOwnProp(o, 'is_forgettable') &&
        hasOwnProp(o, 'is_authentication_required') &&
        hasOwnProp(o, 'is_authenticated') &&
        true
    }
  },
  data: () => ({
    isEditDialogOpen: false,

    isAuthenticatingPaymentMethod: false,
    isSendingMailForPaymentMethodAuthentication: false
  }),
  computed: {
    ...mapGetters('auth', { hasWriteUserRight: 'hasWriteUserRight' })
  },
  methods: {
    ...mapMutations('snackbar', ['addToast']),
    ...mapMutations('user/profile', ['updatePaymentMethod']),

    typeIsLegit(type: string) {
      if (PAYMENT_KINDS.includes(type)) return true

      // @ts-ignore
      this.$airbrakeNotify({
        error: new Error(`unknown payment method type : ${type}`)
      })

      return false
    },

    authenticatePaymentMethod(): Promise<void> {
      return fetchCoreFromComponentAndHandleError({
        $vm: this,

        isLoadingKey: 'isAuthenticatingPaymentMethod',
        errorInToast: true,

        axios: {
          url: `/users/${this.$route.params.userId}/payment_methods/${this.paymentMethod.id}/authenticate`,
          method: 'POST'
        },

        onSuccess: (result: any) => {
          this.addToast({
            text: this.$t(
              'pages.user.profile.paymentMethods.paymentMethodAuthenticated'
            ),
            timeout: 4000
          })
          this.updatePaymentMethod(result.payment_method)
        }
      })
    },

    sendMailForPaymentMethodAuthentication(): Promise<void> {
      return fetchCoreFromComponentAndHandleError({
        $vm: this,

        isLoadingKey: 'isSendingMailForPaymentMethodAuthentication',
        errorInToast: true,

        axios: {
          url: `/users/${this.$route.params.userId}/payment_methods/${this.paymentMethod.id}/send_mail_for_authentication`,
          method: 'POST'
        },

        onSuccess: () => {
          this.addToast({
            text: this.$t(
              'pages.user.profile.paymentMethods.mailForPaymentMethodAuthenticationSent'
            ),
            timeout: 4000
          })
        }
      })
    }
  }
})
