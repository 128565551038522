<template>
  <v-btn :loading="isLoading" v-bind="vBtnProps" @click="fetch">
    {{ $t('pages.transfers.facebookConversation') }}
    <v-icon right>mdi-open-in-new</v-icon>
  </v-btn>
</template>

<script>
import MixinAjaxToCore from '@/mixins/ajaxToCore'
export default {
  mixins: [MixinAjaxToCore],
  props: {
    userId: {
      type: Number,
      required: true
    },
    vBtnProps: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    isLoading: false,
    isLoaded: false,
    url: null
  }),
  methods: {
    fetch() {
      return this.fetchCoreFromComponentAndHandleError({
        isLoadingKey: 'isLoading',
        errorInToast: true,
        axios: {
          url: `/users/${this.userId}/facebook_conversation_url`
        },
        onSuccess: (result) => {
          open(result.url)
        },
        knownPublicErrorCodes: ['FACEBOOK_ERROR']
      })
    }
  }
}
</script>
