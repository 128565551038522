export default () => ({
  isSearching: false,
  hasBeenSearched: false,
  isBlankSearch: false,
  cancelToken: null,
  needRefresh: false,
  error: null,

  text: '',

  isFocused: false,

  programs: [],
  matchStart: null,
  matchEnd: null
})
