import { handleErrorAxiosCoreStore } from '@/utils/functions/handle-errors'
import { UNKNOWN_ERROR_USER } from '@/utils/constants/errors'

async function setState(commit, dispatch, result) {
  await dispatch('pushMessages', result.messages)
  commit('setHasMore', result.has_more)
  commit('setNextPaginationKey', result.next_pagination_key)
}

export const pushMessages = function({ commit, _getters }, messages) {
  commit('pushMessages', messages)
}

export const fetch = async function({ commit, dispatch, state }, { userId }) {
  commit('setLoadingState', true)
  commit('setMessages', [])
  commit('resetError')

  let response
  try {
    response = await this.$axios.get(`/users/${userId}/app_timeline`, {
      params: { is_experimental: state.isExperimental }
    })
  } catch (error) {
    handleErrorAxiosCoreStore({ error, commit, store: this })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setError', UNKNOWN_ERROR_USER)
    commit('setLoadingState', false)
    return
  }

  setState(commit, dispatch, response.data.result)

  commit('setLoadedState', true)
  commit('setLoadingState', false)
}

export const fetchMore = async function(
  { state, commit, dispatch },
  { userId }
) {
  if (state.isfetchingMore) return

  commit('setFetchingMoreState', true)
  commit('resetError')

  let response
  try {
    response = await this.$axios.get(`/users/${userId}/app_timeline`, {
      params: {
        pagination_key: state.nextPaginationKey,
        is_experimental: state.isExperimental
      }
    })
  } catch (error) {
    handleErrorAxiosCoreStore({ error, commit, store: this })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setError', UNKNOWN_ERROR_USER)
    commit('setLoadingState', false)
    return
  }

  setState(commit, dispatch, response.data.result)

  commit('setFetchingMoreState', false)
}
