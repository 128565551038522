<template>
  <v-row>
    <v-col cols="6">
      <ValidationProvider
        v-slot="{ errors }"
        name="groupedRefundValue"
        mode="aggressive"
        :rules="groupedRefundMaxRules"
        slim
      >
        <v-text-field
          :disabled="disabled"
          :readonly="readonly"
          :label="
            $t(
              `crud.offers.edit.fields.groupedRefund.value.label.${modified.groupedRefundType}`
            ) + ' *'
          "
          required
          type="number"
          min="0"
          :max="groupedRefundMax"
          :error-messages="errors"
          :value="refundValue"
          :append-outer-icon="groupedRefundTypeIcon"
          @change="$emit('change:refund-value', $event)"
        />
      </ValidationProvider>
    </v-col>
    <v-col cols="6">
      <ValidationProvider
        v-slot="{ errors }"
        name="groupedRefundMinItems"
        mode="aggressive"
        rules="required|integer|min_value:1"
        slim
      >
        <v-text-field
          :disabled="disabled"
          :readonly="readonly"
          :label="
            $t('crud.offers.edit.fields.groupedRefund.minItems.label') + ' *'
          "
          :hint="$t('crud.offers.edit.fields.groupedRefund.minItems.hint')"
          persistent-hint
          required
          type="number"
          min="0"
          :error-messages="errors"
          :value="minItems"
          @change="$emit('change:min-items', $event)"
        />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { ValidationProvider } from 'vee-validate'

import {
  GROUPED_OFFER_TYPE_PERCENT,
  GROUPED_OFFER_TYPE_QUANTITY,
  GROUPED_OFFER_TYPE_ABSOLUTE
} from '~/components/features/crud/offers/steps/step2components/Grouped.vue'

const MAX_REFUND = 100

export default {
  components: {
    ValidationProvider
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    refundValue: {
      type: String,
      default: null
    },
    minItems: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState('crud/offer', ['modified']),

    groupedRefundTypeIcon() {
      switch (this.modified.groupedRefundType) {
        case GROUPED_OFFER_TYPE_PERCENT:
          return 'mdi-percent'
        case GROUPED_OFFER_TYPE_ABSOLUTE:
          return 'mdi-currency-eur'
        case GROUPED_OFFER_TYPE_QUANTITY:
          return 'mdi-close'
      }

      return undefined
    },

    isGroupedRefundInPercentage() {
      return this.modified.groupedRefundType === GROUPED_OFFER_TYPE_PERCENT
    },
    groupedRefundMax() {
      return this.isGroupedRefundInPercentage ? MAX_REFUND : undefined
    },
    groupedRefundMaxRules() {
      if (this.isGroupedRefundInPercentage) {
        return `required|min_value:0|max_value:${MAX_REFUND}`
      } else {
        return `required|min_value:0`
      }
    }
  }
}
</script>
