import { createLoadableState } from '@/utils/store/concerns/loadable/state'
import { createCancellableState } from '@/utils/store/concerns/cancellable/state'

export default () =>
  createCancellableState(
    createLoadableState({
      purchasesCount: null,
      purchasesTotal: null,
      productsCount: null
    })
  )
