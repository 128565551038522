import {
  KIND_COUPON,
  KIND_FIDZ,
  KIND_DONATION,
  KIND_CASHBACK,
  KIND_GOODY
} from '@/store/program/catalog/state.js'

import {
  TAB_REWARDS,
  TAB_SHELVES,
  TAB_CHANGE_CATALOG
} from '@/pages/programs/management/_programIdentifier/catalog.vue'

import {
  PROGRAM_KIND_POINTS_VIA_PRICE,
  PROGRAM_KIND_POINTS_BY_PURCHASE,
  PROGRAM_KIND_STAMPS,
  PROGRAM_KIND_PUZZLE,
  PROGRAM_KIND_FIDZ
} from '~/store/program/state.js'

export default {
  createModal: {
    modalTitle: 'Créer une récompense',
    buttonCreate: 'Ajouter une récompense',

    kind: 'Type',
    name: 'Nom',
    priority: 'Priorité',
    goody: {
      transporter: "Responsable de l'envoi du cadeau",

      fields: {
        title: 'Civilité: Mme, Mlle ou M.',
        lastName: 'Nom de famille',
        firstName: 'Prénom',
        address1: 'Adresse 1',
        address2: 'Adresse 2',
        postalCode: 'Code postal',
        city: 'Ville',
        externalEmail: 'Email',
        phoneNumber: 'Numéro de téléphone',
        customLabel: 'Nom personalisé sur le cadeau'
      },

      makeFieldMandatory: 'Rendre le champ obligatoire',

      titles: {
        main:
          "Ensemble des informations pouvants être demandées à l'utilisateur pour l'envoi du goody",

        personnalInformations: 'Informations personnelles',
        address: 'Adresse et ville',
        contactInformations: 'Informations de contact',
        misc: 'Autre'
      }
    },
    refund: 'Remboursement',
    recommendedPrice: 'Prix Public Recommandé',

    eansList: 'Liste des EANs',
    tabs: {
      primary: {
        name: 'primaire',
        info:
          "Liste des EANs des produits ciblés par l'offre (les EANs que l'utilisateur peut scanner)"
      },
      secondary: {
        name: 'secondaire',
        info:
          "Liste des EANs des produits assimilés, acceptés du coupon lors d'une recherche par le robot ou taskforce"
      }
    },

    fillEAN:
      'Entrez les codes-barre des produits concernés, séparés par une virgule',
    showEAN:
      'Les codes-barre des produits concernés. En premier les codes barres de la liste primaire, puis ceux de la liste secondaire',

    fidzValue: 'Nombre de Fidz donnés',
    cashbackValue: "Nombre d'euros remboursés",

    illustration: 'Illustration',
    backgroundImage: 'Image de fond',
    adminDescription: "Description interne (non visible dans l'app)",

    description: "Description (visible dans l'app)",
    formattedDescription: "Description formattée (visible dans l'app)",

    kinds: {
      [KIND_COUPON]: 'Remboursement',
      [KIND_FIDZ]: 'Gain de Fidz',
      [KIND_DONATION]: 'Don',
      [KIND_CASHBACK]: 'Euros en cagnotte',
      [KIND_GOODY]: 'Goody'
    },

    none: 'Aucune'
  },
  validate: 'Valider',
  next: 'Suivant',
  prev: 'Précédent',
  availableRewards: {
    noneFound: "Aucune récompense active n'a été trouvée"
  },
  unavailableRewards: {
    noneFound: "Aucune récompense inactive n'a été trouvée"
  },
  editReward: {
    requiredPoints: 'Points requis',
    availableAt: 'Disponible à partir du',
    expiredAt: "N'est plus disponible à partir du",
    placeholderDate: 'DD/MM/YYYY'
  },
  reward: {
    inactive: 'Récompenses inactives',
    active: 'Récompenses actives',

    actions: {
      showDetails: 'voir les détails',
      discounts: {
        on: 'des promotions actives existent',
        off: 'aucune promotion active'
      },
      exclusivePartners: {
        on: 'disponible exclusivement sur certaines app',
        off: 'disponible sur toutes les app'
      },

      edit: 'modifier',
      expire: 'expirer',
      activate: 'activer',
      duplicate: 'dupliquer',

      createDiscount: 'Ajouter une promotion'
    },

    confirm: {
      expiration: 'Êtes-vous sûr de vouloir expirer cette récompense ?',
      activation: 'Êtes-vous sûr de vouloir activer cette récompense ?'
    },
    successfully: {
      expired: 'récompense expirée avec succès',
      activated: 'récompense activée avec succès'
    },

    modal: {
      title: {
        showDetails: 'Détails de la récompense',
        listDiscounts: 'Liste des promotions',
        listExclusivePartners: 'Liste des exclusivités',

        editReward: 'Modifier la récompense',
        addDiscount: 'Ajouter une promotion à cette récompense'
      }
    },

    availableSince: 'Disponible à partir du {date} à {time}',
    hasExpiredSince: "N'est plus disponible depuis le {date} à {time}",
    value: {
      [PROGRAM_KIND_POINTS_VIA_PRICE]:
        'Pour aucun point | Pour {count} point | Pour {count} points',
      [PROGRAM_KIND_POINTS_BY_PURCHASE]:
        'Pour aucun point | Pour {count} point | Pour {count} points',
      [PROGRAM_KIND_STAMPS]:
        'Pour aucun tampon | Pour {count} tampon | Pour {count} tampons',
      [PROGRAM_KIND_PUZZLE]:
        'Pour aucune pièce | Pour {count} pièce | Pour {count} pièces',
      [PROGRAM_KIND_FIDZ]:
        'Pour aucun Fidz | Pour {count} Fidz | Pour {count} Fidz'
    },
    warningExclusivity:
      "Attention, en ajoutant une exclusivité à une récompense, elle n'apparait que dans ce canal ou les canaux sélectionnés "
  },
  modalNewReward: {
    step1: 'Nom et Paramètres',
    step2: {
      [KIND_COUPON]: 'Valeurs et produits',
      [KIND_FIDZ]: 'Valeur',
      [KIND_DONATION]: 'Descriptions et Images',
      [KIND_CASHBACK]: 'Valeur',
      [KIND_GOODY]: 'Goody information'
    },
    step3: {
      [KIND_COUPON]: 'Descriptions et Images',
      [KIND_FIDZ]: 'Descriptions et Images',
      [KIND_DONATION]: 'Récapitulatif',
      [KIND_CASHBACK]: 'Descriptions et Images',
      [KIND_GOODY]: 'Descriptions et Images'
    },
    step4: {
      [KIND_COUPON]: 'Récapitulatif',
      [KIND_FIDZ]: 'Récapitulatif',
      [KIND_CASHBACK]: 'Récapitulatif',
      [KIND_GOODY]: 'Récapitulatif'
    }
  },
  failedRequest: 'Requête échouée',
  successedRequest: 'Requête réussie avec succès',
  shelf: {
    displayInactiveRewards: 'Afficher les récompenses inactives',
    rewardsWithoutShelves: 'Récompenses sans étagère',
    noRewardsWithoutShelves: 'Aucune récompense sans étagère',
    shelves: 'Étagères',
    noRewardsInShelf: 'Aucune récompense',
    updatingRewardFailed: 'Échec de la mise à jour de la récompense',
    orderInAShelfIsNotImportantNorSaved:
      "L'ordre au sein d'une étagère affiché sur cette page n'est pas pertinent (il dépend d'autres critères ; en particulier, du coût en points)",

    createNewRow: 'Ajouter une étagère',
    shelfCreated: 'Étagère ajoutée',
    searchReward: 'Rechercher une récompense',
    selectReward: 'Selectionner une récompense',
    rowName: "Nom de l'étagère",

    textColor: 'Couleur du texte',
    textColorWhite: 'blanc',
    textColorBlack: 'noir',

    backgroundColor: "Couleur d'arrière plan",
    backgroundColorChoice: "Choix de la couleur d'arrière plan",
    backgroundColorChoiceSubtitle: '(Couleur dominante ou personnalisé):',
    colorIsNilOn: 'couleur du programme',
    colorIsNilOff: 'couleur personalisée',

    preview: 'Aperçu',

    changeShelfTitleColor: 'changer la couleur du titre',
    white: 'blanc',
    black: 'noir',

    changeShelfBackgroundColor: 'changer la couleur de fond',
    colorOfProgram: 'couleur du programme',
    colorCustom: 'couleur personnalisée',

    saveChanges: 'enregistrer les changements',
    reorderShelves: 'réordonner les étagères',
    deleteShelf: "supprimer l'étagère",
    addReward: 'ajouter une récompense'
  },
  changeRewards: {
    title: 'Changement programme - retargeting',
    rewardsToReplace: 'Récompenses à remplacer',
    replacementRewards: 'Récompenses de remplacement',
    previewChanges: 'Visualiser les changements',
    modalTitle: 'Prévisualisation des changements',
    deleteRowTooltip: 'Supprime toute la ligne',
    deleteRewardTooltip: 'Supprime la nouvelle récompense',
    modal: {
      back: 'Revenir en arrière',
      submit: 'Effectuer les changements',
      submittedError: 'Erreur lors de la mofification',
      updateNow: 'Effectuer ces changements tout de suite',
      updateLater:
        'Planifier la mise en ligne des changements à une date ultérieure',
      launchHour: 'Heure de la mise en ligne',
      launchDate: 'Date de la mise en ligne',
      updateNowSuccessMessage: 'Vos changements ont bien été appliqués',
      updateLaterSuccessMessage:
        'Vos changements ont bien été enregistrés, ils vont être appliqués prochainement',
      errorMessage: "Une erreur s'est produite",
      timeErrorMessage:
        "Une erreur s'est produite, merci de bien renseigner les deux champs de planification",
      warning:
        'Toutes les récompenses de gauche doivent avoir un remplacement sur la droite',
      loading: 'Veuillez patienter',
      reward: {
        created: 'récompense créée avec succès',
        updated: 'récompense mise à jour avec succès',
        alreadyRequested:
          "Cette récompense a déjà demandée par un(des) utilisateur(s). Afin de garder un historique cohérent, il est impossible de changer son coût. Vous devez la dupliquer et expirer l'ancienne."
      }
    },
    explanations: {
      removeUnecessaryLines:
        'Toutes les récompenses dans la colonne de gauche sont les récompenses que vous voulez expirer, supprimez toutes les lignes des récompenses que vous ne voulez pas modifier en cliquant sur la poubelle à droite de la ligne.',
      attributeReplacementRewards:
        'Pour chaque récompense à expirer (à gauche), associez lui la récompense qui va la remplacer (à droite).',
      previewChanges:
        'Cliquez ensuite sur le bouton "{buttonText}" pour prévisualiser les changements qui vont être appliqués.'
    }
  },

  tabs: {
    title: {
      [TAB_REWARDS]: 'Récompenses',
      [TAB_SHELVES]: 'Étagères',
      [TAB_CHANGE_CATALOG]: 'Changer le catalogue'
    }
  },

  discount: {
    buttonCreate: 'ajouter une promotion',

    header: {
      discountOf:
        'remise de %{count} pt|remise de %{count} pt|remise de %{count} pts',
      validNTimes:
        'valable %{count} fois|valable %{count} fois|valable %{count} fois',
      forNUsers:
        'pour %{count} utilisateur|pour %{count} utilisateur|pour %{count} utilisateurs',
      availableFromTo: 'du %{from} au %{to}',
      availableSince: 'depuis le %{since}'
    },
    public: {
      title: 'promotions publiques',
      noDiscount: 'aucune promotion publique'
    },
    targeted: {
      title: 'promotions ciblées',
      noDiscount: 'aucune promotion ciblée'
    },

    thisPeriod: 'cette promotion',

    form: {
      points: 'Montant de la remise',
      quota: "Nombre d'utilisation possible",

      searchUser: 'Chercher un utilisateur',
      searchUserPlaceholder: 'Taper un mail, un nom, un ID, ...',
      noSearchResults: 'Aucun utilisateur ne correspond à votre recherche',

      startedAt: 'Sélectionner la date de début',
      endedAt: 'Sélectionner la date de fin',
      publicMode: 'Promotion publique',
      personnalMode: 'Promotion ciblée sur un utilisateur',

      mode: {
        public: 'Promotion publique',
        targeted: 'Promotion ciblée sur un utilisateur'
      },

      createdWithSuccess: 'promotion créée avec succès',
      updatedWithSuccess: 'promotion mise à jour avec succès',
      destroyedWithSuccess: 'promotion supprimée avec succés'
    }
  },

  modalExclusivePartners: {
    noneSelectedAvailableEverywhere:
      'aucune exclusivité, cette récompense est disponible partout.',
    onlyOnSelectedApps:
      "cette récompense n'est disponible que sur les app sélectionnées"
  }
}
