import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VInput } from 'vuetify/lib/components/VInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"8"}},[_c('h3',[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayFields),expression:"displayFields"}]},[_c('div',[_c(VFileInput,{attrs:{"disabled":_vm.disabled,"clearable":"","accept":"image/*","label":_vm.disabled ? undefined : _vm.$t('global.image.sendAFile'),"value":_vm.imageFile},on:{"change":function($event){return _vm.$emit('input:file', $event)}}})],1),_vm._v(" "),_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"mr-3"},[_vm._v("\n          "+_vm._s(_vm.$t('global.or'))+"\n        ")]),_vm._v(" "),_c(VTextField,{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"clearable":"","label":_vm.disabled ? undefined : _vm.$t('global.image.useAnUrl'),"value":_vm.imageUrl},on:{"input":function($event){return _vm.$emit('input:url', $event)}}})],1),_vm._v(" "),(_vm.required)?_c('ValidationProvider',{attrs:{"name":("image" + _vm.formattedImageName + "Required"),"mode":"aggressive","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VInput,{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly},model:{value:(_vm.imagePreview),callback:function ($$v) {_vm.imagePreview=$$v},expression:"imagePreview"}}),_vm._v(" "),_c(VAlert,{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],attrs:{"type":"error"}},[_vm._v("\n          "+_vm._s(errors.join('.'))+"\n        ")])]}}],null,false,759384166)}):_vm._e(),_vm._v(" "),(_vm.sizes)?_c('ValidationProvider',{attrs:{"name":("image" + _vm.formattedImageName + "Sizes"),"mode":"aggressive","rules":_vm.rulesOnSizes,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VInput,{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly},model:{value:(_vm.sizes),callback:function ($$v) {_vm.sizes=$$v},expression:"sizes"}}),_vm._v(" "),_c(VAlert,{directives:[{name:"show",rawName:"v-show",value:(errors.length > 0),expression:"errors.length > 0"}],attrs:{"type":"error"}},[_vm._v("\n          "+_vm._s(errors.join('.'))+"\n        ")])]}}],null,false,911080285)}):_vm._e(),_vm._v(" "),_c(VAlert,{directives:[{name:"show",rawName:"v-show",value:(_vm.isMinSizeWarningDisplayed),expression:"isMinSizeWarningDisplayed"}],attrs:{"type":"warning"}},[_vm._v("\n        "+_vm._s(_vm.$t('validations.rules.image_min_size', { min: _vm.minSizeAsWarning }))+"\n      ")]),_vm._v(" "),_c(VAlert,{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],attrs:{"type":"error"}},[_vm._v("\n        "+_vm._s(_vm.error)+"\n      ")])],1)]),_vm._v(" "),_c(VCol,{staticClass:"justify-end",attrs:{"cols":"4"}},[(!_vm.disabled && _vm.imagePreview && _vm.removable)?_c('div',[_c(VSwitch,{staticClass:"mt-0 mb-3",attrs:{"inset":"","hide-details":"","label":_vm.$t('global.image.remove')},on:{"change":function($event){return _vm.$emit('input:should-remove', $event)}},model:{value:(_vm.localShouldRemove),callback:function ($$v) {_vm.localShouldRemove=$$v},expression:"localShouldRemove"}})],1):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayFields),expression:"displayFields"}]},[(_vm.templateUrl)?_c('div',[_c('a',{attrs:{"href":_vm.templateUrl,"target":"_blank"}},[_vm._v("\n          "+_vm._s(_vm._f("capitalize")(_vm.$t('global.template')))+"\n          "),_c(VIcon,{attrs:{"x-small":""}},[_vm._v("mdi-open-in-new")])],1)]):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.imagePreview),expression:"imagePreview"}],staticClass:"d-flex flex-row justify-end"},[_c(VSheet,{attrs:{"outlined":"","tile":""}},[_c('img',{ref:"img",staticClass:"sheet__img",style:({
              maxHeight: (_vm.height + "px")
            }),attrs:{"src":_vm.imagePreview},on:{"load":_vm.setSizes,"error":_vm.onError}})])],1),_vm._v(" "),(_vm.sizes)?_c('div',[_vm._v("\n        "+_vm._s(_vm.$t('global.image.sizes'))+" : "+_vm._s(_vm.sizes.width)+" x\n        "+_vm._s(_vm.sizes.height)+"\n      ")]):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }