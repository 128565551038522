<template>
  <v-tabs align-with-title :background-color="paletteScanobar.black">
    <v-tab v-for="tab in tabs" :key="tab.identifier" nuxt :to="tab.to">
      {{ tab.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
import MixinPaletteScanobar from '@/mixins/paletteScanobar'

export const TAB_REFUND = 'refund'
export const TAB_BONUS = 'bonus'
export const TAB_GROUPED = 'grouped'

export const TABS = [TAB_REFUND, TAB_BONUS, TAB_GROUPED]

export const DEFAULT_TAB = TAB_REFUND

export default {
  mixins: [MixinPaletteScanobar],
  computed: {
    tabs() {
      return TABS.map((tab) => ({
        identifier: tab,
        to: `/animation/offers/${tab}`,
        title: this.$t(`layout.animation.offers.tabs.${tab}`)
      }))
    }
  }
}
</script>
