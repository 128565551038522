import Vue from 'vue'

export const loading = (state) => {
  state.isLoading = true
  state.isLoaded = false
}
export const loaded = (state) => {
  state.isLoading = false
  state.isLoaded = true
}

export const setIsModalOpenForAddingShelf = (state, boolean) => {
  state.isModalOpenForAddingShelf = boolean
}
export const setIsCreatingShelf = (state, boolean) => {
  state.isCreatingShelf = boolean
}

export const setIsLoading = (state, boolean) => {
  state.isLoading = boolean
}
export const setIsLoaded = (state, boolean) => {
  state.isLoaded = boolean
}
export const setIsSearching = (state, boolean) => {
  state.isSearching = boolean
}

function hydrateRow(row) {
  const now = new Date()
  return {
    id: row.identifier,
    name: row.name,
    color: row.color || '#000000',
    isBlack: row.is_dark,
    colorIsNil: row.color === null,
    priority: row.row,
    rewards: row.loyalty_rewards.map((reward) =>
      hydrateReward(reward, now, row)
    ),

    isSaving: false,
    isDeleting: false
  }
}

function hydrateReward(reward, now, row = null) {
  const availableAt = reward.available_at && new Date(reward.available_at)
  const isAvailable = availableAt && availableAt <= now
  const expiredAt = reward.expired_at && new Date(reward.expired_at)
  const isExpired = expiredAt && expiredAt <= now
  return {
    id: reward.identifier,
    name: reward.name,
    points: reward.value,
    priority: reward.priority,
    rowId: row && row.identifier,
    expiredAt,
    availableAt,
    imageUrl: reward.image_url,
    isAvailable,
    isExpired,
    isActive: isAvailable && !isExpired
  }
}

export const setLoyaltyRewardsRows = (state, value) => {
  state.loyaltyRewardsRows = value.map(hydrateRow)
}

export const addShelfFromBackend = (state, value) => {
  state.loyaltyRewardsRows.push(hydrateRow(value))
}

export const setShelfRowsPriority = (state, value) => {
  state.loyaltyRewardsRows = value.map((row, priority) => {
    return { ...row, priority }
  })
}

export const setRewardsPriority = (state, info) => {
  let priority = 0
  let reOrderedPriority = info.value.sort((a, b) => a.points - b.points)
  reOrderedPriority = reOrderedPriority.map((val, i, array) => {
    if (i && array[i - 1].points !== val.points) priority = 0
    else if (i && array[i - 1].points === val.points) priority++
    val.priority = priority
    return val
  })
  state.loyaltyRewardsRows = state.loyaltyRewardsRows.map((row) => {
    if (row.id === info.id) return { ...row, rewards: reOrderedPriority }
    return row
  })
}

export const setRewardsWithoutShelf = (state, value) => {
  const now = new Date()
  state.rewardsWithoutShelf = value.map((reward) => hydrateReward(reward, now))
}

export const updateRewardsWithoutShelf = (state, newRewards) => {
  state.rewardsWithoutShelf = newRewards
}

export const changeShelfName = (state, info) => {
  state.loyaltyRewardsRows = state.loyaltyRewardsRows.map((row) => {
    if (row.id === info.rowId) return { ...row, name: info.name }
    return row
  })
}

export const changeShelfColor = (state, info) => {
  state.loyaltyRewardsRows = state.loyaltyRewardsRows.map((row) => {
    if (row.id === info.rowId) return { ...row, color: info.color }
    return row
  })
}

export const changeIsBlack = (state, info) => {
  state.loyaltyRewardsRows = state.loyaltyRewardsRows.map((row) => {
    if (row.id === info.rowId) return { ...row, isBlack: info.isBlack }
    return row
  })
}

export const changeColorIsNil = (state, info) => {
  state.loyaltyRewardsRows = state.loyaltyRewardsRows.map((row) => {
    if (row.id === info.rowId) return { ...row, colorIsNil: info.colorIsNil }
    return row
  })
}

export const updateRewardsInShelfRow = (state, data) => {
  state.loyaltyRewardsRows = state.loyaltyRewardsRows.map((row) =>
    row.id === data.rowId ? { ...row, rewards: data.rewards } : row
  )
}

export const setRewardSavingState = (state, data) => {
  let found = false
  state.rewardsWithoutShelf.forEach((reward) => {
    if (reward.id === data.rewardId) {
      found = true
      Vue.set(reward, 'isSaving', data.state)
    }
  })
  if (found) return

  state.loyaltyRewardsRows.forEach((row) => {
    row.rewards.forEach((reward) => {
      if (reward.id === data.rewardId) {
        found = true
        Vue.set(reward, 'isSaving', data.state)
      }
    })
  })
}

export const setShelfDeletingState = (state, { rowId, value }) => {
  state.loyaltyRewardsRows.forEach((row) => {
    if (row.id !== rowId) return

    Vue.set(row, 'isDeleting', value)
  })
}
export const setShelfSavingState = (state, { rowId, value }) => {
  state.loyaltyRewardsRows.forEach((row) => {
    if (row.id !== rowId) return

    Vue.set(row, 'isSaving', value)
  })
}

export const setRewardSavedState = (state, data) => {
  let found = false
  state.rewardsWithoutShelf.forEach((reward) => {
    if (reward.id === data.rewardId) {
      found = true
      Vue.set(reward, 'wasSaved', data.state)
    }
  })
  if (found) return

  state.loyaltyRewardsRows.forEach((row) => {
    row.rewards.forEach((reward) => {
      if (reward.id === data.rewardId) {
        found = true
        Vue.set(reward, 'wasSaved', data.state)
      }
    })
  })
}

export const removeShelfFromReward = (state, rewardId) => {
  let rewardToMove

  state.loyaltyRewardsRows.every((row) => {
    const index = row.rewards.findIndex((reward) => reward.id === rewardId)
    if (index === -1) return true

    rewardToMove = row.rewards[index]
    row.rewards.splice(index, 1)
    return false
  })

  if (rewardToMove) {
    state.rewardsWithoutShelf.push(rewardToMove)
  }
}
