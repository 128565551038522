import { get } from 'lodash-es'

// https://github.com/vuejs/vuex/blob/329828dba41dec59a6880e69a32f9da36c62f3bd/src/helpers.js#L138-L152
function normalizeMap(map) {
  return Array.isArray(map)
    ? map.map((key) => ({ key, val: key }))
    : Object.keys(map).map((key) => ({ key, val: map[key] }))
}

// problem : https://github.com/logaretm/vee-validate/issues/1992
// solution based on https://forum.vuejs.org/t/vuex-v-model-on-property-in-nested-object/6242
export const mapModified = (namespace, params) => {
  const res = {}
  normalizeMap(params).forEach(({ key, val }) => {
    res[key] = {
      get() {
        return get(this.$store.state, namespace.replace(/\//g, '.')).modified[
          val
        ]
      },
      set(value) {
        this.updateAttribute({ key, value })
      }
    }
  })
  return res
}
