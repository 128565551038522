export default {
  title: 'Configuration spécifique aux programmes à points',

  brands: {
    givingPoints: {
      title: 'Configuration des marques donnant des points',
      table: {
        headers: {
          name: 'nom',
          actions: 'actions'
        }
      }
    },
    notGivingPoints: {
      title: 'Configuration des marques ne donnant pas de points',
      subtitle:
        'Indiquez uniquement celles qui pourrait porter à confusion (ex: certaines gammes de produits)',
      table: {
        headers: {
          name: 'nom',
          reason: 'reason',
          actions: 'actions'
        }
      },
      reason: {
        label: 'raison',
        hint: "affichée à l'utilisateur lors d'un scan de code-barre"
      }
    },
    confirmDeletion:
      'Êtes-vous sûr de vouloir supprimer la marque %{names} ?|Êtes-vous sûr de vouloir supprimer la marque %{names} ?|Êtes-vous sûr de vouloir supprimer les marques %{names} ?'
  }
}
