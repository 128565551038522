export default () => ({
  isLoading: false,
  isLoaded: false,
  error: null,
  editors: null,
  user: null,

  isFetchingProgram: false,
  loyaltyPrograms: null,
  loyaltyRewards: null
})
