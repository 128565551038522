<template>
  <v-list-item dark class="red darken-1">
    <v-list-item-avatar tile size="100px">
      <v-img
        :alt="$t('crud.offers.edit.products.imageAlt')"
        src="https://cdn1.skerou.com/images/products/unknown.png"
      />
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>
        <strong>{{ $t('crud.offers.edit.products.list.item.ean') }} :</strong>
        {{ ean }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ $t('crud.offers.edit.products.unknownProduct') }}
      </v-list-item-subtitle>
      <v-list-item-title>
        <strong>
          {{ $t('crud.offers.edit.products.mustCreateProduct') }} !
        </strong>
      </v-list-item-title>

      <ProductEditModal
        v-if="isEditing"
        v-model="isEditing"
        :ean="ean"
        @product-saved="$emit('product-created', $event)"
      />
    </v-list-item-content>

    <v-list-item-action v-if="!disabled && !readonly">
      <v-icon :disabled="isEditing" @click="isEditing = true">
        mdi-plus
      </v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import ProductEditModal from './ProductEditModal'
export default {
  components: { ProductEditModal },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    ean: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isEditing: false
  })
}
</script>
