export default {
  data: {
    success: true,
    result: {
      identifier: 'loyalty-program-233',
      name: 'Lotus',
      kind: 'points'
    },
    errors: null
  }
}
