import { createCancellableState } from '@/utils/store/concerns/cancellable/state'
import {
  createLoadableState,
  LoadableState
} from '~/utils/store/concerns/loadable/state'

export interface Product {}

export interface ProductsByEan {
  [key: string]: Product
}

export interface NotLoadableState {
  eansBeingFetched: string[]
  productsByEan: null | ProductsByEan
}
export interface State extends NotLoadableState, LoadableState {}

export default () =>
  createLoadableState(
    createCancellableState({
      eansBeingFetched: [],
      productsByEan: null
    })
  )
