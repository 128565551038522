import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,[_c('ValidationProvider',{attrs:{"name":"rewardKind","mode":"eager","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c(VSelect,{attrs:{"disabled":_vm.disabled || _vm.isUpdatingReward,"readonly":_vm.readonly,"label":_vm.$t('pages.program.catalog.createModal.kind'),"items":_vm.kinds,"outlined":"","hide-details":"","value":_vm.form.kind,"error-messages":errors},on:{"change":_vm.changeKind}})]}}])})],1)],1),_vm._v(" "),_c(VRow,[_c(VCol,[_c('ValidationProvider',{attrs:{"name":"rewardName","mode":"eager","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var errors = ref.errors;
return [_c(VTextField,{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"label":_vm.$t('pages.program.catalog.createModal.name'),"value":_vm.form.name,"error-messages":errors},on:{"input":_vm.changeName}})]}}])})],1)],1),_vm._v(" "),_c(VRow,[_c(VCol,[_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":"rewardAvailableAt","mode":"eager","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"disabled":_vm.disabled,"value":_vm.formattedAvailableAt,"label":_vm.$t('pages.program.catalog.editReward.availableAt'),"placeholder":_vm.$t('pages.program.catalog.editReward.placeholderDate'),"dense":"","outlined":"","hide-details":"","readonly":"","error-messages":errors}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.isMenuOpenAvailableAt),callback:function ($$v) {_vm.isMenuOpenAvailableAt=$$v},expression:"isMenuOpenAvailableAt"}},[_vm._v(" "),_c(VDatePicker,{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.isMenuOpenAvailableAt = false}},model:{value:(_vm.availableAtDate),callback:function ($$v) {_vm.availableAtDate=$$v},expression:"availableAtDate"}})],1)],1),_vm._v(" "),_c(VCol,[_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"disabled":_vm.disabled,"value":_vm.formattedExpiredAt,"label":_vm.$t('pages.program.catalog.editReward.expiredAt'),"placeholder":_vm.$t('pages.program.catalog.editReward.placeholderDate'),"dense":"","outlined":"","hide-details":"","readonly":"","clearable":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.isMenuOpenExpiredAt),callback:function ($$v) {_vm.isMenuOpenExpiredAt=$$v},expression:"isMenuOpenExpiredAt"}},[_vm._v(" "),_c(VDatePicker,{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.isMenuOpenExpiredAt = false}},model:{value:(_vm.expiredAtDate),callback:function ($$v) {_vm.expiredAtDate=$$v},expression:"expiredAtDate"}})],1)],1)],1),_vm._v(" "),(_vm.displayRequiredPointsForSelectedProgram)?_c(VRow,[_c(VCol,[_c('ValidationProvider',{attrs:{"name":"rewardRequiredPoints","mode":"eager","rules":"required|integer|min_value:0","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"type":"number","label":_vm.$t('pages.program.catalog.editReward.requiredPoints'),"value":_vm.form.requiredPoints,"error-messages":errors},on:{"input":_vm.changeRequiredPoints}})]}}],null,false,3932529577)})],1),_vm._v(" "),_c(VCol)],1):_vm._e(),_vm._v(" "),_c(VRow,[_c(VCol,[_c('ValidationProvider',{attrs:{"name":"rewardPriority","mode":"eager","rules":"integer|min_value:0","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"type":"number","label":_vm.$t('pages.program.catalog.createModal.priority'),"value":_vm.form.priority,"error-messages":errors},on:{"change":_vm.changePriority}})]}}])})],1),_vm._v(" "),_c(VCol)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }