import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.discounts),function(discount){return _c(VExpansionPanel,{key:discount.id},[_c(VExpansionPanelHeader,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"2"}},[_vm._v(" #"+_vm._s(discount.id)+" ")]),_vm._v(" "),_c(VCol,{attrs:{"cols":"3"}},[_vm._v("\n          "+_vm._s(_vm.$tc(
              'pages.program.catalog.discount.header.discountOf',
              discount.value,
              {
                count: discount.value
              }
            ))+"\n        ")]),_vm._v(" "),_c(VCol,{attrs:{"cols":"3"}},[_vm._v("\n          "+_vm._s(_vm.$tc(
              'pages.program.catalog.discount.header.validNTimes',
              discount.quota,
              { count: discount.quota }
            ))+"\n        ")]),_vm._v(" "),_c(VCol,{attrs:{"cols":"4"}},[_vm._v("\n          "+_vm._s(discount.ended_at
              ? _vm.$t('pages.program.catalog.discount.header.availableFromTo', {
                  from: _vm.momentFormat(discount.started_at, 'date'),
                  to: _vm.momentFormat(discount.ended_at, 'date')
                })
              : _vm.$t('pages.program.catalog.discount.header.availableSince', {
                  since: _vm.momentFormat(discount.started_at, 'date')
                }))+"\n        ")])],1)],1),_vm._v(" "),_c(VExpansionPanelContent,[_c('EditDiscount',{attrs:{"discount":discount},on:{"updated":function($event){_vm.upsertDiscount({
            rewardId: _vm.rewardId,
            discount: $event
          })
          _vm.panel = -1}}})],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }