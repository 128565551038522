// import { random } from 'lodash-es'
// import loyaltyRewardsRows from '~/utils/mocked-data/program/catalog/shelf/index.js'
// import newShelfRow from '~/utils/mocked-data/program/catalog/shelf/newRow.js'
// import rewardsWithoutShelf from '~/utils/mocked-data/program/catalog/shelf/searchQuery.js'
import { handleAxiosErrorServerFromStore } from '@/utils/functions/handle-errors'

export const fetchShelves = async function({ commit, rootState }) {
  commit('loading')
  let response
  try {
    response = await this.$axios.get(
      `/programs/${rootState.route.params.programIdentifier}/display_loyalty_reward_shelves`
    )
  } catch (error) {
    handleAxiosErrorServerFromStore({
      error,
      commit,
      store: this,
      loadingMutationName: 'loaded'
    })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('loaded')
    return
  }

  commit('setLoyaltyRewardsRows', response.data.result.loyalty_reward_shelves)
  commit('loaded')
}

export const updateShelfRows = async function({ commit, state, rootState }) {
  commit('loading')
  try {
    await Promise.all(
      state.loyaltyRewardsRows.map(async (shelf) => {
        const response = await this.$axios.post(
          `/loyalty_reward_shelf/${shelf.id}/update`,
          {
            row: shelf.priority,
            name: shelf.name,
            color: shelf.color,
            is_dark: shelf.isBlack,
            color_is_nil: shelf.colorIsNil,
            program_identifier: rootState.route.params.programIdentifier
          }
        )
        if (!response.data.success) {
          this.$airbrakeNotifyUnhandledBadRequest({ response })
          throw new Error(
            `Request for update loyalty_reward_shelf: ${shelf.id} failed !`
          )
        }
      })
    )
  } catch (error) {
    handleAxiosErrorServerFromStore({
      error,
      commit,
      store: this,
      loadingMutationName: 'loaded'
    })
    return
  }

  commit('loaded')
}

export const updateShelfRow = async function({ commit, state, rootState }, id) {
  commit('setShelfSavingState', { rowId: id, value: true })

  let response
  try {
    const shelf = state.loyaltyRewardsRows.find((row) => row.id === id)
    response = await this.$axios.post(
      `/loyalty_reward_shelf/${shelf.id}/update`,
      {
        row: shelf.priority,
        name: shelf.name,
        color: shelf.color,
        is_dark: shelf.isBlack,
        color_is_nil: shelf.colorIsNil,
        program_identifier: rootState.route.params.programIdentifier
      }
    )
  } catch (error) {
    handleAxiosErrorServerFromStore({
      error,
      commit,
      store: this,
      loadingMutationName: 'loaded'
    })
    commit('setShelfSavingState', { rowId: id, value: false })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setShelfSavingState', { rowId: id, value: false })
    return
  }

  commit('setShelfSavingState', { rowId: id, value: false })
}

export const removeShelfRow = async function({ commit, state }, id) {
  commit('setShelfDeletingState', { rowId: id, value: true })

  let response
  try {
    response = await this.$axios.post(`/loyalty_reward_shelf/${id}/delete`)
  } catch (error) {
    this.$airbrakeNotify({ error })
    commit('setShelfDeletingState', { rowId: id, value: false })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setShelfDeletingState', { rowId: id, value: false })
    return
  }

  commit(
    'setShelfRowsPriority',
    state.loyaltyRewardsRows.filter((row) => row.id !== id)
  )
  commit('setShelfDeletingState', { rowId: id, value: false })
}

export const createShelfRow = async function(
  { commit, state, rootState },
  payload
) {
  commit('setIsCreatingShelf', true)

  let response
  try {
    response = await this.$axios.post(`/create_loyalty_reward_shelf`, {
      row: state.loyaltyRewardsRows.length,
      name: payload.name,
      color: payload.color,
      is_dark: payload.isBlack,
      color_is_nil: payload.colorIsNil,
      program_identifier: rootState.route.params.programIdentifier
    })
  } catch (error) {
    handleAxiosErrorServerFromStore({
      error,
      commit,
      store: this,
      loadingMutationName: 'setIsCreatingShelf'
    })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setIsCreatingShelf', false)
    return
  }

  commit('addShelfFromBackend', response.data.result)
  commit(
    'snackbar/addToast',
    {
      text: this.$i18n.t('pages.program.catalog.shelf.shelfCreated')
    },
    { root: true }
  )
  commit('setIsCreatingShelf', false)
  commit('setIsModalOpenForAddingShelf', false)
}

export const addRewardToShelfRow = async function({ commit, state }, payload) {
  commit('loading')
  let response
  try {
    response = await this.$axios.post(
      `/loyalty_rewards/${payload.rewardId}/add_loyalty_reward_shelf_id`,
      { loyalty_reward_shelf_identifier: payload.rowId }
    )
  } catch (error) {
    handleAxiosErrorServerFromStore({
      error,
      commit,
      store: this,
      loadingMutationName: 'loaded'
    })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('loaded')
    return
  }

  const row = state.loyaltyRewardsRows.find((row) => row.id === payload.rowId)
  const reward = state.rewardsWithoutShelf.find(
    (reward) => reward.id === payload.rewardId
  )
  commit('setRewardsPriority', {
    value: [...row.rewards, reward],
    id: row.id
  })
  commit('loaded')
}

export const changeShelfOfReward = async function(
  { commit, dispatch },
  { rowId, rewardId, priority }
) {
  commit('setRewardSavingState', { rewardId, state: true })

  let response
  try {
    response = await this.$axios.post(
      `/loyalty_rewards/${rewardId}/update_loyalty_reward_shelf_id`,
      {
        loyalty_reward_shelf_identifier: rowId,
        priority
      }
    )
  } catch (error) {
    this.$airbrakeNotify({ error })
    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t('pages.program.catalog.shelf.updatingRewardFailed')
      },
      { root: true }
    )
    commit('setRewardSavingState', { rewardId, state: false })
    dispatch('fetchShelves')
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t('pages.program.catalog.shelf.updatingRewardFailed')
      },
      { root: true }
    )
    commit('setRewardSavingState', { rewardId, state: false })
    dispatch('fetchShelves')
    return
  }

  commit('setRewardSavingState', { rewardId, state: false })
  commit('setRewardSavedState', { rewardId, state: true })
  setTimeout(() => {
    commit('setRewardSavedState', { rewardId, state: false })
  }, 2000)
}

export const removeShelfFromReward = async function(
  { commit, dispatch },
  rewardId
) {
  commit('setRewardSavingState', { rewardId, state: true })

  commit('removeShelfFromReward', rewardId)

  let response
  try {
    response = await this.$axios.post(
      `/loyalty_rewards/${rewardId}/delete_loyalty_reward_shelf_id`
    )
  } catch (error) {
    handleAxiosErrorServerFromStore({
      error,
      commit,
      store: this,
      loadingMutationName: 'loaded'
    })
    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t('pages.program.catalog.shelf.updatingRewardFailed')
      },
      { root: true }
    )
    commit('setRewardSavingState', { rewardId, state: false })
    dispatch('fetchShelves')
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit(
      'snackbar/addToast',
      {
        text: this.$i18n.t('pages.program.catalog.shelf.updatingRewardFailed')
      },
      { root: true }
    )
    commit('setRewardSavingState', { rewardId, state: false })
    dispatch('fetchShelves')
    return
  }

  commit('setRewardSavingState', { rewardId, state: false })
  commit('setRewardSavedState', { rewardId, state: true })
  setTimeout(() => {
    commit('setRewardSavedState', { rewardId, state: false })
  }, 2000)
}

export const queryRewardsWithoutShelf = async function({ commit, rootState }) {
  commit('setIsSearching', true)

  let response
  try {
    response = await this.$axios.get(
      `/programs/${rootState.route.params.programIdentifier}/loyalty_rewards_without_shelves`
    )
  } catch (error) {
    handleAxiosErrorServerFromStore({
      error,
      commit,
      store: this,
      loadingMutationName: 'setIsSearching'
    })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setIsSearching', false)
    return
  }

  commit('setRewardsWithoutShelf', response.data.result.loyalty_rewards)
  commit('setIsSearching', false)
}
