import Vue from 'vue'
import { findIndex } from 'lodash-es'

import createDefaultState from './state'

export * from '@/utils/store/concerns/loadable/mutations.js'

export const reset = (state) => {
  Object.assign(state, createDefaultState())
}

export const handleResult = (state, result) => {
  state.currentPeriod = result.current_period
  state.previousPeriods = result.periods
  state.partners = result.partners
  state.refreshedAt = result.refreshed_at
  state.selectedPartnerIds = result.partners.map((p) => p.id)
}

export const setPreviousPeriods = (state, periods) => {
  state.previousPeriods = periods
}
export const selectPeriod = (state, period) => {
  state.selectedPeriod = period
}

export const togglePeriodSelected = (state) => {
  state.isPeriodSelected = !state.isPeriodSelected
}

export const selectCurrentProgramIdentifier = (
  state,
  selectedProgramIdentifier
) => {
  state.currentProgramIdentifier = selectedProgramIdentifier
}
export const togglePartner = (state, partnerId) => {
  const index = findIndex(state.selectedPartnerIds, (id) => id === partnerId)

  if (index === -1) {
    state.selectedPartnerIds.push(partnerId)
  } else {
    state.selectedPartnerIds.splice(index, 1)
  }
}

export const setTabErrors = (state, payload) => {
  Vue.set(state.tabErrors, payload.tab, payload.errors)
}
export const resetTabErrors = (state, tab) => {
  Vue.set(state.tabErrors, tab, null)
}
