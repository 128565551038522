<template>
  <LayoutDefault :title="$t('global.work-in-progress')" />
</template>

<script>
import LayoutDefault from '../default'
export default {
  components: { LayoutDefault }
}
</script>
