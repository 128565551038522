export const subpictures = (_, __, rootState) =>
  rootState.user.declaration.declaration
    ? rootState.user.declaration.declaration.details.subpictures
    : []
export const canShowGallery = (_, __, rootState) =>
  rootState.user.declaration.declaration &&
  !rootState.user.declaration.declaration.details.is_archived
export const hasOneSubpicture = (_, getters) => getters.subpictures.length === 1
export const hasMoreThanOneSubpicture = (_, getters) =>
  getters.subpictures.length > 1
