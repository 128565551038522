




import Vue from 'vue'
import { mapState } from 'vuex'

import Common from './SlidePage3/Common.vue'
import Donation from './SlidePage3/Donation.vue'

import {
  KIND_COUPON,
  KIND_FIDZ,
  KIND_CASHBACK,
  KIND_GOODY,
  KIND_DONATION
} from '@/store/program/catalog/state.js'

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('program/catalog', ['form']),

    is() {
      switch (this.form.kind) {
        case KIND_COUPON:
        case KIND_FIDZ:
        case KIND_CASHBACK:
        case KIND_GOODY:
          return Common
        case KIND_DONATION:
          return Donation
        default:
          return 'div'
      }
    }
  }
})
