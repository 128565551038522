<template>
  <v-tooltip right>
    <template #activator="{ on }">
      <v-btn
        v-if="link.to"
        :class="{ 'close-to-bottom-left': !dial }"
        fab
        :absolute="!dial"
        dark
        :small="!dial"
        :x-small="dial"
        :to="link.to"
        v-on="on"
      >
        <v-icon>{{ link.icon }}</v-icon>
      </v-btn>

      <v-btn
        v-else-if="link.onClick"
        :class="{ 'close-to-bottom-left': !dial }"
        fab
        :absolute="!dial"
        dark
        :small="!dial"
        :x-small="dial"
        v-on="on"
        @click="link.onClick"
      >
        <v-icon>{{ link.icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ link.tooltip }}</span>
  </v-tooltip>
</template>

<script>
import hasOwnProp from '@/utils/functions/hasOwnProp'
export default {
  props: {
    link: {
      type: Object,
      required: true,
      validator: (o) =>
        hasOwnProp(o, 'icon') &&
        hasOwnProp(o, 'tooltip') &&
        (hasOwnProp(o, 'to') || hasOwnProp(o, 'onClick')) &&
        true
    },
    dial: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
.close-to-bottom-left {
  left: 8px;
  bottom: 8px;
}
</style>
