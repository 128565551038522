<template>
  <div class="earned-coupon">
    <div>
      ID #{{ id }} - {{ earnedCoupon.offer.class_name }} #{{
        earnedCoupon.offer.id
      }}
      <!--
      small
        - url = offer.class_name === 'GroupedOffer' ? admin_grouped_offer_path(offer) : admin_loyalty_reward_path(offer)
        = link_to "#{offer.class_name} ##{offer.id}", url
      -->
    </div>
    <DisplayCoupon :coupon="earnedCoupon.coupon" />
  </div>
</template>

<script>
import DisplayCoupon from './DisplayCoupon'
import hasOwnProp from '@/utils/functions/hasOwnProp'
import identifierToId from '@/utils/functions/identifierToId.ts'
export default {
  components: { DisplayCoupon },
  props: {
    earnedCoupon: {
      type: Object,
      required: true,
      validator: (o) =>
        hasOwnProp(o, 'identifier') &&
        hasOwnProp(o, 'coupon') &&
        hasOwnProp(o, 'offer') &&
        hasOwnProp(o.offer, 'class_name') &&
        hasOwnProp(o.offer, 'id') &&
        true
    }
  },
  computed: {
    id() {
      return identifierToId(this.earnedCoupon.identifier)
    }
  }
}
</script>

<style scoped>
.earned-coupon {
  display: inline-block;
  width: 350px;
}
</style>
