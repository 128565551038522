<template>
  <v-stepper v-model="currentStep">
    <v-stepper-header>
      <template v-for="n in stepsCount">
        <v-stepper-step
          :key="`stepper-header-${n}`"
          :complete="currentStep > n"
          :step="n"
        >
          {{ titles[n] }}
        </v-stepper-step>
        <v-divider v-if="n !== stepsCount" :key="`stepper-divider-${n}`" />
      </template>
    </v-stepper-header>
  </v-stepper>
</template>

<script>
export default {
  props: {
    stepsCount: {
      type: Number,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    },
    titles: {
      type: Object,
      required: true
    }
  }
}
</script>
