import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,{class:{ 'green lighten-4': _vm.isValid, 'red lighten-4': !_vm.isValid }},[_c(VListItemAvatar,{attrs:{"tile":"","size":"100px"}},[_c(VImg,{attrs:{"alt":_vm.$t('crud.offers.edit.products.imageAlt'),"src":_vm.product.image_url}})],1),_vm._v(" "),_c(VListItemContent,[_c(VListItemTitle,[_c('strong',[_vm._v(_vm._s(_vm.$t('crud.offers.edit.products.list.item.ean'))+" :")]),_vm._v("\n      "+_vm._s(_vm.ean)+"\n    ")]),_vm._v(" "),_c(VListItemSubtitle,[(!_vm.product.name)?_c('strong',{staticClass:"float-right font-italic red--text"},[_vm._v("\n        "+_vm._s(_vm.$t('crud.offers.edit.products.list.item.missingName'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('strong',[_vm._v("\n        "+_vm._s(_vm._f("capitalize")(_vm.$t('crud.offers.edit.products.list.item.name')))+" :\n      ")]),_vm._v("\n      "+_vm._s(_vm.product.name)+"\n    ")]),_vm._v(" "),_c(VListItemSubtitle,[(!_vm.product.brand_identifier)?_c('strong',{staticClass:"float-right font-italic red--text"},[_vm._v("\n        "+_vm._s(_vm.$t('crud.offers.edit.products.list.item.missingBrand'))+"\n      ")]):_vm._e(),_vm._v(" "),_c('strong',[_vm._v("\n        "+_vm._s(_vm._f("capitalize")(_vm.$t('crud.offers.edit.products.list.item.brand')))+" :\n      ")]),_vm._v("\n      "+_vm._s(_vm.product.brand_name)+"\n    ")]),_vm._v(" "),(_vm.isEditing)?_c('ProductEditModal',{attrs:{"ean":_vm.ean,"product":_vm.product},on:{"product-saved":function($event){return _vm.$emit('product-updated', $event)}},model:{value:(_vm.isEditing),callback:function ($$v) {_vm.isEditing=$$v},expression:"isEditing"}}):_vm._e()],1),_vm._v(" "),(!_vm.disabled && !_vm.readonly)?_c(VListItemAction,[_c(VIcon,{attrs:{"disabled":_vm.isEditing},on:{"click":function($event){_vm.isEditing = true}}},[_vm._v("\n      mdi-pencil\n    ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }