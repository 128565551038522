<template>
  <div>
    <ScopesSelect
      v-if="hasScope"
      :scopes="config.scopes"
      :current-scope-identifier="currentScopeIdentifier"
      @change="currentScopeIdentifier = $event"
    />

    <v-data-table
      v-model="selected"
      class="elevation-1"
      :headers="config.headers"
      :items="items"
      :loading="areItemsLoading"
      :options.sync="options"
      :server-items-length="itemsTotalCount"
      :multi-sort="true"
      :selectable-key="'is_editable'"
      :show-select="false"
      :no-data-text="noDataText"
    >
      <template #item.actions="{ item }">
        <div class="d-flex flex-nowrap">
          <template v-for="action in actions">
            <v-icon
              v-if="action === 'show'"
              :key="`${action}-show`"
              small
              class="mr-2"
              @click="$emit('open-show-modal', item)"
            >
              mdi-eye
            </v-icon>
            <v-icon
              v-else-if="action === 'edit'"
              v-show="hasEditRight"
              :key="`${action}-edit`"
              small
              class="mr-2"
              @click="$emit('open-edit-modal', item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="action === 'duplicate'"
              v-show="hasEditRight"
              :key="`${action}-duplicate`"
              small
              class="mr-2"
              @click="$emit('open-duplicate-modal', item)"
            >
              mdi-content-copy
            </v-icon>
            <div v-else :key="action">
              <slot :name="`item.action.${action}`" :item="item" />
            </div>
          </template>
        </div>
      </template>
      <template #no-data>
        <div
          v-if="errorOnFetchItems"
          class="d-flex justify-center align-center"
        >
          {{ errorOnFetchItems }}
          <v-btn
            x-small
            class="ml-5"
            @click="retry"
            v-text="$t('global.retry')"
          />
        </div>
        <slot
          v-else-if="$scopedSlots['data-table.no-data']"
          name="data-table.no-data"
        />
      </template>

      <template
        v-for="[nameComplete, nameForVuetify] in scopedSlotsForDataTable"
        #[nameForVuetify]="slotData"
      >
        <slot :name="nameComplete" v-bind="slotData" />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { find, findIndex, filter, toPairs } from 'lodash-es'

// TODO: https://typescript.nuxtjs.org/
// <script lang="ts">
// import Vue from 'vue'
// import { DataTableHeader } from 'vuetify'

import ScopesSelect from './ScopesSelect'

import MixinAjaxToCore from '@/mixins/ajaxToCore'

import hasOwnProp from '@/utils/functions/hasOwnProp'

// interface Config {
//   headers: DataTableHeader
//   scopes: Object[]
//   default_options: Object
// }

// export default Vue.extend({
export default {
  components: { ScopesSelect },
  mixins: [MixinAjaxToCore],
  props: {
    config: {
      type: Object,
      required: true,
      validator: (o) =>
        hasOwnProp(o, 'headers') &&
        hasOwnProp(o, 'scopes') &&
        hasOwnProp(o, 'default_options')
    },
    actions: {
      type: Array,
      required: true
    },
    useCase: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      default: false
    },
    doNotFilterBasedOnLoyaltyProgram: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const defaultScope =
      find(this.config.scopes, 'is_default') || this.config.scopes[0]
    return {
      areItemsLoaded: false,
      areItemsLoading: false,
      errorOnFetchItems: null,

      options: this.config.default_options,
      currentScopeIdentifier: defaultScope && defaultScope.identifier,

      items: [],
      itemsTotalCount: 0,

      selected: [],

      isUpdatingMany: false,
      isValidating: false,
      isInvalidating: false,
      errorOnUpdateMany: null,
      dialog: false
    }
  },
  computed: {
    ...mapState('auth', ['selectedProgram']),
    ...mapGetters('auth', ['hasWriteAnimationRight', 'hasWriteManagerRight']),

    hasEditRight() {
      switch (this.useCase) {
        case 'managers':
          return this.hasWriteManagerRight
        default:
          return this.hasWriteAnimationRight
      }
    },
    isSelectionEmpty() {
      return this.selected.length === 0
    },
    hasScope() {
      return this.config.scopes.length > 0
    },
    scopedSlotsForDataTable() {
      return filter(toPairs(this.$scopedSlots), ([name, _]) =>
        name.startsWith('data-table')
      ).map(([name, _]) => [name, name.replace(/^data-table\./g, '')])
    },
    paginationOptions() {
      return {
        sort_by: this.options.sortBy,
        sort_desc: this.options.sortDesc,
        page: this.options.page,
        items_per_page: this.options.itemsPerPage
      }
    },
    noDataText() {
      const key = `crud.useCases.${this.useCase}.noneFound`
      if (this.$te(key)) return this.$t(key)

      this.$airbrakeNotify({
        error: new Error(
          `missing translation for key : ${key} (locale: ${this.$i18n.locale}) `
        )
      })

      return undefined
    }
  },
  watch: {
    options: {
      deep: true,
      handler: 'fetchItems'
    },
    currentScopeIdentifier: 'fetchItems',

    value(val) {
      if (val) {
        this.fetchItems()
        this.$emit('input', false)
      }
    },

    doNotFilterBasedOnLoyaltyProgram: 'fetchItems'
  },
  mounted() {
    this.fetchItems()
  },
  methods: {
    retry() {
      return this.fetchItems()
    },

    async fetchItems() {
      await this.fetchCoreFromComponentAndHandleError({
        isLoadedKey: 'areItemsLoaded',
        isLoadingKey: 'areItemsLoading',
        errorKey: 'errorOnFetchItems',

        axios: {
          url: `/crud/${this.useCase}/index`,
          params: {
            scope_identifier: this.currentScopeIdentifier,
            pagination: this.paginationOptions,
            program_identifier: this.selectedProgram.identifier,
            ignore_selected_program: this.doNotFilterBasedOnLoyaltyProgram
          }
        },

        onError: () => {
          this.selected = []
          this.items = []
          this.itemsTotalCount = 0
        },
        onSuccess: (result) => {
          this.selected = []
          this.items = result.items
          this.itemsTotalCount = result.items_total_count
        }
      })
    },

    // validate() {
    //   if (this.isValidating) return
    //   this.isValidating = true

    //   const transferPayments = this.selected.map((tp) => ({
    //     id: tp.id,
    //     is_validated: true
    //   }))

    //   return this.updateMany(transferPayments).then(() => {
    //     this.isValidating = false
    //   })
    // },
    // invalidate() {
    //   if (this.isInvalidating) return
    //   this.isInvalidating = true

    //   const transferPayments = this.selected.map((tp) => ({
    //     id: tp.id,
    //     is_validated: false
    //   }))

    //   return this.updateMany(transferPayments).then(() => {
    //     this.isInvalidating = false
    //   })
    // },
    // updateMany(transferPayments) {
    //   return this.fetchCoreFromComponentAndHandleError({
    //     isLoadingKey: 'isUpdatingMany',
    //     errorKey: 'errorOnUpdateMany',

    //     axios: {
    //       url: '/transfer_payments/update_many',
    //       method: 'POST',
    //       data: {
    //         transfer_payments: transferPayments
    //       }
    //     },

    //     onSuccess: (_result) => {
    //       transferPayments.forEach((tp) => this.removeItem(tp.id))
    //     }
    //   })
    // },

    removeItem(id) {
      const idx = findIndex(this.items, (i) => i.id === id)
      if (idx === -1) {
        this.$airbrakeNotify({
          error: new Error(`${this.useCase}#${id} not in items`)
        })
        return
      }

      this.items.splice(idx, 1)
    }
  }
}
</script>
