import { Context } from '@nuxt/types'

import colorSwitcher from '~/utils/functions/colorSwitcher'

// MONKEY patch
// https://github.com/nuxt-community/vuetify-module/issues/326#issuecomment-627104678
export default (context: Context) => {
  if (process.client && context.store.state.auth.selectedProgram) {
    colorSwitcher(
      context.$vuetify,
      context.store.state.auth.selectedProgram.dominant_color
    )
  }
}
