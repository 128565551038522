import { PROGRAM_KIND_POINTS_VIA_PRICE } from '../state'

import { createLoadableState } from '@/utils/store/concerns/loadable/state'

// import createInitialForm2 from '~/utils/mocked-data/program/create/puzzle.js'
// import createInitialForm2 from '~/utils/mocked-data/program/create/puzzle2.js'
// export const createInitialForm = () => createInitialForm2()

export const AUTO_GENERATED_CLIENT_INDENTIFIER = 'auto'

const FIDMARQUES_ID = 9

export const createEmptyPiece = () => ({
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  ownedImageUrl: null,
  ownedImageFile: null
})

export const createEmptyBoard = (rank, piecesCount) => ({
  rank,
  width: 0,
  height: 0,
  margin: 0,
  emptyImageFile: null,
  emptyImageUrl: null,
  completedImageFile: null,
  completedImageUrl: null,
  pieces: Array(parseInt(piecesCount || 0))
    .fill() // `map` won't do anything without this
    .map((_) => createEmptyPiece())
})

// TODO: get from backend
export const createInitialForm = () => ({
  name: '',
  kind: PROGRAM_KIND_POINTS_VIA_PRICE,
  hidden: true,
  parentId: null,
  clientId: AUTO_GENERATED_CLIENT_INDENTIFIER,
  partners: [FIDMARQUES_ID],
  displayRank: 0,

  tagLine: '',
  catchPhrase: '',
  description: '',
  brandDescription: '',
  disclaimerMessage: '',
  brandHtmlDescription: '',
  isBrandDescriptionImageVisible: false,

  welcomingPoints: 0,
  maxPointsPerMonth: null,
  maxPointsPerYear: null,
  centsForPoints: 50,
  creditCoefficient: 1,

  stampsMaxCount: 10,
  stampsByLine: 5,

  puzzleTotalPiecesCount: 0,
  puzzleBoards: [createEmptyBoard(1, 0)],

  isRenewable: true, // shared by stamps & puzzle

  dominantColor: '#000000',

  images: {
    squareImageFile: null,
    squareImageUrl: null,
    rectoImageFile: null,
    rectoImageUrl: null,
    versoImageFile: null,
    versoImageUrl: null,
    backgroundImageFile: null,
    backgroundImageUrl: null,
    eligibleBrandImageFile: null,
    eligibleBrandImageUrl: null,
    onboardingClubImageFile: null,
    onboardingClubImageUrl: null,
    onboardingClubImage2xFile: null,
    onboardingClubImage2xUrl: null,
    onboardingClubImage3xFile: null,
    onboardingClubImage3xUrl: null,

    stampLogoImageFile: null,
    stampLogoImageUrl: null
  }
})

export default () =>
  createLoadableState({
    isLoadingClients: false,
    isLoadingPartners: false,
    isLoadingParents: false,

    errorsOnParents: [],
    errorsOnClients: [],
    errorsOnPartners: [],

    clients: [],
    partners: [],
    parents: [],

    form: null,

    isCreating: false
  })
