import { handleErrorAxiosCoreStore } from '@/utils/functions/handle-errors'
import { UNKNOWN_ERROR_USER } from '@/utils/constants/errors'

export const fetch = async function({ rootState, commit }) {
  commit('reset')

  const userId = rootState.route.params.userId
  const declarationIdentifier = rootState.route.params.declarationIdentifier

  commit('setLoadingState', true)

  let response
  try {
    response = await this.$axios.get(
      `/users/${userId}/declarations/${declarationIdentifier}`
    )
  } catch (error) {
    handleErrorAxiosCoreStore({ error, commit, store: this })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setError', UNKNOWN_ERROR_USER)
    commit('setLoadingState', false)
    return
  }

  const result = response.data.result

  commit('setDeclaration', result.declaration)
  commit('setLoadedState', true)
  commit('setLoadingState', false)
}

export const refresh = fetch

export const updateDeclarationAfterPurchaseUpdate = function(
  { commit, dispatch },
  { purchaseIdentifier, result }
) {
  if (result.is_purchase_destroyed) {
    commit('destroyPurchase', purchaseIdentifier)
  }
  if (result.purchases) {
    commit('updatePurchases', result.purchases)
  }
  if (result.available_special_offers_at_declaration) {
    commit(
      'updateAvailableSpecialOffersAtDeclaration',
      result.available_special_offers_at_declaration
    )
  }

  dispatch('user/refreshProgramsAndRewards', null, { root: true })
}
