export default () => ({
  isLoading: false,
  isLoaded: false,
  isExperimental: false,
  error: null,

  messages: [],
  hasMore: null,
  nextPaginationKey: null,

  isfetchingMore: false
})
