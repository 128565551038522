<template>
  <div class="pa-3">
    <span v-text="text" />

    <br v-if="withLineReturn" />

    <span v-if="doNotWorry" v-text="$t('errors.do-not-worry')" />

    <v-btn v-if="retriable" :loading="isLoading" small @click="$emit('retry')">
      {{ $t('global.retry') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    withLineReturn: {
      type: Boolean,
      default: false
    },
    doNotWorry: {
      type: Boolean,
      default: false
    },
    retriable: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
