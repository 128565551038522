




































import Vue from 'vue'
import RewardListItem from './RewardsListItem.vue'
import DisplayErrorV3 from '~/components/layouts/DisplayErrorV3.vue'
export default Vue.extend({
  components: {
    RewardListItem,
    DisplayErrorV3
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    isLoaded: {
      type: Boolean,
      required: true
    },
    error: {
      type: String,
      default: null
    },
    rewards: {
      type: Array,
      required: true
    },
    noRewardText: {
      type: String,
      required: true
    }
  },
  computed: {
    rewardsCount(): number {
      return this.rewards.length
    }
  }
})
