import { compact, some, every } from 'lodash-es'

import { State, TreeNode, Denom, SPECIAL_DENOMS_NODE_ID } from './state'

function recursiveFlattenNodes(nodes: TreeNode[]) {
  const results: TreeNode[] = []
  nodes.forEach((node) => {
    if (!node) return

    results.push(node)

    if (!node.children) return
    if (node.children.length === 0) return

    results.push(...recursiveFlattenNodes(node.children))
  })
  return results
}
function recursiveNodesWithChildren(nodes: TreeNode[]) {
  const results: TreeNode[] = []
  nodes.forEach((node) => {
    if (!node) return
    if (!node.children) return
    if (node.children.length === 0) return

    results.push(node)
    results.push(...recursiveNodesWithChildren(node.children))
  })
  return results
}

// https://dev.to/3vilarthas/vuex-typescript-m4j
export type Getters = {
  useCore: Boolean
  selectedOriginNodeIsSpecialDenoms: null | Boolean

  nodeWithUnlinkedDenoms: TreeNode
  allNodes: TreeNode[]
  allNodesExceptUnlinkedDenoms: TreeNode[]
  rootNodes: TreeNode[]
  nodesWithChildrenFromRoots: TreeNode[]
  denomIdsToTransfer: number[]
  denomsNotSelected: Denom[]
  regexpOnDenoms: null | RegExp
  regexpOnProducts: null | RegExp
}

export const isLoading = (state: State) => some(Object.values(state.isLoading))
export const isLoaded = (state: State) => every(Object.values(state.isLoaded))
export const error = (state: State) => compact(Object.values(state.error))[0]

export const useCore = (_state, _getters, rootState) =>
  rootState.auth.selectedProgram?.is_core_the_source_of_truth_for_analytics // eslint-disable-line camelcase

export const nodeWithUnlinkedDenoms = (_state, _getters, rootState) => ({
  id: SPECIAL_DENOMS_NODE_ID,
  label: rootState.auth.selectedProgram
    .is_core_the_source_of_truth_for_analytics
    ? 'Libellés non validés'
    : 'Libellés non triés',
  children: []
})

export const selectedOriginNodeIsSpecialDenoms = (state: State) =>
  state.selectedOriginNode &&
  state.selectedOriginNode.id === SPECIAL_DENOMS_NODE_ID

export const selectedTargetNodeIsSpecialDenoms = (state: State) =>
  state.selectedTargetNode &&
  state.selectedTargetNode.id === SPECIAL_DENOMS_NODE_ID

export const selectedOriginNodeIsLeaf = (state: State) =>
  state.selectedOriginNode && state.selectedOriginNode.children.length === 0

export const displayCheckboxForFinalState = (_state: State, getters: Getters) =>
  getters.useCore && !getters.selectedOriginNodeIsSpecialDenoms

export const allNodesExceptUnlinkedDenoms = (state: State) =>
  compact([state.errorNode, state.tree])
export const allNodes = (state: State, getters: Getters) =>
  getters.useCore
    ? getters.allNodesExceptUnlinkedDenoms
    : compact([getters.nodeWithUnlinkedDenoms, state.errorNode, state.tree])
export const nodesFlatten = (_: State, getters: Getters) =>
  recursiveFlattenNodes(getters.allNodes)
export const nodesWithChildren = (_: State, getters: Getters) =>
  recursiveNodesWithChildren(getters.allNodes)

export const rootNodes = (state: State) =>
  state.tree ? state.tree.children : []
export const nodesWithChildrenFromRoots = (_: State, getters: Getters) =>
  recursiveNodesWithChildren(getters.rootNodes)
export const nodeIdsWithChildrenFromRoots = (_: State, getters: Getters) =>
  getters.nodesWithChildrenFromRoots.map((node) => node.id)

export const denomIdsToTransfer = (state: State) =>
  state.denomsToMove.map((denom) => denom.id)

export const denomsNotSelected = (state: State, getters: Getters) =>
  state.selectedOriginNodeDenoms.filter(
    (denom) => !getters.denomIdsToTransfer.includes(denom.id)
  )

export const regexpOnDenoms = (state: State) =>
  state.filterOnDenoms && new RegExp(state.filterOnDenoms, 'i')
export const displayedDenoms = (_state: State, getters: Getters) => {
  if (!getters.regexpOnDenoms) {
    return getters.denomsNotSelected
  }

  return getters.denomsNotSelected.filter((denom) =>
    denom.value.match(getters.regexpOnDenoms)
  )
}

export const regexpOnProducts = (state: State) =>
  state.filterOnProducts && new RegExp(state.filterOnProducts, 'i')
export const displayedProducts = (state: State, getters: Getters) => {
  if (!getters.regexpOnProducts) {
    return state.selectedTargetNodeProducts
  }

  return state.selectedTargetNodeProducts.filter(
    (product) =>
      product.name.match(getters.regexpOnProducts) ||
      (product.ean && product.ean.match(getters.regexpOnProducts))
  )
}

export const noDenomsToMove = (state: State) => state.denomsToMove.length === 0
