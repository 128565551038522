












import Vue from 'vue'
import WZoom from 'vanilla-js-wheel-zoom'

function changeTransformRotateProperty(element: HTMLElement, value: Number) {
  element.style.transform = `${element.style.transform.replace(
    /(^|\s)rotate\(\d+deg\)($|\s)/i,
    ''
  )} rotate(${value}deg)`
}

export default Vue.extend({
  props: {
    imageUrl: {
      type: String,
      required: true
    },
    imageAlt: {
      type: String,
      required: true
    },
    angle: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    wzoom: null as InstanceType<typeof WZoom> | null,
    observer: null as MutationObserver | null
  }),
  watch: {
    angle: 'setStyleTransform'
  },
  mounted() {
    this.wzoom = WZoom.create(this.$refs.img, { maxScale: 10, speed: 5 })
    this.observer = new MutationObserver(this.setStyleTransform)
    this.observer.observe(this.$refs.img as Element, {
      attributes: true,
      attributeFilter: ['style']
    })
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    // automatic check on image height/width to put it vertical
    // /!\ current angle is affecting the outcome
    changeAngleIfNeeded() {
      const rect = (this.$refs.img as Element).getBoundingClientRect()
      let correctAngle
      if ((this.angle / 90) % 2 === 0) {
        correctAngle = rect.height >= rect.width ? 0 : 90
      } else {
        correctAngle = rect.height >= rect.width ? 90 : 0
      }
      this.$emit('set-angle', correctAngle)
    },
    setStyleTransform() {
      changeTransformRotateProperty(this.$refs.img as HTMLElement, this.angle)
    }
  }
})
