export default {
  noJobs: 'aucune extractions',
  launchNewExtraction: 'lancer une nouvelle extraction',
  launchTheExtraction: "lancer l'extraction",
  kind: "type d'extraction",
  extractionCreatedWithSuccess: 'extraction lancée avec succès',
  areYouSureYouWantToDestroyThisExtraction:
    'Êtes-vous sûr de vouloir détruire cette extraction ? Cette action est irréversible.',
  extractionDeletedWithSuccess: 'extraction supprimée avec succès',

  params: {
    title: "paramètres de l'extraction",
    pleaseSelectAJobKind: "veuillez sélectionner un type d'extraction",

    labels: {
      program: 'programme'
    },

    categoryFocus: {
      who: {
        title: 'qui',
        selectUsers: 'sélectionner les utilisateurs',
        withSegment: 'avec un segment',
        dependingOnTheirActivity: 'selon leur activité',

        activityPeriodStartDate: "début de la période d'activité",
        activityPeriodEndDate: "fin de la période d'activité",
        activityMinNumberOfYearlyReceipts:
          'nombre de déclarations minimum par an',
        activityMaxNumberOfYearlyReceipts:
          'nombre de déclarations maximum par an',
        activityMinYearlyDeclaredSpending: 'total minimum acheté par an',
        activityMaxYearlyDeclaredSpending: 'total maximum acheté par an'
      },
      what: {
        title: 'quoi',
        purchasePeriodStartDate: "début de la période d'achat",
        purchasePeriodEndDate: "fin de la période d'achat",

        categories: {
          title: 'catégories',
          tabs: 'Catégorie n°%{index}',
          name: 'nom'
        }
      }
    }
  }
}
