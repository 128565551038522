export default {
  charges: 'charges',
  notes: 'notes',
  start: 'début',
  end: 'fin',
  card: 'carton',
  user: 'utilisateur',
  banNotFound: 'ban introuvable',
  otherBans: 'autres suspensions',
  revokeThisBan: 'révoquer ce bannissement',
  banRevokedSuccessfully: 'ban révoqué avec succès'
}
