import { HOME_PROGRAMS } from '@/utils/constants/routes'

import { PROGRAM_KIND_FIDZ } from '~/store/program/state.js'

import {
  TAB_PERIODS,
  TAB_PERIODS_NEW,
  TAB_CATALOG,
  TAB_ASSETS,
  TAB_BRANDS
} from '~/components/layouts/program/Bar'

export default (ctx) => {
  const program = ctx.store.state.auth.selectedProgram
  if (!program) return

  const programIdentifier = program.identifier

  if (program.kind === PROGRAM_KIND_FIDZ) {
    return ctx.redirect(`${HOME_PROGRAMS}/${programIdentifier}/${TAB_CATALOG}`)
  }

  if (program.has_parent) {
    return ctx.redirect(`${HOME_PROGRAMS}/${programIdentifier}/${TAB_ASSETS}`)
  }

  if (ctx.store.getters['auth/isManager']) {
    return ctx.redirect(`${HOME_PROGRAMS}/${programIdentifier}/${TAB_BRANDS}`)
  }

  switch (
    ctx.store.state.auth.selectedProgram
      .is_core_the_source_of_truth_for_analytics
  ) {
    case true:
      return ctx.redirect(
        `${HOME_PROGRAMS}/${programIdentifier}/${TAB_PERIODS_NEW}`
      )
    case false:
      return ctx.redirect(
        `${HOME_PROGRAMS}/${programIdentifier}/${TAB_PERIODS}`
      )
    default:
      return ctx.redirect(
        `${HOME_PROGRAMS}/${programIdentifier}/${TAB_CATALOG}`
      )
  }
}
