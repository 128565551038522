export default {
  profile: {
    first_name: 'mathieu',
    last_name: 'derelle',
    email: 'derelle.mathieu@purchease.com',
    declarations_count: 24,
    payment_methods: [
      {
        app_name: 'FidMarques',
        type: 'PayPal',
        identifier: 'mathieu.derelle@gmail.com',
        is_forgettable: false
      },
      {
        app_name: 'FidMarques',
        type: 'IBAN',
        identifier: 'FR76 **** **** **** **** **** 850',
        is_forgettable: true
      },
      {
        app_name: 'Scanobar',
        type: 'Lydia',
        identifier: '06 ** ** ** 14',
        is_forgettable: true
      }
    ],
    benefits_by_period: [
      {
        identifier: '2020-02-23/to/2021-03-23',
        title: 'Gains depuis inscription',
        benefits: [
          {
            identifier: 'program-52',
            program_name: 'Heineken',
            program_roundable_image_url:
              'https://cdn1.fidmarques.com/images/loyalty_programs/production/52-c52a16c2244192006e3c82a280d39c75-roundable.png',
            total_quantity: 3,
            total_price: '4343.34',
            total_benefits_earned: 2343,
            total_benefits_burned: 340,
            total_refunds_count: 2,
            total_refunded_amount: '23.34'
          },
          {
            identifier: 'program-118',
            program_name: 'Desperados',
            program_roundable_image_url:
              'https://cdn1.fidmarques.com/images/loyalty_programs/production/118-3fee7844e7ec9c377c3db1a1c6ced067-roundable.png',
            total_quantity: 2,
            total_price: '123.34',
            total_benefits_earned: 12,
            total_benefits_burned: 0,
            total_refunds_count: 0,
            total_refunded_amount: '4.3'
          }
        ]
      }
    ]
  }
}
