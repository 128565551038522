import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image"},[_c(VToolbar,{attrs:{"flat":""}},[_c(VToolbarTitle,{attrs:{"title":_vm.title}},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),(_vm.selectedImage || _vm.subpicture.pdf_url)?_c(VSpacer):_vm._e(),_vm._v(" "),(_vm.selectedImage)?[_c(VBtn,{attrs:{"icon":"","tile":""},on:{"click":_vm.rotate}},[_c(VIcon,[_vm._v("mdi-rotate-right")])],1),_vm._v(" "),_c(VBtn,{attrs:{"icon":"","tile":"","href":_vm.selectedImage.url,"target":"_blank"}},[_c(VIcon,[_vm._v("mdi-open-in-new")])],1)]:_vm._e(),_vm._v(" "),(_vm.subpicture.pdf_url)?_c(VBtn,{attrs:{"icon":"","tile":"","href":_vm.subpicture.pdf_url,"target":"_blank"}},[_c(VIcon,[_vm._v("mdi-file-pdf-box")])],1):_vm._e()],2),_vm._v(" "),_c('client-only',[(_vm.selectedImage)?_c('DeclarationZoom',{attrs:{"image-url":_vm.selectedImage.url,"image-alt":_vm.imageAlt(_vm.selectedImage),"angle":_vm.angle},on:{"set-angle":_vm.setAngle}}):_vm._e()],1),_vm._v(" "),(_vm.subpicture.images.length > 1)?_c(VSheet,{attrs:{"elevation":"6"}},[_c(VTabs,{attrs:{"show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider),_vm._v(" "),_vm._l((_vm.subpicture.images),function(image){return _c(VTab,{key:image.name_short,attrs:{"title":image.name_long_i18n}},[_vm._v("\n        "+_vm._s(image.name_short)+"\n      ")])})],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }