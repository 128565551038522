






















































import Vue from 'vue'
import { mapGetters } from 'vuex'

import AddRewardLoading from './AddRewardLoading.vue'
import RewardItemLoading from './RewardItemLoading.vue'

export default Vue.extend({
  components: {
    AddRewardLoading,
    RewardItemLoading
  },
  computed: {
    ...mapGetters('auth', ['hasWriteContentRight'])
  }
})
