<template>
  <div class="coupon" :style="{ borderColor: coupon.dominant_color }">
    <div class="coupon__image">
      <img :src="coupon.image_url" />
    </div>
    <div class="coupon__data">
      <strong>
        {{ coupon.name }}
      </strong>
      <br />
      <strong :style="{ color: coupon.dominant_color }">
        {{ coupon.formatted_amount }}
      </strong>
      <em>
        {{ coupon.formatted_expiration }}
      </em>
    </div>
    <div
      class="coupon__brand"
      :style="{ backgroundColor: coupon.dominant_color }"
    >
      <img :src="coupon.brand_image_url" />
    </div>
  </div>
</template>

<script>
import hasOwnProp from '@/utils/functions/hasOwnProp'
export default {
  props: {
    coupon: {
      type: Object,
      required: true,
      validator: (o) =>
        hasOwnProp(o, 'dominant_color') &&
        hasOwnProp(o, 'image_url') &&
        hasOwnProp(o, 'name') &&
        hasOwnProp(o, 'formatted_amount') &&
        hasOwnProp(o, 'formatted_expiration') &&
        hasOwnProp(o, 'brand_image_url') &&
        true
    }
  }
}
</script>

<style scoped>
.coupon {
  border: 2px dashed orange;
  border-radius: 5px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.coupon__image {
  display: table-cell;
  width: 30%;
}
.coupon__image img {
  width: 90%;
}
.coupon__data {
  vertical-align: top;
  display: table-cell;
  height: 100%;
  width: 50%;
}
.coupon__brand {
  display: table-cell;
  vertical-align: middle;
  width: 20%;
}
.coupon__brand > img {
  margin-left: 10%;
  width: 80%;
  border-radius: 50%;
}
</style>
