export const setLoadingState = (state, boolean) => {
  state.isLoading = boolean
}
export const setLoadedState = (state, boolean) => {
  state.isLoaded = boolean
}
export const setError = (state, text) => {
  state.error = text
}
export const resetError = (state) => {
  state.error = null
}
