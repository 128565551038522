<template>
  <v-chip
    dark
    label
    close
    nuxt
    :to="to"
    :color="banColor"
    :close-icon="closeIcon"
    @click:close="revertBan(ban)"
  >
    {{ text }}
  </v-chip>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { momentFormat } from '~/plugins/filters'
import { handleErrorAxiosCoreComponent } from '@/utils/functions/handle-errors'
export default {
  props: {
    ban: {
      type: Object,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    closeIcon: {
      type: String,
      required: false,
      default: ''
    },
    to: {
      type: String,
      required: false,
      default: undefined
    },
    editor: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    ...mapGetters('user', ['routeBasePath']),
    ...mapState('user', ['user', 'editors']),

    manyApps() {
      return this.user.apps.length > 1
    },
    text() {
      switch (this.message) {
        case 'bannedAt':
          return this.bannedAt
        case 'banMessage':
          return this.banMessage
        case 'currentBanMessage':
          return this.currentBanMessage
        case 'otherBan':
          return `#${this.ban.id}`
        case 'editorName':
          return this.ban.editor_name
        default:
          return undefined
      }
    },
    banColor() {
      switch (this.ban.kind) {
        case 'yellow':
          return 'yellow darken-3'
        case 'red':
          return 'red darken-3'
        default:
          return undefined
      }
    },
    currentBanMessage() {
      switch (this.ban.kind) {
        case 'yellow':
          if (this.manyApps) {
            return this.$t('pages.users.modal.ban.currentlyBlockAtEditor', {
              date: momentFormat(this.ban.start_at, 'date'),
              editor: this.editor
            })
          } else {
            return this.$t('pages.users.modal.ban.currentlyBlockAt', {
              date: momentFormat(this.ban.start_at, 'date')
            })
          }
        case 'red':
          if (this.manyApps) {
            return this.$t('pages.users.modal.ban.currentlyBlockAtEditor', {
              date: momentFormat(this.ban.start_at, 'date'),
              editor: this.editor
            })
          } else {
            return this.$t('pages.users.modal.ban.currentlyBlockAt', {
              date: momentFormat(this.ban.start_at, 'date')
            })
          }
        default:
          return undefined
      }
    },

    banMessage() {
      switch (this.ban.kind) {
        case 'yellow':
          if (this.manyApps) {
            return this.$t('pages.users.modal.ban.previouslyBlockAtOnEditor', {
              date: momentFormat(this.ban.start_at, 'date'),
              editor: this.editor
            })
          } else {
            return this.$t('pages.users.modal.ban.previouslyBlockAt', {
              date: momentFormat(this.ban.start_at, 'date')
            })
          }
        case 'red':
          if (this.manyApps) {
            return this.$t('pages.users.modal.ban.banAtOnEditor', {
              date: momentFormat(this.ban.start_at, 'date'),
              editor: this.editor
            })
          } else {
            return this.$t('pages.users.modal.ban.banAt', {
              date: momentFormat(this.ban.start_at, 'date')
            })
          }
        default:
          return undefined
      }
    },
    bannedAt() {
      return this.$t('layout.user.header.bannedAt', {
        date: momentFormat(this.ban.start_at, 'date')
      })
    }
  },
  methods: {
    ...mapActions('user', ['fetchUserFromRoute']),

    async revertBan(ban) {
      let response
      try {
        response = await this.$axios.post(`/bans/${ban.id}/delete`)
      } catch (error) {
        await handleErrorAxiosCoreComponent({
          $vm: this,
          error,
          errorKey: 'errorOnDeleteBan'
        })
        return
      }
      if (!response.data.success) {
        this.$airbrakeNotifyUnhandledBadRequest({ response })
        return
      }
      this.fetchUserFromRoute()
    }
  }
}
</script>
