// https://github.com/highcharts/highcharts/issues/13710
export default (H) => {
  H.seriesTypes.pie.prototype.drawEmpty = function() {
    const start = this.startAngleRad
    const end = this.endAngleRad
    const options = this.options
    // Draw auxiliary graph if there're no visible points.
    if (this.total === 0) {
      const centerX = this.center[0]
      const centerY = this.center[1]
      if (!this.graph) {
        this.graph = this.chart.renderer
          .arc(centerX, centerY, this.center[1] / 2, 0, start, end)
          .addClass('highcharts-empty-series')
          .add(this.group)
      }
      this.graph.attr({
        d: H.SVGRenderer.prototype.symbols.arc(
          centerX,
          centerY,
          this.center[2] / 2,
          0,
          {
            start,
            end,
            innerR: this.center[3] / 2
          }
        )
      })
      if (!this.chart.styledMode) {
        this.graph.attr({
          'stroke-width': options.borderWidth,
          fill: options.fillColor || 'none',
          stroke: options.color || '#cccccc'
        })
      }
    } else if (this.graph) {
      // Destroy the graph object.
      this.graph = this.graph.destroy()
    }
  }
}
