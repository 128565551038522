<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <ValidationProvider
          v-slot="{ errors }"
          name="refundValue"
          mode="eager"
          :rules="
            `required|${
              isRefundTypePercent ? 'integer' : 'double'
            }|between:0,100|min_value:0|max_value:100`
          "
          slim
        >
          <v-text-field
            :disabled="disabled"
            :readonly="readonly"
            type="number"
            min="0"
            :max="isRefundTypePercent ? 100 : undefined"
            :label="$t('pages.program.catalog.createModal.refund')"
            :error-messages="errors"
            :append-outer-icon="refundTypeIcon"
            :value="form.refund.value"
            @change="changeDiscountValue"
          />
        </ValidationProvider>
      </v-col>
      <v-col>
        <v-radio-group
          v-if="!disabled"
          :disabled="disabled"
          :readonly="readonly"
          row
          :value="form.refund.type"
          @change="changeDiscountType"
        >
          <v-radio
            off-icon="mdi-percent-outline"
            on-icon="mdi-percent"
            :value="DISCOUNT_TYPE_PERCENT"
          />
          <v-radio
            off-icon="mdi-currency-eur"
            on-icon="mdi-currency-eur"
            :value="DISCOUNT_TYPE_ABSOLUTE"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <ValidationProvider
          v-slot="{ errors }"
          name="refundRecommendedPrice"
          mode="eager"
          rules="required|double|min_value:0"
          slim
        >
          <v-text-field
            :disabled="disabled"
            :readonly="readonly"
            type="number"
            min="0"
            append-outer-icon="mdi-currency-eur"
            :label="$t('pages.program.catalog.createModal.recommendedPrice')"
            :error-messages="errors"
            :value="form.refund.recommendedPublicPrice"
            @change="changeRecommendedPublicPrice"
          />
        </ValidationProvider>
      </v-col>
      <v-col />
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>
              <v-icon left dark>mdi-barcode</v-icon>
              {{ $t('pages.program.catalog.createModal.eansList') }}
            </v-toolbar-title>
          </v-toolbar>
          <v-tabs
            v-model="tabSelected"
            background-color="primary"
            center-active
            dark
          >
            <v-tab v-for="tab in tabs" :key="tab">
              {{ $t(`pages.program.catalog.createModal.tabs.${tab}.name`) }}
            </v-tab>
          </v-tabs>
          <v-card-text>
            <v-tabs-items v-model="tabSelected">
              <v-tab-item v-for="tab in tabs" :key="tab">
                <p>
                  {{ $t(`pages.program.catalog.createModal.tabs.${tab}.info`) }}
                </p>
                <ListAndVerifyEans
                  :disabled="disabled"
                  :readonly="readonly"
                  :eans="
                    tab === TAB_PRIMARY
                      ? form.refund.eanPrimaryProducts
                      : form.refund.eanSecondaryProducts
                  "
                  :eans-required="tab === TAB_PRIMARY"
                  @change="
                    changeEanProducts({
                      key:
                        tab === TAB_PRIMARY
                          ? 'eanPrimaryProducts'
                          : 'eanSecondaryProducts',
                      value: $event
                    })
                  "
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { ValidationProvider } from 'vee-validate'

import ListAndVerifyEans from '../ListAndVerifyEans'

export const DISCOUNT_TYPE_PERCENT = 'percentage'
export const DISCOUNT_TYPE_ABSOLUTE = 'absolute'

const TAB_PRIMARY = 'primary'
const TAB_SECONDARY = 'secondary'

export default {
  components: {
    ValidationProvider,
    ListAndVerifyEans
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    DISCOUNT_TYPE_PERCENT,
    DISCOUNT_TYPE_ABSOLUTE,

    TAB_PRIMARY,
    tabs: [TAB_PRIMARY, TAB_SECONDARY],

    tabSelected: 0,

    areProductsLoading: false,
    areProductsLoaded: false
  }),
  computed: {
    ...mapState('program/catalog', ['form']),
    ...mapGetters('program/catalog', [
      'isRefundTypePercent',
      'isRefundTypeAbsolute'
    ]),

    refundTypeIcon() {
      if (!this.disabled) {
        return undefined
      }

      if (this.isRefundTypePercent) return 'mdi-percent'
      if (this.isRefundTypeAbsolute) return 'mdi-currency-eur'

      return undefined
    }
  },
  watch: {
    'form.refund.type'() {
      this.changeDiscountValue(this.form.refund.value)
    }
  },
  methods: {
    ...mapMutations('program/catalog', [
      'changeEanProducts',
      'changeDiscountValue',
      'changeDiscountType',
      'changeRecommendedPublicPrice'
    ])
  }
}
</script>

<style scoped>
.error-font {
  font-size: 12px;
}
</style>
