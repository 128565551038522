// import MixinPaletteFidmarques from "~/mixins/paletteFidmarques";
// import MixinPaletteScanobar from '~/mixins/paletteScanobar'
// import MixinPalettePurchease from '~/mixins/palettePurchease'

// const { palettePurchease } = MixinPalettePurchease.data()
// const { paletteScanobar } = MixinPaletteScanobar.data()

import { Framework } from 'vuetify'
import colors from 'vuetify/es5/util/colors'

export function resetTheme($vuetify: Framework) {
  $vuetify.theme.themes.light.primary = colors.blue.darken2
  $vuetify.theme.themes.dark.primary = colors.blue.darken2
}

export default function($vuetify: Framework, primaryColor: string): void {
  // MONKEY PATCH :
  // when program session payload is too old
  // dominant color is not in it
  // and this will break the website for them
  // (impossible to disconnect or do anything)
  if (!primaryColor) return

  $vuetify.theme.themes.light.primary = primaryColor
  $vuetify.theme.themes.dark.primary = primaryColor
}
