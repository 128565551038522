import cancellableFetchFromStore from '@/utils/functions/analytics/cancellableFetch/fromStore'
// import cancellableFetchFromStore from '@/utils/functions/analytics/cancellableFetch/mocked/fromStore'

import { TAB_SALES } from '@/components/layouts/metrics/program/Bar.vue'
// import payload from '@/utils/mocked-data/metrics/program/charts/sales-per-region'

export const fetch = function(ctx) {
  return cancellableFetchFromStore({
    ctx,
    store: this,
    tab: TAB_SALES,
    proxy: {
      method: 'post',
      path:
        '/internal_api/sas_bo_front_end/v1/programs_metrics/charts/sales_per_retailer',
      data: ctx.rootGetters['metrics/program/paramsForBackend']
    }
  })
  // return cancellableFetchFromStore({
  //   ctx,
  //   store: this,
  //   tab: TAB_SALES,
  //   payload
  // })
}

export const refresh = fetch
