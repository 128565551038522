import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v("\n    "+_vm._s(_vm.$t('pages.user.profile.pot.modal.transferRequest'))+" -\n    "+_vm._s(_vm._f("momentFormat")(_vm.transferPayment.created_at,'date'))+"\n  ")]),_vm._v(" "),_c(VList,{attrs:{"outlined":"","two-line":""}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v("\n          "+_vm._s(_vm.$t(("pages.user.profile.pot.modal.kind." + (_vm.transferPayment.type))))+"\n        ")]),_vm._v(" "),_c(VListItemSubtitle,[_vm._v("\n          "+_vm._s(_vm.transferPayment.formatted_status)+"\n        ")])],1),_vm._v(" "),_c(VListItemAction,[_c('div',{staticClass:"text-h6",style:({ color: _vm.color })},[_vm._v("\n          "+_vm._s(_vm._f("money")(_vm.transferPayment.amount))+"\n        ")]),_vm._v(" "),(_vm.transferPayment.sent_at)?_c('span',[_vm._v("\n          "+_vm._s(_vm.$t('pages.user.profile.pot.modal.transferedOnYourAccount'))+"\n        ")]):_vm._e()])],1)],1),_vm._v(" "),(_vm.transferPayment.potRefunds)?_c(VList,{attrs:{"outlined":"","two-line":""}},[_vm._l((_vm.transferPayment.potRefunds),function(potRefund){return _c('ListPotRefundsItem',{key:potRefund.id,attrs:{"pot-refund":potRefund}})}),_vm._v(" "),_vm._l((_vm.transferPayment.otherAppPotRefunds),function(app){return _c('ListPotRefundsOtherAppItem',{key:app.id,attrs:{"app":app}})})],2):(_vm.isLast && _vm.hasMore)?_c(VList,{attrs:{"outlined":"","two-line":""}},[_c('IntersectionObserver',{on:{"intersect":_vm.fetchNextTransfer}}),_vm._v(" "),_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"max-width":"800","type":"list-item-two-line"}}),_vm._v(" "),_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"max-width":"800","type":"list-item-two-line"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }