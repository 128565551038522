import {
  PROGRAM_KIND_POINTS_VIA_PRICE,
  PROGRAM_KIND_POINTS_BY_PURCHASE,
  PROGRAM_KIND_STAMPS,
  PROGRAM_KIND_PUZZLE,
  PROGRAM_KIND_COLLECTOR
} from '~/store/program/state.js'

export default {
  name: 'Nom',
  kind: 'Type',
  tagLine: 'Slogan',
  catchPhrase: "Phrase d'accroche",
  parentProgram: 'Programme parent',
  partnerApps: 'Applications partenaires',
  client: 'Client',
  displayRank: "Rang d'affichage",

  dominantColor: 'Couleur dominante',

  stepper: {
    step1: 'Nom et Type',
    step2: {
      [PROGRAM_KIND_POINTS_VIA_PRICE]: 'Points',
      [PROGRAM_KIND_POINTS_BY_PURCHASE]: 'Point Par Achat',
      [PROGRAM_KIND_STAMPS]: 'Tampons',
      [PROGRAM_KIND_PUZZLE]: 'Puzzle',
      [PROGRAM_KIND_COLLECTOR]: 'Collector'
    },
    step3: 'Couleur & Images',
    step4: 'Descriptions',
    step5: 'Récapitulatif'
  },

  titles: {
    tabBrand: 'Onglet "Notre Marque"',
    preview: 'Aperçu',
    description: 'Description',
    misc: 'Autre'
  },
  description: {
    hint:
      "apparaît dans le message Timeline (app v3) de découverte de programme et en haut de page personnalisée d'un programme ([url=https://api.purchease.com/?with=clubsocopa]exemple[/url])"
  },
  brandDescription: {
    html: {
      label: 'Version HTML'
    },
    text: {
      label: 'Version texte',
      conditionOfDisplay: 'affichée si la version HTML est vide'
    }
  },

  fields: {
    collector: {
      nothingToDoHere: "Vous n'avez rien à faire.",
      devsMustDoTheWork: 'Les devs doivent faire le travail.'
    },

    isRenewable: 'carte renouvelable',

    kind: {
      labels: {
        [PROGRAM_KIND_POINTS_VIA_PRICE]:
          'Carte à points (points dépendant du prix)',
        [PROGRAM_KIND_POINTS_BY_PURCHASE]:
          'Carte à points (un point par achat)',
        [PROGRAM_KIND_STAMPS]: 'Carte à tampons',
        [PROGRAM_KIND_PUZZLE]:
          "Carte Puzzle (sans règle d'acquisition spécifique par pièce)",
        [PROGRAM_KIND_COLLECTOR]: 'Carte collector'
      }
    },
    stamps: {
      stampsMaxCount: 'nombre total de tampons ',
      stampsByLine: 'nombre de tampons par ligne',
      stampLogoImage: 'image du tampon'
    },
    puzzle: {
      puzzleTotalPiecesCount: 'nombre total de pièces',
      possibleBoards: 'Puzzle possibles',
      puzzleBoardRank: 'Rang',
      puzzleBoardWidth: 'largeur du puzzle',
      puzzleBoardHeight: 'hauteur du puzzle',
      puzzleBoardMargin: "marge dans l'image autour du puzzle",
      puzzleBoardEmptyImage: 'image du puzzle (vide)',
      puzzleBoardCompletedImage: 'image du puzzle (complété)',
      boards: {
        tabs: 'Puzzle n°%{index}'
      },
      pieces: {
        title: 'Pièces',
        noPieces:
          "Aucune piéce, merci d'indiquer un nombre total de piéces supérieur à 0",
        tabs: 'Pièce n°%{index}',
        labels: {
          x: "position de l'image par rapport au bord gauche du puzzle",
          y: "position de l'image par rapport au bord supérieur du puzzle",
          width: "largeur de l'image de la pièce",
          height: "hauteur de l'image de la pièce",
          ownedImage: 'image de la pièce si obtenue'
        }
      }
    },
    pointsByPurchase: {
      nothingToConfigureForNow: 'rien à configurer pour le moment'
    }
  }
}
