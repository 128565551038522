import classification from './classification'
import declaration from './declaration'
import declarations from './declarations'
import managers from './managers'
import * as metrics from './metrics'
import pointsOfSale from './pointsOfSale'
import productsCollection from './productsCollection'
import * as program from './program'
import programs from './programs'
import selectProgram from './selectProgram'
import sources from './sources'
import titles from './titles'
import transfers from './transfers'
import * as user from './user'
import users from './users'
import error from './error'
import analyticsJobs from './analyticsJobs'

export default {
  analyticsJobs,
  classification,
  declaration,
  declarations,
  managers,
  metrics,
  pointsOfSale,
  productsCollection,
  program,
  programs,
  selectProgram,
  sources,
  titles,
  transfers,
  user,
  users,
  error
}
