import { reject, sortBy } from 'lodash-es'

import { RESET } from './profile/pot/mutation-types'

import { ALL_PROGRAM_KINDS } from '@/store/program/state.js'

import {
  handleAxiosErrorServerFromStoreV3,
  handleAxiosErrorFrontendFromStoreV3,
  handleAxiosErrorServerFromStore,
  handleAxiosErrorFrontendFromStore
} from '@/utils/functions/handle-errors'

export const reset = function({ commit }) {
  commit('user/app/timeline/reset', null, { root: true })
  commit('user/declaration/reset', null, { root: true })
  commit('user/declarations/reset', null, { root: true })
  commit('user/profile/reset', null, { root: true })
  commit('user/profile/earnsAndBurns/reset', null, { root: true })
  commit(`user/profile/pot/${RESET}`, null, { root: true })
  commit('user/profile/wallet/pending/reset', null, { root: true })
  commit('user/profile/wallet/used/reset', null, { root: true })
  // commit('user/timeline/search', null, { root: true }) // handled in another context
  commit('user/timeline/reset', null, { root: true })
  commit('reset')
}

export const fetchUserFromRoute = function({ state, rootState, dispatch }) {
  if (!rootState.route.params.userId) return

  const userId = parseInt(rootState.route.params.userId)
  if (state.isLoaded && userId === state.user.id) return

  return dispatch('fetchUserById', { userId })
}

export const refreshUserFromRoute = function({ rootState, dispatch }) {
  if (!rootState.route.params.userId) return

  return dispatch('fetchUserById', {
    userId: parseInt(rootState.route.params.userId)
  })
}

export const fetchUserById = async function(
  { state, commit, dispatch },
  { userId }
) {
  if (state.isLoading) return

  commit('setLoadingState', true)
  commit('setUser', null)
  commit('resetError')

  let response
  try {
    response = await this.$axios.get(`/users/${userId}`)
  } catch (error) {
    handleAxiosErrorServerFromStore({ error, commit, store: this })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStore({ response, commit, store: this })
    return
  }

  commit('setUser', response.data.result.user)
  dispatch('fetchProgramsAndRewards')

  commit('setLoadedState', true)
  commit('setLoadingState', false)
}

export const fetchProgramsAndRewards = async function({ state, commit }) {
  if (state.isFetchingProgram) return

  commit('setProgramFetchingState', true)

  let response
  try {
    response = await this.$axios.get(
      `/users/${state.user.id}/programs_and_rewards`
    )
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      errorInToast: true,
      commit,
      store: this
    })
    commit('setProgramFetchingState', false)
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStoreV3({
      response,
      errorInToast: true,
      commit,
      store: this
    })
    commit('setProgramFetchingState', false)
    return
  }

  commit('setLoyaltyPrograms', response.data.result.loyalty_programs)
  commit('setLoyaltyRewards', response.data.result.loyalty_rewards)
  commit('setProgramFetchingState', false)

  verifyAllProgramKindsAreKnown(this.$airbrakeNotify, state)
}

function verifyAllProgramKindsAreKnown($airbrakeNotify, state) {
  const programsWithUnhandledKinds = reject(state.loyaltyPrograms, (lp) =>
    ALL_PROGRAM_KINDS.includes(lp.kind)
  )
  if (programsWithUnhandledKinds.length === 0) return

  const identifiers = sortBy(
    programsWithUnhandledKinds.map((p) => p.identifier)
  )
  const kinds = sortBy(programsWithUnhandledKinds.map((p) => p.kind))
  const message = `programs (${identifiers}) with unhandled kinds : ${kinds}`
  $airbrakeNotify({ error: new Error(message) })
}

export const refreshProgramsAndRewards = fetchProgramsAndRewards
