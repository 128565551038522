<template>
  <v-icon
    :size="size"
    :color="inverse ? color : 'white'"
    :style="inverse ? undefined : style"
    v-on="on"
  >
    {{ iconName }}
  </v-icon>
</template>

<script>
import MixinPaletteScanobar from '@/mixins/paletteScanobar'
import hasOwnProp from '@/utils/functions/hasOwnProp'

export default {
  mixins: [MixinPaletteScanobar],
  props: {
    inverse: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 16
    },
    event: {
      type: Object,
      required: true,
      validator: (o) =>
        hasOwnProp(o, 'identifier') &&
        hasOwnProp(o, 'is_active') &&
        hasOwnProp(o, 'is_pending') &&
        true
    },
    on: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    color() {
      return this.event.is_active ? 'primary' : this.paletteScanobar.black
    },
    style() {
      const backgroundColor = this.event.is_active
        ? this.$vuetify.theme.currentTheme.primary
        : this.paletteScanobar.black
      return { backgroundColor }
    },
    iconName() {
      if (this.event.is_pending) return 'mdi-loading mdi-spin'

      switch (this.event.identifier) {
        case 'sas_bo_api_captured_or_created_at':
          return 'mdi-camera'
        case 'sas_bo_api_upload_finished_at':
          return 'mdi-package-down'
        case 'sas_bo_api_pushed_tu_user_at':
          return 'mdi-database-check'
        case 'sas_bo_api_user_opened_at':
          return 'mdi-eye-check'
        case 'sas_bo_api_user_reviewed_at':
          return 'mdi-check-bold'
        case 'user_review_analyze':
          return 'mdi-database-check'
        case 'admin_review':
        case 'admin_review_done_by_admin':
        case 'admin_review_done_by_system':
          return 'mdi-shield-check'
        default:
          this.$airbrakeNotify({
            error: new Error(
              `unknown timestamp identifier : ${this.event.identifier}`
            )
          })
          return 'mdi-check-bold'
      }
    }
  }
}
</script>
