<template>
  <component
    :is="is"
    :disabled="disabled"
    :readonly="readonly"
    :ean="ean"
    :product="product"
  />
</template>

<script>
import { mapState } from 'vuex'
import ProductsListItemEmpty from './ProductsListItemEmpty'
import ProductsListItemProduct from './ProductsListItemProduct'
import ProductsListItemLoading from './ProductsListItemLoading'
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    ean: {
      type: String,
      required: true
    }
  },
  data: () => ({
    product: null
  }),
  computed: {
    ...mapState('products-by-ean', ['isLoading', 'productsByEan']),

    is() {
      if (this.product) {
        return ProductsListItemProduct
      }

      if (this.isLoading) {
        return ProductsListItemLoading
      }

      return ProductsListItemEmpty
    }
  },
  watch: {
    productsByEan: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.productsByEan) return

        this.product = this.productsByEan[this.ean]
      }
    }
  }
}
</script>
