<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <h3>
          {{ $t('crud.offers.edit.fields.groupedRefund.type.title') }}
        </h3>
        <v-radio-group
          :disabled="disabled"
          :readonly="readonly"
          :value="modified.groupedRefundType"
          @change="updateAttribute({ key: 'groupedRefundType', value: $event })"
        >
          <v-radio
            v-show="
              !disabled ||
                modified.groupedRefundType === GROUPED_OFFER_TYPE_PERCENT
            "
            off-icon="mdi-percent-outline"
            on-icon="mdi-percent"
            :value="GROUPED_OFFER_TYPE_PERCENT"
            :label="
              $t(
                `crud.offers.edit.fields.groupedRefund.type.label.${GROUPED_OFFER_TYPE_PERCENT}`
              )
            "
          />
          <v-radio
            v-show="
              !disabled ||
                modified.groupedRefundType === GROUPED_OFFER_TYPE_ABSOLUTE
            "
            off-icon="mdi-currency-eur"
            on-icon="mdi-currency-eur"
            :value="GROUPED_OFFER_TYPE_ABSOLUTE"
            :label="
              $t(
                `crud.offers.edit.fields.groupedRefund.type.label.${GROUPED_OFFER_TYPE_ABSOLUTE}`
              )
            "
          />
          <v-radio
            v-show="
              !disabled ||
                modified.groupedRefundType === GROUPED_OFFER_TYPE_QUANTITY
            "
            off-icon="mdi-close"
            on-icon="mdi-close"
            :value="GROUPED_OFFER_TYPE_QUANTITY"
            :label="
              $t(
                `crud.offers.edit.fields.groupedRefund.type.label.${GROUPED_OFFER_TYPE_QUANTITY}`
              )
            "
          />
        </v-radio-group>
      </v-col>
      <v-col cols="6">
        <ValidationProvider
          v-slot="{ errors }"
          name="groupedRefundQuota"
          mode="eager"
          rules="required|integer|min_value:1"
          slim
        >
          <v-text-field
            :disabled="disabled"
            :readonly="readonly"
            :label="
              $t('crud.offers.edit.fields.groupedRefund.quota.label') + ' *'
            "
            :hint="$t('crud.offers.edit.fields.groupedRefund.quota.hint')"
            persistent-hint
            required
            type="number"
            min="0"
            :error-messages="errors"
            :value="modified.groupedRefundQuota"
            @change="
              updateAttribute({ key: 'groupedRefundQuota', value: $event })
            "
          />
        </ValidationProvider>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>
              <v-icon left dark>mdi-poll</v-icon>
              {{ $t('crud.offers.edit.fields.groupedRefund.variants.label') }}
              <v-icon
                right
                dark
                @click="isVariantsHintOpen = !isVariantsHintOpen"
              >
                mdi-information-outline
              </v-icon>
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              v-show="!readonly && hasVariants"
              icon
              @click="removeLastVariantAndOpenLast"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn v-show="!readonly" icon @click="addVariantAndOpenIt">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card v-show="isVariantsHintOpen" flat>
            <v-card-text>
              <div v-for="index in 2" :key="index">
                {{
                  $t(
                    `crud.offers.edit.fields.groupedRefund.variants.hint.line${index}`
                  )
                }}
              </div>
              <ul>
                <li v-for="index in 2" :key="index">
                  {{
                    $t(
                      `crud.offers.edit.fields.groupedRefund.variants.hint.example${index}`
                    )
                  }}
                </li>
              </ul>
            </v-card-text>
          </v-card>
          <v-tabs
            v-model="tabSelectedForVariants"
            background-color="primary"
            center-active
            dark
          >
            <v-tab>
              {{
                $t('crud.offers.edit.fields.groupedRefund.variants.tabs', {
                  index: 1
                })
              }}
            </v-tab>
            <v-tab v-for="tab in tabsForVariants" :key="tab.index">
              {{ tab.title }}
            </v-tab>
          </v-tabs>
          <v-card-text>
            <v-tabs-items v-model="tabSelectedForVariants">
              <v-tab-item>
                <GroupedRefundValueAndMinItems
                  :disabled="disabled"
                  :readonly="readonly"
                  :refund-value="modified.groupedRefundValue"
                  :min-items="modified.groupedRefundMinItems"
                  @change:refund-value="
                    updateAttribute({
                      key: 'groupedRefundValue',
                      value: $event
                    })
                  "
                  @change:min-items="
                    updateAttribute({
                      key: 'groupedRefundMinItems',
                      value: $event
                    })
                  "
                />
              </v-tab-item>
              <v-tab-item v-for="tab in tabsForVariants" :key="tab.index">
                <GroupedRefundValueAndMinItems
                  :disabled="disabled"
                  :readonly="readonly"
                  :refund-value="
                    modified.groupedRefundVariants[tab.index].refundValue
                  "
                  :min-items="
                    modified.groupedRefundVariants[tab.index].minItems
                  "
                  @change:refund-value="
                    updateVariantAttribute({
                      index: tab.index,
                      key: 'refundValue',
                      value: $event
                    })
                  "
                  @change:min-items="
                    updateVariantAttribute({
                      index: tab.index,
                      key: 'minItems',
                      value: $event
                    })
                  "
                />
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>
              <v-icon left dark>mdi-barcode</v-icon>
              {{
                $t('crud.offers.edit.fields.groupedRefund.productsRanges.title')
              }}
              <v-icon
                right
                dark
                @click="isProductsRangesHintOpen = !isProductsRangesHintOpen"
              >
                mdi-information-outline
              </v-icon>
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              v-show="!readonly && twoOrMoreProductsRanges"
              icon
              @click="removeLastProductAndOpenLast"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn v-show="!readonly" icon @click="addProductAndOpenIt">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card v-show="isProductsRangesHintOpen" flat>
            <v-card-text>
              <div v-for="index in 3" :key="index">
                {{
                  $t(
                    `crud.offers.edit.fields.groupedRefund.productsRanges.hint.line${index}`
                  )
                }}
              </div>
            </v-card-text>
          </v-card>
          <v-tabs
            v-model="tabSelectedForProductsRanges"
            background-color="primary"
            center-active
            dark
          >
            <v-tab v-for="tab in tabsForProductsRanges" :key="tab.index">
              {{ tab.title }}
            </v-tab>
          </v-tabs>
          <v-card-text>
            <v-tabs-items v-model="tabSelectedForProductsRanges">
              <v-tab-item v-for="tab in tabsForProductsRanges" :key="tab.index">
                <v-card flat class="mt-2">
                  <ListAndVerifyEans
                    :disabled="disabled"
                    :readonly="readonly"
                    :eans="modified.groupedRefundProductsRanges[tab.index]"
                    eans-required
                    @change="
                      changeEansOnGroupedRefunds({
                        index: tab.index,
                        value: $event
                      })
                    "
                  />
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ValidationProvider } from 'vee-validate'

import GroupedRefundValueAndMinItems from './GroupedRefundValueAndMinItems.vue'
import ListAndVerifyEans from '~/components/program/catalog/create-modal/ListAndVerifyEans.vue'

export const GROUPED_OFFER_TYPE_PERCENT = 'PERCENTAGE'
export const GROUPED_OFFER_TYPE_QUANTITY = 'QUANTITY'
export const GROUPED_OFFER_TYPE_ABSOLUTE = 'ABSOLUTE'

export default {
  components: {
    GroupedRefundValueAndMinItems,
    ListAndVerifyEans,
    ValidationProvider
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    GROUPED_OFFER_TYPE_PERCENT,
    GROUPED_OFFER_TYPE_QUANTITY,
    GROUPED_OFFER_TYPE_ABSOLUTE,

    tabSelectedForVariants: 0,
    tabSelectedForProductsRanges: 0,

    isVariantsHintOpen: false,
    isProductsRangesHintOpen: false
  }),
  computed: {
    ...mapState('crud/offer', ['modified']),

    variantsCount() {
      return this.modified.groupedRefundVariants.length
    },
    hasVariants() {
      return this.variantsCount > 0
    },
    tabsForVariants() {
      return this.modified.groupedRefundVariants.map((_, index) => ({
        index,
        title: this.$t('crud.offers.edit.fields.groupedRefund.variants.tabs', {
          index: index + 2
        })
      }))
    },

    productsRangesCount() {
      return this.modified.groupedRefundProductsRanges.length
    },
    twoOrMoreProductsRanges() {
      return this.productsRangesCount >= 2
    },
    tabsForProductsRanges() {
      return this.modified.groupedRefundProductsRanges.map((_, index) => ({
        index,
        title: this.$t(
          'crud.offers.edit.fields.groupedRefund.productsRanges.tabs',
          { index: index + 1 }
        )
      }))
    }
  },
  methods: {
    ...mapMutations('crud/offer', [
      'updateAttribute',
      'updateVariantAttribute',

      'changeEansOnGroupedRefunds',
      'addProductToGroupedRefund',
      'removeLastProductFromGroupedRefund',

      'addVariantToGroupedRefund',
      'removeLastVariantFromGroupedRefund'
    ]),

    openLastProduct() {
      this.tabSelectedForProductsRanges = this.productsrangesCount - 1
    },
    openLastProductOnNextTick() {
      this.$nextTick(() => {
        this.openLastProduct()
      })
    },
    addProductAndOpenIt() {
      this.addProductToGroupedRefund()
      this.openLastProductOnNextTick()
    },
    removeLastProductAndOpenLast() {
      this.removeLastProductFromGroupedRefund()
      this.openLastProductOnNextTick()
    },

    openLastVariant() {
      this.tabSelectedForVariants = this.variantsCount
    },
    openLastVariantOnNextTick() {
      this.$nextTick(() => {
        this.openLastVariant()
      })
    },
    addVariantAndOpenIt() {
      this.addVariantToGroupedRefund()
      this.openLastVariantOnNextTick()
    },
    removeLastVariantAndOpenLast() {
      this.removeLastVariantFromGroupedRefund()
      this.openLastVariantOnNextTick()
    }
  }
}
</script>
