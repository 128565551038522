// https://github.com/highcharts/highcharts/issues/13885
export default (H) => {
  H.wrap(H.seriesTypes.pie.prototype, 'animate', function(proceed, _init) {
    const series = this
    const startAngleRad = series.startAngleRad

    if (this.options.reversed) {
      series.startAngleRad = series.endAngleRad
    }

    proceed.apply(this, Array.prototype.slice.call(arguments, 1))

    if (this.options.reversed) {
      series.startAngleRad = startAngleRad
    }
  })
}
