import { some, maxBy, toPairs, fromPairs } from 'lodash-es'

import hasOwnProp from './hasOwnProp'

interface PermissionOptions {
  isPurcheaseAdmin: boolean
  isScanobarAdmin: boolean
  permittedActions: Array<string>
}

const RULES = {
  '/': (_options: PermissionOptions): boolean => true,

  '/auth/login': (_options: PermissionOptions): boolean => true,
  '/auth/resetting_password': (_options: PermissionOptions): boolean => true,

  '/error': (_options: PermissionOptions): boolean => true,

  '/metrics': (options: PermissionOptions): boolean =>
    options.permittedActions.includes('metrics_read'),

  '/users/management': (options: PermissionOptions): boolean =>
    options.permittedActions.includes('user_read'),
  '/users/fraud': (options: PermissionOptions): boolean =>
    options.permittedActions.includes('user_read'),
  '/users/transfers': (options: PermissionOptions): boolean =>
    options.permittedActions.includes('transfers_read'),
  '/users/segments': (options: PermissionOptions): boolean =>
    options.permittedActions.includes('animation_read'),

  '/animation': (options: PermissionOptions): boolean =>
    options.permittedActions.includes('animation_read'),

  '/programs/rules': (options: PermissionOptions): boolean =>
    options.permittedActions.includes('content_read'),

  '/programs/management': (options: PermissionOptions): boolean =>
    options.permittedActions.includes('content_read'),

  '/programs/classification': (options: PermissionOptions): boolean =>
    options.permittedActions.includes('classification_write'),
  '/programs/classification-v2': (options: PermissionOptions): boolean =>
    options.permittedActions.includes('classification_write'),

  '/sources': (options: PermissionOptions): boolean =>
    options.permittedActions.includes('content_read'),

  '/sources/products-collection': (options: PermissionOptions): boolean =>
    options.permittedActions.includes('metrics_read'),

  '/administrators/managers': (options: PermissionOptions): boolean =>
    options.permittedActions.includes('manager_read')
}

export const createTester = (options: PermissionOptions) => {
  const rights = fromPairs(
    toPairs(RULES).map(([url, callback]) => [url, callback(options)])
  )
  const rightsEntries = Object.entries(rights)

  return {
    test(currentPath: string): boolean {
      if (hasOwnProp(rights, currentPath)) return rights[currentPath]

      // item has children
      // => check if at least one child is displayed
      const childrenRoutes = rightsEntries.filter(([path, _isAllowed]) =>
        path.startsWith(currentPath)
      )
      if (childrenRoutes.length > 0) {
        return some(childrenRoutes, ([_path, isAllowed]) => isAllowed)
      }

      // no children
      // => let's use parent item permissions
      const ancestorRoutes = rightsEntries.filter(([path, _isAllowed]) =>
        currentPath.startsWith(path)
      )
      const mostAccurateAncestorRoute = maxBy(
        ancestorRoutes,
        ([path, _isAllowed]) => path.length
      )
      if (mostAccurateAncestorRoute) {
        return mostAccurateAncestorRoute[1]
      }

      return false
    }
  }
}
