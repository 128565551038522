import { fetchFromApi } from '@/utils/store/concerns/loadable/actions'

import { handleErrorAxiosCoreStore } from '@/utils/functions/handle-errors'
import { UNKNOWN_ERROR_USER } from '@/utils/constants/errors'

export const fetch = function({ commit, state }, { userId }) {
  commit('reset')
  fetchFromApi(this, commit, `/users/${userId}/fidmarques_wallet_used`, {
    params: { limit: state.limit, page: state.page }
  })
}

export const fetchMore = async function({ commit, state }, { userId }) {
  commit('setLoadingMoreState', true)
  commit('setErrorOnFetchMore', null)

  let response
  try {
    response = await this.$axios.get(
      `/users/${userId}/fidmarques_wallet_used`,
      {
        params: { limit: state.limit, page: state.page }
      }
    )
  } catch (error) {
    handleErrorAxiosCoreStore({
      error,
      commit,
      store: this,
      loadingMutationName: 'setLoadingMoreState',
      settingErrorMutationName: 'setErrorOnFetchMore'
    })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setErrorOnFetchMore', UNKNOWN_ERROR_USER)
    commit('setLoadingMoreState', false)
    return
  }

  commit('appendData', response.data.result)
  commit('setLoadingMoreState', false)
}
