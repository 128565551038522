import { TreeNode } from '../../../classification-v2/state'

import createDefaultState, { State } from './state'

export const reset = (state: State) => {
  Object.assign(state, createDefaultState())
}

export const setDeletingNodeState = (state: State, bool: Boolean) => {
  state.isDeletingNode = bool
}

export const setCreatingNodeState = (state: State, bool: Boolean) => {
  state.isCreatingNode = bool
}
export const setRenamingNodeState = (state: State, bool: Boolean) => {
  state.isRenamingNode = bool
}
export const setMovingNodeState = (state: State, bool: Boolean) => {
  state.isMovingNode = bool
}
export const setLastBackendCallFailedState = (state: State, bool: Boolean) => {
  state.lastBackendCallFailed = bool
}

export const setEditedNodeName = (state: State, str: string) => {
  state.editedNodeName = str
}
export const editNode = (state: State, node: TreeNode) => {
  state.editedNodeId = node.id
  state.editedNodeName = node.label
}
export const resetEditedNode = (state: State) => {
  state.editedNodeId = null
  state.editedNodeName = ''
}
export const setDeletingNodeId = (state: State, id: null | number) => {
  state.deletingNodeId = id
}
