















import Vue from 'vue'
import DeclarationImage from './DeclarationImage.vue'
export default Vue.extend({
  components: { DeclarationImage },
  props: {
    subpictures: {
      type: Array,
      required: true
    }
  }
})
