export interface LoadableState {
  isLoading: boolean
  isLoaded: boolean
  error: string | null
}

export const createLoadableState = (state: any) => ({
  // to use components Loading
  // isLoading: true,
  // isLoaded: true,

  // to use components Loaded
  isLoading: false,
  isLoaded: false,

  error: null,

  ...state
})
