export function translationIndex(value) {
  const absValue = value.abs()
  if (absValue.isZero()) return 0
  if (absValue.isGreaterThanOrEqualTo(2)) return 2

  return 1
}

export default ({ app }) => {
  app.i18n.missing = function(locale, key) {
    app.$airbrakeNotify({
      error: new Error(`missing translation (locale: ${locale}, key: ${key})`)
    })
  }
}
