



































import Vue from 'vue'

export default Vue.extend({
  props: {
    reward: {
      type: Object,
      required: true
    },
    canDelete: {
      type: Boolean,
      required: false
    }
  }
})
