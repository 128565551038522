// DEPRECATED: CancelToken
// https://github.com/axios/axios/tree/2e70cecda42993e1153248f0f96715c3c55f7f39#canceltoken-deprecated

import { CANCEL_TOKEN_FOR_REPEATED_CALL } from '@/utils/constants/cancel-tokens'

export const cancelPreviousRequest = (state) => {
  state.cancelToken.cancel(CANCEL_TOKEN_FOR_REPEATED_CALL)
  state.cancelToken = null
}

export const setCancelToken = (state, source) => {
  state.cancelToken = source
}
