






















































































import Vue from 'vue'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { ValidationProvider } from 'vee-validate'
export default Vue.extend({
  components: {
    ValidationProvider
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('program/catalog', ['form']),
    ...mapGetters('program/catalog', [
      'isCreatingReward',
      'illustrationPreview',
      'backgroundImagePreview'
    ])
  },
  methods: {
    ...mapMutations('program/catalog', [
      'updateAttribute',
      'changeAdminDescription',
      'changeRewardFormattedDescription'
    ])
  }
})
