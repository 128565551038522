// import mockedData from '@/utils/mocked-data/user/profile'

import { fetchFromApi } from '@/utils/store/concerns/loadable/actions'
import { fetchFromApiMocked } from '@/utils/store/concerns/mockable/actions'

export const fetch = function({ state, commit }, { userId }) {
  if (state.isMocked) {
    fetchFromApiMocked(commit, {})
  } else {
    fetchFromApi(this, commit, `/users/${userId}/earns_and_burns`)
  }
}
