import { CancelToken } from 'axios'
import { CANCEL_TOKEN_FOR_REPEATED_CALL } from '~/utils/constants/cancel-tokens'

export default function cancelPreviousRequestAndGetNewCancelToken({ $vm }) {
  if ($vm.cancelToken) {
    $vm.cancelToken.cancel(CANCEL_TOKEN_FOR_REPEATED_CALL)
    $vm.cancelToken = null
  }
  const source = CancelToken.source()
  $vm.cancelToken = source
  return source.token
}
