<template>
  <LayoutDefault>
    <template #app-bar__right>
      <LayoutProgramSearch />
    </template>

    <template #default>
      <slot v-if="$scopedSlots.default" />
      <nuxt v-else />
    </template>
  </LayoutDefault>
</template>

<script>
import LayoutDefault from './default'
import LayoutProgramSearch from '@/components/layouts/program/Search'
export default {
  components: {
    LayoutDefault,
    LayoutProgramSearch
  }
}
</script>
