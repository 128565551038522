


























import Vue from 'vue'

export default Vue.extend({
  props: {
    rewards: {
      type: Array,
      required: true
    },
    nextRowMessage: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    selectedReward(selected: number) {
      this.$emit('reward-is-selected', selected)
    }
  }
})
