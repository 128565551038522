import createDefaultState, { State } from './state'

export * from '@/utils/store/concerns/loadable/mutations.js'

export const reset = (state: State) => {
  Object.assign(state, createDefaultState())
}

export const setData = (state: State, data: any) => {
  state.lockableScreens = data.lockable_screens
  state.lockedScreens = data.locked_screens
}
export const unlockScreen = (state: State, screen: string) => {
  const index = state.lockedScreens.indexOf(screen)
  if (index === -1) return

  state.lockedScreens.splice(index, 1)
}
export const lockScreen = (state: State, screen: string) => {
  const index = state.lockedScreens.indexOf(screen)
  if (index === -1) {
    state.lockedScreens.push(screen)
  }
}
export const setLockLoadingState = (state: State, bool: boolean) => {
  state.isTogglingLock = bool
}
