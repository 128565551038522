<template>
  <div class="pa-5">
    <v-alert v-if="isMocked" color="error" dark>
      Les données présentes sur cette page sont statiques (elles ne viennent pas
      du serveur)
    </v-alert>

    <v-card v-if="profile.has_wallet && isPurcheaseAdmin" class="mb-2">
      <v-card-actions>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-btn
                class="primary white--text"
                elevation="2"
                fab
                small
                v-bind="attrs"
                v-on="on"
                @click="isDialogOpenForWallet = true"
              >
                <v-icon>mdi-wallet</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t('pages.user.profile.earnedCoupons.title') }}</span>
        </v-tooltip>
        <ModalWallet
          v-if="isDialogOpenForWallet"
          @close="isDialogOpenForWallet = false"
        />
      </v-card-actions>
    </v-card>

    <v-card class="mb-2">
      <v-card-title v-if="noPaymentMethod">
        {{ $t('pages.user.profile.paymentMethods.none') }}
      </v-card-title>
      <v-card-title v-else>
        {{ $t('pages.user.profile.paymentMethods.title') }}
      </v-card-title>
      <v-card-text v-if="!noPaymentMethod">
        <p v-if="anyLegacyPaymentMethod">
          {{ $t('pages.user.profile.paymentMethods.hintForLegacy') }}
        </p>
        <v-list three-line>
          <PaymentMethodShow
            v-for="paymentMethod in profile.payment_methods"
            :key="paymentMethod.uid"
            :payment-method="paymentMethod"
          />
        </v-list>
      </v-card-text>
      <v-card-actions v-if="hasWriteUserRight">
        <v-dialog v-model="isDialogOpenForAddingPaymentMethod" width="600">
          <template #activator="{ on, attrs }">
            <v-btn color="primary" text v-bind="attrs" v-on="on">
              {{
                $t(
                  'pages.user.profile.paymentMethods.addOrReplacePaymentMethod'
                )
              }}
            </v-btn>
          </template>

          <PaymentMethodAdd
            v-if="isDialogOpenForAddingPaymentMethod"
            @close="isDialogOpenForAddingPaymentMethod = false"
          />
        </v-dialog>
      </v-card-actions>
    </v-card>

    <v-card class="mb-2">
      <v-card-title>
        {{ $t('pages.user.profile.potAndTransfers.title') }}
      </v-card-title>
      <v-card-text>
        <div>
          {{ $t('pages.user.profile.pot.currentAmount') }} :
          {{
            profile.pot_and_transfers.total_current_amount
              | money({ suffix: ' €' })
          }}
        </div>
        <div>
          {{ $t('pages.user.profile.pot.cumulatedAmount') }} :
          {{
            profile.pot_and_transfers.total_cumulated_amount
              | money({ suffix: ' €' })
          }}
        </div>
      </v-card-text>
      <v-card-actions>
        <TransferButtonAndModal />
      </v-card-actions>
    </v-card>

    <v-card
      v-for="(benefit_by_period, index) in profile.benefits_by_period"
      :key="benefit_by_period.identifier"
      class="mb-2"
    >
      <v-card-title>
        {{ benefit_by_period.title }}

        <v-btn
          v-if="benefit_by_period.benefits.length > 1"
          icon
          @click="toggleBenefitByPeriod(index)"
        >
          <v-icon>
            {{ tabs[index] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-show="tabs[index]">
        <div v-if="benefit_by_period.benefits.length === 0">
          {{ benefit_by_period.empty_placeholder }}
        </div>
        <div v-else>
          <BenefitsTable :benefits="benefit_by_period.benefits" />
        </div>
      </v-card-text>
    </v-card>

    <v-card v-if="profile.special_operations" class="mb-2">
      <v-card-title>
        {{ $t('pages.user.profile.specialOperations') }}
      </v-card-title>
      <v-card-text>
        <v-list two-line>
          <v-list-item
            v-for="item in profile.special_operations"
            :key="item.identifier"
          >
            <v-list-item-avatar tile>
              <v-img :src="item.image_url" alt="item.image_alt" contain />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ item.status }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { some } from 'lodash-es'

import BenefitsTable from './BenefitsTable'
import ModalWallet from './wallet/Modal'

import PaymentMethodShow from './payment-methods/Show.vue'
import PaymentMethodAdd from './payment-methods/Add.vue'

import TransferButtonAndModal from '@/components/features/user/profile/TransferButtonAndModal'

export const PAYMENT_KIND_IBAN = 'IBAN'
export const PAYMENT_KIND_LYDIA = 'Lydia'
export const PAYMENT_KIND_PAYPAL = 'PayPal'
export const PAYMENT_KINDS = [
  PAYMENT_KIND_IBAN,
  PAYMENT_KIND_LYDIA,
  PAYMENT_KIND_PAYPAL
]
export const ALLOWED_PAYMENT_KINDS = [
  PAYMENT_KIND_IBAN,
  // PAYMENT_KIND_LYDIA, // only on scano, and no more scano...
  PAYMENT_KIND_PAYPAL
]

export default {
  components: {
    PaymentMethodShow,
    PaymentMethodAdd,
    BenefitsTable,
    TransferButtonAndModal,
    ModalWallet
  },
  data: () => ({
    isDialogOpenForWallet: false,
    isDialogOpenForAddingPaymentMethod: false,
    tabs: []
  }),
  computed: {
    ...mapGetters('auth', ['isPurcheaseAdmin', 'hasWriteUserRight']),
    ...mapState('user/profile', ['isMocked', 'profile']),

    noPaymentMethod() {
      return this.profile.payment_methods.length === 0
    },
    anyLegacyPaymentMethod() {
      return some(this.profile.payment_methods, (pm) => pm.is_legacy)
    }
  },
  watch: {
    profile: {
      immediate: true,
      handler() {
        if (this.profile.benefits_by_period.length === 0) {
          this.tabs = []
          return
        }

        const fill = this.profile.benefits_by_period[0].benefits.length < 2
        this.tabs = new Array(this.profile.benefits_by_period.length).fill(fill)
      }
    }
  },
  methods: {
    toggleBenefitByPeriod(index) {
      this.$set(this.tabs, index, !this.tabs[index])
    }
  }
}
</script>
