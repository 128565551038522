<template>
  <div>
    <IntersectionObserver
      @intersect="fetchMore({ userId: $route.params.userId })"
    />

    <template v-if="errorOnFetchMore">
      <v-progress-linear value="100" color="red" />
      <DisplayError
        :error="errorOnFetchMore"
        retriable
        @retry="fetchMore({ userId: $route.params.userId })"
      />
    </template>
    <template v-else>
      {{
        $t('pages.user.profile.earnedCoupons.used.fetchMore') | capitalize
      }}...
      <v-progress-linear indeterminate color="cyan" />
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import DisplayError from '@/components/layouts/DisplayError'
import IntersectionObserver from '@/components/layouts/IntersectionObserver'
export default {
  components: { DisplayError, IntersectionObserver },
  computed: {
    ...mapState('user/profile/wallet/used', ['errorOnFetchMore'])
  },
  methods: {
    ...mapActions('user/profile/wallet/used', ['fetchMore'])
  }
}
</script>
