import Vue from 'vue'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import highchartsMore from 'highcharts/highcharts-more'

import Accessibility from 'highcharts/modules/accessibility'
import Drilldown from 'highcharts/modules/drilldown'
import Exporting from 'highcharts/modules/exporting'
import ExportData from 'highcharts/modules/export-data'

import Maps from 'highcharts/modules/map'

import funnel from 'highcharts/modules/funnel'
import venn from 'highcharts/modules/venn'

import fixDrawEmpty from './highcharts/fix-draw-empty'
import reversedPie from './highcharts/reversed-pie'

Vue.use(HighchartsVue)

// frontend
// if (typeof Highcharts === 'object') {
if (process.client) {
  highchartsMore(Highcharts)
  Accessibility(Highcharts)
  Drilldown(Highcharts)
  Exporting(Highcharts)
  ExportData(Highcharts)

  Maps(Highcharts)

  fixDrawEmpty(Highcharts)
  reversedPie(Highcharts)

  funnel(Highcharts)
  venn(Highcharts)

  Highcharts.setOptions({
    credits: {
      enabled: false
    },
    colors: ['#1c91ff', '#34a234', '#f4d829', '#e98514', '#e63b0e', '#7b47a1'],

    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'downloadPNG',
            'downloadJPEG',
            'downloadSVG',
            'downloadPDF',
            'separator',
            'downloadCSV',
            'downloadXLS'
          ]
        }
      }
    }
  })
}

function translations(locale) {
  switch (locale) {
    case 'fr':
      return {
        downloadPNG: 'Télécharger en image (PNG)',
        downloadJPEG: 'Télécharger en image (JPEG)',
        downloadSVG: 'Télécharger en image vectorielle (SVG)',
        downloadPDF: 'Télécharger en PDF',
        downloadCSV: 'Télécharger en CSV',
        downloadXLS: 'Télécharger en XLS',
        drillUpText: '◁ Retour'
      }
    case 'en':
      return defaultLang
    default:
      return defaultLang
  }
}

function updateLocale(locale) {
  Highcharts.setOptions({
    lang: translations(locale)
  })
}

const defaultLang = {
  downloadPNG: 'Download PNG image',
  downloadJPEG: 'Download JPEG image',
  downloadSVG: 'Download SVG vector image',
  downloadPDF: 'Download PDF document',
  downloadCSV: 'Download CSV',
  downloadXLS: 'Download XLS',
  drillUpText: '◁ Back'
}

export default ({ app }) => {
  if (process.server) return

  updateLocale(app.i18n.locale)
  app.i18n.onLanguageSwitched = (_oldLocale, newLocale) => {
    updateLocale(newLocale)
  }
}
