<template>
  <v-overlay v-if="isScreenLocked" absolute>
    <div>
      <v-icon small>mdi-lock</v-icon>
      {{ $t('pages.user.app.screenLocked') }}

      <v-btn
        v-if="hasWriteUserRight"
        :loading="isTogglingLock"
        primary
        small
        class="ml-5"
        @click="unlockScreen({ screen })"
      >
        <v-icon small left>mdi-lock-open-variant</v-icon>
        {{ $t('global.unlock') }}
      </v-btn>
    </div>
  </v-overlay>
  <v-alert
    v-else
    border="left"
    colored-border
    type="info"
    elevation="2"
    icon="mdi-lock-open-variant"
    class="ma-3"
  >
    {{ $t('pages.user.app.screenUnlocked') }}

    <!-- <v-btn
      v-if="hasWriteUserRight"
      :loading="isTogglingLock"
      primary
      small
      class="ml-5"
      @click="lockScreen({ screen })"
    >
      <v-icon small left>mdi-lock</v-icon>
      {{ $t('global.lock') }}
    </v-btn> -->
  </v-alert>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
export default {
  props: {
    screen: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', { hasWriteUserRight: 'hasWriteUserRight' }),
    ...mapState('user/app', {
      isTogglingLock: 'isTogglingLock'
    }),
    ...mapGetters('user/app', {
      computeIsScreenLocked: 'computeIsScreenLocked'
    }),
    isScreenLocked() {
      return this.computeIsScreenLocked(this.screen)
    }
  },
  methods: {
    ...mapActions('user/app', ['lockScreen', 'unlockScreen'])
  }
}
</script>
