import { HOME } from '~/utils/constants/routes'

export default (ctx) => {
  if (!ctx.store.getters['auth/isLogged']) return

  if (ctx.route.query.r) {
    ctx.redirect(ctx.route.query.r)
  } else {
    ctx.redirect(HOME)
  }
}
