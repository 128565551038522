import hasOwnProp from './hasOwnProp'

export default function(series) {
  return series.map((serie) => ({
    ...serie,
    data: serie.data.map((point) => {
      let res
      if (hasOwnProp(point, 'y')) {
        res = { ...point }
      } else {
        res = { y: point }
      }

      if (res.y > 10) {
        // if pie share is big enough,
        // display dataLabel on top of section
        res.dataLabels = { distance: '-40%' }
      } else {
        // display outside of the pie otherwize
        res.dataLabels = { distance: '10%' }
      }

      return res
    })
  }))
}
