<template>
  <v-speed-dial
    v-model="isFabMenuOpen"
    class="close-to-bottom-left"
    absolute
    bottom
    left
    direction="top"
    transition="slide-y-reverse-transition"
    @click.native.stop
  >
    <template #activator>
      <v-btn v-model="isFabMenuOpen" small dark fab>
        <v-icon v-if="isFabMenuOpen">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-cog
        </v-icon>
      </v-btn>
    </template>

    <TheSettingsSingleLink
      v-for="link in links"
      :key="link.key"
      :link="link"
      dial
    />
  </v-speed-dial>
</template>

<script>
import TheSettingsSingleLink from '@/components/layouts/TheSettingsSingleLink'
export default {
  components: {
    TheSettingsSingleLink
  },
  props: {
    links: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    isFabMenuOpen: false
  })
}
</script>

<style scoped>
.close-to-bottom-left {
  left: 8px;
  bottom: 8px;
}
</style>
