import iban from 'iban'
import { compact, every, some, isNil } from 'lodash-es'
import { configure, extend } from 'vee-validate'
import {
  confirmed,
  double,
  email,
  integer,
  min,
  max,
  min_value, // eslint-disable-line camelcase
  max_value, // eslint-disable-line camelcase
  between,
  numeric,
  required
} from 'vee-validate/dist/rules'

import eansTexteareaToArray from '~/utils/functions/forms/eansTexteareaToArray'

extend('confirmed', confirmed)
extend('double', double)
extend('email', email)
extend('integer', integer)
extend('min', min)
extend('max', max)
extend('min_value', min_value)
extend('max_value', max_value)
extend('numeric', numeric)
extend('between', between)
extend('required', required)

extend('eans', (value) => {
  const eans = eansTexteareaToArray(value)
  if (every(eans, (ean) => ean.match(/^\d+$/))) {
    return true
  }

  return null // 'this value must be a comma-separated list of EANs (only digits)'
})

extend('at_least_one_product_required', (productsCount) => {
  if (productsCount > 0) {
    return true
  }

  return null // 'at least one product is required'
})

extend('all_products_exist', (productsByEan) => {
  if (some(productsByEan, (val) => isNil(val))) {
    return null // 'some products do not exist, you must create them'
  }

  return true
})

extend('all_products_are_valid', (productsByEan) => {
  const products = compact(Object.values(productsByEan))
  if (every(products, (p) => p.name && p.brand_identifier)) {
    return true
  }

  return null // 'some products are not valid, you must update them'
})

extend('xor', {
  params: ['other'],
  validate: (value, { other }) => {
    // valid formula but not really explicit : !!value ^ !!other
    if (value && other) return false

    return !!(value || other)
  }
})

extend('image_squared', (imageSizes) => {
  return imageSizes.width === imageSizes.height
})
extend('image_min_size', {
  params: ['min'],
  validate: (imageSizes, { min }) => {
    const minSize = parseInt(min, 10)

    return imageSizes.width >= minSize && imageSizes.height >= minSize
  }
})
extend('image_max_width', {
  params: ['max'],
  validate: (imageSizes, { max }) => {
    const maxSize = parseInt(max, 10)

    return imageSizes.width <= maxSize
  }
})
extend('image_width', {
  params: ['width'],
  validate: (imageSizes, { width }) => {
    const widthSize = parseInt(width, 10)

    return imageSizes.width === widthSize
  }
})
extend('image_height', {
  params: ['height'],
  validate: (imageSizes, { height }) => {
    const heightSize = parseInt(height, 10)

    return imageSizes.height === heightSize
  }
})

extend('iban', (text) => iban.isValid(text) || null)

extend('json', (text) => {
  try {
    JSON.parse(text)
    return true
  } catch (e) {
    return false
  }
})

// https://vee-validate.logaretm.com/v3/guide/localization.html#using-other-i18n-libraries
// TODO: may be be needed to translate "target"
// https://github.com/logaretm/vee-validate/blob/0a47674ebccfc42772408afaba2f45056afcae77/locale/fr.json#L27
export default (ctx) => {
  configure({
    defaultMessage: (_, values) =>
      ctx.app.i18n.t(`validations.rules.${values._rule_}`, {
        ...values
        // neither "_field_" nor "field" are used in the translations
        // _field_: ctx.app.i18n.t(`validations.fields.${values._field_}`)
      })
  })
}
