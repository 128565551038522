import { createLoadableState } from '@/utils/store/concerns/loadable/state'
import { createCancellableState } from '@/utils/store/concerns/cancellable/state'

export default () =>
  createCancellableState(
    createLoadableState({
      categories: null,
      series: null
    })
  )
