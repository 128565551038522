export default {
  experimental: 'Timeline expérimentale',
  timelineIsEmpty: 'aucun message',
  table: {
    headers: {
      author: 'Auteur',
      title: 'Titre',
      content: 'Contenu',
      category: 'Catégorie',
      created_at: 'Date de création'
    }
  },
  buttons: {
    sendMessage: 'Envoyer un message'
  },
  sendMessage: {
    title: 'Titre',
    content: 'Contenu',
    messageSent: 'message envoyé'
  }
}
