import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"elevation":"2"}},[_c(VToolbar,[_c(VIcon,[_vm._v("\n      mdi-drag-horizontal-variant\n    ")]),_vm._v(" "),_c(VToolbarTitle,[_c(VSkeletonLoader,{staticClass:"mt-1-5 px-3",attrs:{"type":"text","width":"200"}})],1),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-5"},[_c(VSwitch,{attrs:{"value":true,"color":"#FFF","inset":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c(VSkeletonLoader,{staticClass:"mt-1-5",attrs:{"type":"text","width":"50"}})]},proxy:true}])})],1),_vm._v(" "),_c('div',{staticClass:"mr-5"},[_c(VSwitch,{attrs:{"value":true,"color":"#FFF","inset":"","hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c(VSkeletonLoader,{staticClass:"mt-1-5",attrs:{"type":"text","width":"50"}})]},proxy:true}])})],1)]),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c('div',[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-content-save")])],1),_vm._v(" "),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v("mdi-delete")])],1),_vm._v(" "),(_vm.hasWriteContentRight)?_c('AddRewardLoading'):_vm._e()],1)],1),_vm._v(" "),_c(VCardText,[_c('div',{staticClass:"d-flex flex-nowrap overflow-x-auto"},_vm._l(([0, 1, 2]),function(index){return _c('RewardItemLoading',{key:index})}),1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }