































































































import Vue from 'vue'
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { capitalize, find } from 'lodash-es'

import SlidePage1 from './SlidePage1.vue'
import SlidePage2 from './SlidePage2.vue'
import SlidePage3 from './SlidePage3.vue'
import SlidePage4 from './SlidePage4.vue'

import { fetchCoreFromComponentAndHandleError } from '@/utils/functions/fetch-and-handle-errors'
import toFormData from '@/utils/functions/toFormData'

export default Vue.extend({
  components: {
    ValidationObserver,
    SlidePage1,
    SlidePage2,
    SlidePage3,
    SlidePage4
  },
  props: {
    reward: {
      type: Object,
      default: null
    },
    duplicate: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isSaving: false,
    currentStep: 1
  }),
  computed: {
    ...mapState('program/catalog', { form: 'form' }),
    ...mapGetters('program/catalog', {
      isCreatingReward: 'isCreatingReward',
      payloadForBackend: 'payloadForBackend'
    }),

    stepsCount(): number {
      return this.form.kind === 'donation' ? 3 : 4
    }
  },
  created() {
    if (this.reward) {
      if (this.duplicate) {
        this.duplicateReward(this.reward)
      } else {
        this.initializeWithReward(this.reward)
      }
    } else {
      this.initializeWithDefault()
    }
  },
  methods: {
    ...mapMutations('program/catalog', [
      'initializeWithReward',
      'initializeWithDefault',
      'duplicateReward'
    ]),
    ...mapActions('program/catalog', ['fetchRewards']),
    ...mapMutations('snackbar', ['addToast']),

    goToNext(): void {
      this.validateCurrentStep().then((success) => {
        if (!success) return
        if (this.stepsCount > this.currentStep) this.currentStep += 1
      })
    },
    goToPrev(): void {
      if (this.currentStep > 1) this.currentStep -= 1
    },
    nameOfStep(idx: number): string {
      switch (idx) {
        case 1:
          return this.$t('pages.program.catalog.modalNewReward.step1') as string
        case 2:
          return this.$t(
            `pages.program.catalog.modalNewReward.step2.${this.form.kind}`
          ) as string
        case 3:
          return this.$t(
            `pages.program.catalog.modalNewReward.step3.${this.form.kind}`
          ) as string
        case 4:
          return this.$t(
            `pages.program.catalog.modalNewReward.step4.${this.form.kind}`
          ) as string
        default:
          return ''
      }
    },
    async validateButtonAction(): Promise<any> {
      const success = await this.validateCurrentStep()
      if (!success) return

      const action = this.isCreatingReward
        ? 'create_loyalty_reward'
        : 'update_loyalty_reward'
      return fetchCoreFromComponentAndHandleError({
        $vm: this,
        isLoadingKey: 'isSaving',
        errorInToast: true,

        axios: {
          url: `/programs/${this.$route.params.programIdentifier}/${action}`,
          method: 'POST',
          data: toFormData(this.payloadForBackend),
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        },

        onSuccess: () => {
          const action = this.isCreatingReward ? 'created' : 'updated'
          this.addToast({
            text: capitalize(
              this.$t(
                `pages.program.catalog.changeRewards.modal.reward.${action}`
              ) as string
            ),
            timeout: 4 * 1000
          })

          this.fetchRewards()
          this.$emit('close')
        },
        onErrorFrontend: (response: any) => {
          const error = find(
            response.data.errors,
            (e) => e.error_code === 'REWARD_ALREADY_REQUESTED'
          )
          if (!error) return

          this.addToast({
            text: this.$t(
              `pages.program.catalog.changeRewards.modal.reward.alreadyRequested`
            ) as string,
            timeout: 16 * 1000
          })
          this.isSaving = false
          return true
        }
      })
    },
    validateCurrentStep() {
      return (this.$refs[`validator-step-${this.currentStep}`] as InstanceType<
        typeof ValidationObserver
      >).validate()
    }
  }
})
