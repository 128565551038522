<template>
  <v-tabs align-with-title :background-color="paletteScanobar.black">
    <v-tab v-for="tab in tabs" :key="tab.identifier" nuxt :to="tab.to">
      {{ tab.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
import MixinPaletteScanobar from '@/mixins/paletteScanobar'

export const TAB_EMAILS = 'emails'
export const TAB_MESSAGES = 'messages'

export const TABS = [TAB_EMAILS, TAB_MESSAGES]

export const DEFAULT_TAB = TAB_EMAILS

export default {
  mixins: [MixinPaletteScanobar],
  computed: {
    tabs() {
      return TABS.map((tab) => ({
        identifier: tab,
        to: `/animation/messages/${tab}`,
        title: this.$t(`layout.animation.messages.tabs.${tab}`)
      }))
    }
  }
}
</script>
