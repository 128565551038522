import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/actions.js'), 'actions.js')
  resolveStoreModules(require('../store/state.ts'), 'state.ts')
  resolveStoreModules(require('../store/analytics-jobs/state.ts'), 'analytics-jobs/state.ts')
  resolveStoreModules(require('../store/auth/actions.js'), 'auth/actions.js')
  resolveStoreModules(require('../store/auth/getters.js'), 'auth/getters.js')
  resolveStoreModules(require('../store/auth/mutations.js'), 'auth/mutations.js')
  resolveStoreModules(require('../store/auth/state.ts'), 'auth/state.ts')
  resolveStoreModules(require('../store/brands-by-id/actions.ts'), 'brands-by-id/actions.ts')
  resolveStoreModules(require('../store/brands-by-id/getters.ts'), 'brands-by-id/getters.ts')
  resolveStoreModules(require('../store/brands-by-id/mutations.ts'), 'brands-by-id/mutations.ts')
  resolveStoreModules(require('../store/brands-by-id/state.ts'), 'brands-by-id/state.ts')
  resolveStoreModules(require('../store/layout/mutations.js'), 'layout/mutations.js')
  resolveStoreModules(require('../store/layout/state.js'), 'layout/state.js')
  resolveStoreModules(require('../store/user/state.js'), 'user/state.js')
  resolveStoreModules(require('../store/analytics-jobs/mutations.ts'), 'analytics-jobs/mutations.ts')
  resolveStoreModules(require('../store/user/getters.js'), 'user/getters.js')
  resolveStoreModules(require('../store/user/actions.js'), 'user/actions.js')
  resolveStoreModules(require('../store/program/mutations.js'), 'program/mutations.js')
  resolveStoreModules(require('../store/snackbar/state.js'), 'snackbar/state.js')
  resolveStoreModules(require('../store/snackbar/mutations.js'), 'snackbar/mutations.js')
  resolveStoreModules(require('../store/program/state.js'), 'program/state.js')
  resolveStoreModules(require('../store/products-by-ean/actions.ts'), 'products-by-ean/actions.ts')
  resolveStoreModules(require('../store/products-by-ean/getters.ts'), 'products-by-ean/getters.ts')
  resolveStoreModules(require('../store/products-by-ean/mutations.ts'), 'products-by-ean/mutations.ts')
  resolveStoreModules(require('../store/products-by-ean/state.ts'), 'products-by-ean/state.ts')
  resolveStoreModules(require('../store/program/actions.js'), 'program/actions.js')
  resolveStoreModules(require('../store/analytics-jobs/actions.ts'), 'analytics-jobs/actions.ts')
  resolveStoreModules(require('../store/program/getters.js'), 'program/getters.js')
  resolveStoreModules(require('../store/user/mutations.js'), 'user/mutations.js')
  resolveStoreModules(require('../store/layout/user/mutations.js'), 'layout/user/mutations.js')
  resolveStoreModules(require('../store/layout/user/state.js'), 'layout/user/state.js')
  resolveStoreModules(require('../store/user/timeline/mutations.js'), 'user/timeline/mutations.js')
  resolveStoreModules(require('../store/user/timeline/getters.js'), 'user/timeline/getters.js')
  resolveStoreModules(require('../store/user/timeline/actions.js'), 'user/timeline/actions.js')
  resolveStoreModules(require('../store/crud/managers/getters.js'), 'crud/managers/getters.js')
  resolveStoreModules(require('../store/user/search/state.js'), 'user/search/state.js')
  resolveStoreModules(require('../store/user/search/mutations.js'), 'user/search/mutations.js')
  resolveStoreModules(require('../store/metrics/canals/getters.js'), 'metrics/canals/getters.js')
  resolveStoreModules(require('../store/user/search/getters.js'), 'user/search/getters.js')
  resolveStoreModules(require('../store/user/search/actions.js'), 'user/search/actions.js')
  resolveStoreModules(require('../store/user/profile/state.js'), 'user/profile/state.js')
  resolveStoreModules(require('../store/metrics/program/actions.js'), 'metrics/program/actions.js')
  resolveStoreModules(require('../store/user/profile/mutations.js'), 'user/profile/mutations.js')
  resolveStoreModules(require('../store/user/profile/actions.js'), 'user/profile/actions.js')
  resolveStoreModules(require('../store/crud/managers/mutations.js'), 'crud/managers/mutations.js')
  resolveStoreModules(require('../store/crud/managers/state.js'), 'crud/managers/state.js')
  resolveStoreModules(require('../store/user/declarations/state.js'), 'user/declarations/state.js')
  resolveStoreModules(require('../store/user/declarations/mutations.js'), 'user/declarations/mutations.js')
  resolveStoreModules(require('../store/user/declarations/getters.js'), 'user/declarations/getters.js')
  resolveStoreModules(require('../store/user/declarations/actions.js'), 'user/declarations/actions.js')
  resolveStoreModules(require('../store/user/declaration/state.js'), 'user/declaration/state.js')
  resolveStoreModules(require('../store/user/declaration/mutations.js'), 'user/declaration/mutations.js')
  resolveStoreModules(require('../store/user/declaration/getters.js'), 'user/declaration/getters.js')
  resolveStoreModules(require('../store/user/declaration/actions.js'), 'user/declaration/actions.js')
  resolveStoreModules(require('../store/user/app/state.ts'), 'user/app/state.ts')
  resolveStoreModules(require('../store/user/app/mutations.ts'), 'user/app/mutations.ts')
  resolveStoreModules(require('../store/user/app/getters.ts'), 'user/app/getters.ts')
  resolveStoreModules(require('../store/user/app/actions.ts'), 'user/app/actions.ts')
  resolveStoreModules(require('../store/crud/messages/getters.js'), 'crud/messages/getters.js')
  resolveStoreModules(require('../store/crud/messages/mutations.js'), 'crud/messages/mutations.js')
  resolveStoreModules(require('../store/crud/messages/state.js'), 'crud/messages/state.js')
  resolveStoreModules(require('../store/crud/offer/getters.js'), 'crud/offer/getters.js')
  resolveStoreModules(require('../store/crud/offer/mutations.js'), 'crud/offer/mutations.js')
  resolveStoreModules(require('../store/program/stamps/state.ts'), 'program/stamps/state.ts')
  resolveStoreModules(require('../store/program/stamps/mutations.ts'), 'program/stamps/mutations.ts')
  resolveStoreModules(require('../store/program/stamps/actions.ts'), 'program/stamps/actions.ts')
  resolveStoreModules(require('../store/program/search/state.js'), 'program/search/state.js')
  resolveStoreModules(require('../store/program/search/mutations.js'), 'program/search/mutations.js')
  resolveStoreModules(require('../store/program/search/getters.js'), 'program/search/getters.js')
  resolveStoreModules(require('../store/program/search/actions.js'), 'program/search/actions.js')
  resolveStoreModules(require('../store/program/rules/state.js'), 'program/rules/state.js')
  resolveStoreModules(require('../store/program/rules/mutations.js'), 'program/rules/mutations.js')
  resolveStoreModules(require('../store/program/rules/getters.js'), 'program/rules/getters.js')
  resolveStoreModules(require('../store/program/rules/actions.js'), 'program/rules/actions.js')
  resolveStoreModules(require('../store/program/renewal-strategy/state.js'), 'program/renewal-strategy/state.js')
  resolveStoreModules(require('../store/program/renewal-strategy/mutations.js'), 'program/renewal-strategy/mutations.js')
  resolveStoreModules(require('../store/program/renewal-strategy/getters.js'), 'program/renewal-strategy/getters.js')
  resolveStoreModules(require('../store/program/renewal-strategy/actions.js'), 'program/renewal-strategy/actions.js')
  resolveStoreModules(require('../store/program/puzzle/state.ts'), 'program/puzzle/state.ts')
  resolveStoreModules(require('../store/metrics/program/getters.js'), 'metrics/program/getters.js')
  resolveStoreModules(require('../store/program/puzzle/mutations.ts'), 'program/puzzle/mutations.ts')
  resolveStoreModules(require('../store/program/puzzle/actions.ts'), 'program/puzzle/actions.ts')
  resolveStoreModules(require('../store/program/precisions/state.ts'), 'program/precisions/state.ts')
  resolveStoreModules(require('../store/program/precisions/mutations.ts'), 'program/precisions/mutations.ts')
  resolveStoreModules(require('../store/program/precisions/getters.ts'), 'program/precisions/getters.ts')
  resolveStoreModules(require('../store/program/precisions/actions.ts'), 'program/precisions/actions.ts')
  resolveStoreModules(require('../store/metrics/program/mutations.js'), 'metrics/program/mutations.js')
  resolveStoreModules(require('../store/metrics/program/state.js'), 'metrics/program/state.js')
  resolveStoreModules(require('../store/crud/offer/state.js'), 'crud/offer/state.js')
  resolveStoreModules(require('../store/crud/products-collections/getters.js'), 'crud/products-collections/getters.js')
  resolveStoreModules(require('../store/crud/products-collections/mutations.js'), 'crud/products-collections/mutations.js')
  resolveStoreModules(require('../store/crud/products-collections/state.js'), 'crud/products-collections/state.js')
  resolveStoreModules(require('../store/user/timeline/state.js'), 'user/timeline/state.js')
  resolveStoreModules(require('../store/program/catalog/actions.js'), 'program/catalog/actions.js')
  resolveStoreModules(require('../store/program/catalog/getters.js'), 'program/catalog/getters.js')
  resolveStoreModules(require('../store/program/catalog/mutations.js'), 'program/catalog/mutations.js')
  resolveStoreModules(require('../store/program/points/state.ts'), 'program/points/state.ts')
  resolveStoreModules(require('../store/crud/managers/actions.js'), 'crud/managers/actions.js')
  resolveStoreModules(require('../store/program/points/mutations.ts'), 'program/points/mutations.ts')
  resolveStoreModules(require('../store/program/points/actions.ts'), 'program/points/actions.ts')
  resolveStoreModules(require('../store/program/catalog/state.js'), 'program/catalog/state.js')
  resolveStoreModules(require('../store/program/classification-v2/actions.ts'), 'program/classification-v2/actions.ts')
  resolveStoreModules(require('../store/program/classification-v2/getters.ts'), 'program/classification-v2/getters.ts')
  resolveStoreModules(require('../store/program/classification-v2/mutations.ts'), 'program/classification-v2/mutations.ts')
  resolveStoreModules(require('../store/program/classification-v2/state.ts'), 'program/classification-v2/state.ts')
  resolveStoreModules(require('../store/program/create/actions.js'), 'program/create/actions.js')
  resolveStoreModules(require('../store/program/create/getters.js'), 'program/create/getters.js')
  resolveStoreModules(require('../store/program/create/mutations.js'), 'program/create/mutations.js')
  resolveStoreModules(require('../store/program/create/state.js'), 'program/create/state.js')
  resolveStoreModules(require('../store/program/descriptions/actions.ts'), 'program/descriptions/actions.ts')
  resolveStoreModules(require('../store/program/descriptions/mutations.ts'), 'program/descriptions/mutations.ts')
  resolveStoreModules(require('../store/program/descriptions/state.ts'), 'program/descriptions/state.ts')
  resolveStoreModules(require('../store/layout/user/actions.js'), 'layout/user/actions.js')
  resolveStoreModules(require('../store/program/import-file-with-eans/getters.js'), 'program/import-file-with-eans/getters.js')
  resolveStoreModules(require('../store/program/import-file-with-eans/mutations.js'), 'program/import-file-with-eans/mutations.js')
  resolveStoreModules(require('../store/program/import-file-with-eans/state.js'), 'program/import-file-with-eans/state.js')
  resolveStoreModules(require('../store/layout/user/getters.js'), 'layout/user/getters.js')
  resolveStoreModules(require('../store/user/app/timeline/getters.js'), 'user/app/timeline/getters.js')
  resolveStoreModules(require('../store/program/catalog/shelf/state.js'), 'program/catalog/shelf/state.js')
  resolveStoreModules(require('../store/program/catalog/shelf/actions.js'), 'program/catalog/shelf/actions.js')
  resolveStoreModules(require('../store/metrics/canals/general/actions.js'), 'metrics/canals/general/actions.js')
  resolveStoreModules(require('../store/metrics/canals/general/mutations.js'), 'metrics/canals/general/mutations.js')
  resolveStoreModules(require('../store/metrics/canals/general/state.js'), 'metrics/canals/general/state.js')
  resolveStoreModules(require('../store/metrics/canals/points-of-sale/actions.js'), 'metrics/canals/points-of-sale/actions.js')
  resolveStoreModules(require('../store/metrics/canals/points-of-sale/mutations.js'), 'metrics/canals/points-of-sale/mutations.js')
  resolveStoreModules(require('../store/metrics/canals/points-of-sale/state.js'), 'metrics/canals/points-of-sale/state.js')
  resolveStoreModules(require('../store/metrics/program/general/state.js'), 'metrics/program/general/state.js')
  resolveStoreModules(require('../store/metrics/program/general/mutations.js'), 'metrics/program/general/mutations.js')
  resolveStoreModules(require('../store/metrics/program/general/actions.js'), 'metrics/program/general/actions.js')
  resolveStoreModules(require('../store/user/profile/pot/state.js'), 'user/profile/pot/state.js')
  resolveStoreModules(require('../store/user/profile/pot/mutations.js'), 'user/profile/pot/mutations.js')
  resolveStoreModules(require('../store/user/profile/pot/mutation-types.js'), 'user/profile/pot/mutation-types.js')
  resolveStoreModules(require('../store/user/profile/pot/getters.js'), 'user/profile/pot/getters.js')
  resolveStoreModules(require('../store/user/profile/pot/actions.js'), 'user/profile/pot/actions.js')
  resolveStoreModules(require('../store/user/profile/earnsAndBurns/state.js'), 'user/profile/earnsAndBurns/state.js')
  resolveStoreModules(require('../store/user/profile/earnsAndBurns/mutations.js'), 'user/profile/earnsAndBurns/mutations.js')
  resolveStoreModules(require('../store/user/profile/earnsAndBurns/actions.js'), 'user/profile/earnsAndBurns/actions.js')
  resolveStoreModules(require('../store/user/app/timeline/state.js'), 'user/app/timeline/state.js')
  resolveStoreModules(require('../store/user/app/timeline/mutations.js'), 'user/app/timeline/mutations.js')
  resolveStoreModules(require('../store/program/catalog/shelf/mutations.js'), 'program/catalog/shelf/mutations.js')
  resolveStoreModules(require('../store/user/app/timeline/actions.js'), 'user/app/timeline/actions.js')
  resolveStoreModules(require('../store/program/catalog/shelf/getters.js'), 'program/catalog/shelf/getters.js')
  resolveStoreModules(require('../store/program/sources/products/tree/mutations.ts'), 'program/sources/products/tree/mutations.ts')
  resolveStoreModules(require('../store/metrics/canals/charts/onboarding/actions.js'), 'metrics/canals/charts/onboarding/actions.js')
  resolveStoreModules(require('../store/metrics/canals/charts/onboarding/mutations.js'), 'metrics/canals/charts/onboarding/mutations.js')
  resolveStoreModules(require('../store/metrics/program/charts/retention/state.js'), 'metrics/program/charts/retention/state.js')
  resolveStoreModules(require('../store/metrics/program/charts/retention/mutations.js'), 'metrics/program/charts/retention/mutations.js')
  resolveStoreModules(require('../store/metrics/program/charts/retention/actions.js'), 'metrics/program/charts/retention/actions.js')
  resolveStoreModules(require('../store/metrics/canals/charts/onboarding/state.js'), 'metrics/canals/charts/onboarding/state.js')
  resolveStoreModules(require('../store/metrics/program/individual-behaviour/gms/state.js'), 'metrics/program/individual-behaviour/gms/state.js')
  resolveStoreModules(require('../store/metrics/program/individual-behaviour/gms/mutations.js'), 'metrics/program/individual-behaviour/gms/mutations.js')
  resolveStoreModules(require('../store/metrics/program/individual-behaviour/gms/actions.js'), 'metrics/program/individual-behaviour/gms/actions.js')
  resolveStoreModules(require('../store/metrics/program/individual-behaviour/bars/state.js'), 'metrics/program/individual-behaviour/bars/state.js')
  resolveStoreModules(require('../store/metrics/program/individual-behaviour/bars/mutations.js'), 'metrics/program/individual-behaviour/bars/mutations.js')
  resolveStoreModules(require('../store/user/profile/wallet/used/state.js'), 'user/profile/wallet/used/state.js')
  resolveStoreModules(require('../store/program/sources/products/tree/getters.ts'), 'program/sources/products/tree/getters.ts')
  resolveStoreModules(require('../store/program/sources/products/tree/actions.ts'), 'program/sources/products/tree/actions.ts')
  resolveStoreModules(require('../store/user/profile/wallet/used/mutations.js'), 'user/profile/wallet/used/mutations.js')
  resolveStoreModules(require('../store/user/profile/wallet/used/actions.js'), 'user/profile/wallet/used/actions.js')
  resolveStoreModules(require('../store/user/profile/wallet/pending/state.js'), 'user/profile/wallet/pending/state.js')
  resolveStoreModules(require('../store/metrics/program/charts/individual-behaviour/state.js'), 'metrics/program/charts/individual-behaviour/state.js')
  resolveStoreModules(require('../store/metrics/program/charts/individual-behaviour/mutations.js'), 'metrics/program/charts/individual-behaviour/mutations.js')
  resolveStoreModules(require('../store/metrics/program/charts/individual-behaviour/getters.js'), 'metrics/program/charts/individual-behaviour/getters.js')
  resolveStoreModules(require('../store/metrics/program/charts/individual-behaviour/actions.js'), 'metrics/program/charts/individual-behaviour/actions.js')
  resolveStoreModules(require('../store/metrics/program/charts/activity/state.js'), 'metrics/program/charts/activity/state.js')
  resolveStoreModules(require('../store/metrics/program/charts/activity/mutations.js'), 'metrics/program/charts/activity/mutations.js')
  resolveStoreModules(require('../store/metrics/program/charts/activity/getters.js'), 'metrics/program/charts/activity/getters.js')
  resolveStoreModules(require('../store/metrics/program/charts/activity/actions.js'), 'metrics/program/charts/activity/actions.js')
  resolveStoreModules(require('../store/program/sources/products/tree/state.ts'), 'program/sources/products/tree/state.ts')
  resolveStoreModules(require('../store/metrics/program/charts/activity-shares/mutations.js'), 'metrics/program/charts/activity-shares/mutations.js')
  resolveStoreModules(require('../store/user/profile/wallet/pending/mutations.js'), 'user/profile/wallet/pending/mutations.js')
  resolveStoreModules(require('../store/user/profile/wallet/pending/actions.js'), 'user/profile/wallet/pending/actions.js')
  resolveStoreModules(require('../store/metrics/program/individual-behaviour/bars/actions.js'), 'metrics/program/individual-behaviour/bars/actions.js')
  resolveStoreModules(require('../store/metrics/program/charts/activity-shares/actions.js'), 'metrics/program/charts/activity-shares/actions.js')
  resolveStoreModules(require('../store/metrics/program/charts/activity-shares/state.js'), 'metrics/program/charts/activity-shares/state.js')
  resolveStoreModules(require('../store/metrics/program/charts/sales/per-region/mutations.js'), 'metrics/program/charts/sales/per-region/mutations.js')
  resolveStoreModules(require('../store/metrics/program/charts/sales/per-retailer/actions.js'), 'metrics/program/charts/sales/per-retailer/actions.js')
  resolveStoreModules(require('../store/metrics/program/charts/sales/per-retailer/mutations.js'), 'metrics/program/charts/sales/per-retailer/mutations.js')
  resolveStoreModules(require('../store/metrics/program/charts/sales/per-retailer/state.js'), 'metrics/program/charts/sales/per-retailer/state.js')
  resolveStoreModules(require('../store/metrics/program/charts/sales/per-region/actions.js'), 'metrics/program/charts/sales/per-region/actions.js')
  resolveStoreModules(require('../store/metrics/program/charts/sales/gms/state.js'), 'metrics/program/charts/sales/gms/state.js')
  resolveStoreModules(require('../store/metrics/program/charts/sales/gms/mutations.js'), 'metrics/program/charts/sales/gms/mutations.js')
  resolveStoreModules(require('../store/metrics/program/charts/mixity/bars/actions.js'), 'metrics/program/charts/mixity/bars/actions.js')
  resolveStoreModules(require('../store/metrics/program/charts/sales/gms/actions.js'), 'metrics/program/charts/sales/gms/actions.js')
  resolveStoreModules(require('../store/metrics/program/charts/sales/per-region/state.js'), 'metrics/program/charts/sales/per-region/state.js')
  resolveStoreModules(require('../store/metrics/program/charts/mixity/bars/getters.js'), 'metrics/program/charts/mixity/bars/getters.js')
  resolveStoreModules(require('../store/metrics/program/charts/mixity/bars/mutations.js'), 'metrics/program/charts/mixity/bars/mutations.js')
  resolveStoreModules(require('../store/metrics/program/charts/mixity/bars/state.js'), 'metrics/program/charts/mixity/bars/state.js')
  resolveStoreModules(require('../store/metrics/program/charts/mixity/gms/actions.js'), 'metrics/program/charts/mixity/gms/actions.js')
  resolveStoreModules(require('../store/metrics/program/charts/mixity/gms/getters.js'), 'metrics/program/charts/mixity/gms/getters.js')
  resolveStoreModules(require('../store/metrics/program/charts/mixity/gms/mutations.js'), 'metrics/program/charts/mixity/gms/mutations.js')
  resolveStoreModules(require('../store/metrics/program/charts/mixity/gms/state.js'), 'metrics/program/charts/mixity/gms/state.js')
  resolveStoreModules(require('../store/metrics/program/charts/sales/bars/actions.js'), 'metrics/program/charts/sales/bars/actions.js')
  resolveStoreModules(require('../store/metrics/program/charts/sales/bars/mutations.js'), 'metrics/program/charts/sales/bars/mutations.js')
  resolveStoreModules(require('../store/metrics/program/charts/sales/bars/state.js'), 'metrics/program/charts/sales/bars/state.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const storeModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(storeModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
