import {
  handleAxiosErrorServerFromStoreV3,
  handleAxiosErrorFrontendFromStoreV3
} from '@/utils/functions/handle-errors'

import { HOME_PROGRAMS } from '@/utils/constants/routes'

export async function fetchParents({ state, commit }) {
  if (state.isLoadingParents) return

  commit('setParentsLoadingState', true)
  commit('resetErrorOnParents')

  let response
  try {
    response = await this.$axios.get('/programs')
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setParentsLoadingState',
      mutationNameForSettingError: 'setErrorOnParents'
    })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStoreV3({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setParentsLoadingState',
      mutationNameForSettingError: 'setErrorOnParents'
    })
    return
  }

  commit('setParents', response.data.result.programs)
  commit('setParentsLoadingState', false)
}

export async function fetchClients({ state, commit }) {
  if (state.isLoadingClients) return

  commit('setClientsLoadingState', true)
  commit('resetErrorOnClients')

  let response
  try {
    response = await this.$axios.get('/clients/index')
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setClientsLoadingState',
      mutationNameForSettingError: 'setErrorOnClients'
    })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStoreV3({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setClientsLoadingState',
      mutationNameForSettingError: 'setErrorOnClients'
    })
    return
  }

  commit('setClients', response.data.result.clients)
  commit('setClientsLoadingState', false)
}

export async function fetchPartners({ state, commit }) {
  if (state.isLoadingfetchPartners) return

  commit('setPartnersLoadingState', true)
  commit('resetErrorOnPartners')

  let response
  try {
    response = await this.$axios.get('/partners/index')
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setPartnersLoadingState',
      mutationNameForSettingError: 'setErrorOnPartners'
    })
    return
  }

  if (!response.data.success) {
    handleAxiosErrorFrontendFromStoreV3({
      response,
      commit,
      store: this,
      mutationNameForLoadingState: 'setPartnersLoadingState',
      mutationNameForSettingError: 'setErrorOnPartners'
    })
    return
  }

  commit('setPartners', response.data.result.partners)
  commit('setPartnersLoadingState', false)
}

export async function createNewProgram({ state, getters, commit, dispatch }) {
  if (state.isCreating) return

  commit('setCreatingState', true)

  let response
  try {
    response = await this.$axios.post(
      '/programs/create',
      getters.formattedPayloadForBackend,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  } catch (error) {
    handleAxiosErrorServerFromStoreV3({
      error,
      commit,
      store: this,
      mutationNameForLoadingState: 'setCreatingState',
      errorInToast: true
    })
    return
  }

  if (!response.data.success) {
    this.$airbrakeNotifyUnhandledBadRequest({ response })
    commit('setCreatingState', false)
    commit(
      'snackbar/addToast',
      { text: this.$i18n.t('pages.program.catalog.failedRequest') },
      { root: true }
    )
    return
  }

  commit(
    'snackbar/addToast',
    { text: this.$i18n.t('pages.program.catalog.successedRequest') },
    { root: true }
  )
  await dispatch('program/search/refreshPrograms', null, { root: true })
  this.$router.push(
    `${HOME_PROGRAMS}/${response.data.result.program.identifier}`
  )
  commit('setCreatingState', false)
  commit('resetForm')
}
