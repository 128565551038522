import coreOrigin from '../../../utils/constants/origin-core'

export const sendResetLink = async function({ commit, state }) {
  commit('setLoadingState', true)
  let response
  try {
    response = await this.$axios.post(
      'accounts/reset_password',
      {
        email: state.modified.email
      },
      { baseURL: coreOrigin }
    )
  } catch (error) {
    return
  }

  if (!response.data.success) {
    commit('setLoadingState', false)
    return
  }

  commit('setLoadingState', false)
}
