<template>
  <v-list three-line dense>
    <template v-for="(ean, index) in eans">
      <ProductsListItem
        :key="`${ean}-item`"
        :disabled="disabled"
        :readonly="readonly"
        :ean="ean"
      />
      <v-divider
        v-if="index !== length - 1"
        :key="`${ean}-divider`"
        horizontal
        dark
      />
    </template>
  </v-list>
</template>

<script>
import ProductsListItem from './ProductsListItem'
export default {
  components: {
    ProductsListItem
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    eans: {
      type: Array,
      required: true
    }
  },
  computed: {
    length() {
      return this.eans.length
    }
  }
}
</script>
