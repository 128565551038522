export const MODES = ['multi-line', 'vertical']
export const X_VALUES = ['left', 'right']
export const Y_VALUES = ['top', 'bottom']

export default () => ({
  active: false,
  text: '',
  color: null,
  mode: null,
  x: null,
  y: 'bottom',
  timeout: null
})
