















































import { mapState, mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'
import SelectReward from './SelectReward.vue'
import CatalogCurrentReward from './CatalogCurrentReward.vue'

export default Vue.extend({
  components: { SelectReward, CatalogCurrentReward },
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    newReward: {},
    defaultReward: {}
  }),
  computed: {
    ...mapState('program/catalog', [
      'newLoyaltyRewards',
      'newLoyaltyRewardsModel'
    ]),
    ...mapGetters('program/catalog', ['loyaltyRewardToChange'])
  },
  methods: {
    ...mapMutations('program/catalog', [
      'addNewLoyaltyReward',
      'deleteNewLoyaltyReward'
    ]),

    deleteReward() {
      this.deleteNewLoyaltyReward(
        this.newLoyaltyRewards.indexOf(this.newReward)
      )
      this.$nextTick(() => {
        this.newReward = Object.assign({}, {})
      })
    },
    deleteRowRewards() {
      if (Object.keys(this.newReward).length > 0) {
        this.deleteReward()
      }
      this.$emit('row-is-deleted', this.loyaltyRewardToChange(this.index))
    },
    addRewardToChange(rewardId: number) {
      const index = this.newLoyaltyRewardsModel.findIndex(
        (r: any) => r.loyalty_reward.id === rewardId
      )
      this.newReward = this.newLoyaltyRewardsModel[index] as Object
      this.addNewLoyaltyReward(this.newReward)
    }
  }
})
