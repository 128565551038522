<template>
  <LayoutUser>
    <v-container fluid>
      <v-card>
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          color="white"
          background-color="orange"
        />
        <v-toolbar
          dark
          color="orange"
          src="https://cdn2.fidmarques.com/production/custom_landing_pages/assets/background-fidmarques@2x.png"
        >
          <v-tabs v-model="tabSelected" optional centered>
            <v-tab
              v-for="tab in tabs"
              :key="tab.key"
              nuxt
              :to="tab.to"
              :tab-value="tab.key"
            >
              <v-img
                :src="tab.src"
                :alt="tab.title"
                :height="size"
                :width="size"
                contain
              />
            </v-tab>
          </v-tabs>
        </v-toolbar>

        <v-card tile class="min-height-100px">
          <ScreenLockStatus v-if="screenCanBeLocked" :screen="screen" />

          <v-card-text>
            <nuxt />
          </v-card-text>
        </v-card>
      </v-card>
    </v-container>
  </LayoutUser>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import LayoutUser from '../user'
import ScreenLockStatus from '~/components/features/user/app/ScreenLockStatus.vue'
import {
  SCREEN_MISC,
  SCREEN_COUPONS,
  SCREEN_TIMELINE,
  SCREEN_CARDS,
  SCREEN_POT
} from '~/store/user/app/state.ts'
export default {
  components: {
    LayoutUser,
    ScreenLockStatus
  },
  data: () => ({
    tabSelected: SCREEN_TIMELINE
  }),
  computed: {
    ...mapGetters('user', ['routeBasePath']),
    ...mapState('user/app', ['isLoading', 'isLoaded', 'error']),
    ...mapGetters('user/app', ['computeScreenCanBeLocked']),

    size() {
      return 36
    },
    tabs() {
      return [
        {
          key: SCREEN_MISC,
          to: `${this.routeBasePath}/app/misc`,
          src: require('~/assets/icons/user/app/misc/misc@3x.png'),
          title: this.$t('layout.user.app.tabs.misc')
        },
        {
          key: SCREEN_COUPONS,
          to: `${this.routeBasePath}/app/coupons`,
          src: require('~/assets/icons/user/app/coupons/coupons@3x.png'),
          title: this.$t('layout.user.app.tabs.coupons')
        },
        {
          key: SCREEN_TIMELINE,
          to: `${this.routeBasePath}/app/timeline`,
          src: require('~/assets/icons/user/app/timeline/feed@3x.png'),
          title: this.$t('layout.user.app.tabs.timeline')
        },
        {
          key: SCREEN_CARDS,
          to: `${this.routeBasePath}/app/cards`,
          src: require('~/assets/icons/user/app/cards/cards@3x.png'),
          title: this.$t('layout.user.app.tabs.cards')
        },
        {
          key: SCREEN_POT,
          to: `${this.routeBasePath}/app/pot`,
          src: require('~/assets/icons/user/app/pot/pot@3x.png'),
          title: this.$t('layout.user.app.tabs.pot')
        }
      ]
    },
    screen() {
      return this.tabSelected
    },
    screenCanBeLocked() {
      return this.computeScreenCanBeLocked(this.screen)
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    ...mapActions('user/app', ['fetch'])
  }
}
</script>

<style scoped>
.min-height-100px {
  min-height: 100px;
}
</style>
