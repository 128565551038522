<template>
  <div class="scopes">
    <ScopesCheckbox
      v-for="scope in scopes"
      :key="scope.identifier"
      :scope="scope"
      :is-selected="scope.identifier === currentScopeIdentifier"
      class="mr-2"
      @selected="$emit('change', $event)"
    />
  </div>
</template>

<script>
import ScopesCheckbox from './ScopesCheckbox'
export default {
  components: { ScopesCheckbox },
  props: {
    scopes: {
      type: Array,
      required: true
    },
    currentScopeIdentifier: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.scopes {
  text-align: center;
  line-height: 50px;
  margin-bottom: 18px;
}
</style>
