import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"scrollable":""},on:{"click:outside":function($event){_vm.isOpen = false}},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[(_vm.isLoading)?_c(VCard,{attrs:{"color":"primary","dark":""}},[_c(VCardText,[_vm._v("\n      "+_vm._s(_vm.$t('global.loading'))+"...\n      "),_c(VProgressLinear,{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1):(_vm.error)?_c(VCard,{attrs:{"color":"primary","dark":""}},[_c('div',{staticClass:"pa-3 d-flex align-center justify-center"},[_c('DisplayError',{attrs:{"error":_vm.error,"retriable":""},on:{"retry":function($event){return _vm.fetch({ userId: _vm.$route.params.userId })}}})],1)]):(!_vm.isLoaded)?_c(VCard,{attrs:{"color":"primary","dark":""}}):_c(VCard,[_c(VCardTitle,[_vm._v("\n      "+_vm._s(_vm.programName)+"\n\n      "),(_vm.cashbackFrozenReady)?[_c(VSpacer),_vm._v(" "),_c(VSwitch,{staticClass:"v-input--reverse",attrs:{"disabled":_vm.isTogglingCashbackFrozen,"loading":_vm.isTogglingCashbackFrozen,"label":_vm.$t(
              _vm.isCashbackFrozen
                ? 'pages.user.profile.earnsAndBurns.isCashbackFrozen.on'
                : 'pages.user.profile.earnsAndBurns.isCashbackFrozen.off'
            ),"error-messages":_vm.togglingErrorMessages,"success-messages":_vm.togglingSuccessMessages},model:{value:(_vm.isCashbackFrozen),callback:function ($$v) {_vm.isCashbackFrozen=$$v},expression:"isCashbackFrozen"}})]:_vm._e()],2),_vm._v(" "),_c(VDivider),_vm._v(" "),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.HEADERS,"items":_vm.items,"disable-pagination":"","hide-default-footer":"","locale":_vm.$i18n.locale},scopedSlots:_vm._u([{key:"header.amountEarned",fn:function(){return [_vm._v("\n          "+_vm._s(_vm.$t('pages.user.profile.earnsAndBurns.headers.amountEarned'))+"\n          "),_c('span',{staticClass:"error--text"},[_c('br'),_vm._v("\n            ("+_vm._s(_vm.$t('pages.user.profile.earnsAndBurns.headers.withoutCapping'))+")\n          ")])]},proxy:true},{key:"item.origin",fn:function(ref){
            var item = ref.item;
return [_c('BenefitModalItemOrigin',{attrs:{"item":item}}),_vm._v(" "),(item.origin_details && item.origin_details.length > 0)?_c('br'):_vm._e(),_vm._v(" "),(item.origin_details && item.origin_details.length > 0)?_c('span',[_vm._v("\n            (\n            "),_vm._l((item.origin_details),function(detail,index){return _c('span',{key:index},[_c('BenefitModalItemDetail',{attrs:{"detail":detail}}),_vm._v(" "),(index !== item.origin_details.length - 1)?_c('span',[_vm._v(",")]):_vm._e()],1)}),_vm._v("\n            )\n          ")],2):_vm._e()]}},{key:"item.datetime",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v("\n            "+_vm._s(_vm._f("momentFormat")(item.datetime,'human-long'))+"\n          ")])]}},{key:"item.amountEarned",fn:function(ref){
            var item = ref.item;
return [(item.amountEarned)?_c('BenefitsTableValue',{attrs:{"program-kind":_vm.programKind,"value":item.amountEarned}}):_vm._e(),_vm._v(" "),(item.amount_without_capping)?_c('span',{staticClass:"error--text"},[_c('br'),_vm._v("\n            (\n            "),_c('BenefitsTableValue',{attrs:{"program-kind":_vm.programKind,"value":item.amount_without_capping}}),_vm._v("\n            )\n          ")],1):_vm._e()]}},{key:"item.amountBurned",fn:function(ref){
            var item = ref.item;
return [(item.amountBurned)?_c('BenefitsTableValue',{attrs:{"program-kind":_vm.programKind,"value":item.amountBurned}}):_vm._e()]}},{key:"item.balance",fn:function(ref){
            var item = ref.item;
return [_c('BenefitsTableValue',{attrs:{"program-kind":_vm.programKind,"value":item.balance}})]}}])})],1),_vm._v(" "),_c(VDivider),_vm._v(" "),_c(VCardActions,[_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v("\n        "+_vm._s(_vm.$t('global.close'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }