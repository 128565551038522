<template>
  <v-dialog v-model="isDialogOpen" :width="width">
    <template #activator="{ on, attrs }">
      <v-btn elevation="2" icon v-bind="attrs" v-on="on">
        <v-icon>mdi-file-upload</v-icon>
      </v-btn>
    </template>

    <v-card v-if="isDialogOpen">
      <v-card-title class="text-h5 primary white--text">
        {{ $t('layout.program.importProducts.title') }}
        <v-spacer />
        <v-btn icon dark @click="isDialogOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-stepper v-model="currentStep">
        <v-stepper-header>
          <template v-for="n in stepsCount">
            <v-stepper-step
              :key="`stepper-header-${n}`"
              :complete="currentStep > n"
              :editable="currentStep > n"
              :step="n"
            >
              {{ titles[n] }}
            </v-stepper-step>
            <v-divider v-if="n !== stepsCount" :key="`stepper-divider-${n}`" />
          </template>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content
            v-for="n in stepsCount"
            :key="`stepper-content-${n}`"
            :step="n"
          >
            <component
              :is="`Step${n}`"
              @close="isDialogOpen = false"
              @go-to-prev-step="currentStep -= 1"
              @go-to-next-step="currentStep += 1"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { fromPairs } from 'lodash-es'

import * as steps from './steps'

import StepperHeader from '@/components/features/stepper/StepperHeader.vue'
import StepperItems from '@/components/features/stepper/StepperItems.vue'

export default {
  components: {
    ...steps,

    ValidationObserver,
    ValidationProvider,

    StepperHeader,
    StepperItems
  },

  data: () => ({
    isDialogOpen: false,

    currentStep: 1,
    stepsCount: 2
  }),

  computed: {
    titles() {
      return fromPairs(
        [...Array(this.stepsCount).keys()].map((i) => [
          i + 1,
          this.$t(`layout.program.importProducts.wizard.steps.${i + 1}`)
        ])
      )
    },

    width() {
      return this.currentStep === 1 ? 500 : 1000
    }
  },

  watch: {
    isDialogOpen() {
      if (!this.isDialogOpen) {
        this.reset()
        this.currentStep = 1
      }
    }
  },

  methods: {
    ...mapMutations('program/import-file-with-eans', ['reset'])
  }
}
</script>
