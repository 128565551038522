





















































































































import Vue from 'vue'
import { mapState, mapMutations, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { capitalize } from 'lodash-es'

import {
  PAYMENT_KIND_IBAN,
  PAYMENT_KIND_LYDIA,
  PAYMENT_KIND_PAYPAL,
  ALLOWED_PAYMENT_KINDS
} from '../Loaded.vue'

import { fetchCoreFromComponentAndHandleError } from '@/utils/functions/fetch-and-handle-errors'

interface App {
  id: number
  name: string
}

export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    capitalize,
    PAYMENT_KINDS: ALLOWED_PAYMENT_KINDS,

    form: {
      editorId: null,
      kind: null as null | string,
      identifier: null,
      userFirstName: null,
      userLastName: null
    },

    isSubmitting: false
  }),
  computed: {
    ...mapState('user', {
      isLoadingUser: 'isLoading',
      user: 'user'
    }),

    apps(): App[] {
      return this.user ? this.user.apps : []
    },

    paymentMethodIdentifierRules(): string {
      switch (this.form.kind) {
        case PAYMENT_KIND_IBAN:
          return 'required|iban'
        case PAYMENT_KIND_LYDIA:
          return 'required'
        case PAYMENT_KIND_PAYPAL:
          return 'required|email'
      }

      return 'required'
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (!this.user) return

        this.form.userFirstName = this.user.first_name
        this.form.userLastName = this.user.last_name
      }
    }
  },
  methods: {
    ...mapMutations('snackbar', ['addToast']),
    ...mapMutations('user/profile', ['upsertPaymentMethodByAppName']),
    ...mapActions('user', ['refreshUserFromRoute']),

    submit(): Promise<void> {
      return fetchCoreFromComponentAndHandleError({
        $vm: this,

        isLoadingKey: 'isSubmitting',
        errorInToast: true,

        axios: {
          url: `/users/${this.$route.params.userId}/create_payment_method`,
          method: 'POST',
          data: {
            editor_id: this.form.editorId,
            kind: this.form.kind,
            identifier: this.form.identifier,
            user_first_name: this.form.userFirstName,
            user_last_name: this.form.userLastName
          }
        },

        onSuccess: (result: any) => {
          this.addToast({
            text: this.$t(
              'pages.user.profile.paymentMethods.paymentMethodAdded'
            ),
            timeout: 4000
          })
          this.$emit('close')
          this.upsertPaymentMethodByAppName(result.payment_method)
          // user names could have been updated
          // so we need to reload the user
          this.refreshUserFromRoute()
        }
      })
    }
  }
})
