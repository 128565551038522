export const SERVER_NOT_REACHABLE = 'SERVER_NOT_REACHABLE'
export const SERVER_ERROR = 'SERVER_ERROR'

export const UNAUTHORIZED = 'UNAUTHORIZED'

export const UNKNOWN_ERROR_SERVER = 'UNKNOWN_ERROR_SERVER'
export const UNKNOWN_ERROR_USER = 'UNKNOWN_ERROR_USER'

export const STATUS_ERROR_FOR_USER = 450
export const STATUS_ERROR_CORE = 550
export const STATUS_ERROR_ANALYTICS = 551

export const PAYLOAD_TOO_BIG = 'PAYLOAD_TOO_BIG'

// defined by core

// auth
export const UNKNOWN_EMAIL_OR_PASSWORD = 'UNKNOWN_EMAIL_OR_PASSWORD'
export const ACCESS_REVOKED = 'ACCESS_REVOKED'

// import by file
export const INVALID_CONTENT_TYPE = 'INVALID_CONTENT_TYPE'
export const MISSING_HEADER = 'MISSING_HEADER'
