import { createLoadableState } from '@/utils/store/concerns/loadable/state'
import { createMockableState } from '@/utils/store/concerns/mockable/state'

export default () =>
  createLoadableState(
    createMockableState({
      isSearching: true,
      loyaltyRewardsRows: [],
      rewardsWithoutShelf: [],

      isModalOpenForAddingShelf: false,
      isCreatingShelf: false
    })
  )
