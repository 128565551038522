export default () => ({
  isPotLoading: true,
  isPotLoaded: false,
  isFetchingMore: false,
  pot: null,
  potRefunds: [],
  otherAppPotRefunds: [],
  transferPayments: [],
  previousTransferPaymentId: null,
  error: null
})
