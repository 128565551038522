<template>
  <v-container fluid>
    <v-card>
      <v-tabs v-model="currentTab">
        <v-tab v-for="tab in tabs" :key="tab.key" :to="'#' + tab.key">
          {{ tab.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTab">
        <v-tab-item :value="TAB_REWARDS">
          <v-container fluid>
            <CatalogShow />
          </v-container>
        </v-tab-item>
        <v-tab-item :value="TAB_SHELVES">
          <v-container fluid>
            <RewardsShelves />
          </v-container>
        </v-tab-item>
        <v-tab-item :value="TAB_CHANGE_CATALOG">
          <v-container fluid>
            <CatalogChange />
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import CatalogShow from '~/components/program/catalog/CatalogShow.vue'
import RewardsShelves from '@/components/program/catalog/shelf/Shelf.vue'
import CatalogChange from '~/components/program/catalog/CatalogChange.vue'

export const TAB_REWARDS = 'rewards'
export const TAB_SHELVES = 'shelves'
export const TAB_CHANGE_CATALOG = 'change_catalog'
export const tabs = [TAB_REWARDS, TAB_SHELVES, TAB_CHANGE_CATALOG]
export const defaultTab = TAB_REWARDS

export default {
  layout: 'default/program',
  middleware: ['programReader'],

  components: {
    CatalogShow,
    RewardsShelves,
    CatalogChange
  },
  data() {
    return {
      TAB_REWARDS,
      TAB_SHELVES,
      TAB_CHANGE_CATALOG,

      currentTab: this.tabFromRoute
    }
  },
  computed: {
    tabFromRoute() {
      return this.$route.hash && this.$route.hash.replace(/^#/, '')
    },
    tabs() {
      return tabs.map((tab) => ({
        title: this.$t(`pages.program.catalog.tabs.title.${tab}`),
        key: tab
      }))
    }
  },
  mounted() {
    if (this.tabFromRoute && tabs.includes(this.tabFromRoute)) return

    this.$router.push({ hash: defaultTab })
  }
}
</script>
