import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"5"}},[(_vm.loyaltyRewardToChange(_vm.index))?_c('CatalogCurrentReward',{attrs:{"reward":_vm.loyaltyRewardToChange(_vm.index)}}):_vm._e()],1),_vm._v(" "),_c(VCol,{attrs:{"cols":"1"}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VIcon,{attrs:{"large":""}},[_vm._v("\n          mdi-arrow-right\n        ")])],1)],1),_vm._v(" "),_c(VCol,{attrs:{"cols":"5"}},[(_vm.newReward.loyalty_reward)?_c('CatalogCurrentReward',{attrs:{"reward":_vm.newReward,"can-delete":""},on:{"delete-reward":_vm.deleteReward}}):_c('SelectReward',{attrs:{"rewards":_vm.newLoyaltyRewardsModel},on:{"reward-is-selected":_vm.addRewardToChange}})],1),_vm._v(" "),_c(VCol,{attrs:{"cols":"1"}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({attrs:{"large":""},on:{"click":_vm.deleteRowRewards}},'v-icon',attrs,false),on),[_vm._v("\n              mdi-delete\n            ")])]}}])},[_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.$t('pages.program.catalog.changeRewards.deleteRowTooltip'))+"\n          ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }