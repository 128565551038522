import moment from 'moment'
import {
  camelCase,
  compact,
  filter,
  groupBy,
  orderBy,
  uniq,
  uniqBy
} from 'lodash-es'

const DATE_FIELDS = ['date_time']
// const DATE_FIELDS = ['captured_at', 'created_at', 'paid_at']

export const isEmpty = (state) => state.isLoaded && state.events.length === 0

export const allEventKinds = (state) =>
  uniq(state.events.map((event) => event.kind))
export const anySelectedKind = (state) => state.selectedKinds.length > 0
export const noSelectedKind = (state) => state.selectedKinds.length === 0

export const allPrograms = (state) =>
  orderBy(
    uniqBy(
      compact(state.events.map((event) => event.data.program)),
      'identifier'
    ),
    'name'
  )
export const anyProgram = (_, getters) => getters.allPrograms.length > 0
export const noProgram = (_, getters) => getters.allPrograms.length === 0

export const allProgramIdentifiers = (_, getters) =>
  getters.allPrograms.map((p) => p.identifier)
export const anySelectedProgram = (state) =>
  state.selectedProgramIdentifiers.length > 0

export const displayFetchMore = (state, getters) =>
  state.hasMore &&
  getters.anySelectedKind &&
  (getters.noProgram || getters.anySelectedProgram)

export const eventsHydrated = (state) =>
  state.events.map((event) => {
    const res = { ...event }
    DATE_FIELDS.forEach((key) => {
      res[camelCase(key)] = event[key] && moment(event[key])
    })
    res.date = res.dateTime.format(moment.HTML5_FMT.DATE)
    return res
  })

export const eventsSortedGroupedByDate = (_, getters) =>
  groupBy(
    orderBy(getters.eventsHydrated, ['date', 'dateTime'], ['desc', 'desc']),
    'date'
  )

export const eventsSortedFiltered = (state, getters) =>
  filter(getters.eventsHydrated, (e) => {
    if (!state.selectedKinds.includes(e.kind)) return false
    if (!e.data.program) return true
    return state.selectedProgramIdentifiers.includes(e.data.program.identifier)
  })

export const eventsSortedFilteredGroupedByDate = (_, getters) =>
  groupBy(
    orderBy(
      getters.eventsSortedFiltered,
      ['date', 'dateTime'],
      ['desc', 'desc']
    ),
    'date'
  )
