


























































import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

export interface Reward {
  id: string
  name: string
  imageUrl: string
  points: number
  expiredAt: null | Date
  availableAt: string
  isAvailable: boolean
  isExpired: boolean
  isActive: boolean
}

export default Vue.extend({
  props: {
    reward: {
      type: Object as () => Reward,
      required: true
    },
    rowId: {
      type: String,
      required: false,
      default: 'none'
    },
    inShelf: {
      type: Boolean,
      required: false,
      default: true
    },
    showInactiveRewards: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('auth', { selectedProgram: 'selectedProgram' }),

    availabilityText(): string {
      if (this.reward.expiredAt && this.reward.isExpired) {
        const expiredAt = moment(this.reward.expiredAt)
        return this.$t('pages.program.catalog.reward.hasExpiredSince', {
          date: expiredAt.format('DD/MM/YYYY'),
          time: expiredAt.format('HH[h]mm')
        }) as string
      }

      if (this.reward.availableAt) {
        const availableAt = moment(this.reward.availableAt)
        return this.$t('pages.program.catalog.reward.availableSince', {
          date: availableAt.format('DD/MM/YYYY'),
          time: availableAt.format('HH[h]mm')
        }) as string
      }

      return ''
    }
  },
  methods: {
    ...mapActions('program/catalog/shelf', ['removeShelfFromReward'])
  }
})
