export default {
  methods: {
    resetData() {
      const initialState = this.$options.data.call(this)
      Object.keys(initialState).map((key) => {
        this.$set(this.$data, key, initialState[key])
      })
    }
  }
}
